import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, bindable, bindingMode, containerless, customElement } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as Constants from '../../../../constants';
import { Zeus } from '../../../../generated';
import { FilterQueryOp, Predicate } from 'breeze-client';

@customElement('location-selected-list')
@autoinject
@containerless
export class LocationSelectedList extends ListViewModelBase<Zeus.Web.Model.Location> {
  @bindable({ defaultBindingMode: bindingMode.toView })
  private locationSelected: Array<Zeus.Web.Model.Location>;
  @bindable()
  private isRangeLocationMode: boolean = false;

  public ressourceName: string = Constants.EntityTypeNames.Location;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location));
    this.service.gridDataSource.expands = [
      'storage', 'dedicatedToRotationLevel', 'storageLocationType', 'defaultStorageVolume.volumeType',
      'articleLocations.article.unitOfMeasures.unitOfMeasure',
      'articleLocations.article.articleVolumeConfigs.storageVolume.volumeType',
      'articleLocations.article.articleLocations'];
  }
  public bind() {
    let predicate = Predicate.or(this.locationSelected.map(x => new Predicate('id', FilterQueryOp.Equals, x.id)));
    this.service.gridDataSource.getPredicatesFunction = () => predicate;
    this.initializeGridOptions();
  }

  public async attached() {
    await super.attached();
    this.routeName = "print-location-list";
    this.selectAll = true;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("storage.identifier"),
        field: "locationIdentifier",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("storage.width"),
        field: "storageUnitWidth",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.length"),
        field: "storageUnitLength",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.height"),
        field: "storageUnitHeight",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("location.dedicatedToRotationLevelId"),
        field: "dedicatedToRotationLevel.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ArticleRotationLevel,
        },
      },
      {
        headerName: this.i18n.tr("storage.storageLocationTypeId"),
        field: "storageLocationType.denomination._translation",
        hide: true,
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.StorageLocationType
        }
      },
      {
        headerName: this.i18n.tr("storage.zoneName"),
        field: "storage.storageGroup.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.zoneGroupName"),
        field: "storage.storageGroup.parentStorageGroup.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.warehouseName"),
        field: "storage.storageGroup.parentStorageGroup.parentStorageGroup.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.siteName"),
        field: "storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.isUnavailable"),
        field: "storage.isUnavailable",
        hide: true,
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("location.storageId"),
        field: "storage.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("location.moreThanOneArticleAllowed"),
        field: "moreThanOneArticleAllowed",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("location.notAvailableForPicking"),
        field: "notAvailableForPicking",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("location.notAvailableForInput"),
        field: "notAvailableForInput",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("location.articlesLinked"),
        sortable: false,
        filter: false,
        resizable: true,
        // Remove default onCellClickedBehaviour
        onCellClicked: () => { },
        cellRenderer: (params: { data: Zeus.Web.Model.Location }) => {
          return params.data?.articleLocations?.map(articleLocation => {
            return `<a href="/articles/${articleLocation.articleId}" title="${articleLocation.article.description._translation}" class="ui-link" style="color: rgba(0, 0, 0, 0.87);">`
              + `${articleLocation.article.reference}</a> (${articleLocation.currentQuantityInLocation || 0})`;
          }).join(', ');
        },
        minWidth: 175,
      },
      {
        headerName: this.i18n.tr("location.locationVolume"),
        field: "defaultStorageVolume.volumeType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.StorageVolumeType
        }
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];

    if (!this.isRangeLocationMode) {
      defs.splice(0, 0, GridHelper.getSelectedColDef(this));
    }

    return defs;
  }
}
