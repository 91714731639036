import { Router } from 'aurelia-router';
import { BindingEngine, autoinject, computedFrom } from "aurelia-framework";
import { bindable, customElement } from "aurelia-templating";
import { Zeus } from "../../generated";
import { CustomLogger, ViewModelBase } from "digiwall-lib";

@customElement("storage-selection")
@autoinject
export class StorageSelection extends ViewModelBase {
  public ribbonHeaderText = "";
  public ressourceName = "";

  @bindable public storageSelectionDto: Zeus.Web.Model.StorageSelectionDTO;
  @bindable public isValid: boolean;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
  }

  private setIsValid(): void {
    let props = ['ModulaId', 'TrayId', 'TrayWidth', 'TrayDepth'];
    this.isValid = props.every(x =>
      this.storageSelectionDto['from' + x] != null
      && this.storageSelectionDto['to' + x] != null
      && this.storageSelectionDto['from' + x] <= this.storageSelectionDto['to' + x]
    );
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.storageSelectionDto, "fromModulaId").subscribe((newVal, oldVal) => {
        this.setIsValid();
      }),
      this.bindingEngine.propertyObserver(this.storageSelectionDto, "toModulaId").subscribe((newVal, oldVal) => {
        this.setIsValid();
      }),
      this.bindingEngine.propertyObserver(this.storageSelectionDto, "fromTrayId").subscribe((newVal, oldVal) => {
        this.setIsValid();
      }),
      this.bindingEngine.propertyObserver(this.storageSelectionDto, "toTrayId").subscribe((newVal, oldVal) => {
        this.setIsValid();
      }),
      this.bindingEngine.propertyObserver(this.storageSelectionDto, "fromTrayWidth").subscribe((newVal, oldVal) => {
        this.setIsValid();
      }),
      this.bindingEngine.propertyObserver(this.storageSelectionDto, "toTrayWidth").subscribe((newVal, oldVal) => {
        this.setIsValid();
      }),
      this.bindingEngine.propertyObserver(this.storageSelectionDto, "fromTrayDepth").subscribe((newVal, oldVal) => {
        this.setIsValid();
      }),
      this.bindingEngine.propertyObserver(this.storageSelectionDto, "toTrayDepth").subscribe((newVal, oldVal) => {
        this.setIsValid();
      }),
    );
  }

}
