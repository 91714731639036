import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { PermissionsConfig } from "digiwall-lib";
import * as BLConstants from "./constants";
import translationJson from './locales/index';
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";

export class BadgeLoginRoot extends AppModuleRoot {
  public get name() {
    return AppModule.BadgeLogin;
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return {};
  }

  protected get pathName(): string {
    return 'badge-login';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [];
  }

  protected get resourceNames(): {} {
    return BLConstants.EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {};
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
