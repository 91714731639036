import { FilterQueryOp, Predicate } from "breeze-client";
import { ServiceBase } from "digiwall-lib";
import { Zeus } from 'generated';
import * as Constants from "../../../constants";
import { InventoryBaseFloatingBox } from './inventory-base-floating-box';
import { Utils } from "utils/utils";

export class InventoryTrayContainerFloatingBox extends InventoryBaseFloatingBox<Zeus.Web.Model.InventoryWorkOrderTrayContainer> {
  private trayContainerService: ServiceBase<Zeus.Web.Model.TrayContainer>;
  private storageService: ServiceBase<Zeus.Web.Model.Storage>;

  private storageId: number;
  private storage: Zeus.Web.Model.Storage = {} as any;
  private shouldSaveInventoryLink: boolean = true;

  constructor(workOrder: Zeus.Web.Model.WorkOrder, trayToAvoidIds: number[] = [], forExtraPick: boolean = false) {
    super(workOrder, 'trayContainer', new ServiceBase<Zeus.Web.Model.InventoryWorkOrderTrayContainer>(Constants.EntityTypeNames.InventoryWorkOrderTrayContainer));
    this.trayContainerService = new ServiceBase<Zeus.Web.Model.TrayContainer>(Constants.EntityTypeNames.TrayContainer);
    this.shouldSaveInventoryLink = false == forExtraPick;

    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.storageService.gridDataSource.customSelect2Predicates = () => {
      return Utils.storagePredicateLimitedToStorageGroup(this.workOrder.limitedToStorageGroup);
    };

    this.trayContainerService.gridDataSource.expands = ['storage'];
    this.trayContainerService.gridDataSource.queryParameters = { havingStock: true };
    const _this = this;
    this.trayContainerService.gridDataSource.customSelect2Predicates = () => {
      let limitPredicate: Predicate = null;
      let storagePred = _this.storageId ? new Predicate('storageId', FilterQueryOp.Equals, _this.storageId) : null;
      if (storagePred != null) {
        // If a storage is selected, then (because of the custom predicates on the storage select) we don't need to check the wo limit
        limitPredicate = storagePred;
      }
      // If null, we need to check the limit
      else if (_this.workOrder.limitedToStorageGroupId == null) {
        limitPredicate = null;
      }
      // Storages are always linked to a ZONE
      else {
        limitPredicate = Utils.storagePredicateLimitedToStorageGroup(_this.workOrder.limitedToStorageGroup, "storage.");
      }

      if (forExtraPick) {
        let predicate = new Predicate("trayPickIsEnabled", FilterQueryOp.Equals, true);
        limitPredicate = limitPredicate ? limitPredicate.and(predicate) : predicate;
      }
      return _this.applyUniquenessPredicate([..._this.workOrder.inventoryLinks.map(x => (x as any).trayContainerId), ...trayToAvoidIds], limitPredicate);
    };
  }

  private applyUniquenessPredicate(trayToAvoidIds: number[], predicate: Predicate = null) {
    return trayToAvoidIds.filter(x => x != null).reduce((predicate, trayToAvoidId) => {
      let notEqual = new Predicate('id', FilterQueryOp.NotEquals, trayToAvoidId);
      return predicate ? predicate.and(notEqual) : notEqual;
    }, predicate);
  }

  private async storageSelected(value: number) {
    if (value > 0 && value != this.storage?.id) {
      this.storage = await this.storageService.getEntityByIdFromLocalCache(value);
    }
  }

  protected async saveAndClose() {
    if (this.shouldSaveInventoryLink) {
      await super.saveAndClose();
    } else {
      this.dialogController.ok(this.link);
    }
  }
}
