import { bindable, customElement } from "aurelia-templating";
import { ServiceBase } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../../../constants';
import { BindingEngine, Disposable } from "aurelia-binding";
import { Container } from "aurelia-framework";
import { EntityState, EntityStateSymbol } from "breeze-client";

@customElement('inventory-cycle-count')
export class InventoryCycleCount {
  @bindable private isModifiable: boolean;
  @bindable protected workOrder: Zeus.Web.Model.WorkOrder;
  @bindable private onLoaded: () => void;

  protected bindingEngine: BindingEngine;
  protected disposables: Disposable[] = [];
  constructor() {
    this.bindingEngine = Container.instance.get(BindingEngine);
    this.disposables.push(
      this.bindingEngine.expressionObserver(this, 'workOrder.entityAspect.entityState').subscribe((state: EntityStateSymbol) => {
        console.debug("InventoryCycleCount - state ", state);
        if (state == EntityState.Unchanged) {
          if (this.onLoaded) this.onLoaded();
        } else {
          console.warn("InventoryCycleCount onreload not triggered");
        }
      }),
    );
  }

  public async attached() {
    if (this.workOrder.cycleCountParameterId == null) {
      let cycleCountParameterService = new ServiceBase<Zeus.Web.Model.CycleCountParameter>(Constants.EntityTypeNames.CycleCountParameter);
      this.workOrder.cycleCountParameter = await cycleCountParameterService.createEntity({ locationNumber: 1 });
    }
    this.onLoaded();
  }
}
