$primary: #23bcec;
$secondary: #8e8e8e;
$warning: #fc7a1e;
$info: #337ab7;
// 36b4e5 // our primary
// Their colors
// 23bcec // primary
// 337ab7 // a
// 23527c // a hover
// 4497b9 // vert canard clair

$ribbon-height: 40px;
$min-desktop-width: 1100px;

$font-color: rgb(70, 77, 105);

$navbar-text-color: white !default;
$navbar-icon-color: $navbar-text-color !default;
$navbar-background: $primary !default;

$navbar-active-text-color: darken($primary, 25%) !default;
$navbar-active-icon-color: $navbar-active-text-color !default;
$navbar-active-background: white !default;

$brand-logo-width: 70px;
$brand-logo-width-condensed: 50px;

$floating-box-view-background-header: $primary !default;
$floating-box-view-color-header: white !default;

$ux-dialog-background-header: $floating-box-view-background-header !default;
$ux-dialog-color-header: $floating-box-view-color-header !default;
$ux-dialog-color-header-hover: $floating-box-view-color-header !default;
$ux-dialog-background-footer: $floating-box-view-color-header !default;
$ux-dialog-color-footer: black !default;

$menu-hover-bg: $primary;
$menu-hover-color: white;
$menu-active-border-color: $primary;
