import { Router } from 'aurelia-router';
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, EnumerationTypeService, ServiceBase, Various } from 'digiwall-lib';
import { Zeus } from "../generated";
import { autoinject, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants'
import { ValidationRules } from 'aurelia-validation';

@autoinject
export class BayDeviceModal extends EntityDetailViewModelBase<Zeus.Web.Model.BayDevice> {
  public ressourceName: string = Constants.EntityTypeNames.BayDevice;

  public deviceTypeService: EnumerationTypeService;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.BayDevice>(Constants.EntityTypeNames.BayDevice));
    this.deviceTypeService = new EnumerationTypeService(Constants.EnumerationTypes.DeviceType);
  }

  @computedFrom("editingMode")
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("article.article");
    }
  }

  @computedFrom("entity.deviceTypeId")
  get isLaserPointer() {
    return this.entity.deviceTypeId == Constants.DeviceType.LaserPointer;
  }

  @computedFrom("entity.deviceTypeId")
  get isFootbar() {
    return this.entity.deviceTypeId == Constants.DeviceType.Footbar;
  }

  public async activate(params) {
    await super.activate(params);
    const id = params.param1;

    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();

      if (params.callback != null)
        params.callback(this.entity);
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'bay', 'ledBarSize');
      this.controller.addObject(this.entity);
    }

    ValidationRules
      .ensure('deviceTypeId').required().withMessage('baydevice.errorDeviceTypeMandatory')
      .ensure('portNumber').required().when(x => this.isFootbar).min(1)
      .ensure('ipAddress').required().matches(/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/g)
      .on(this.entity);
  }

}
