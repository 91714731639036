export class Application {
}

export enum EnumerationTypes {
  LedBarSizes = 40
}

export class EntityTypeNames {
  public static GLBConfiguration = "GLBConfiguration";
  public static GLBConfigurationPart = "GLBConfigurationPart";
  public static GLBConfigurationPartDataField = "GLBConfigurationPartDataField";

  public static ResourceNames = {
    GLBConfiguration: "GLBConfigurations",
    GLBConfigurationPart: "GLBConfigurationParts",
    GLBConfigurationPartDataField: "GLBConfigurationPartDataFields"
  };
}

export class Permissions {
  public static GLBConfiguration = 'glb-configuration';
}

export enum DeviceType {
  GraphicalLedBar = 80
};
