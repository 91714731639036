import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { PermissionsConfig } from "digiwall-lib";
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";

export class StandardTrayTemplatesRoot extends AppModuleRoot {
  public get name() {
    return AppModule.StandardTrayTemplates;
  }

  public get translations(): { [key: string]: object; } {
    return {};
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return {};
  }

  protected get pathName(): string {
    return 'standard-tray-templates';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [];
  }

  protected get resourceNames(): {} {
    return {};
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {};
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
