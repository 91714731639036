planning-component,
pre-planning-component,
planning-component-extended {
  display: flex;
  flex-flow: column;
  height: 100%;
  & div.row {
    width: 100%;
  }
  & md-card {
    display: flex;
    flex-flow: column;
  }
}
div[slot=planning-component] {
  overflow-x: auto;
  overflow-y: hidden;
  #pc-head {
    #pc-head-container {
      padding: 0;
      #pc-thead-visible {
        font-size: 14px;
        >tr {
          border: none;
          >th {
            height: 35px;
            padding: 0px 5px;
            border-right: 1px solid rgba(0,0,0,.12);
            min-width: 250px;
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
  }
  #pc-body {
    #pc-body-content {
      padding: 0;
      #pc-body-scroll {
        overflow: hidden scroll;
        padding-bottom: 10px;
      }
      #pc-body-visible {
        td,>div {
          vertical-align: top;
          min-height: 100%;
          border-right: 1px solid rgba(0,0,0,.12);
          padding: 5px;
          min-width: 250px;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}

.draggable {
  min-height: 50px;
}

.showed-date {
  display: flex;
  margin: auto;
  width: max-content;
  [ref=input] {
    cursor: pointer !important;
    text-align: center;
  }
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cell-design {
  background-color: white;
  color: black;
  text-align : center;
  display: grid; 
  width: 100%;
  position: relative;
  border: solid 1px darkslategray;
  border-left: solid 5px darkslategray;
  margin-bottom: 8px; 
  border-radius: 3px;
  padding: 5px;
  -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.25);
  box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.25);
  cursor: grab;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 13px;
  div.row {
    margin: 0!important;
    div.col {
      padding: 0!important;
    }
  }
  .btn-floating {
    position: absolute;
    top: 3px;
    right: 3px;
    opacity: 0;
    height: 25px;
    width: 25px;
    line-height: 25px;
    border-radius: 25%;
    >i {
      line-height: 25px;
      font-size: 25px;
    }
  }
  .material-icons {
    font-size: 22px;
  }
  .intervention-menu {
    >li {
      min-height: 30px;
      >a {
        padding: 7px 8px;
      }
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.35);
    -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.35);
    box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.35);
    .btn-floating {
      opacity: 1;
    }
  }
  &.unconfirmed {
    background-color: rgb(255, 180, 75);
  }
}

.sub-menu{
  width: 100%; 
  font-weight: bold; 
  display: flex;
  margin-bottom: 5px;
  font-size: 15px;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  span{
    float: left;
  }
  i{
    float: left;
  }
}

.checkbox-weekend {
  margin-left: 0; 
  display: flex; 
  align-items: center; 
  justify-content: center;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  cursor: grabbing;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  margin-left: 0 !important;
  width: 100% !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.production-stage-design {
  color: black;
  text-align : center;
  display: grid; 
  width: 100%;
  position: relative;
  border: solid 1px darkslategray;
  border-left: solid 3px darkslategray;
  border-right: solid 3px darkslategray;
  margin-bottom: 8px; 
  padding: 5px;
  min-height: 100px;
  -webkit-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.15);
  box-shadow: 1px 3px 5px 0px rgba(0,0,0,0.15);
  cursor: default;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 13px;
  &:last-child {
    margin-bottom: 0;
  }
}

md-card.planning-md-card {
  height: 100%;
  & .card {
    height: 100%;
  }
  & .card-content {
    height: 100%;
    & .card-title {
      display: none;
    }
  }
}
