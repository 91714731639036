import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import * as Constants from '../../constants';
import { ImportXLS as BaseImportXLS } from '../../import-xls/import-xls';
import { Endpoints } from 'endpoints';

export interface ImportXLSResult {
  HeaderError: string;
  IsFileValid: boolean;
  NbRequest: number;
  NbOk: number;
  NbTotalError: number;
  NbOtherError: number;
  NbMissingValue: number;
  NbBadFormat: number;
  ResultFileURL: string;
}

@autoinject
export class ImportXLS extends BaseImportXLS {
  public ressourceName: string = Constants.EntityTypeNames.WorkOrder;

  private workOrderType: Constants.WorkOrderType = Constants.WorkOrderType.Input;

  constructor(router: Router, logger: CustomLogger, dialogController: DialogController, httpClient: HttpClient) {
    super(router, logger, dialogController, httpClient);
  }

  public async activate() {
    let params = {
      type: Constants.EntityTypeNames.WorkOrder,
      title: 'workorder.importFile',
      patternUrl: '',
      importUrl: '',
      folderName: ''
    };
    await super.activate(params);
    this.woTypeChanged();
    this.viewResponse = false;
  }

  private woTypeChanged() {
    switch (this.workOrderType) {
      case Constants.WorkOrderType.Input:
        this.params.patternUrl = Endpoints.ImportXlsx.GetWorkOrderInputXlsPattern;
        this.params.importUrl = Endpoints.ImportXlsx.ImportWorkOrderInputXLS;
        this.params.folderName = 'work_order_imports/input';
        break;
      case Constants.WorkOrderType.Inventory:
        this.params.patternUrl = Endpoints.ImportXlsx.GetWorkOrderInventoryXlsPattern;
        this.params.importUrl = Endpoints.ImportXlsx.ImportWorkOrderInventoryXLS;
        this.params.folderName = 'work_order_imports/inventory';
        break;
      case Constants.WorkOrderType.Picking:
        this.params.patternUrl = Endpoints.ImportXlsx.GetWorkOrderPickingXLSPattern;
        this.params.importUrl = Endpoints.ImportXlsx.ImportWorkOrderPickingXLS;
        this.params.folderName = 'work_order_imports/picking';
        break;
    }
  }

  /* public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, "workOrderType").subscribe((newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.folderName = "work_order_imports/";

          switch (this.workOrderType) {
            case Constants.WorkOrderType.Input: this.folderName += "input";
            break;
      
            case Constants.WorkOrderType.Inventory: this.folderName += "inventory";
            break;

            case Constants.WorkOrderType.Picking: this.folderName += "picking";
            break;
          }
        }
      })
    );
  } */
}
