.tray-preview-container {
  .col {
    &.legend-y {
      width: 1rem !important;
    }
  
    &.preview {
      width: calc(100% - 2rem) !important;

      > ui-content {
        overflow: hidden;

        > div {
          border: 2px solid black;
        }
      }
    }
  }

  strong {
    font-size: 1.5rem;
  }

  .location{
    &-block {
      border: 1px solid black;
      position: absolute;
      text-align: center;
      display: flex;
      justify-content: center;
      align-content: center;

      &.selected {
        border: thick double black;
      }

      &.faded {
        background: repeating-linear-gradient(45deg, rgba(230, 230, 230, .8), rgba(230, 230, 230, .8) 10px, #fff 10px, #fff 20px);
        
        > .background {
          opacity: 0.4;
        }
      }

      > .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      > .volume-text {
        margin-top: auto;
        margin-bottom: auto;
        z-index: 1;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
