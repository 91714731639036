import { Predicate, FilterQueryOp } from 'breeze-client';
import { FluentRuleCustomizer } from 'aurelia-validation';
import { EditingModeEnum, ServiceBase, Role, EntityTypeNames, UserDetailBase, DialogBoxViewModel, Actions } from 'digiwall-lib';
import { Zeus } from "../generated";
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import * as RTConstants from '../app-modules/restricted-trays/constants';
import * as BLConstants from '../app-modules/badge-login/constants';
import { UserUtils } from '../utils/utils-users';
import { AppModuleEnum } from 'app-modules/constants';
import { AppModuleService } from 'app-modules/app-module-service';
import { RestrictedTrays } from 'app-modules/restricted-trays/generated';
import { Utils } from 'utils/utils';
import { DataFormat } from 'select2';
import { Endpoints } from 'endpoints';
import { LinkBadge } from '../app-modules/badge-login/link-badge/link-badge';
import * as toastr from 'toastr';

@autoinject
export class UserDetail extends UserDetailBase<Zeus.Web.Model.ZeusUser> {
  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  private userSiteManagementService: ServiceBase<Zeus.Web.Model.UserSiteManagement>;
  private roleService: ServiceBase<Role>;

  private storageService: ServiceBase<Zeus.Web.Model.Storage>;
  private trayRestrictionService: ServiceBase<RestrictedTrays.Model.TrayRestriction>;

  private severalZonesExist: boolean = false;

  constructor(private bindingEngine: BindingEngine, private appModuleService: AppModuleService) {
    super('multiple', Constants.EntityTypeNames.ZeusUser);

    this.roleService = new ServiceBase<Role>(EntityTypeNames.Role);
    this.roleService.gridDataSource.expands = ['permissions'];
    this.roleService.gridDataSource.queryParameters = { hideHelpdesk: false };

    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.storageGroupService.gridDataSource.expands = ['storageGroupType'];
    this.storageGroupService.gridDataSource.customSelect2Predicates = () => {
      if (this.entity?.userSiteManagements?.length > 0) {
        return Predicate.and(this.entity.userSiteManagements.filter(x => x.storageGroupId != null).map(x =>
          new Predicate('id', FilterQueryOp.NotEquals, x.storageGroupId)
        ));
      }
      return null;
    }

    this.userSiteManagementService = new ServiceBase<Zeus.Web.Model.UserSiteManagement>(Constants.EntityTypeNames.UserSiteManagement);
  }

  @computedFrom('authService.currentUser')
  private get isCurrentHelpDeskUser(): boolean {
    return this.authService.checkAccess("helpdesk", Actions.All);
  }

  @computedFrom('entity.roles.length')
  private get isEntityHelpDeskUser(): boolean {
    return UserUtils.isUserHelpDesk(this.entity);
  }

  // Old : Entity can be edited if the current user is a helpdesk user. If not, the entity can only be modified if not a helpdesk user
  // New : You are helpdesk or the entity is not helpdesk and you have permission
  @computedFrom('isCurrentHelpDeskUser', 'isEntityHelpDeskUser')
  private get canEditUser(): boolean {
    return this.isCurrentHelpDeskUser
      || (false == this.isEntityHelpDeskUser && this.canAddOrUpdate);
  }

  @computedFrom('hasFreeSiteSelection', 'entity.userSiteManagements.length')
  private get canDeleteStorageGroup(): boolean {
    return this.hasFreeSiteSelection || this.entity?.userSiteManagements?.length > 1;
  }

  @computedFrom('entity.roles.length')
  private get hasFreeSiteSelection(): boolean {
    return this.entity.roles?.some(userRole => userRole.role?.permissions?.some(rolePermission => rolePermission.permissionId == 'free-site-selection:storage-group'));
  }

  protected activateExpands(): string[] {
    return ['roles.role.permissions', 'userSiteManagements.storageGroup.storageGroupType'];
  }

  protected activateQueryParameters(): object {
    return { filterDisabled: false };
  }

  public async activate(params) {
    await super.activate(params);
    if (this.entity.entityAspect.entityState.isAdded()) {
      this.entity.languageCode = "en";
    }

    this.disposables.push(
      this.bindingEngine.collectionObserver(this.entity.roles).subscribe(() => this.setUserZone(true))
    );

    this.setUserZone(false);
  }

  protected getValidationRules(): FluentRuleCustomizer<unknown, any> {
    return super.getValidationRules()
      .ensure('userSiteManagements')
      .satisfies((userSiteManagements: Array<Zeus.Web.Model.UserSiteManagement>) => {
        return userSiteManagements != null && userSiteManagements.length > 0;
      }).withMessage("user.userSiteManagementMandatory")
      .when((user: Zeus.Web.Model.ZeusUser) => false == UserUtils.isUserHelpDesk(user) && false == user.disabled)
  }

  private async setUserZone(isRoleUpdated: boolean) {
    // Don't add the user zone when the entity is no longer attached (cancelling a creation for example)
    if (isRoleUpdated && !this.entity?.entityAspect.entityState.isAddedModifiedOrDeleted()) {
      return;
    }

    let response: Response = await this.httpClient.fetch(Endpoints.Utils.GetOnlyExistingZone);
    if (response.ok) {
      if (response.status == 204) { // No content
        this.severalZonesExist = true;
      }
      else {
        let result = await response.json();
        if (result != null) {
          if ((this.editingMode == EditingModeEnum.Create || isRoleUpdated) && false == this.hasFreeSiteSelection) {
            let newUserSite = this.addStorageGroup();
            newUserSite.storageGroupId = result;
          }
        }
      }
    }
  }

  public async save(silentSave: boolean = false, byPassLocking?: boolean, navigateBack: boolean = false) {
    if (false == this.canEditUser) {
      return;
    }
    await super.save(silentSave, byPassLocking, false);
    if (navigateBack) {
      this.doNavigateBack();
    }
  }

  public addStorageGroup() {
    if (false == this.canEditUser) {
      return;
    }
    return this.userSiteManagementService.createEntity({
      userId: this.entity.id
    }, true);
  }

  public async removeStorageGroup(userSiteManagement: Zeus.Web.Model.UserSiteManagement) {
    if (false == this.canEditUser) {
      return;
    }

    let confirmDelete = true;

    if (!this.hasFreeSiteSelection) {
      await this.loadTrayRestrictions();
      let trayRestrictionsToRemove = this.entity.trayRestrictions.filter(tr => !this.isChildOf(tr.tray.storage.storageGroup, this.entity.userSiteManagements.filter(usm => usm.id != userSiteManagement.id).map(usm => usm.storageGroupId)));
      if (trayRestrictionsToRemove?.length > 0) {
        confirmDelete = await this.box.showQuestion(this.i18n.tr('user.trayRestrictionsImpacted'), this.i18n.tr('menu.del-filter-set-title'),
          [
            { label: this.i18n.tr('general.no'), theme: 'primary', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(false) },
            { label: this.i18n.tr('general.yes'), theme: 'warning', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(true) }
          ]
        ).whenClosed(async result => {
          if (result.output) {
            Utils.detachOrDeleteEntity(trayRestrictionsToRemove);
          }
          return result.output;
        });
      }
    }

    if (confirmDelete) {
      Utils.detachOrDeleteEntity(userSiteManagement);
    }
  }

  private isChildOf(parentOfStorage: Zeus.Web.Model.StorageGroup, parentIds: number[]) {
    return parentOfStorage && (parentIds.includes(parentOfStorage.id) || this.isChildOf(parentOfStorage.parentStorageGroup, parentIds));
  }

  //#region RestrictedTrays
  @computedFrom('entity.id')
  private get isRestrictedTrays() {
    return this.appModuleService.isActive(AppModuleEnum.RestrictedTrays);
  }

  private trayRestrictionsLoaded: boolean = false;
  private async loadTrayRestrictions() {
    if (false == this.trayRestrictionsLoaded) {
      // --- Init services ---
      this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
      this.storageService.gridDataSource.expands = ['trayContainers'];
      this.storageService.gridDataSource.queryParameters = { withRestrictedTrays: true };
      this.storageService.gridDataSource.customSelect2Predicates = () => {
        if (this.hasFreeSiteSelection) {
          return null;
        }
        return Predicate.or(this.entity.userSiteManagements.map(usm => Utils.storagePredicateLimitedToStorageGroup(usm.storageGroup)));
      };

      this.trayRestrictionService = new ServiceBase<RestrictedTrays.Model.TrayRestriction>(RTConstants.EntityTypeNames.TrayRestriction);

      // --- Init ui-table ---
      await this.trayRestrictionService.getEntities(new Predicate("userId", FilterQueryOp.Equals, this.entity.id), ['tray.storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup']);
      for (let trayRestriction of this.entity.trayRestrictions) {
        (trayRestriction as UserTrayRestriction).storage = { id: trayRestriction.tray.storageId, text: trayRestriction.tray.storage.name };
      }

      this.trayRestrictionsLoaded = true;
    }
  }

  private addTrayRestriction() {
    if (false == this.canEditUser) {
      return;
    }

    let trayRestriction: UserTrayRestriction = this.trayRestrictionService.createEntity({
      userId: this.entity.id
    }, true);
    trayRestriction.storage = { id: "", text: "" };
    trayRestriction.trayService = new ServiceBase<Zeus.Web.Model.TrayContainer>(Constants.EntityTypeNames.TrayContainer);
    trayRestriction.trayService.gridDataSource.customSelect2Predicates = () => {
      let trayPredicate: Predicate = new Predicate("isUnavailable", FilterQueryOp.Equals, false).and("isRestricted", FilterQueryOp.Equals, true);
      if (trayRestriction.storage) {
        trayPredicate = trayPredicate.and(new Predicate("storageId", FilterQueryOp.Equals, Utils.extractIdFromDataFormat(trayRestriction.storage)));
      }
      if (this.entity.trayRestrictions?.length > 0) {
        trayPredicate = trayPredicate.and(this.entity.trayRestrictions.map(tr => new Predicate("id", FilterQueryOp.NotEquals, tr.trayId)));
      }
      return trayPredicate;
    };
  }

  private removeTrayRestriction(trayRestriction: RestrictedTrays.Model.TrayRestriction) {
    if (false == this.canEditUser) {
      return;
    }
    Utils.detachOrDeleteEntity(trayRestriction);
  }
  //#endregion

  //#region BadgeLogin
  @computedFrom('authService.usernameType')
  private get isLocalAuth(): boolean {
    return this.authService.usernameType == "username";
  }

  @computedFrom('entity.id')
  private get isBadgeLoginActive() {
    return this.appModuleService.isActive(AppModuleEnum.BadgeLogin);
  }

  @computedFrom('isLocalAuth', 'isBadgeLoginActive')
  private get canUseBadge() {
    return this.isLocalAuth && this.isBadgeLoginActive;
  }

  private async linkBadge() {
    await this.dialogService.open({ viewModel: LinkBadge, model: this.entity.id, lock: false }).whenClosed((result) => {
      if (!result.wasCancelled && result.output as boolean) {
        this.entity.hasBadge = true;
      }
    });;
  }

  private async unlinkBadge() {
    let response: Response = await this.httpClient.post(BLConstants.Application.RemoveUserBadgeLink + "?userId=" + this.entity.id);
    if (response.ok) {
      toastr.success(this.i18n.tr("badgelogin.badgeUnlinkSuccess"));
      this.entity.hasBadge = false;
    }
    else {
      toastr.error(this.i18n.tr("badgelogin.badgeUnlinkFail"));
    }
  }
  //#endregion
}

interface UserTrayRestriction extends RestrictedTrays.Model.TrayRestriction {
  storage: DataFormat;
  trayService: ServiceBase<Zeus.Web.Model.TrayContainer>;
}
