export enum EnumerationTypes {
}

export class EntityTypeNames {

  public static TrayRestriction = "TrayRestriction";

  public static ResourceNames = {
    TrayRestriction: "TrayRestrictions"
  };
}
