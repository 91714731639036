import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomLogger, ViewModelBase, ServiceBase } from 'digiwall-lib';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { FadeableLocation } from './tray-preview';

@autoinject
export class TrayPreviewDialog extends ViewModelBase {

  private service: ServiceBase<Zeus.Web.Model.TrayContainer>;
  private tray: Zeus.Web.Model.TrayContainer;

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController) {
    super(router, logger);
    this.service = new ServiceBase<Zeus.Web.Model.TrayContainer>(Constants.EntityTypeNames.TrayContainer);
  }

  public async activate(params: { trayId: number, locationId?: number, dialogType: any }) {
    await super.activate(params);
    this.tray = await this.service.getEntityById(
      params.trayId,
      'locations.defaultStorageVolume.volumeType',
      'storage'
    );
    // In case of tray preview from location: fade other locations
    if(params.locationId != null) {
      this.tray.locations.forEach((loc: FadeableLocation) => loc.fade = loc.id != params.locationId);
    }
  }

  detached(): void {
    // Reset fade property
    this.tray?.locations?.forEach((loc: FadeableLocation) => delete loc.fade);
    super.detached();
  }

  public async close() {
    this.dialogController.ok();
  }

  get ribbonHeaderText(): string {
    return '';
  }
  get ressourceName(): string {
    return '';
  }
}
