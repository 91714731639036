import { BindingEngine, computedFrom, Disposable } from "aurelia-binding";
import { Container } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { bindable } from "aurelia-templating";
import { EntityState, EntityStateSymbol, FilterQueryOp, Predicate } from "breeze-client";
import { Box, ServiceBase } from "digiwall-lib";
import { Zeus } from "generated";
import { InventoryBaseFloatingBox } from 'work-orders/floating-box/inventory/inventory-base-floating-box';
import * as Constants from '../../../constants';

export abstract class InventoryBaseActions<T extends Zeus.Web.Model.InventoryWorkOrderManyToMany> {
  @bindable
  private isModifiable: boolean;
  @bindable
  protected workOrder: Zeus.Web.Model.WorkOrder;
  @bindable
  protected saveWorkOrder: () => Promise<boolean>;
  @bindable
  private onLoaded: () => void;

  protected box: Box;
  protected i18n: I18N;
  protected inventoryLinks: T[] = [];

  protected bindingEngine: BindingEngine;
  protected disposables: Disposable[] = [];

  constructor(protected service: ServiceBase<T>) {
    this.box = Container.instance.get(Box);
    this.i18n = Container.instance.get(I18N);
    this.bindingEngine = Container.instance.get(BindingEngine);
    // Every time the workOrder is saved (e.g. on state change), reload the inventoryLinks
    // This is also called on first attached
    this.disposables.push(
      this.bindingEngine.expressionObserver(this, 'workOrder.entityAspect.entityState').subscribe((state: EntityStateSymbol) => {
        console.debug("InventoryBaseActions - state ", state);
        if (state == EntityState.Unchanged) {
          this.reload();
        } else {
          console.warn("InventoryBaseActions onreload not triggered");
        }
      }),
    );
  }

  private async reload() {
    // Load without locations first
    await this.service.getEntities(new Predicate('workOrderId', FilterQueryOp.Equals, this.workOrder.id), this.getExpands(false));
    this.workOrder.inventoryLinks.forEach(link => this.markNbrLocationsLoading(link));
    this.inventoryLinks.splice(0, this.inventoryLinks.length, ...this.workOrder.inventoryLinks as T[]);
    if (this.onLoaded) {
      this.onLoaded();
    }
    await this.loadLocations();
  }

  private markNbrLocationsLoading(link: any) {
    link.nbrLocations = this.i18n.tr('common:select2.loadingMore');
  }

  private async loadLocations() {
    await this.service.getEntities(new Predicate('workOrderId', FilterQueryOp.Equals, this.workOrder.id), this.getExpands(true));
    this.inventoryLinks.forEach(link => {
      this.setIsCancellable(link);
      this.setNumberOfLocations(link);
    });
  }

  private detached() {
    this.disposables.forEach(x => x.dispose());
  }

  protected abstract getExpands(withLocations: boolean): string[];
  protected abstract setNumberOfLocations(entity: T): T;
  protected abstract getFloatingBoxInstance(): InventoryBaseFloatingBox<T>;

  protected async onAdded(newLink: T) { }

  protected getRowClass(inv: T) {
    return inv.hasBeenCancelled ? 'work-order-line-cancelled' : '';
  }

  private async openAdd() {
    if (this.workOrder.id <= 0) {
      let saveOk = await this.saveWorkOrder();
      if (saveOk == false) {
        return;
      }
    }
    this.box.showFloatingBox(this.getFloatingBoxInstance()).whenClosed(async response => {
      if (false == response.wasCancelled && response.output != null) {
        let newLink: T = response.output;
        let index = this.inventoryLinks.findIndex(x => x.id == newLink.id);
        if (index > -1) {
          this.inventoryLinks.splice(index, 1);
        }
        (newLink as any).nbrLocations = this.i18n.tr('common:select2.loadingMore');

        // Fetch nbr of locations
        await this.service.getEntityById(newLink.id, ...this.getExpands(true)) as T;
        await this.onAdded(newLink);
        this.setIsCancellable(newLink);
        this.inventoryLinks.push(newLink);
        this.setNumberOfLocations(newLink);
      }
    });
  }

  private async removeLink(entity: T) {
    let index = this.inventoryLinks.findIndex(x => x == entity);
    await this.service.deleteEntities([entity], true);
    this.inventoryLinks.splice(index, 1);
  }

  @computedFrom('workOrder.workOrderStatusId')
  private get cancellable(): boolean {
    return this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.ReadyToBeProcessed ||
      this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.PartiallyProcessed;
  }

  private async cancelLink(entity: T) {
    entity.hasBeenCancelled = true;
    await this.service.saveEntities([entity]);
    this.setIsCancellable(entity);
  }

  private setIsCancellable(entity: T): T {
    (entity as any).isCancellable = false == entity.hasBeenCancelled && (entity.locationForInventories == null || entity.locationForInventories.length == 0 || entity.locationForInventories.filter(x => false == x.msgHasBeenSent).length > 0);
    return entity;
  }
}
