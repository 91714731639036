import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various } from 'digiwall-lib';
import { Zeus } from "../generated";
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';

@autoinject
export class CostCenterDetail extends EntityDetailViewModelBase<Zeus.Web.Model.CostCenter> {
  public ressourceName: string = Constants.EntityTypeNames.CostCenter;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.CostCenter>(Constants.EntityTypeNames.CostCenter));
  }

  public async activate(params) {
    let id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      this.entity.active = true;
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.controller.addObject(this.entity);
    }
  }

  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("costcenter.addCostCenter");
    } else {
      if (this.entity) {
        return this.entity.costCenterNum.toString();
      }
    }
  }
}
