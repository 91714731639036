import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { Actions, PermissionsConfig, EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";
import translationJson from './locales/index';
import * as Constants from '../../constants';
import * as BConstants from './constants';

export class BorrowingRoot extends AppModuleRoot {
  public get name() {
    return AppModule.Borrowing;
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return {};
  }

  protected get pathName(): string {
    return 'borrowing';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      {
        before: Constants.RouteName.AppParam,
        route: 'reports/borrowing/borrowedArticles',
        name: 'borrowing/borrowed-articles',
        moduleId: 'reports/borrowed-articles',
        title: 'borrowedArticles.title',
        nav: true,
        settings: {
          group: Constants.MenuType.Reports,
          resource: BConstants.Permissions.WorkOrderBorrowing_AsResource,
          action: Actions.Read
        }
      }
    ];
  }

  protected get resourceNames(): {} {
    return BConstants.EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        {
          context: BConstants.Permissions.WorkOrderBorrowing,
          qualifiedName: [Constants.MenuType.WorkOrders, 'borrowing.borrowing']
        }
      ],
      permissionNames: {}
    };
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
    metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.Article, function () {
      this.borrowingQuantityStr = null;
      this.borrowingQuantity = null;
    });
  }
}
