import { BindingEngine, Container } from "aurelia-framework";
import { FloatingBoxViewModel, QueryResults, ServiceBase } from "digiwall-lib";
import { Zeus } from 'generated';
import * as Constants from '../../../constants';

export abstract class InventoryBaseFloatingBox<T extends Zeus.Web.Model.InventoryWorkOrderManyToMany> extends FloatingBoxViewModel {
  protected canSave: boolean = false;
  protected link: T;
  private workOrderService: ServiceBase<Zeus.Web.Model.WorkOrder>;

  constructor(protected workOrder: Zeus.Web.Model.WorkOrder, private targetType: string, protected service: ServiceBase<T>, private floatingName: string = null) {
    super('./inventory-' + (floatingName ?? targetType) + '-floating-box.html', 'sm');
    this.link = this.service.createEntity({}, true);
    this.workOrderService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.disposables.push(
      Container.instance.get(BindingEngine).propertyObserver(this.link, targetType + 'Id').subscribe(newValue => this.canSave = newValue != null)
    );
  }

  public async activate(config: any): Promise<void> {
    await super.activate(config);
  }

  public async cancel() {
    if (this.link?.entityAspect.entityState.isAdded()) {
      this.link.entityAspect.setDetached();
    }
    await super.cancel();
  }

  private mainSelect;
  private mainSelectReady() {
    setTimeout(() => {
      this.mainSelect.open();
    }, 300); // 300ms is the floating box animation time
  }

  protected async saveAndClose() {
    if (true == await this.save()) {
      this.dialogController.ok(this.link);
    }
  }

  protected async save(silentSave: boolean = false): Promise<boolean> {
    if (this.canSave == false || false == await this.beforeSave()) {
      return false;
    }
    if (this.workOrder.entityAspect.entityState.isModified()) {
      await this.workOrderService.saveEntity(this.workOrder, silentSave);
    }
    if (this.link.entityAspect.entityState.isAdded()) {
      this.link.workOrder = this.workOrder;
    }
    if (this.link.entityAspect.entityState.isAddedModifiedOrDeleted()) {
      await this.service.saveEntity(this.link, silentSave);
    }
    return true;
  }

  protected async beforeSave(): Promise<boolean> {
    return true;
  };
}
