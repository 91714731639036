import { Container } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Box, ShowEditDialogOptions } from 'digiwall-lib';
import { Zeus } from 'generated';
import { LocationDetail } from 'locations/location-detail';

export class LocationUtils {
  public static getIntCoordFromIdentifier(identifier: string): Array<number> {
    let [posX, posY] = this.getStringCoordFromIdentifier(identifier);
    if (posX != null && posY != null) {
      return [parseInt(posX), parseInt(posY)];
    }
    return [];
  }

  public static getStringCoordFromIdentifier(identifier: string): Array<string> {
    if (identifier != null && identifier.trim() != '') {
      let coord = identifier.split('.');
      let positionX = coord[coord.length - 2];
      let positionY = coord[coord.length - 1];
      return [positionX, positionY];
    }
    return [];
  }

  public static getLocationAllVolumes(location: Zeus.Web.Model.Location): Zeus.Web.Model.ILocationVolume[] {
    if (location == null) {
      return [];
    }
    return [
      {
        capacityInVolumeType: location.defaultCapacityInVolume,
        storageVolume: location.defaultStorageVolume,
        storageVolumeId: location.defaultStorageVolumeId,
      },
      ...(location.alternateLocationVolumes || [])
    ];
  }

  public static async addLocationModal(previousStorageId?: number, previousTrayId?: number): Promise<boolean> {
    let i18n = Container.instance.get(I18N);
    let box = Container.instance.get(Box);

    let wasCancelled = true;

    await box.showEditDialog(LocationDetail, -100, i18n.tr("location.location"),
      new ShowEditDialogOptions({
        icon: 'ze-location-coordinates',
        callback: previousStorageId != null ? (params) => {
          params.storage = previousStorageId;
          params.tray = previousTrayId;
        } : null
      })).whenClosed(async (result) => {
        if (!result.wasCancelled) {
          wasCancelled = false;
        }
      });
    return wasCancelled;
  }
}
