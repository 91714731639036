
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { autoinject, useView } from 'aurelia-framework';
import { AlgorithmDetail } from "./algorithm-detail";

@autoinject
@useView('./algorithm-detail.html')
export class AlgorithmDetailPicking extends AlgorithmDetail<Zeus.Web.Model.PickingAlgorithm, Zeus.Web.Model.PickingAlgorithmPart> {

  constructor() {
    super(Constants.EntityTypeNames.PickingAlgorithm, Constants.EntityTypeNames.PickingAlgorithmPart,
      Constants.WorkOrderType.Picking, Constants.EnumerationTypes.PickingGoal);
  }
}
