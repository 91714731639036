import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class DynamicStorageSentMessage extends ListViewModelBase<Zeus.Web.Model.DynamicStorageSentMessage> {
  public ressourceName: string = Constants.EntityTypeNames.DynamicStorageSentMessage;

  constructor(
    router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.DynamicStorageSentMessage>(Constants.EntityTypeNames.DynamicStorageSentMessage));
    this.service.gridDataSource.expands = ['messageType', 'originalWorkOrder.workOrderPriority'];
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.DynamicStorageSentMessage) {
    if (entity && entity.id) {
      return '/sent-messages/' + entity.id;
    }
    return null;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("message.messageTypeId"),
        field: "messageType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ModulaMessageType
        }
      },
      {
        headerName: this.i18n.tr("batchprocess.batchprocess"),
        field: "batchProcessId",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("message.bayNumber"),
        field: "bayNumber",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("message.machineId"),
        field: "machineId",
        type: FieldType.Number
      },

      {
        headerName: this.i18n.tr("message.businessProcessingComment"),
        field: "businessProcessingComment",
        type: FieldType.String
      },

      {
        headerName: this.i18n.tr("message.trayId"),
        field: "trayId",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("workorder.workOrderPriorityId"),
        field: "originalWorkOrder.workOrderPriority.name._translation",
        cellRenderer: 'enumerationRenderer',
        type: FieldType.OneToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          serviceName: Constants.EntityTypeNames.WorkOrderPriority,
        }
      },
      {
        headerName: this.i18n.tr("message.responseReceived"),
        field: "responseReceived",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("message.messageId"),
        field: "messageId",
        type: FieldType.Base
      },
      {
        headerName: this.i18n.tr("message.content"),
        field: "content",
        hide: true,
        type: FieldType.String
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser, {
        createdTimeColDef: {
          sort: 'desc'
        }
      }),
    ];
    return defs;
  }
} 
