import { Router } from 'aurelia-router';
import { CamelCase, CustomLogger, EnumerationTypeService, ViewModelBase } from 'digiwall-lib';
import { autoinject, computedFrom, viewResources } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import * as Constants from '../constants';
import { Zeus } from 'generated';
import { HttpClient, json } from 'aurelia-fetch-client';
import * as toastr from 'toastr';
import { TrayPreviewDialog } from './tray-preview-dialog';
import { StorageSelection } from './storage-selection/storage-selection';
import { Utils } from 'utils/utils';
import { Endpoints } from 'endpoints';

@autoinject
@viewResources(StorageSelection)
export class UpdateLocations extends ViewModelBase {
  public ribbonHeaderText: string = this.i18n.tr("location.massiveUpdate");
  public ressourceName: string = "";

  private storageLocationTypeService: EnumerationTypeService;
  private articleRotationLevelTypeService: EnumerationTypeService;
  private locationBlockService: EnumerationTypeService;

  private locationUpdator: Zeus.Web.Model.LocationUpdator = {};

  private locationsToUpdate: Array<Zeus.Web.Model.LocationToUpdate> = [];
  private notFoundLevel: number;
  private newStorageLocationType: string;
  private newDedicatedToRotationLevel: string;
  private newLocationBlock: string;
  private isValidDTO: boolean = false;

  constructor(router: Router, logger: CustomLogger, private dialogService: DialogService, private httpClient: HttpClient, private dialogController: DialogController) {
    super(router, logger);
    this.storageLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageLocationType);
    this.articleRotationLevelTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ArticleRotationLevel);
    this.locationBlockService = new EnumerationTypeService(Constants.EnumerationTypes.InventoryBlock);
  }

  @computedFrom(
    'locationUpdator.storageLocationType', 'locationUpdator.dedicatedToRotationLevel', 'locationUpdator.locationBlock')
  private get isInvalid(): boolean {
    return this.locationUpdator.storageLocationType == null
      && this.locationUpdator.dedicatedToRotationLevel == null
      && this.locationUpdator.locationBlock == null;
  }

  private async bulkUpdateLocations() {
    if (true == this.isValidDTO && false == this.isInvalid) {
      this.notFoundLevel = null;
      let response: Response = await this.httpClient.post(Constants.Application.RemoteServiceName + Endpoints.Location.BulkUpdateLocations, json(this.locationUpdator));
      if (response.ok) {
        let result: Zeus.Web.Model.LocationUpdaterResult = JSON.parse(await response.text(), (key: string, value: any) => { return CamelCase.convertKeysToCamelCase(key, value) });
        this.notFoundLevel = result.locationLevelWithoutResults;
        toastr.success(this.i18n.tr("location.massiveUpdateDone"));
        this.dialogController.ok();
      }
      else {
        toastr.error(this.i18n.tr("location.massiveUpdateError"));
      }
    }
    else {
      toastr.error(this.i18n.tr("location.cantUpdateWithNothing"));
    }
  }

  private async generatePreview() {
    if (true == this.isValidDTO && false == this.isInvalid) {
      this.notFoundLevel = null;
      let response: Response = await this.httpClient.post(Constants.Application.RemoteServiceName + Endpoints.Location.GetLocationsToUpdate, json(this.locationUpdator));
      if (response.ok) {
        let result: Zeus.Web.Model.LocationUpdaterResult = JSON.parse(await response.text(), (key: string, value: any) => { return CamelCase.convertKeysToCamelCase(key, value) });
        this.notFoundLevel = result.locationLevelWithoutResults;
        this.locationsToUpdate.splice(0);
        this.locationsToUpdate.push(...result.locationsToUpdate);
        this.newStorageLocationType = (await this.storageLocationTypeService.getEntityByIdFromLocalCache(Utils.extractIdFromDataFormat(this.locationUpdator.storageLocationType)))?.denomination._translation;
        this.newDedicatedToRotationLevel = (await this.articleRotationLevelTypeService.getEntityByIdFromLocalCache(Utils.extractIdFromDataFormat(this.locationUpdator.dedicatedToRotationLevel)))?.denomination._translation;
        this.newLocationBlock = (await this.locationBlockService.getEntityByIdFromLocalCache(Utils.extractIdFromDataFormat(this.locationUpdator.locationBlock)))?.denomination._translation;
      }
      else {
        toastr.error(this.i18n.tr("location.cantGeneratePreview"));
      }
    }
  }

  private async consultTray(trayContainerId: number, locationId: number) {
    await this.dialogService.open({
      viewModel: TrayPreviewDialog,
      model: { trayId: trayContainerId, locationId: locationId },
    });
  }

  public close() {
    this.dialogController.cancel();
  }
}
