import environment from 'environment';
import { EntityTypeNames as CoreEntityTypeNames } from 'digiwall-lib';

export class Application {
  public static ApplicationTitle = "Vanasoft WMS";
  public static get RemoteServiceName() {
    return environment.remoteApiUrl;
  }
  public static RemoteFileServiceName = "/api/file/upload";
  public static RemoteFileServiceRotate = "/api/file/rotate";

  public static SettingSocketPort = 55100;
  public static SettingSocketIpAddress = '127.0.0.1';
  public static SettingSendTimeOutMs = 100;
  public static SettingReceiveTimeOutMs = 200;
}

export class Roles {
  public static Administrator = "Administrator";
  public static Picker = "Picker";
  public static Helpdesk = "Helpdesk";
}

export class RoleNames {
  public static Mobile = "role.mobile"
}

export enum EnumRoles {
  Helpdesk = 1,
  Administrator,
  Picker,
}

export class Permissions {
  public static Helpdesk = 'all:helpdesk';
  public static Access = 'access';
  public static LaunchBatchProcess = 'launch';
  public static CancelBatchProcess = 'cancel';
  public static FreeSiteSelection = 'free-site-selection';
  public static StorageGroupParam = 'storageGroupParameter';
  public static AddInventory = "addinventory";
  public static Consult = 'consult';
  public static InputOrdersMissingData = "inputOrderMissingData"
}

export class Extensions {
  public static All = "[a-zA-Z0-9_]*";
  public static File = ".jpeg,.png,.jpg,.svg,.gif,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx";
  public static Image = ".jpeg,.png,.jpg";
  public static Office = ".doc,.docx,.ppt,.pptx,.xls,.xlsx";
}

export class AGGridColumnsWidth {
  public static IsSelected = 40;
}

export enum EnumerationTypes {
  Language = 0,
  StorageGroupType = 2,
  StorageType = 3,
  DynamicStorageType = 4,
  StorageVolumeType = 5,
  UnitOfMeasure = 6,
  ModulaMessageType = 7,
  DeviceType = 8,
  ArticleDisplayableData = 9,
  StorageLocationType = 10,
  ArticleRotationLevel = 11,
  WorkOrderType = 12,
  OrderSubtype = 13,
  WorkOrderStatus = 14,
  SortingLocationType = 15,
  StorageStrategy = 16,
  BatchProcessExecutionStatus = 17,
  BatchProcessStorageType = 18,
  BatchProcessResultStatus = 19,
  DataFileImportType = 20,
  InventoryTarget = 21,
  FileType = 24,
  PickingGoal = 27,
  ERPReturnCommunicationType = 28,
  InventoryBlock = 37,
  AlternateLocationOnShortPick = 43
};

export enum ReportType {
  Docx, Pdf, Zip, Xlsx
};

export class MenuType {
  public static HomePage = "menu.homePage";
  public static Users = "menu.users";
  public static Storages = "menu.storages";
  public static StorageGroups = "menu.storageGroups";
  public static Locations = "menu.locations";
  public static EnumerationStoragevolumeTypes = "menu.numeration-storagevolumetypes";
  public static EnumerationUnitOfMeasures = "menu.numeration-unitofmeasures";
  public static ApplicationParameters = "menu.applicationParameters";
  public static HardwareParameters = "menu.hardwareParameters";
  public static Articles = "menu.articles";
  public static Orders = "menu.orders";
  public static WorkOrders = "menu.workorders";
  public static Reports = "menu.reports";
  public static Tests = "Tests";
}

export class RouteName {
  public static ImportLog = "data-file-import-log";
  public static AppParam = "application-parameters";
  public static SentMessage = "sent-messages";
  public static Storages = "storages";
  public static Locations = "locations";
  public static ProcessMonitoring = "process-monitoring";
}

export class Various {
  public static SessionSelectedSiteManagementEvent = "selected-site-changed";

  public static DelayBeforeLogoutChangedEvent = "delay-before-logout-changed-event";
}

export class BroadcastChannel {
  public static AutoLogout = "auto_logout";
  public static AutoLogoutRestart = "auto_logout_restart";
  public static AutoLogoutDisconnect = "auto_logout_disconnect";
}

export class Language {
  public static FR = "FR";
  public static NL = "NL";
}

export class Themes {
  static YellowTheme = {
    primaryColor: "#fdd835",
    accentColor: "#fbc02d",
    errorColor: "red"
  }
  static RedTheme = {
    primaryColor: "#e64a19",
    accentColor: "#bf360c",
    errorColor: "red"
  }
  static GreyTheme = {
    primaryColor: "#595959",
    accentColor: "#666666",
    errorColor: "red",
    navbar: {
      bgColor: "white",
      color: "#6C6C6C"
    }
  }
}

export class EntityTypeNames {
  public static EnumerationType = "EnumerationType";
  public static Locality = "Locality";
  public static Country = "Country";
  public static LanguagePriority = "LanguagePriority";
  public static LocationLevel = "LocationLevel";
  public static LocationFormat = "LocationFormat";
  public static Storage = "Storage";
  public static StorageGroup = "StorageGroup";
  public static StorageType = "StorageType";
  public static ZeusUser = "ZeusUser";
  public static EnumerationUnitOfMeasure = "EnumerationUnitOfMeasure";
  public static AgGridView = "AgGridView";
  public static ArticleFamily = "ArticleFamily";
  public static Article = "Article";
  public static ArticleUnitOfMeasureSize = "ArticleUnitOfMeasureSize";
  public static ArticleStorageGroup = "ArticleStorageGroup";
  public static ArticleSubUnitOfMeasure = "ArticleSubUnitOfMeasure";
  public static ArticleVolumeConfig = "ArticleVolumeConfig";
  public static ArticleBarcodeEan = "ArticleBarcodeEan";
  public static Location = "Location";
  public static LocationVolume = "LocationVolume";
  public static StorageVolume = "StorageVolume";
  public static Message = "Message";
  public static ArticleReferenceAliasTag = "ArticleReferenceAliasTag";
  public static DynamicStorageTechnicalSetting = "DynamicStorageTechnicalSetting";
  public static BayDevice = "BayDevice";
  public static Bay = "Bay";
  public static ArticleLocation = "ArticleLocation";
  public static WorkOrder = "WorkOrder";
  public static ArticleToInput = "ArticleToInput";
  public static ArticleToPick = "ArticleToPick";
  public static ArticleToAction = "ArticleToAction";
  public static DynamicStorageSentMessage = "DynamicStorageSentMessage";
  public static AuditLog = "AuditLog";
  public static EnumerationTypeColor = "EnumerationTypeColor";
  public static TrayTemplate = "TrayTemplate";
  public static TrayTemplatePosition = "TrayTemplatePosition";
  public static DynamicStorageReceivedMessage = "DynamicStorageReceivedMessage";
  public static ArticleArticleFamily = "ArticleArticleFamily";
  public static ApplicationParameters = "ApplicationParameters";
  public static HardwareParameters = "HardwareParameters";
  public static WorkOrderPriority = "WorkOrderPriority";
  public static LocationForInputPicking = "LocationForInputPicking";
  public static LocationForInput = "LocationForInput";
  public static LocationForPicking = "LocationForPicking";
  public static SiteStructureGeneratorModel = "SiteStructureGeneratorModel";
  public static ResetData = "ResetData";
  public static BatchProcess = "BatchProcess";
  public static Supplier = "Supplier";
  public static SupplierArticle = "SupplierArticle";
  public static ActionHistory = "ActionHistory";
  public static UserSiteManagement = "UserSiteManagement";
  public static DataFileImportLog = "DataFileImportLog";
  public static LocationForInventory = "LocationForInventory";
  public static InventoryWorkOrderManyToMany = "InventoryWorkOrderManyToMany";
  public static InventoryWorkOrderTrayContainer = "InventoryWorkOrderTrayContainer";
  public static InventoryWorkOrderStorage = "InventoryWorkOrderStorage";
  public static InventoryWorkOrderArticle = "InventoryWorkOrderArticle";
  public static TrayContainer = "TrayContainer";
  public static InformationMessage = "InformationMessage";
  public static Project = "Project";
  public static CostCenter = "CostCenter";
  public static InputPickingAlgorithm = "InputPickingAlgorithm";
  public static InputPickingAlgorithmPart = "InputPickingAlgorithmPart";
  public static PickingAlgorithm = "PickingAlgorithm";
  public static PickingAlgorithmPart = "PickingAlgorithmPart";
  public static InputAlgorithm = "InputAlgorithm";
  public static InputAlgorithmPart = "InputAlgorithmPart";
  public static WOMessageType = "WOMessageType";
  public static BayMessageType = "BayMessageType";
  public static CycleCountParameter = "CycleCountParameter";
  public static ErpBinDestination = "ErpBinDestination";
  public static ConveyorInfo = "ConveyorInfo";
  public static SequenceNumber = "SequenceNumber";
  public static CustomProperties = "CustomProperties";
  public static ReceptionParameters = "ReceptionParameters";
  public static MissingDataInputWorkOrderLine = "MissingDataInputWorkOrderLine";
  public static ReceptionParametersField = "ReceptionParametersField";
  public static OverflowStorageVolume = "OverflowStorageVolume";

  public static ResourceNames = {
    AgGridView: "AgGridViews",
    EnumerationType: "EnumerationTypes",
    Locality: "Localities",
    Country: "Countries",
    LanguagePriority: "LanguagePriorities",
    User: "Users",
    LocationLevel: "LocationLevels",
    LocationFormat: "LocationFormats",
    Storage: "Storages",
    StorageGroup: "StorageGroups",
    ArticleFamily: "ArticleFamilies",
    Article: "Articles",
    ZeusUser: "ZeusUsers",
    Role: CoreEntityTypeNames.ResourceNames.Role,
    ArticleUnitOfMeasureSize: "ArticleUnitOfMeasureSizes",
    ArticleStorageGroup: "ArticleStorageGroups",
    ArticleSubUnitOfMeasure: "ArticleSubUnitOfMeasures",
    ArticleVolumeConfig: "ArticleVolumeConfigs",
    Location: "Locations",
    LocationVolume: "LocationVolumes",
    StorageVolume: "StorageVolumes",
    Message: "Messages",
    ArticleReferenceAliasTag: "ArticleReferenceAliasTags",
    ArticleBarcodeEan: "ArticleBarcodeEans",
    DynamicStorageTechnicalSetting: "DynamicStorageTechnicalSettings",
    BayDevice: "BayDevices",
    Bay: "Bays",
    ArticleLocation: "ArticleLocations",
    WorkOrder: "WorkOrders",
    ArticleToPick: "ArticleToPicks",
    ArticleToInput: "ArticleToInputs",
    ArticleToAction: "ArticleToActions",
    DynamicStorageSentMessage: "DynamicStorageSentMessages",
    AuditLog: "AuditLogs",
    DynamicStorageReceivedMessage: "DynamicStorageReceivedMessages",
    EnumerationTypeColor: "EnumerationTypeColors",
    TrayTemplate: "TrayTemplates",
    TrayTemplatePosition: "TrayTemplatePositions",
    ArticleArticleFamily: "ArticleArticleFamilies",
    ApplicationParameters: "ApplicationParameters",
    HardwareParameters: "HardwareParameters",
    WorkOrderPriority: "WorkOrderPriorities",
    LocationForAction: "LocationForActions",
    LocationForInputPicking: "LocationForInputPickings",
    LocationForInput: "LocationForInputs",
    LocationForPicking: "LocationForPickings",
    LocationForInventories: "LocationForInventories",
    BatchProcess: "BatchProcesses",
    Supplier: "Suppliers",
    SupplierArticle: "SupplierArticles",
    ActionHistory: "ActionHistories",
    UserSiteManagement: "UserSiteManagements",
    DataFileImportLog: "DataFileImportLogs",
    InformationMessage: "InformationMessages",
    TrayContainer: "TrayContainers",
    LocationForInventory: "LocationForInventories",
    InventoryWorkOrderTrayContainer: "InventoryWorkOrderTrayContainers",
    InventoryWorkOrderStorage: "InventoryWorkOrderStorages",
    InventoryWorkOrderArticle: "InventoryWorkOrderArticles",
    Project: "Projects",
    CostCenter: "CostCenters",
    InputPickingAlgorithm: "InputPickingAlgorithms",
    InputPickingAlgorithmPart: "InputPickingAlgorithmParts",
    PickingAlgorithm: "PickingAlgorithms",
    PickingAlgorithmPart: "PickingAlgorithmParts",
    InputAlgorithm: "InputAlgorithms",
    InputAlgorithmPart: "InputAlgorithmParts",
    WOMessageType: "WOMessageTypes",
    BayMessageType: "BayMessageTypes",
    CycleCountParameter: "CycleCountParameters",
    ErpBinDestination: "ErpBinDestination",
    ConveyorInfo: "ConveyorInfo",
    SequenceNumber: "SequenceNumbers",
    CustomProperties: "CustomProperties",
    ReceptionParameters: "ReceptionParameters",
    MissingDataInputWorkOrderLine: "MissingDataInputWorkOrderLines",
    ReceptionParametersField: "ReceptionParametersFields",
    OverflowStorageVolume: "OverflowStorageVolumes"
  };
}

export const RefreshHomePageEvent = 'RefreshHomePage';
export const ImportFinishedEvent = 'ImportFinished';
export const SiteSelectionChanged = 'SiteSelectionChanged';

export class TimeInMilliseconds {
  public static HourInMilliseconds: number = 3600000;
  public static DayInMilliseconds: number = 86400000;
  public static WeekInMilliseconds: number = 604800000;
  public static MonthInMiliseconds: number = 2592000000;
}

export enum StorageGroupTypes {
  Site = 20,
  Warehouse = 21,
  ZoneGroup = 22,
  Zone = 23
};

export enum StorageTypes {
  Dynamic = 30,
  Static = 31
};

export enum DeviceType {
  LaserPointer = 81,
  Footbar = 82
};

export enum MessageType {
  MoveTrayToBay = 70,
  MoveTrayForInput = 71,
  MoveTrayForPicking = 72,
  MoveTrayForInventory = 73,
};

export enum WorkOrderType {
  Input = 110,
  ArticleMoving = 111,
  Inventory = 112,
  Picking = 113
};

export enum OrderSubtype {
  NormalInput = 120,
  PickingReturn = 122,
  Borrowing = 123,
  TrayPick = 124
}

export enum WorkOrderStatus {
  InConstruction = 130,
  ReadyToBeProcessed = 131,
  UnderProcess = 132,
  Finalised = 133,
  Cancelled = 134,
  PartiallyProcessed = 135,
  FinalisedIncomplete = 136
};


export enum BatchProccesExecutionStatus {
  Finalised = 170,
  InProgress = 171,
  Expired = 172,
  Cancelled = 173
};

export enum BatchProcessResultStatus {
  Completed = 190,
  PartiallyDone = 191,
  PartiallyDoneWithErrors = 192,
  NothingProcessed = 193
}

export enum SortingLocation {
  ByArticleDefaultVolume = 150,
  ByArticleABCRotationLevel = 151,
  ByArticleStockSpreadingAmongStorages = 152,
  ByLocationStringRepresentation = 153,
  ByNumberOfOccupiedLocationSpeadingAmongStorages = 154,
  FirstLocationLinkedtoTheArticle = 155,
  ByReducingtheNumberOfLocationNeeded = 156,
  BySpreadingTheCurrentWorkChargeAmongStorages = 157
}

export enum InventoryTarget {
  TrayContainer = 210,
  Storage = 211,
  Article = 212,
  ArticleLocations = 213,
  CycleCount = 214
}

export enum FileTypeId {
  LabelTemplateFile = 240
}

export enum InventoryBlockId {
  Unlocked = 370, Locked = 371
}

export class EnumColorWOToProcess {
  public static Green: String = "lightgreen";
  public static Orange: String = "lightsalmon";
  public static Red: String = "lightcoral";
  public static White: String = "white";
}

export class WorkOrderProcessEvents {
  public static ReloadWorkOrder = "ReloadWorkOrder";
  public static BatchProcessSent = "BatchProcessSent";
  public static ReloadArticleToActions = "ReloadArticleToActions";
  public static ReloadTrayPickWorkOrders = "ReloadTrayPickWorkOrders";
}

export enum DataFileImportType {
  Article = 200,
  Supplier,
  CostCenter,
  Project,
  InputWorkOrder,
  PickingWorkOrder,
  InventoryWorkOrder
}

export enum ActionInvWOError {
  launch, cancel, edit
}

export class SessionStorage {
  public static WoCreateShortcuts = "wo-create-shortcuts";
}

export enum CarrierType {
  BinAssignment = "BinAssignment",
  WorkstationPositionAssignment = "WorkstationPositionAssignment",
}

export class GridColors {
  public static Warning = "lightcoral"
}

export class ReceptionParametersFieldName {
  public static BaseUOMDimensions: string = "BaseUOMDimensions";
  public static BaseUOMWeight: string = "BaseUOMWeight";
  public static DefaultVolumeWithMaxQty: string = "DefaultVolumeWithMaxQty";
  public static PicturePresent: string = "PicturePresent";
  public static PictureRecent: string = "PictureRecent";
  public static NbrLocationsBelowMax: string = "NbrLocationsBelowMax";
  public static LocationType1: string = "LocationType1";
  public static ArticleRotationLevel: string = "ArticleRotationLevel";
  public static Handling: string = "Handling";
  public static EANCode: string = "EANCode";
  public static MaxBinWeight: string = "MaxBinWeight";
}
export namespace UIInternal {
  export const EVT_UI_TABLE_PAGINATION_RELOAD = "EVT_UI_TABLE_PAGINATION_RELOAD";
}
