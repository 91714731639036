import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, ListViewModelBase, FieldType, Various, GridHelper, getButtonsColDef, ButtonsColDefAction } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { ColDef } from 'ag-grid-community';
import { Endpoints } from 'endpoints';

@autoinject
export class OrderTracking extends ListViewModelBase<Zeus.Web.Model.DynamicStorageSentMessage> {
  public ressourceName: string = Constants.EntityTypeNames.BatchProcess;

  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private httpClient: HttpClient) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.DynamicStorageSentMessage>(Constants.EntityTypeNames.DynamicStorageSentMessage));
    this.service.gridDataSource.expands = ['batchProcess.executionStatus', 'batchProcess.resultStatus', 'originalWorkOrder.workOrderType',
      'originalWorkOrder.workOrderPriority', 'locationForAction.location', 'locationForAction.article', 'locationForAction.unitOfMeasure',
      'orderStatus', 'workstationUser'];
    this.service.gridDataSource.queryParameters = { isOrderTracking: true };
  }

  public async activate(params) {
    await super.activate(params);
    if (!isNaN(params.param1)) {
      this.service.gridDataSource.queryParameters = { batchId: params.param1 };
      this.initializeGridOptions();
    }
  }

  public afterResetFilters() {
    this.agGridSortingHelper.applyDefaultSortModel();
  }

  getDetailsUrl(self: any, entity: Zeus.Web.Model.DynamicStorageSentMessage): string {
    return '/order-tracking/' + entity.id;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      getButtonsColDef<Zeus.Web.Model.DynamicStorageSentMessage>([
        new ButtonsColDefAction<Zeus.Web.Model.DynamicStorageSentMessage>({
          icon: 'digi-impossible',
          uiTheme: 'danger',
          title: this.i18n.tr('ordertracking.cancel'),
          hidden: dynamicStorageSentMessage => dynamicStorageSentMessage?.batchProcess?.executionStatusId != Constants.BatchProccesExecutionStatus.InProgress,
          clicked: async dynamicStorageSentMessage => {
            let response = await this.httpClient.post(Endpoints.BatchProcess.CancelDynamicStorageSentMessage
              + "?dynamicStorageSentMessageId=" + dynamicStorageSentMessage.id);
            if (response.ok) {
              this.log(this.i18n.tr('ordertracking.cancelled'), null, true);
              // Refresh data
              this.initializeGridOptions();
            } else {
              this.logError(await response.text(), null, true);
            }
          }
        })
      ]),
      {
        headerName: this.i18n.tr("ordertracking.process"),
        field: "batchProcess.id",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("batchprocess.startProcessDateTime"),
        field: "batchProcess.startProcessDateTime",
        type: FieldType.DateTime,
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormatWithHours); },
        sort: 'desc'
      },
      {
        headerName: this.i18n.tr("batchprocess.endProcessDateTime"),
        field: "batchProcess.endProcessDateTime",
        type: FieldType.DateTime,
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormatWithHours); },
      },
      {
        headerName: this.i18n.tr("batchprocess.createdByFullName"),
        field: "workstationUser.fullName",
        type: FieldType.User,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          userServiceName: Constants.EntityTypeNames.ZeusUser,
        }
      },
      {
        headerName: this.i18n.tr("batchprocess.workOrderId"),
        field: "originalWorkOrderId",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("workorder.workOrderName"),
        field: "originalWorkOrder.workOrderName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workorder.workOrderTypeId"),
        field: "originalWorkOrder.workOrderType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.WorkOrderType,
        }
      },
      {
        headerName: this.i18n.tr("locationforinput.locationId"),
        field: "locationForAction.location.locationIdentifier",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("articletoinput.articleId"),
        field: "locationForAction.article.reference",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("article.description") + '(FR)',
        field: "locationForAction.article.description.lang1",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.description") + '(NL)',
        field: "locationForAction.article.description.lang2",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.description") + '(EN)',
        field: "locationForAction.article.description.lang3",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("ordertracking.quantity"),
        field: "locationForAction.requestedActionQuantity",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("ordertracking.realQuantity"),
        field: "locationForAction.reallyActionQuantity",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("ordertracking.countedQuantity"),
        field: "locationForAction.countedQuantity",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("articleunitofmeasuresize.unitOfMeasureId"),
        field: "locationForAction.unitOfMeasure.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.UnitOfMeasure,
        }
      },
      {
        headerName: this.i18n.tr("workorder.workOrderPriorityId"),
        field: "originalWorkOrder.workOrderPriority.name._translation",
        cellRenderer: 'enumerationRenderer',
        type: FieldType.OneToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          serviceName: Constants.EntityTypeNames.WorkOrderPriority,
        }
      },
      {
        headerName: this.i18n.tr("ordertracking.processStatus"),
        field: "batchProcess.executionStatus.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.BatchProcessExecutionStatus,
        }
      },
      {
        headerName: this.i18n.tr("batchprocess.resultStatus"),
        field: "batchProcess.resultStatus.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.BatchProcessResultStatus,
        }
      },
      {
        headerName: this.i18n.tr("ordertracking.orderStatus"),
        field: "orderStatus.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.BatchProcessResultStatus,
        }
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
}
