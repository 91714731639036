import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class LocationFormatList extends ListViewModelBase<Zeus.Web.Model.LocationFormat> {
  constructor(
    router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.LocationFormat>(Constants.EntityTypeNames.LocationFormat));
  }

  public ressourceName: string = Constants.EntityTypeNames.LocationFormat;

  public getDetailsUrl(self, entity: Zeus.Web.Model.LocationFormat) {
    if (entity && entity.id) {
      let url = '/location-formats/' + entity.id;
      return url;
    }
    return null;
  }

  protected isSelectable(entity: Zeus.Web.Model.LocationFormat): boolean {
    return !entity?.isDynamicStorageFormat;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("locationformat.name"),
        field: "name",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("locationformat.mask"),
        field: "mask",
        type: FieldType.String
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
} 
