import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { Actions, PermissionsConfig } from "digiwall-lib";
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";
import translationJson from './locales/index';
import { Container } from "aurelia-dependency-injection";
import { MenuType, PermissionContexts, PermissionName, EntityTypeNames } from "./constants";
import { Permissions, EntityTypeNames as EntityTypeNamesRoot, RouteName, MenuType as MenuTypeRoot } from "../../constants";

export class FreePickingRoot extends AppModuleRoot {
  public get name() {
    return AppModule.FreePicking;
  }

  public registerServices(instance: Container): void {
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return {};
  }

  protected get pathName(): string {
    return 'free-picking';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      {
        before: RouteName.AppParam,
        route: 'reports/free-picking',
        name: 'borrowing/free-picking',
        moduleId: 'reports/free-picking',
        title: 'report.title',
        nav: true,
        settings: {
          group: MenuTypeRoot.Reports,
          action: Actions.Read
        }
      }
    ];
  }

  protected get resourceNames(): {} {
    return EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        { context: PermissionContexts.ExtraPick, qualifiedName: [MenuType.FreePicking] },
        { context: PermissionContexts.TrayPick, qualifiedName: [MenuType.FreePicking] }
      ],
      permissionNames: {
        [PermissionContexts.ExtraPick]: {
          [Permissions.Access]: PermissionName.ExtraPick
        },
        [PermissionContexts.TrayPick]: {
          [Permissions.Access]: PermissionName.TrayPick
        }
      }
    };
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
    metadataStore.registerEntityTypeCtor(EntityTypeNamesRoot.WorkOrder, function () {
      this.trayPicks = [];
    });
  }
}
