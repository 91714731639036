export class Application {
  public static VerifyBayOfGroupColorByWorkstationBayGroup = "/api/DropToLight/Utils/VerifyBayOfGroupColorByWorkstationBayGroup";
  public static VerifyBayOfGroupColorByBay = "/api/DropToLight/Utils/VerifyBayOfGroupColorByBay";
  public static VerifyUniqueLogicalId = "/api/DropToLight/Utils/VerifyUniqueLogicalId";
}

export enum EnumerationTypes {
  ColorMode = 29,
  DTLTagDisplayMode = 30,
  BayGroupType = 31,
  DTLColor = 32,
  WorkstationPositionSide = 33,
  DTLDisplayType = 34,
  WorkstationPositionColor = 35,
}

export class EntityTypeNames {
  public static Workstation = "Workstation";
  public static WorkstationBayGroup = "WorkstationBayGroup";
  public static BayOfWorkstationBayGroup = "BayOfWorkstationBayGroup";
  public static DTLController = "DTLController";
  public static DTLWorkstationPosition = "DTLWorkstationPosition";
  public static DTLTag = "DTLTag";
  public static DTLTagType = "DTLTagType";
  public static DTLTagTypeColor = "DTLTagTypeColor";
  public static DTLTagTypeDisplay = "DTLTagTypeDisplay";
  public static DTLTagTypeDisplayMode = "DTLTagTypeDisplayMode";
  public static WorkstationPosition = "WorkstationPosition";

  public static ResourceNames = {
    Workstation: "Workstations",
    WorkstationBayGroup: "WorkstationBayGroups",
    BayOfWorkstationBayGroup: "BayOfWorkstationBayGroups",
    DTLController: "DTLControllers",
    DTLWorkstationPosition: "DTLWorkstationPositions",
    DTLTag: "DTLTags",
    DTLTagType: "DTLTagTypes",
    DTLTagTypeColor: "DTLTagTypeColors",
    DTLTagTypeDisplay: "DTLTagTypeDisplays",
    DTLTagTypeDisplayMode: "DTLTagTypeDisplayModes",
    WorkstationPosition: "WorkstationPositions",
  };
}

export enum ColorModeId {
  DTLColor = 290,
  DynamicStorageColor = 291,
  NoColor = 292
}

export enum BayGroupTypeId {
  Input = 310,
  Picking = 311
}

export enum WorkstationPositionSideId {
  One = 330,
  Two = 331
}
