.work-order-to-process {
  .container-row {
    height: calc(100% - 1rem);
    > .col {
      height: 100%;
      padding: 0;
    }
  }
}

.wo-line {
  &-white,
  &-white.ag-row-hover:not(.ag-full-width-row)::before {
    background-color: white !important;
  }
  &-green,
  &-green.ag-row-hover:not(.ag-full-width-row)::before {
    background-color: lightgreen !important;
  }
  &-orange,
  &-orange.ag-row-hover:not(.ag-full-width-row)::before {
    background-color: lightsalmon !important;
  }
  &-red,
  &-red.ag-row-hover:not(.ag-full-width-row)::before {
    background-color: lightcoral !important;
  }
}
