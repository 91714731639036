import { I18N } from 'aurelia-i18n';
import { HttpClient } from 'aurelia-fetch-client';
import { Container, autoinject } from "aurelia-framework";
import { ServiceBase } from 'digiwall-lib';
import { Zeus } from "generated";
import * as Constants from '../constants';
import * as toastr from 'toastr';
import { Endpoints } from 'endpoints';
import { LocationForActionService } from './utils/location-for-action-service';

@autoinject
export class ApiService {

  private _articleToActionService: ServiceBase<Zeus.Web.Model.ArticleToAction>;
  private _lfipService: ServiceBase<Zeus.Web.Model.LocationForInputPicking>;
  private _locationService: ServiceBase<Zeus.Web.Model.Location>;

  constructor(private http: HttpClient, private i18n: I18N) {
  }

  // Lazy load services on getter
  private get articleToActionService(): ServiceBase<Zeus.Web.Model.ArticleToAction> {
    if (this._articleToActionService == null) {
      this._articleToActionService = new ServiceBase<Zeus.Web.Model.ArticleToAction>(Constants.EntityTypeNames.ArticleToAction);
    }
    return this._articleToActionService;
  }
  private get lfipService(): ServiceBase<Zeus.Web.Model.LocationForInputPicking> {
    if (this._lfipService == null) {
      this._lfipService = new ServiceBase<Zeus.Web.Model.LocationForInputPicking>(Constants.EntityTypeNames.LocationForInputPicking);
    }
    return this._lfipService;
  }
  private get locationService(): ServiceBase<Zeus.Web.Model.Location> {
    if (this._locationService == null) {
      this._locationService = new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location);

      let entityType = this.locationService.manager.metadataStore.getEntityType(Constants.EntityTypeNames.Location);
      if (!entityType || !(entityType as any).isFrozen) {
        this._locationService.manager.metadataStore.registerEntityTypeCtor(Constants.EntityTypeNames.Location, function () {
          this.usedSpaceForInput = 0;
          this.freeSpaceForInput = 0;
        });
      }
    }
    return this._locationService;
  }

  public async getCompatibleLocationForArticleToAction(articleToAction: Zeus.Web.Model.ArticleToAction, paging?: { skip?: number; take?: number; pageNumber?: number; }): Promise<Zeus.Web.Model.Location[]> {
    if (articleToAction != null) {
      let dto = new GetLocationForActionDTO(articleToAction);
      return this.locationService.getEntities(null, null, { getLocationForActionDTO: btoa(dto.stringify()) }, null, paging);
    }
  }

  /**
   * Get all locationForInputPickings of the given articleToAction.
   * It updates the breeze entity properties cache directly.
   */
  public async getLocationForInputPickings(articleToAction: Zeus.Web.Model.ArticleToAction): Promise<void> {
    if (articleToAction != null && articleToAction.isFindingLocation != true) {

      articleToAction.isFindingLocation = true;
      if (articleToAction.locationForInputPickings.length) {
        await this.deleteArticleToActionsLocationForInputPickings([articleToAction]);
      }

      let dto = new GetLocationForActionDTO(articleToAction);

      let response = await this.http.post(Endpoints.WorkOrder.GetLocationForInputPicking, dto.stringify());

      if (response.ok) {
        await this.articleToActionService.getEntityById(articleToAction.id, 'locationForInputPickings.location.alternateLocationVolumes.storageVolume.volumeType',
          'locationForInputPickings.location.defaultStorageVolume.volumeType', 'locationForInputPickings.location.articleLocations.article');

        articleToAction.locationForInputPickings.sort((a, b) => a.id - b.id);
        let totalRequestedQuantity = articleToAction.locationForInputPickings.reduce((sum, location: Zeus.Web.Model.LocationForInputPicking) => {
          return sum + location.requestedActionQuantity;
        }, 0);

        let requestedQty = articleToAction.requestedActionQuantity;
        if (articleToAction.actionWorkOrder.workOrderTypeId == Constants.WorkOrderType.Picking) {
          let articleUOM = articleToAction.article.unitOfMeasures.find(x => x.unitOfMeasureId == articleToAction.unitOfMeasureId);
          if (articleUOM != null) {
            requestedQty *= articleUOM.quantityInStockCountUOM;
          }
        }

        articleToAction.qtyNotInlocation = requestedQty - totalRequestedQuantity;
        articleToAction.locatedAll = articleToAction.qtyNotInlocation == 0;
      }

      articleToAction.isFindingLocation = false;
      return Promise.resolve(null);
    }
  }

  public async getLocationForInputPickingFromList(articleToActions: Array<Zeus.Web.Model.ArticleToAction>): Promise<Array<Zeus.Web.Model.GetLocationForActionResponse>> {
    if (articleToActions?.length > 0) {

      await this.deleteArticleToActionsLocationForInputPickings(articleToActions);

      let dtos = articleToActions.map(a => new GetLocationForActionDTO(a));

      let response = await this.http.post(Endpoints.WorkOrder.GetLocationForInputPickingFromList, JSON.stringify(dtos));

      let apiResponse: Array<Zeus.Web.Model.GetLocationForActionResponse> = await response.json();
      return Promise.resolve(apiResponse);
    }
    return Promise.resolve(null);
  }

  /**
   * Delete the locationForInputPickings of the given work order. 
   * It updates the breeze entity properties cache directly.
   *
   * @param {Zeus.Web.Model.WorkOrder} workOrder
   * @memberof ApiService
   */
  public async deleteWorkOrderLocationForInputPickings(workOrder: Zeus.Web.Model.WorkOrder, articleToActionToKeep: number[] = null): Promise<boolean> {
    if (workOrder.workOrderStatusId === Constants.WorkOrderStatus.Finalised) return Promise.resolve(false);
    if (workOrder?.articleToActions?.length) {
      return this.deleteArticleToActionsLocationForInputPickings(workOrder.articleToActions.filter(ati => null == (articleToActionToKeep || []).find(id => id == ati.id)));
    } else {
      return Promise.resolve(false);
    }
  }

  public async unloadWorkOrders(workOrderIds: number[], articleToActionToKeepIds: number[] = null, articleToActionToForceDeleteIds: number[] = null) {
    let url = this.http.baseUrl + Endpoints.WorkOrder.UnloadWorkOrders;
    let dto: UnloadWorkordersDto = { workOrderIds, articleToActionToKeepIds, articleToActionToForceDeleteIds };
    let request = new Request(url, {
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(dto),
      keepalive: true
    });
    let response = await this.http.post(request, JSON.stringify({ workOrderIds }))
    return response.ok;
  }

  /**
   *
   *
   * @param {Zeus.Web.Model.ArticleToAction[]} articleToActions
   * @return {*}  {Promise<void>}
   * @memberof ApiService
   */
  public async deleteArticleToActionsLocationForInputPickings(articleToActions: Zeus.Web.Model.ArticleToAction[], silentAlert = true): Promise<boolean> {
    try {
      if (articleToActions?.length > 0) {
        let toDel: Zeus.Web.Model.LocationForInputPicking[] = [];
        for (let ata of articleToActions) {
          let locs = Container.instance.get(LocationForActionService).extractDeletableLocationForInputPickings(ata);
          if (locs.length) {
            toDel = toDel.concat(...locs);
          }
        };

        if (false == silentAlert && articleToActions.some(ati => ati.locationForInputPickings.some(lfi => lfi.manuallyBlocked && lfi.msgHasBeenSent == false))) {
          toastr.info(this.i18n.tr('workorder.containsManuallyBlockedLocations'));
        }

        if (toDel.length) {
          return await this.lfipService.deleteEntities(toDel, false, null, true);
        } else {
          Promise.resolve(true);
        }
      } else {
        Promise.resolve(true);
      }
    } catch (error) {
      console.error(error);
      Promise.resolve(false);
    }
  }

  public async computeUsedAndFreeSpaceForInput(articleToInput: Zeus.Web.Model.ArticleToInput) {
    return this._computeUsedAndFreeSpaceForInput(articleToInput);
  }

  public async computeUsedAndFreeSpaceForInputWithParams(articleToInput: Zeus.Web.Model.ArticleToInput) {
    return this._computeUsedAndFreeSpaceForInput(articleToInput, null);
  }

  public async computeUsedAndFreeSpaceForInputWithParamsAndLocations(articleToInput: Zeus.Web.Model.ArticleToInput, locations: Zeus.Web.Model.Location[]) {
    return this._computeUsedAndFreeSpaceForInput(articleToInput, locations);
  }

  private async _computeUsedAndFreeSpaceForInput(
    articleToInput: Zeus.Web.Model.ArticleToInput,
    locations: Zeus.Web.Model.Location[] = null
  ): Promise<void> {

    let dto = new ComputeUsedAndFreeSpaceDto({
      LocationForActionDto: new GetLocationForActionDTO(articleToInput),
      LocationIds: locations?.length > 0 ? locations.map(l => l.id) : null
    });
    let url = Endpoints.WorkOrder.ComputeUsedAndFreeSpaceForInput + "?articleToInputId=" + articleToInput.id;

    let resp = await this.http.post(url, dto.stringify());
    if (resp.ok) {
      let value: Zeus.Web.Model.UsedAndFreeSpaceForInputResult[] = await resp.json();
      if (value != null && value.length) {
        value.forEach(uaf => {
          if (uaf.FreeSpaceForInput != null && uaf.LocationId != null && uaf.UsedSpaceForInput != null) {
            if (articleToInput.locationForInputPickings?.length > 0) {
              let found = articleToInput.locationForInputPickings.find(l => l.location != null && l.location.id == uaf.LocationId);
              if (found != null) {
                found.location.usedSpaceForInput = uaf.UsedSpaceForInput;
                found.location.freeSpaceForInput = uaf.FreeSpaceForInput;
                found.location.lastInputTime = uaf.LastInputTime;
              }
            }
            if (locations?.length > 0) {
              let found = locations.find(l => l.id == uaf.LocationId);
              if (found) {
                found.usedSpaceForInput = uaf.UsedSpaceForInput;
                found.freeSpaceForInput = uaf.FreeSpaceForInput;
                found.lastInputTime = uaf.LastInputTime;
              }
            }
          }
        });
      }

      return Promise.resolve();
    }
    return Promise.resolve();
  }

  public async computePickableQuantity(articleToPick: Zeus.Web.Model.ArticleToPick, locations: Zeus.Web.Model.Location[] = null): Promise<void> {
    let dto = new LocationIdListDto({
      LocationIds: locations?.length > 0 ? locations.map(l => l.id) : null
    });
    let url = Endpoints.WorkOrder.ComputePickableQuantity + "?articleToPickId=" + articleToPick.id;

    let response = await this.http.post(url, dto.stringify());
    if (response.ok) {
      let value: Zeus.Web.Model.PickableQuantityResult[] = await response.json();
      if (value != null && value.length) {
        value.forEach(pickableResult => {
          if (pickableResult.PickableQuantity != null && pickableResult.LocationId != null) {
            if (articleToPick.locationForInputPickings?.length > 0) {
              let found = articleToPick.locationForInputPickings.find(l => l.location != null && l.location.id == pickableResult.LocationId);
              if (found != null) {
                found.location.pickableQuantity = pickableResult.PickableQuantity;
              }
            }
            if (locations?.length > 0) {
              let found = locations.find(l => l.id == pickableResult.LocationId);
              if (found) {
                found.pickableQuantity = pickableResult.PickableQuantity;
              }
            }
          }
        });
      }

      return Promise.resolve();
    }
    return Promise.resolve();
  }

  public async resetArticleToActionLocalProperties(ata: Zeus.Web.Model.ArticleToAction): Promise<void> {
    if (ata == null) return Promise.resolve();

    ata.allreadySearchForLocations = false;
    ata.qtyNotInlocation = null;
    ata.locatedAll = null;
    if (ata.entityAspect?.entityManager?.hasChanges()) {
      await ata.entityAspect.entityManager.saveChanges();
    }
    return Promise.resolve();
  }

  public async resetArticleToActionsLocalProperties(atas: Array<Zeus.Web.Model.ArticleToAction>, articleToActionToKeep: number[] = null): Promise<void> {
    atas = (atas || []).filter(ati => null == (articleToActionToKeep || []).find(id => id == ati.id));

    if (atas == null || atas.length == 0) return Promise.resolve();

    atas.forEach(ati => {
      ati.requestedActionQuantity = ati.requestedActionQuantity || 0;
      ati.allreadySearchForLocations = false;
      ati.qtyNotInlocation = null;
      ati.locatedAll = null;
    });

    return this.articleToActionService.saveEntities(atas, true);
  }

  public resetArticleToActionsLocalPropertiesHelper(articleToActions: Zeus.Web.Model.ArticleToAction[]) {
    articleToActions.forEach(ati => {
      ati.requestedActionQuantity = ati.requestedActionQuantity || 0;
      ati.qtyNotInlocation = null;
      ati.locatedAll = null;
    });
  }

  /**
   *
   *
   * @param {Zeus.Web.Model.SiteStructureGeneratorModel} model
   * @return {*}  {Promise<boolean>}
   * @memberof ApiService
   */
  public async createParcOfLocations(model: Zeus.Web.Model.SiteStructureGeneratorModel): Promise<boolean> {
    let response = await this.http.post(Endpoints.Location.CreateParcOfLocations, JSON.stringify(model));
    if (response.ok) {
      return Promise.resolve(await response.text() == "true");
    }
  }

  /**
   * Send a message to the api to move to tray of the given location.
   */
  public async moveTrayToBay(trayContainerId: number, modula: number | Zeus.Web.Model.Storage, storageId: number = null, bayNumber: number = 1): Promise<string> {
    if (trayContainerId != null && modula != null && (typeof modula != 'number' || storageId != null)) {
      let message = {
        machineId: 0,
        storageId: 0,
        trayId: trayContainerId,
        bayNumber: bayNumber,
        messageTypeId: Constants.MessageType.MoveTrayToBay,
      };

      if (typeof modula == 'number') {
        message.machineId = modula;
        message.storageId = storageId;
      } else {
        message.machineId = modula.dynamicStorageId;
        message.storageId = modula.id;
      }

      let postResult = await this.http.post(Endpoints.Message.MessageSendEndPoint, JSON.stringify(message));
      if (postResult.ok) {
        return await postResult.json();
      } else {
        throw (await postResult.text());
      }
    } else {
      console.error("TrayContaineId xor storageId can't be null");
      throw ("Error");
    }
  }

  public async LaunchBatchProcess(dto: Zeus.Web.Model.LaunchBatchProcessDTO): Promise<string | boolean> {
    let response = await this.http.post(Endpoints.BatchProcess.LaunchBatchProcess, JSON.stringify(dto));
    return await response.text();
  }

  public async unlinkBinFromWorkOrder(workOrderIds: number[]): Promise<Response> {
    return await this.http.post(Endpoints.BatchProcess.UnlinkBinFromWorkOrder, JSON.stringify(workOrderIds));
  }

  public async storageGroupOnUserSite(storageGroupId: number): Promise<boolean> {
    let onSameSite = false;
    if (storageGroupId != null) {
      let response = await this.http.get(Endpoints.StorageGroup.IsOnUserSite + '?storageGroupId=' + storageGroupId);
      if (response.ok) {
        onSameSite = await response.json();
      }
    }
    return onSameSite;
  }

  public async LogFrontendError(error: string): Promise<void> {
    await this.http.post(Endpoints.Log.LogFrontendError, JSON.stringify(error));
  }
}
export class LocationIdListDto {
  public LocationIds: number[];

  constructor(data?: Partial<LocationIdListDto>) {
    if (data) {
      Object.assign(this, data);
    }
  }

  public stringify(): string {
    return JSON.stringify(this);
  }
}
export class ComputeUsedAndFreeSpaceDto extends LocationIdListDto {
  public LocationForActionDto: GetLocationForActionDTO;

  constructor(data?: Partial<ComputeUsedAndFreeSpaceDto>) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }
}
export class GetLocationForActionDTO {
  WorkOrderTypeId: number;
  ArticleToActionId: number;
  UOMId: number;

  constructor(articleToAction: Zeus.Web.Model.ArticleToAction) {
    this.WorkOrderTypeId = articleToAction.actionWorkOrder.workOrderTypeId
    this.ArticleToActionId = articleToAction.id;
    this.UOMId = articleToAction.unitOfMeasureId;
  }

  public stringify(): string {
    return JSON.stringify(this);
  }
}


export interface UnloadWorkordersDto {
  workOrderIds: number[];
  articleToActionToKeepIds: number[];
  articleToActionToForceDeleteIds: number[];
}
