import { I18N } from 'aurelia-i18n';
import { Container, computedFrom } from 'aurelia-framework';
import { Config } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { AppModuleEnum } from './constants';
import * as breeze from 'breeze-client';
import { AppModuleRoot } from './app-module-root';
import { Endpoints } from 'endpoints';
import { AppModuleProvider } from './app-module-provider';

export abstract class AppModuleService {

  private _activeModules: AppModuleEnum[];

  constructor(protected config: Config, protected httpClient: HttpClient, protected appModuleProvider: AppModuleProvider) { }

  public async initialize() {
    if (this._activeModules != null) {
      return;
    }
    this._activeModules = [];
    let response = await this.httpClient.fetch(Endpoints.AppModule.AppModulesActiveModules);
    if (response.ok) {
      this._activeModules = await response.json();
      this.setupServices();
      this.setupTranslations();
      this.setupRoutes();
      this.setupPermissions();
      this.setupEnumerationTypeCategories();

      await this.setupChangeActivations();
    }
  }

  @computedFrom('_activeModules.length')
  public get activeModules(): AppModuleEnum[] {
    return [...this._activeModules];
  }

  protected checkInitialized() {
    if (this.activeModules == null) {
      throw 'AppModuleService is not initialized'
    }
  }

  public isActive(module: AppModuleEnum = null) {
    this.checkInitialized();
    return module == null || this.activeModules.some(x => x == module);
  }

  private setupServices() {
    this.activeModules.forEach(x => this.appModuleProvider.get(x).registerServices(Container.instance));
  }

  private setupRoutes() {
    this.activeModules.forEach(x => this.appModuleProvider.get(x).setupRoutes(this.config));
  }

  private setupTranslations() {
    const i18n = Container.instance.get(I18N);

    // EN is fallback language for Zeus
    this.importTranslationsForEachModule(i18n, 'en');

    i18n.i18next.on('languageChanged', () => this.importTranslationsForEachModule(i18n, i18n.getLocale()));
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
    this.checkInitialized();
    this.activeModules.forEach(x => this.appModuleProvider.get(x).setupModel(metadataStore, manager));
  }

  private setupPermissions() {
    this.activeModules.forEach(x => this.appModuleProvider.get(x).setupPermissions(this.config.permissionsConfig));
  }

  private setupEnumerationTypeCategories() {
    this.activeModules.forEach(x => {
      let appModuleRoot: AppModuleRoot = this.appModuleProvider.get(x);
      this.config.enumerationTypeConfig.enumerationTypeCategories.push(...appModuleRoot.enumerationTypeCategories);
      Object.assign(this.config.enumerationTypeConfig.enumerationTypes, appModuleRoot.enumerationTypeList);
    });
  }

  private importedTranslations = [];
  private importTranslationsForEachModule(i18n: I18N, locale: string) {
    if (this.importedTranslations.some(x => x == locale)) {
      // Already imported
      return;
    }

    // Import for each module
    this.activeModules.forEach(x => {
      const translations = this.appModuleProvider.get(x).translations[locale];
      if (translations != null) {
        i18n.i18next.addResourceBundle(locale, 'translation', translations, true);
      }
    });

    // Mark as imported
    this.importedTranslations.push(locale);
  }

  protected abstract setupChangeActivations(): Promise<void>;
}
