import { Actions, Config } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from "aurelia-dependency-injection";
import * as Constants from '../constants';
import { Endpoints } from 'endpoints';
import { AppModuleProvider } from './app-module-provider';
import { AppModuleService } from './app-module-service';

@autoinject
export class ZeusAppModuleService extends AppModuleService {

  constructor(config: Config, httpClient: HttpClient, appModuleProvider: AppModuleProvider) {
    super(config, httpClient, appModuleProvider);
  }

  protected async setupChangeActivations(): Promise<void> {
    let response = await this.httpClient.fetch(Endpoints.AppModule.AppModulesIsDevelopment);
    if (response.ok && await response.text() == 'true') {
      let index = this.config.routes.findIndex(r => r.name == 'reset-data');
      this.config.routes.splice(index, 0,
        {
          route: 'app-modules/change-activations',
          name: 'app-modules-change-activations',
          moduleId: 'app-modules/change-activations',
          title: "appmodules.appModules",
          nav: true,
          settings: { group: Constants.MenuType.Tests, resource: Constants.Roles.Helpdesk, action: Actions.All }
        },
      );
    }
  }
}
