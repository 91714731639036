import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { PermissionsConfig } from "digiwall-lib";
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";
import translationJson from './locales/index';
import { Container } from "aurelia-dependency-injection";
import { LocationForActionService } from "external-src/utils/location-for-action-service";
import { LocationForActionService as CartReceptionLocationForActionService } from "../../external-src/app-modules/cart-reception/location-for-action-service";
import { Permissions, RoleNames, MenuType } from "../../constants";
import * as ReceptionConstants from "../../reception/constants";
import { ReceptionActivator } from "reception/reception-activator";

export class CartReceptionRoot extends AppModuleRoot {
  public get name() {
    return AppModule.CartReception;
  }

  public registerServices(instance: Container): void {
    instance.registerSingleton(LocationForActionService, CartReceptionLocationForActionService);
  }

  public get translations(): { [key: string]: object; } {
    return this.mergeTranslations([
      Container.instance.get(ReceptionActivator).translations,
      translationJson
    ]);
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return Container.instance.get(ReceptionActivator).enumerationTypeList;
  }

  protected get pathName(): string {
    return 'cart-reception';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      ...Container.instance.get(ReceptionActivator).getRoutes()
    ];
  }

  protected get resourceNames(): {} {
    return {};
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        { context: ReceptionConstants.Permissions.Reception, qualifiedName: [RoleNames.Mobile] },
        { context: ReceptionConstants.Permissions.ReceptionParameters, isCrud: true, qualifiedName: [MenuType.ApplicationParameters, 'applicationparameters.receptionParameters'] }
      ],
      permissionNames: {
        [ReceptionConstants.Permissions.Reception]: {
          [Permissions.Access]: ReceptionConstants.RoleNames.Reception
        }
      }
    };
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
