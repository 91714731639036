import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { Actions, PermissionsConfig } from "digiwall-lib";
import * as LPConstants from "./constants";
import * as Constants from "./../../constants";
import translationJson from './locales/index';
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";

export class LabelPrintingRoot extends AppModuleRoot {
  public get name() {
    return AppModule.LabelPrinting;
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [
      { category: LPConstants.EnumerationTypes.LabelPrinter, isClosed: false, name: "enumerationtype.labelPrinter" },
    ];
  }

  public get enumerationTypeList(): object {
    return LPConstants.EnumerationTypes;
  }

  protected get pathName(): string {
    return 'label-printing';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      {
        route: 'label-templates/:param1',
        moduleId: 'label-templates/label-template-detail',
        title: "labeltemplate.labeltemplate",
        nav: false,
        settings: { resource: LPConstants.EntityTypeNames.LabelTemplate, action: [Actions.Create, Actions.Update] },
        href: "/label-templates/-100"
      },
      {
        after: 'label-templates',
        route: 'label-printing-sent-messages/:param1',
        moduleId: 'sent-messages/sent-message-detail',
        title: "lpsentmessage.lpsentmessage",
        nav: false,
        settings: { group: Constants.MenuType.Tests, resource: Constants.Roles.Helpdesk, action: Actions.All },
        href: "/label-printing-sent-messages/-100"
      },
      {
        after: Constants.RouteName.ImportLog,
        name: 'label-templates',
        route: 'label-templates/all',
        moduleId: 'label-templates/label-template-list',
        title: "labeltemplate.labeltemplates",
        nav: true,
        settings: { group: Constants.MenuType.ApplicationParameters, resource: LPConstants.EntityTypeNames.LabelTemplate, action: Actions.Read }
      },
      {
        after: 'label-templates',
        route: 'label-print-configuration/all',
        moduleId: 'label-print-configurations/label-print-configuration',
        title: "labelprintconfiguration.labelprintconfigurations",
        nav: true,
        settings: { group: Constants.MenuType.ApplicationParameters, resource: LPConstants.EntityTypeNames.LabelTemplate, action: Actions.Read }
      },
      {
        after: Constants.RouteName.SentMessage,
        name: 'lp-sent-messages',
        route: 'label-printing-sent-messages/all',
        moduleId: 'sent-messages/sent-message-list',
        title: "lpsentmessage.lpsentmessages",
        nav: true,
        settings: { group: Constants.MenuType.Tests, resource: Constants.Roles.Helpdesk, action: Actions.All }
      }
    ];
  }

  protected get resourceNames(): {} {
    return LPConstants.EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {};
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
