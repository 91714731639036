import { customElement, ViewSlot } from 'aurelia-templating';
import { bindable, autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { CustomLogger, ViewModelBase, UIInternal } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { Zeus } from 'generated';
import { DialogService } from 'aurelia-dialog';
import { TrayPreviewDialog } from './tray-preview-dialog';
import { LocationUtils } from '../utils/utils-locations';

export type FadeableLocation = Zeus.Web.Model.LocationBase & { fade?: boolean };

@customElement('tray-preview')
@autoinject
export class TrayPreview extends ViewModelBase {
  public ribbonHeaderText: string = "";
  public ressourceName: string = "";

  @bindable
  public locations: FadeableLocation[] = [];
  @bindable
  public locationGenerator: Zeus.Web.Model.LocationGenerator = null;

  @bindable
  public trayWidth: number;
  @bindable
  public trayDepth: number;

  @bindable
  public locationId: number;
  @bindable
  public trayContainerId: number;

  private trayPreview: HTMLElement;
  private viewSlot: ViewSlot;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogService: DialogService) {
    super(router, logger);
  }

  public async attached() {
    if (this.trayDepth != null && this.trayWidth != null) {
      this.drawTrayPreviewFrame();
    }
    if (this.locationGenerator != null || (this.locations != null && this.locations.length > 0)) {
      this.buildTrayPreview();
    }

    this.disposables.push(
      this.bindingEngine.propertyObserver(this, "locations").subscribe((newValue, oldValue) => {
        if (newValue != null) {
          if (newValue != oldValue) {
            this.buildTrayPreview();
          }
        } else {
          this.emptyTrayPreview();
        }
      }),
      this.bindingEngine.propertyObserver(this, "locationGenerator").subscribe((newValue, oldValue) => {
        if (newValue != null) {
          if (newValue != oldValue) {
            this.buildTrayPreview();
          }
        } else {
          this.emptyTrayPreview();
        }
      }),
      this.bindingEngine.propertyObserver(this, "trayDepth").subscribe((newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.redraw();
        }
      }),
      this.bindingEngine.propertyObserver(this, "trayWidth").subscribe((newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.redraw();
        }
      }),
      this.bindingEngine.propertyObserver(this.trayPreview, "offsetWidth").subscribe((newValue, oldValue) => {
        if (newValue != null && newValue != oldValue && newValue != 0) {
          this.redraw();
        }
      })
    );
  }

  private async openTrayPreviewDialog() {
    if (this.locationId && this.trayContainerId) {
      await this.dialogService.open({
        viewModel: TrayPreviewDialog,
        model: { trayId: this.trayContainerId, locationId: this.locationId },
      });
    }
  }

  public redraw() {
    this.drawTrayPreviewFrame();
    this.buildTrayPreview();
  }

  private get singleLocationSelected(): number | null {
    if (this.locations == null) {
      return null;
    } else if (this.locationId != null) {
      return this.locationId;
    }
    let nonFaded = this.locations.filter(x => !x.fade);
    return nonFaded.length == 1 ? nonFaded[0].id : null;
  }

  private drawTrayPreviewFrame() {
    this.trayPreview.style.height = (this.trayDepth / this.trayWidth) * this.trayPreview.offsetWidth + "px";
  }

  private buildLocationBlock(location: (Zeus.Web.Model.LocationBase | Zeus.Web.Model.LocationGenerator) & { fade?: boolean }) {
    let locX;
    let locY;

    let blockLoc = document.createElement("div");
    blockLoc.classList.add('location-block');

    let blockColor = document.createElement("div");
    blockColor.classList.add('background');

    let locWidth: number;
    let locHeight: number;
    if ('locationIdentifier' in location) {
      [locX, locY] = LocationUtils.getIntCoordFromIdentifier(location.locationIdentifier);

      blockLoc.style.left = ((locX - (location.storageUnitWidth / 2)) / this.trayWidth) * 100 + "%";
      blockLoc.style.bottom = ((locY - (location.storageUnitLength / 2)) / this.trayDepth) * 100 + "%";
      locWidth = (location.storageUnitWidth / this.trayWidth) * 100;
      locHeight = (location.storageUnitLength / this.trayDepth) * 100;
      blockColor.style.backgroundColor = location.defaultStorageVolume.volumeType.backgroundColor;
    }
    else {
      locX = location.startingFromX;
      locY = location.startingFromY;

      blockLoc.style.left = (locX / this.trayWidth) * 100 + "%";
      blockLoc.style.bottom = (locY / this.trayDepth) * 100 + "%";
      locWidth = (location.boxWidth / this.trayWidth) * 100;
      locHeight = (location.boxDepth / this.trayDepth) * 100;
      blockColor.style.backgroundColor = location.volumeTypeBackground;
    }

    if (location.fade === true) {
      blockLoc.classList.add('faded');
    }
    blockLoc.appendChild(blockColor);

    blockLoc.style.width = locWidth + "%";
    blockLoc.style.height = locHeight + "%";

    let blockData: string;
    let boxWidth: number;
    let boxLength: number;
    if ('locationIdentifier' in location) {
      let [coordX, coordY] = LocationUtils.getStringCoordFromIdentifier(location.locationIdentifier);
      // On ne remplace pas le undefined pcq le locationVolume est mtn mandatory, on souhaite donc montrer qu'il est manquant
      blockData = location.defaultStorageVolume.volumeType.denomination._translation + "<br>" + coordX + "." + coordY;
      boxWidth = location.storageUnitWidth;
      boxLength = location.storageUnitLength;

      if (this.singleLocationSelected === location.id) {
        blockLoc.classList.add('selected');
      }
    }
    else {
      blockData = location.volumeTypeName + "<br>" + (Math.floor(locX + location.boxWidth / 2)) + "." + (Math.floor(locY + location.boxDepth / 2));
      boxWidth = location.boxWidth;
      boxLength = location.boxDepth;
    }

    blockLoc.setAttribute("ui-tooltip", "value: " + blockData + "; position: right");

    let txt = document.createElement("span");
    txt.innerHTML = blockData;
    txt.classList.add('volume-text');
    txt.style.fontSize = this.calculateFontSize(boxWidth, boxLength) + "px";
    if ('locationIdentifier' in location) {
      txt.style.color = location.defaultStorageVolume.volumeType.textColor;
    }
    blockLoc.appendChild(txt);

    let view = UIInternal.compileTemplate("<template>" + blockLoc.outerHTML + "</template>", this);
    this.viewSlot = new ViewSlot(this.trayPreview, true);
    this.viewSlot.add(view as any);
    this.viewSlot.attached();
  }

  public detached() {
    this.viewSlot.detached();
    super.detached();
  }

  private calculateFontSize(boxWidth: number, boxDepth: number) {
    let size = boxWidth > boxDepth ? boxWidth / boxDepth : boxDepth / boxWidth;
    size = boxWidth == boxDepth ? size * 20 : size * 10;
    while (size > boxWidth || size > boxDepth) {
      size /= 1.5;
    }
    return size;
  }

  private buildTrayPreview() {
    this.emptyTrayPreview();

    this.locations?.forEach(loc => this.buildLocationBlock(loc));

    if (this.locationGenerator != null) {
      let locGeneratorCopy: any = {};
      Object.assign(locGeneratorCopy, this.locationGenerator); //To avoid changes on source object
      let initialX = this.locationGenerator.startingFromX;

      for (let iRow = 0; iRow < locGeneratorCopy.nbBoxesDepth; iRow++) {
        locGeneratorCopy.startingFromX = initialX;
        for (let iCol = 0; iCol < locGeneratorCopy.nbBoxesWidth; iCol++) {
          this.buildLocationBlock(locGeneratorCopy);
          locGeneratorCopy.startingFromX += locGeneratorCopy.boxWidth;
        }
        locGeneratorCopy.startingFromY += locGeneratorCopy.boxDepth;
      }
    }
  }

  private emptyTrayPreview() {
    this.trayPreview.innerHTML = "";
  }
}
