import { Disposable } from "aurelia-framework";

export class UnloadHelper {

  private listeners: (() => void | Promise<void>)[] = [];

  constructor() {
    window.addEventListener('beforeunload', () => this.trigger());
  }

  public async trigger() {
    await Promise.all(this.listeners.map(l => l()));
  }

  public subscribe(listener: () => void | Promise<void>): Disposable {
    let index = this.listeners.length;
    this.listeners.push(listener);
    return {
      dispose: () => this.listeners.splice(index, 1)
    }
  }
}
