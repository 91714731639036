import { DialogController } from 'aurelia-dialog';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, ServiceBase } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Zeus } from 'generated';
import { observable } from 'aurelia-typed-observable-plugin';
import { Predicate, FilterQueryOp } from 'breeze-client';

@autoinject
export class InformationMessage extends EntityDetailViewModelBase<Zeus.Web.Model.InformationMessage>{
  public ressourceName: string = Constants.EntityTypeNames.InformationMessage;
  public documentTitle: string = Constants.EntityTypeNames.InformationMessage;

  private myInformaitonMessages: Array<Zeus.Web.Model.InformationMessage>;
  private readDateTimePredicate = new Predicate("readDateTime", FilterQueryOp.Equals, null);
  private userIdPredicate = new Predicate("userId", FilterQueryOp.Equals, this.authService.currentUser.id);
  private isBusy = false;

  @observable
  public allMessages: boolean;

  constructor(public element: Element, router: Router, logger: CustomLogger, private dialogController: DialogController) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.InformationMessage>(Constants.EntityTypeNames.InformationMessage));
  }

  public async activate(params: any) {
    await super.activate(params || {});
    await this.refreshMessages();
    this.allMessages = false;
  }

  public attached() {
    this.element.parentElement.className = "info-message-container";
    this.resize();
    this.authService.currentUser.userData.nbMessagesUnread = 0;
  }

  public async detached() {
    for (let message of this.myInformaitonMessages){
      if(message.displayDateTime == null){
        message.displayDateTime = new Date();
        await this.service.saveEntity(message, true);
      }
    }
    super.detached();
  }

  public async deleteMessage(message: Zeus.Web.Model.InformationMessage){
    if(!this.isBusy){
      this.isBusy = true;
      await this.service.deleteEntities([message], false, null, true);
      await this.refreshMessages();
      this.resize();
      this.isBusy = false;
    }
  }

  public async toggleArchive(message: Zeus.Web.Model.InformationMessage){
    if(!this.isBusy){
      this.isBusy = true;
      if(message.readDateTime != null){
        message.readDateTime = null;
      }
      else {
        message.readDateTime = new Date();
      }
      await this.service.saveEntity(message, true);
      await this.refreshMessages();
      this.resize();
      this.isBusy = false;
    }
  }

  public async allMessagesChanged(newValue, oldValue) { 
    if (newValue != oldValue && oldValue != undefined) {
      this.allMessages = newValue;
      await this.refreshMessages();
      this.resize();
    }
  }

  private async refreshMessages(){
   if(this.allMessages == true){
    this.myInformaitonMessages = await this.service.getEntities(this.userIdPredicate, null, null, { propertyPaths: "createdTime", isDescending: true});
    }
    else {
    this.myInformaitonMessages = await this.service.getEntities(this.userIdPredicate.and(this.readDateTimePredicate), null, null, { propertyPaths: "createdTime", isDescending: true});
    }
  }

  private resize(){
    if(this.myInformaitonMessages.length > 1){
      this.element.children.item(0).children.item(0).parentElement.style.height = "100%";
    }
    else {
      this.element.children.item(0).children.item(0).parentElement.style.height = null;
    }
  }
}
