export const Endpoint = "/api/extensions/getCustomProperties";

export enum EntityType {
  WorkOrder = "workOrder"
}

export enum PropertyType {
  Bool = "bool",
  String = "string",
  Int = "int",
  Decimal = "decimal"
}

export enum LanguageCode {
  French = "French", Dutch = "Dutch", English = "English"
}

export enum DetailViewSlot {
  AfterForm, CustomPropertyPanel
}

export interface CustomPropertyMetaData {
  entityType: EntityType;
  propertyType: PropertyType;
  propertyNumber: number;
  label: { [key: string]: string };
  listViewMetaData: ListViewMetaData;
  detailViewMetaData: DetailViewMetaData;
}

export interface ListViewMetaData {
  isShownInList: boolean;
  isHiddenByDefault: boolean;
}

export interface DetailViewMetaData {
  showInDetail: boolean;
  isReadOnly: boolean;
  relativePosition: number;
  viewSlot: DetailViewSlot;
}

export interface CustomPropDetail {
  propertyType: PropertyType;
  property: string;
  labels: { [key: string]: string };
  label: string;
  isReadOnly: boolean;
  position: number;
  viewSlot: string;
}
