@import "../../node_modules/select2/src/scss/core";
@import "scss/variables";

@import "scss/_forms";

@import "scss/_gallery";

@import "scss/planning-component";

$mdi-font-path: "fonts";
$mdi-font-size-base: 1em;
@import "../../node_modules/@mdi/font/scss/materialdesignicons";

.theme-light {
  @import "../../node_modules/digiwall-lib/src/sass/light-theme";
  @import "../../node_modules/digiwall-lib/src/sass/theme/select2";
}

@import "../../node_modules/digiwall-lib/src/sass/digiwall-framework";

@import "scss/_general";
@import "scss/screen-specific";

//@import "../../node_modules/digiwall-lib/src/sass/dw-icons";
@import "./custom-icons/DigiWall-Icons";
@import "./custom-icons/Zeus-Icons";

.animate-slide-in-right.au-enter-active:not(.ui-dialog-wrapper) {
  animation: none;
}

.animate-slide-out-left.au-leave-active:not(.ui-dialog-wrapper) {
  animation: none;
}
