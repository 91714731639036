import { FilterQueryOp, Predicate } from "breeze-client";
import { QueryResults, ServiceBase } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from "../../constants";

export function CreateLotNumberService(entity: {articleId: number}) : ServiceBase<Zeus.Web.Model.ArticleLocation> {
  let service = new ServiceBase<Zeus.Web.Model.ArticleLocation>(Constants.EntityTypeNames.ArticleLocation);
  service.gridDataSource.customSelect2Predicates = () => 
    entity?.articleId ? new Predicate('articleId', FilterQueryOp.Equals, entity?.articleId) : null;

  // Fetch distinct lot numbers
  const lotNumberGetDataFn = service.gridDataSource.getDataFunction;
  service.gridDataSource.getDataFunction = (params, expands, quickFilter, queryCriteria, queryParameters) => { 
    let promise = lotNumberGetDataFn(params, expands, quickFilter, queryCriteria, queryParameters);
    promise.then((results: QueryResults<Zeus.Web.Model.ArticleLocation>) => {
        results.results = Object.values(results.results.reduce((results: any, al: Zeus.Web.Model.ArticleLocation) => {
          if(al.lotNumber && null == results[al.lotNumber]) {
            results[al.lotNumber] = al;
          }
          return results;
        }, []));
        results.fullCount = results.results.length;
        return results;
      });
    return promise;
  }

  return service;
}
