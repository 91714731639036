import { autoinject, bindable, customElement } from "aurelia-framework";
import { DetailViewSlot, EntityType } from "../custom-properties-model";
import { Zeus } from 'generated';

@autoinject
@customElement('custom-property-panel')
export class CustomPropertiesPanel {
  @bindable private entityType: EntityType;
  @bindable private entity: Zeus.Web.Model.IWithCustomProperties;

  private DetailViewSlot = DetailViewSlot;
}
