/* Use very specific class as this is used for template in dropdown, which is not part of the specific screen
 * Also, cannot be specific CSS (inside component) as in those files we do not have access to variables (here color secondary)
 */
.storage-group-parent-hierarchy {
  color: $secondary;
  
  ui-icon {
    font-size: .75rem;
    line-height: .75;
  }
}
