@font-face {
  font-family: 'DigiWall-Icons';
  src:  url('fonts/DigiWall-Icons.eot?uvxncu');
  src:  url('fonts/DigiWall-Icons.eot?uvxncu#iefix') format('embedded-opentype'),
    url('fonts/DigiWall-Icons.woff2?uvxncu') format('woff2'),
    url('fonts/DigiWall-Icons.ttf?uvxncu') format('truetype'),
    url('fonts/DigiWall-Icons.woff?uvxncu') format('woff'),
    url('fonts/DigiWall-Icons.svg?uvxncu#DigiWall-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="digi-"], [class*=" digi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'DigiWall-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.digi-stop:before {
  content: "\e9b4";
}
.digi-close:before {
  content: "\e9b3";
}
.digi-invoices:before {
  content: "\e9b2";
}
.digi-preview:before {
  content: "\e9b1";
}
.digi-link-chain:before {
  content: "\e9a4";
}
.digi-move-line:before {
  content: "\e9a0";
}
.digi-move-line-down:before {
  content: "\e9b0";
}
.digi-move:before {
  content: "\e9af";
}
.digi-check-1:before {
  content: "\e9ac";
}
.digi-check-2:before {
  content: "\e9ad";
}
.digi-check-3:before {
  content: "\e9ae";
}
.digi-info-input:before {
  content: "\e9ab";
}
.digi-minus-circle:before {
  content: "\e9a8";
}
.digi-plus-circle:before {
  content: "\e9a9";
}
.digi-thumb:before {
  content: "\e92b";
}
.digi-email:before {
  content: "\e9a7";
}
.digi-exclamation-circle:before {
  content: "\e9a6";
}
.digi-files-ticketing:before {
  content: "\e9a5";
}
.digi-worksite:before {
  content: "\e9a3";
}
.digi-computer:before {
  content: "\e99e";
}
.digi-settings-2:before {
  content: "\e99f";
}
.digi-calendar-futur:before {
  content: "\e99d";
}
.digi-calendar-past:before {
  content: "\e99c";
}
.digi-calcul:before {
  content: "\e99a";
}
.digi-calculator:before {
  content: "\e99b";
}
.digi-impose:before {
  content: "\e999";
}
.digi-XML-file:before {
  content: "\e998";
}
.digi-expand:before {
  content: "\e996";
}
.digi-collapse:before {
  content: "\e997";
}
.digi-zip-file:before {
  content: "\e995";
}
.digi-alert-danger:before {
  content: "\e98e";
}
.digi-cross-circle:before {
  content: "\e991";
}
.digi-alert-warning:before {
  content: "\e992";
}
.digi-alert-info:before {
  content: "\e993";
}
.digi-paste:before {
  content: "\e98f";
}
.digi-share:before {
  content: "\e98c";
}
.digi-stock-inventories:before {
  content: "\e98d";
}
.digi-file:before {
  content: "\e93b";
}
.digi-user:before {
  content: "\e933";
}
.digi-key-value:before {
  content: "\e987";
}
.digi-edit-square:before {
  content: "\e90e";
}
.digi-pen:before {
  content: "\e986";
}
.digi-edit:before {
  content: "\e990";
}
.digi-minus:before {
  content: "\e989";
}
.digi-top:before {
  content: "\e98b";
}
.digi-print:before {
  content: "\e988";
}
.digi-pinterest-3:before {
  content: "\e979";
}
.digi-linkedin-3:before {
  content: "\e97a";
}
.digi-instagram-3:before {
  content: "\e97b";
}
.digi-facebook-3:before {
  content: "\e97c";
}
.digi-pinterest-2:before {
  content: "\e97d";
}
.digi-linkedin-2:before {
  content: "\e97e";
}
.digi-instagram-2:before {
  content: "\e97f";
}
.digi-facebook-2:before {
  content: "\e980";
}
.digi-pinterest-1:before {
  content: "\e981";
}
.digi-linkedin-1:before {
  content: "\e982";
}
.digi-instagram-1:before {
  content: "\e983";
}
.digi-facebook-1:before {
  content: "\e984";
}
.digi-settings:before {
  content: "\e985";
}
.digi-checkbox-minus:before {
  content: "\e971";
}
.digi-checkbox-square:before {
  content: "\e973";
}
.digi-checkbox-valid:before {
  content: "\e974";
}
.digi-checkbox:before {
  content: "\e978";
}
.digi-important:before {
  content: "\e970";
}
.digi-clients:before {
  content: "\e96f";
}
.digi-user-profil:before {
  content: "\e96c";
}
.digi-euro:before {
  content: "\e96d";
}
.digi-dollar:before {
  content: "\e96e";
}
.digi-clock-2:before {
  content: "\e96b";
}
.digi-calendar-cross:before {
  content: "\e96a";
}
.digi-xlsx-file:before {
  content: "\e957";
}
.digi-txt-file:before {
  content: "\e959";
}
.digi-ppt-file:before {
  content: "\e960";
}
.digi-xls-file:before {
  content: "\e961";
}
.digi-jpg-file:before {
  content: "\e962";
}
.digi-png-file:before {
  content: "\e963";
}
.digi-docx-file:before {
  content: "\e967";
}
.digi-doc-file:before {
  content: "\e968";
}
.digi-dashboard:before {
  content: "\e955";
}
.digi-home:before {
  content: "\e956";
}
.digi-stock:before {
  content: "\e94e";
}
.digi-reception:before {
  content: "\e952";
}
.digi-purchase-orders:before {
  content: "\e954";
}
.digi-departement:before {
  content: "\e94b";
}
.digi-unlock:before {
  content: "\e94a";
}
.digi-labo:before {
  content: "\e949";
}
.digi-arrow-down-up:before {
  content: "\e948";
}
.digi-customer-order:before {
  content: "\e946";
}
.digi-export-csv:before {
  content: "\e919";
}
.digi-rotation-right:before {
  content: "\e91a";
}
.digi-arrow-previous:before {
  content: "\e91b";
}
.digi-arrow-next:before {
  content: "\e91e";
}
.digi-arrow-inside:before {
  content: "\e91f";
}
.digi-arrow-outside:before {
  content: "\e929";
}
.digi-arrow-up-down:before {
  content: "\e92c";
}
.digi-lock:before {
  content: "\e92d";
}
.digi-rotation-left:before {
  content: "\e92e";
}
.digi-three-points-horizontal:before {
  content: "\e92f";
}
.digi-three-points-vertical:before {
  content: "\e93e";
}
.digi-open-tree:before {
  content: "\e917";
}
.digi-close-tree:before {
  content: "\e918";
}
.digi-csv-file:before {
  content: "\e915";
}
.digi-view-settings:before {
  content: "\e916";
}
.digi-loader:before {
  content: "\e942";
}
.digi-archive:before {
  content: "\e943";
}
.digi-read:before {
  content: "\e9a2";
}
.digi-key:before {
  content: "\e93f";
}
.digi-exclamation:before {
  content: "\e940";
}
.digi-upload:before {
  content: "\e941";
}
.digi-dragdrop:before {
  content: "\e9a1";
}
.digi-eye-off:before {
  content: "\e934";
}
.digi-plus:before {
  content: "\e98a";
}
.digi-menu:before {
  content: "\e90b";
}
.digi-arrow-right:before {
  content: "\e926";
}
.digi-arrow-left:before {
  content: "\e975";
}
.digi-info:before {
  content: "\e976";
}
.digi-text:before {
  content: "\e977";
}
.digi-notification:before {
  content: "\e95f";
}
.digi-notification-bell:before {
  content: "\e9aa";
}
.digi-projects:before {
  content: "\e972";
}
.digi-add-project:before {
  content: "\e950";
}
.digi-add-users:before {
  content: "\e951";
}
.digi-users:before {
  content: "\e95e";
}
.digi-send-email:before {
  content: "\e964";
}
.digi-copy:before {
  content: "\e947";
}
.digi-send-notification:before {
  content: "\e969";
}
.digi-down:before {
  content: "\e965";
}
.digi-up:before {
  content: "\e966";
}
.digi-calendar:before {
  content: "\e928";
}
.digi-eye:before {
  content: "\e93d";
}
.digi-play:before {
  content: "\e95a";
}
.digi-back:before {
  content: "\e95b";
}
.digi-comments:before {
  content: "\e95c";
}
.digi-data:before {
  content: "\e95d";
}
.digi-filter:before {
  content: "\e914";
}
.digi-reset-filter-transparent:before {
  content: "\e927";
}
.digi-briefcase:before {
  content: "\e958";
}
.digi-holidays:before {
  content: "\e953";
}
.digi-information-messages:before {
  content: "\e94c";
}
.digi-enumeration:before {
  content: "\e94d";
}
.digi-roles:before {
  content: "\e94f";
}
.digi-weather:before {
  content: "\e945";
}
.digi-reset-filter:before {
  content: "\e944";
}
.digi-pdf-file:before {
  content: "\e900";
}
.digi-impossible:before {
  content: "\e901";
}
.digi-heart:before {
  content: "\e902";
}
.digi-text-file:before {
  content: "\e903";
}
.digi-add-document:before {
  content: "\e904";
}
.digi-check-circle:before {
  content: "\e905";
}
.digi-alert-success:before {
  content: "\e994";
}
.digi-trash:before {
  content: "\e906";
}
.digi-questions:before {
  content: "\e907";
}
.digi-plus-cercle:before {
  content: "\e908";
}
.digi-clock:before {
  content: "\e909";
}
.digi-check:before {
  content: "\e90a";
}
.digi-refresh:before {
  content: "\e90c";
}
.digi-options:before {
  content: "\e90d";
}
.digi-crop:before {
  content: "\e90f";
}
.digi-palette:before {
  content: "\e910";
}
.digi-pictures:before {
  content: "\e911";
}
.digi-add-file:before {
  content: "\e912";
}
.digi-valid:before {
  content: "\e913";
}
.digi-comment:before {
  content: "\e91c";
}
.digi-cross:before {
  content: "\e91d";
}
.digi-cancel:before {
  content: "\e920";
}
.digi-image:before {
  content: "\e921";
}
.digi-external-emails:before {
  content: "\e922";
}
.digi-attachments:before {
  content: "\e923";
}
.digi-items:before {
  content: "\e924";
}
.digi-save:before {
  content: "\e925";
}
.digi-download:before {
  content: "\e92a";
}
.digi-plus-circle-transparent:before {
  content: "\e930";
}
.digi-tasks:before {
  content: "\e931";
}
.digi-www:before {
  content: "\e932";
}
.digi-synchronization:before {
  content: "\e935";
}
.digi-search:before {
  content: "\e936";
}
.digi-pin:before {
  content: "\e937";
}
.digi-phone:before {
  content: "\e938";
}
.digi-message:before {
  content: "\e939";
}
.digi-log-out:before {
  content: "\e93a";
}
.digi-map:before {
  content: "\e93c";
}
