import { TrayPreview } from '../locations/tray-preview';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, ViewModelBase } from 'digiwall-lib';
import { autoinject, BindingEngine } from 'aurelia-framework';
import * as toastr from 'toastr';
import { DialogController } from 'aurelia-dialog';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { Utils } from 'utils/utils';

@autoinject
export class GeneratePositions extends ViewModelBase {
  public ribbonHeaderText: string = this.i18n.tr("traytemplate.generatePositions");
  public ressourceName: string = "";

  private storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;

  private positionService: ServiceBase<Zeus.Web.Model.TrayTemplatePosition>;
  private locationVolumeService: ServiceBase<Zeus.Web.Model.LocationVolume>;

  private trayPreview: TrayPreview;
  private displayPreview: boolean = false;

  private locationGenerator: Zeus.Web.Model.LocationGenerator = {};

  private storageVolumes: Array<Zeus.Web.Model.StorageVolume>;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private dialogController: DialogController, private httpClient: HttpClient) {
    super(router, logger);

    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];

    this.positionService = new ServiceBase<Zeus.Web.Model.TrayTemplatePosition>(Constants.EntityTypeNames.TrayTemplatePosition);
    this.locationVolumeService = new ServiceBase<Zeus.Web.Model.LocationVolume>(Constants.EntityTypeNames.LocationVolume);
  }

  public async activate(params) {
    await super.activate(params);
    this.storageVolumes = await this.storageVolumeService.getEntities();
    this.locationGenerator.trayTemplate = params.trayTemplate;
    (<any>this.locationGenerator).fade = true;
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.locationGenerator, "storageVolume").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          let currentStorageVolume = this.storageVolumes.find(x => x.id == this.locationGenerator.storageVolume as any);
          this.locationGenerator.boxWidth = currentStorageVolume.width;
          this.locationGenerator.boxDepth = currentStorageVolume.depth;
          this.locationGenerator.volumeTypeName = currentStorageVolume.volumeType.denomination._translation;
          this.locationGenerator.volumeTypeBackground = currentStorageVolume.volumeType.backgroundColor;
        }
      })
    );
  }

  private isValid() {
    if (this.locationGenerator.storageVolume == null) {
      toastr.error(this.i18n.tr("location.volumeMandatory"));
      return false;
    }

    if (this.locationGenerator.startingFromX == null || this.locationGenerator.startingFromX < 0 || (this.locationGenerator.startingFromX + (this.locationGenerator.nbBoxesWidth * this.locationGenerator.boxWidth) > this.locationGenerator.trayTemplate.trayWidthCm)) {
      toastr.error(this.i18n.tr("location.widthOutOfTray"));
      return false;
    }

    if (this.locationGenerator.startingFromY == null || this.locationGenerator.startingFromY < 0 || (this.locationGenerator.startingFromY + (this.locationGenerator.nbBoxesDepth * this.locationGenerator.boxDepth) > this.locationGenerator.trayTemplate.trayDepthCm)) {
      toastr.error(this.i18n.tr("location.lengthOutOfTray"));
      return false;
    }

    return true;
  }

  public viewResult() {
    if (this.isValid()) {
      if (!this.displayPreview) {
        this.displayPreview = true;
      } else {
        this.trayPreview?.redraw();
      }
    }
  }

  public async generate() {
    if (this.isValid()) {
      let initialX = this.locationGenerator.startingFromX;
      let halfWidth = this.locationGenerator.boxWidth / 2;
      let halfLength = this.locationGenerator.boxDepth / 2;
      let startingFromY = this.locationGenerator.startingFromY;

      let locations: Zeus.Web.Model.TrayTemplatePosition[] = [];

      for (let iRow = 0; iRow < this.locationGenerator.nbBoxesDepth; iRow++) {
        // Start of row
        let startingFromX = initialX;
        for (let iCol = 0; iCol < this.locationGenerator.nbBoxesWidth; iCol++) {
          let location: Zeus.Web.Model.TrayTemplatePosition = this.positionService.createEntity({
            storageUnitWidth: this.locationGenerator.boxWidth,
            storageUnitLength: this.locationGenerator.boxDepth,
            locationIdentifier: Math.floor(startingFromX + halfWidth) + "." + Math.floor(startingFromY + halfLength),
            defaultCapacityInVolume: 1,
            defaultStorageVolumeId: Utils.extractIdFromDataFormat(this.locationGenerator.storageVolume)
          }, true);

          locations.push(location);

          // Inc column
          startingFromX += this.locationGenerator.boxWidth;
        }
        // End of row
        // Inc row
        startingFromY += this.locationGenerator.boxDepth;
      }
      this.dialogController.ok(locations);
    }
  }

  public cancel() {
    this.dialogController.cancel();
  }
}
