import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, GridHelper, getButtonsColDef, ButtonsColDefAction } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class DataFileImportLogList extends ListViewModelBase<Zeus.Web.Model.DataFileImportLog> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.DataFileImportLog>(Constants.EntityTypeNames.DataFileImportLog));
    this.service.gridDataSource.expands = ["dataFileImportType", "user", "highLevelResult"];
  }

  public ressourceName: string = Constants.EntityTypeNames.DataFileImportLog;

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("importlog.dataFileImportType"),
        field: "dataFileImportType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.DataFileImportType,
        }
      },
      {
        headerName: this.i18n.tr("zeususer.zeususer"),
        field: "user.fullName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("importlog.importationDateTime"),
        field: "importationDateTime",
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormatWithHours); },
        type: FieldType.DateTime,
        sort: 'desc'
      },
      {
        headerName: this.i18n.tr("importlog.highLevelResult"),
        field: "highLevelResult.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.BatchProcessResultStatus,
        }
      },
      <any>getButtonsColDef<Zeus.Web.Model.DataFileImportLog>([
        new ButtonsColDefAction<Zeus.Web.Model.DataFileImportLog>({
          icon: 'digi-download',
          type: 'tool',
          uiTheme: 'primary',
          size: 'md',
          clicked: async dataFileImportLog => {
            if (dataFileImportLog.importedFileWithResults != null) {
              this.router.navigate(dataFileImportLog.importedFileWithResults, { replace: false })
            }
          }
        })
      ]),
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
}
