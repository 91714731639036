export class Application {
}

export enum EnumerationTypes {
}

export class EntityTypeNames {
  public static ResourceNames = {
  };
}
