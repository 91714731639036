import { autoinject } from "aurelia-framework";
import { DateUtils, UIInternal } from "digiwall-lib";

@autoinject
export class ArrayHelper {
  constructor(private dateUtils: DateUtils) { }

  public moveBefore(array, itemMatcherFn, siblingMatcherFn) {
    let removedItem = this.remove(array, itemMatcherFn);
    let nextIndex = array.findIndex(siblingMatcherFn);
    array.splice(nextIndex >= 0 ? nextIndex : array.length, 0, removedItem);
  }

  public remove(array, matcherFn) {
    let index = array.findIndex(matcherFn);
    if (index >= 0) {
      return array.splice(index, 1)[0];
    }
  }

  public sortBy<T>(array: T[], sortProperty: string, sortOrder: "asc" | "desc" = "asc"): T[] {
    array.sort((a, b) => {
      const aValue = UIInternal.getValue(a, sortProperty);
      const bValue = UIInternal.getValue(b, sortProperty);
      let comparison: number;
      if (this.isDateValue(aValue) || this.isDateValue(bValue)) {
        comparison = this.dateUtils.isBefore(aValue, bValue) ? -1 : 1;
      } else if (this.isNumberValue(aValue) && this.isNumberValue(bValue)) {
        comparison = parseFloat(aValue) < parseFloat(bValue) ? -1 : 1;
      } else {
        comparison = (aValue?.toString().toLowerCase() || '').localeCompare(bValue?.toString().toLowerCase() || '');
      }
      return sortOrder === 'desc' ? comparison * -1 : comparison;
    });
    return array;
  }

  private isDateValue(value: any): boolean {
    return value != null && isDate(value);
  }

  private isNumberValue(value: any): boolean {
    return value != null && !isNaN(value) && isDecimal(value);
  }

  public flatten<T>(array): T[] {
    let flattenArray = [];
    for (let row of array)
      for (let element of row)
        flattenArray.push(element);

    array = flattenArray;

    return array;
  }
}
