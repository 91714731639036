import { ColDef, GridOptions } from 'ag-grid-community';
import { DetailViewSlot, Endpoint, EntityType, CustomPropDetail, CustomPropertyMetaData, LanguageCode, PropertyType } from './custom-properties-model';
import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { FieldType, ListViewModelBase } from 'digiwall-lib';

interface CustomPropColDef<TData = any> extends ColDef<TData> {
  headerNames: { [key: string]: string, fallback: string };
}

@autoinject
export class CustomPropertiesExtensionService {
  private _listDefinitions: Map<EntityType, CustomPropColDef[]>;
  private _detailDefinitions: Map<EntityType, CustomPropDetail[]>;

  constructor(private httpClient: HttpClient, private i18n: I18N) { }

  public async initialize() {
    if (this._detailDefinitions != null && this._listDefinitions != null) {
      return;
    }
    this._detailDefinitions = new Map<EntityType, CustomPropDetail[]>();
    this._listDefinitions = new Map<EntityType, CustomPropColDef[]>();

    let response = await this.httpClient.fetch(Endpoint);
    if (response.ok) {
      try {
        let customPropertiesList = await response.json() as CustomPropertyMetaData[];

        customPropertiesList.forEach(x => {
          if (false == this._detailDefinitions.has(x.entityType)) {
            this._detailDefinitions.set(x.entityType, []);
          }
          this._detailDefinitions.get(x.entityType).push(this.buildDetailData(x));

          if (false == this._listDefinitions.has(x.entityType)) {
            this._listDefinitions.set(x.entityType, []);
          }
          this._listDefinitions.get(x.entityType).push(this.buildColDef(x));
        });
        this._detailDefinitions.forEach((colDefs, entityType) => {
          this._detailDefinitions.set(entityType, colDefs.filter(x => x).sort((a, b) => a.position - b.position));
        });
        this._listDefinitions.forEach((colDefs, entityType) => this._listDefinitions.set(entityType, colDefs.filter(x => x)));
      } catch {
        // No custom properties
      }
    }
  }

  private propertyPath(metaData: CustomPropertyMetaData) {
    return metaData.propertyType + metaData.propertyNumber;
  }

  private localeToLanguageCode() {
    switch (this.i18n.getLocale()) {
      case 'fr': return LanguageCode.French;
      case 'nl': return LanguageCode.Dutch;
      default: return LanguageCode.English;
    }
  }

  private fallbackLabel(labels: { [key: string]: string }) {
    return labels[Object.keys(labels)[0]];
  }

  //#region list
  public getColDefs(listView: ListViewModelBase<any>, gridOptions: GridOptions, entityType: EntityType) {
    return (this._listDefinitions?.get(entityType) ?? []).map(x => {
      // Make headername change with language change
      x.headerName = x.headerNames[this.localeToLanguageCode()] ?? x.headerNames.fallback;
      return x;
    });
  }

  private buildColDef(metaData: CustomPropertyMetaData) {
    if (false == metaData.listViewMetaData.isShownInList) {
      return null;
    }

    return {
      field: 'customProperties.' + this.propertyPath(metaData),
      hide: metaData.listViewMetaData.isHiddenByDefault,
      type: this.fieldType(metaData.propertyType),
      headerNames: Object.assign({}, metaData.label, { fallback: this.fallbackLabel(metaData.label) })
    } as CustomPropColDef;
  }

  private fieldType(propertyType: PropertyType) {
    switch (propertyType) {
      case PropertyType.Bool: return FieldType.Boolean;
      case PropertyType.Int:
      case PropertyType.Decimal: return FieldType.Number;
      case PropertyType.String: return FieldType.String;
      default: console.error(`Property type ${propertyType} not supported`);
    }
  }
  //#endregion

  //#region detail
  public getCustomProp(entityType: EntityType, viewSlot: DetailViewSlot): CustomPropDetail[] {
    return (this._detailDefinitions.get(entityType) ?? [])
      .filter(x => x.viewSlot.toLocaleLowerCase() == DetailViewSlot[viewSlot].toLocaleLowerCase())
      .map(x => {
        x.label = x.labels[this.localeToLanguageCode()] ?? this.fallbackLabel(x.labels);
        return x;
      });
  }

  private buildDetailData(metaData: CustomPropertyMetaData): CustomPropDetail {
    if (false == metaData.detailViewMetaData.showInDetail) {
      return null;
    }

    return {
      propertyType: metaData.propertyType,
      property: this.propertyPath(metaData),
      labels: metaData.label,
      isReadOnly: metaData.detailViewMetaData.isReadOnly,
      position: metaData.detailViewMetaData.relativePosition,
      viewSlot: metaData.detailViewMetaData.viewSlot.toString(),
    } as CustomPropDetail;
  }
  //#endregion
}
