import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various, EnumerationTypeService } from 'digiwall-lib';
import { GraphicalLedBar } from "../generated";
import { Zeus } from "../../../generated";
import { autoinject, computedFrom } from 'aurelia-framework';
import * as GLBConstants from '../constants';
import * as Constants from '../../../constants';
import { Utils } from 'utils/utils';

@autoinject
export abstract class GLBConfigurationDetail extends EntityDetailViewModelBase<GraphicalLedBar.Model.GLBConfiguration> {
  private woMessageTypeService: ServiceBase<Zeus.Web.Model.WOMessageType>;
  private partService: ServiceBase<GraphicalLedBar.Model.GLBConfigurationPart>;
  private partDataFieldService: ServiceBase<GraphicalLedBar.Model.GLBConfigurationPartDataField>;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<GraphicalLedBar.Model.GLBConfiguration>(GLBConstants.EntityTypeNames.GLBConfiguration));

    this.woMessageTypeService = new ServiceBase<Zeus.Web.Model.WOMessageType>(Constants.EntityTypeNames.WOMessageType);
    this.woMessageTypeService.gridDataSource.expands = ['workOrderType', 'orderSubtype'];
    this.partService = new ServiceBase<GraphicalLedBar.Model.GLBConfigurationPart>(GLBConstants.EntityTypeNames.GLBConfigurationPart);
    this.partDataFieldService = new ServiceBase<GraphicalLedBar.Model.GLBConfigurationPartDataField>(GLBConstants.EntityTypeNames.GLBConfigurationPartDataField);

    this.partDataFieldService.gridDataSource.customSelect2QueryParameters = () => {
      return { woTypeId: this.entity.wOMessageType?.workOrderTypeId };
    }
  }

  public async activate(params) {
    const id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'wOMessageType.workOrderType', 'wOMessageType.orderSubtype', 'parts.dataField');
      this.entity.parts?.sort((a, b) => a.order - b.order);
    }
    this.controller.addObject(this.entity);
  }

  @computedFrom('editingMode')
  public get documentTitle() {
    return this.i18n.tr("glbconfiguration.glbconfiguration");
  }

  public async beforeSave(): Promise<boolean | void> {
    if (this.entity.parts.some(p => p.maxLength <= 0)) {
      this.logError(this.i18n.tr('glbconfigurationpart.maxLengthMinimum'), null, true);
      return false;
    }
    return true;
  }

  //#region Part
  protected async addPart() {
    await this.partService.createEntity({ graphicalLedBarConfiguration: this.entity, order: this.entity.parts.length + 1 });
  }

  private removePart(part: GraphicalLedBar.Model.GLBConfigurationPart) {
    Utils.detachOrDeleteEntity(part);
    this.controller.removeObject(part);
    this.resetPartsOrder();
  }

  private movePart(index: number, delta: number) {
    let newIndex = Math.max(0, Math.min(this.entity.parts.length - 1, index + delta));
    if (newIndex == index) {
      return;
    }
    let part = this.entity.parts.splice(index, 1);
    this.entity.parts.splice(newIndex, 0, ...part);
    this.resetPartsOrder();
  }

  private resetPartsOrder() {
    this.entity.parts.forEach((part, index) => part.order = index + 1);
  }
  //#endregion
}

