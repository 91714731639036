import { OrderSubtype, SessionStorage } from './../../constants';
import { Router } from 'aurelia-router';
import { EnumerationTypes, WorkOrderType } from '../../constants';
import { Actions, AuthService, EnumerationType, EnumerationTypeService, UIInternal, Various } from 'digiwall-lib';
import { autoinject, bindable, computedFrom, customElement } from "aurelia-framework";
import { FilterQueryOp, Predicate } from 'breeze-client';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import * as BConstants from 'app-modules/borrowing/constants';
import * as FPConstants from 'app-modules/free-picking/constants';

@autoinject
@customElement('wo-create-shortcuts')
export class WorkOrderCreateShortcuts {
  @bindable private onNavigated: () => void;
  @bindable private onClick: (woTypeId: number, subTypeId?: number) => void;
  @bindable private action: string = Actions.Create;
  @bindable private showModuleButton: boolean = false;

  private borrowingActive: boolean = this.appModuleService.isActive(AppModuleEnum.Borrowing);
  private freePickingActive: boolean = this.appModuleService.isActive(AppModuleEnum.FreePicking);

  private buttons: ButtonConfig[] = [];

  private shortcutButtonsOrder: Array<{ id: number, order: number }> = [
    { id: WorkOrderType.Input, order: 0 },
    { id: WorkOrderType.Picking, order: 1 },
    { id: WorkOrderType.Inventory, order: 2 }
  ];

  constructor(private router: Router, private authService: AuthService, private appModuleService: AppModuleService) { }

  public async attached() {
    await this.buildButtons();

    UIInternal.subscribe(UIInternal.EVT_CHANGE_LANGUAGE, async () => {
      sessionStorage.removeItem(SessionStorage.WoCreateShortcuts);
      await this.buildButtons();
    });
  }

  private async buildButtons() {
    let buttons = await this.createButtons();
    this.buttons ??= [];
    this.buttons.splice(0)
    this.buttons.push(...buttons.filter(btn =>
      this.authService.checkPermissonAccess({
        resource: 'workordertype' + btn.woTypeLabel,
        action: this.action
      })
    ));
  }

  private async createButtons(): Promise<ButtonConfig[]> {
    let fromSessionsStorage = sessionStorage.getItem(SessionStorage.WoCreateShortcuts);
    if (fromSessionsStorage) {
      return JSON.parse(fromSessionsStorage).map((parsed: Partial<ButtonConfig>) => new ButtonConfig(parsed));
    }

    let woTypes = await new EnumerationTypeService(EnumerationTypes.WorkOrderType).getEntities(
      Predicate.or(
        new Predicate('id', FilterQueryOp.Equals, WorkOrderType.Input),
        new Predicate('id', FilterQueryOp.Equals, WorkOrderType.Picking),
        new Predicate('id', FilterQueryOp.Equals, WorkOrderType.Inventory)
      )
    );

    let woSubtypes = await new EnumerationTypeService(EnumerationTypes.OrderSubtype).getAll();
    let buttons: ButtonConfig[] = [];

    for (let woType of woTypes) {
      let button = new ButtonConfig({
        woTypeId: woType.id,
        woTypeLabel: woType.uniqueCode.toLocaleLowerCase(),
        textColor: woType.textColor,
        backgroundColor: woType.backgroundColor,

        label: woType.denomination._translation
      });

      if (woType.id == WorkOrderType.Input) {
        buttons.push(this.makeSubtypeButton(woSubtypes.find(x => x.id == OrderSubtype.PickingReturn), button));
        if (this.borrowingActive) {
          buttons.push(this.makeSubtypeButton(woSubtypes.find(x => x.id == BConstants.OrderSubtype.BorrowingReturn), button));
        }
      } else if (woType.id == WorkOrderType.Picking) {
        if (this.borrowingActive) {
          buttons.push(this.makeSubtypeButton(woSubtypes.find(x => x.id == BConstants.OrderSubtype.Borrowing), button));
        }
        if (this.freePickingActive) {
          buttons.push(this.makeSubtypeButton(woSubtypes.find(x => x.id == FPConstants.OrderSubtype.TrayPick), button));
        }
      }

      buttons.splice(this.shortcutButtonsOrder.find(x => x.id == woType.id).order, 0, button);
    }

    // Save in session storage for quicker access later in the session
    sessionStorage.setItem(SessionStorage.WoCreateShortcuts, JSON.stringify(buttons));

    return buttons.map((parsed: Partial<ButtonConfig>) => new ButtonConfig(parsed));
  }

  private makeSubtypeButton(subType: EnumerationType, button: ButtonConfig) {
    return Object.assign({}, button, { label: subType.denomination._translation, subTypeId: subType.id });
  }

  private clickButton(button: ButtonConfig) {
    if (this.onClick) {
      return this.onClick(button.woTypeId, button.subTypeId);
    }

    let url = '/work-orders/' + Various.NewId + '?workOrderTypeId=' + button.woTypeId;
    if (button.subTypeId) {
      url += '&subTypeId=' + button.subTypeId;
    }
    this.router.navigate(url);

    if (this.onNavigated) {
      this.onNavigated();
    }
  }

  private showButton(button: ButtonConfig) {
    if (button.subTypeId == BConstants.OrderSubtype.Borrowing || button.subTypeId == BConstants.OrderSubtype.BorrowingReturn) {
      return this.borrowingActive && this.showModuleButton && this.authService.checkAccess(BConstants.Permissions.WorkOrderBorrowing_AsResource, this.action);
    }
    if (button.subTypeId == FPConstants.OrderSubtype.TrayPick) {
      return this.freePickingActive && this.showModuleButton && this.authService.checkAccess(FPConstants.Permissions.TrayPick, FPConstants.Action.Access);
    }
    return true;
  }
}

class ButtonConfig {
  woTypeId: number;
  woTypeLabel: string;
  textColor: string;
  backgroundColor: string;

  label: string;
  subTypeId?: number;

  constructor(init?: Partial<ButtonConfig>) {
    Object.assign(this, init);
  }

  @computedFrom("woTypeId", "subTypeId")
  public get icon(): string {
    switch (this.woTypeId) {
      case WorkOrderType.Input:
        if (this.subTypeId == OrderSubtype.PickingReturn) {
          return 'ze-picking-return';
        }
        if (this.subTypeId == BConstants.OrderSubtype.BorrowingReturn) {
          return 'ze-borrowing-return';
        }
        return 'ze-input';
      case WorkOrderType.Inventory:
        return 'ze-inventory';
      case WorkOrderType.Picking:
        if (this.subTypeId == BConstants.OrderSubtype.Borrowing) {
          return 'ze-borrowing';
        }
        if (this.subTypeId == FPConstants.OrderSubtype.TrayPick) {
          return 'ze-Tray-pick';
        }
        return 'ze-picking';

    }
    return '';
  }
}
