import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationTypeService, FileFlow, Various, PictureHelper } from 'digiwall-lib';
import { Zeus } from "../../../generated";
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../../../constants';
import * as LPConstants from '../constants';
import { LabelPrinting } from '../generated';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { ZeusUtilsMlFieldHelper } from 'utils/utils-ml-field-helper';

@autoinject
export class LabelTemplateDetail extends EntityDetailViewModelBase<LabelPrinting.Model.LabelTemplate> {
  public ressourceName: string = LPConstants.EntityTypeNames.LabelTemplate;

  private labelTypeService: EnumerationTypeService;
  private labelPrinterService: EnumerationTypeService;
  private labelBarcodeService: EnumerationTypeService;

  private labelTemplateFileService: ServiceBase<LabelPrinting.Model.LabelTemplateFile>;
  private labelTemplateDefaultSGPrinterService: ServiceBase<LabelPrinting.Model.LabelTemplateDefaultStorageGroupPrinter>;
  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  private labelVariableService: ServiceBase<LabelPrinting.Model.LabelVariable>;

  private excludedStorageGroupIds: Array<number> = [];
  private labelVariables: Array<LabelPrinting.Model.LabelVariable> = [];

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private fileHelper: PictureHelper) {
    super(router, logger);
    super.initialize(new ServiceBase<LabelPrinting.Model.LabelTemplate>(LPConstants.EntityTypeNames.LabelTemplate));

    this.labelTypeService = new EnumerationTypeService(LPConstants.EnumerationTypes.LabelType);
    this.labelPrinterService = new EnumerationTypeService(LPConstants.EnumerationTypes.LabelPrinter);
    this.labelBarcodeService = new EnumerationTypeService(LPConstants.EnumerationTypes.LabelBarcodeArticle);

    this.labelTemplateFileService = new ServiceBase<LabelPrinting.Model.LabelTemplateFile>(LPConstants.EntityTypeNames.LabelTemplateFile);
    this.labelTemplateDefaultSGPrinterService = new ServiceBase<LabelPrinting.Model.LabelTemplateDefaultStorageGroupPrinter>(LPConstants.EntityTypeNames.LabelTemplateDefaultStorageGroupPrinter);
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.storageGroupService.gridDataSource.expands = ['storageGroupType'];
    this.labelVariableService = new ServiceBase<LabelPrinting.Model.LabelVariable>(LPConstants.EntityTypeNames.LabelVariable);
  }

  public async activate(params) {
    let id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity({ labelTypeId: null, labelBarcodeId: null, templateFileId: null, labelPrinterId: null });
      this.entity.languageCode = this.authService.currentUser.userData.languageCode;
      this.entity.activeTemplate = true;
    }
    else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id, 'labelType', 'labelBarcode', 'templateFile', 'labelPrinter', 'storageGroups.storageGroup.storageGroupType');
      this.controller.addObject(this.entity);
      await this.fetchLabelVariables();
    }
  }

  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("labeltemplate.addLabelTemplate");
    }
    else {
      if (this.entity) {
        return this.entity.name._translation;
      }
    }
  }

  public attached() {
    this.labelBarcodeService.gridDataSource.customSelect2QueryParameters = () => {
      let category: number = 0;
      if (this.entity.labelTypeId == LPConstants.LabelTypeId.Article) {
        category = LPConstants.EnumerationTypes.LabelBarcodeArticle;
      }
      if (this.entity.labelTypeId == LPConstants.LabelTypeId.Location) {
        category = LPConstants.EnumerationTypes.LabelBarcodeLocation;
      }
      if (this.entity.labelTypeId == LPConstants.LabelTypeId.Order) {
        category = LPConstants.EnumerationTypes.LabelBarcodeOrder;
      }
      if (this.entity.labelTypeId == LPConstants.LabelTypeId.Pick) {
        category = LPConstants.EnumerationTypes.LabelBarcodePick;
      }
      return { category: category, onlyActifs: true };
    };

    this.entity.storageGroups.forEach(labelTempDefaultSG => {
      this.excludedStorageGroupIds.push(labelTempDefaultSG.storageGroupId);
    });

    this.storageGroupService.gridDataSource.queryParameters = { excludedStorageGroupIds: this.excludedStorageGroupIds };

    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "labelTypeId").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.entity.labelBarcodeId = null;
          this.entity.labelBarcode = null;
          await this.fetchLabelVariables();
        }
      }),
      this.bindingEngine.propertyObserver(this.entity, "activeTemplate").subscribe((newValue, oldValue) => {
        if (newValue != null && newValue != oldValue && !newValue) {
          this.entity.defaultTemplate = false;
        }
      })
    );
  }

  public async beforeSave() {
    return await ZeusUtilsMlFieldHelper.BeforeSave(this.entity, [
      { prop: this.entity.name, label: "labeltemplate.name", isRequired: true }
    ]);
  }

  //#region Files
  public uploadedFile(fileUploaded: FileFlow) {
    this.entity.templateFile.url = fileUploaded.url;
  }

  public async uploadFile(file: FileFlow) {
    let fileEntity: LabelPrinting.Model.LabelTemplateFile = await this.labelTemplateFileService.createEntity();
    fileEntity.name = file.name;
    fileEntity.size = file.size;
    fileEntity.fileTypeId = Constants.FileTypeId.LabelTemplateFile;

    this.entity.templateFileId = fileEntity.id;
    this.entity.templateFile = fileEntity;
    return fileEntity;
  }

  public confirmTemplate(flow) {
    return flow[0].file.name.endsWith(".txt");
  }

  public folderName() {
    return 'label_templates/' + new Date().getTime();
  }
  //#endregion

  //#region storage groups default printer
  public async addStorageGroupDefaultPrinter() {
    let newSGDefaultPrinter = await this.labelTemplateDefaultSGPrinterService.createEntity({ storageGroupId: null, labelPrinterId: null });
    newSGDefaultPrinter.labelTemplateId = this.entity.id;
    this.entity.storageGroups.push(newSGDefaultPrinter);

    this.addLabelTemDefaultSGObserver(newSGDefaultPrinter);
  }

  public addLabelTemDefaultSGObserver(storageGroupDefaultPrinter: LabelPrinting.Model.LabelTemplateDefaultStorageGroupPrinter) {
    this.disposables.push(
      this.bindingEngine.propertyObserver(storageGroupDefaultPrinter, "storageGroupId").subscribe((newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.excludedStorageGroupIds.push(newValue);
        }
        if (this.excludedStorageGroupIds.includes(oldValue)) {
          this.excludedStorageGroupIds.splice(this.excludedStorageGroupIds.indexOf(oldValue), 1);
        }
      })
    );
  }

  public async removeStorageGroupDefaultPrinter(storageGroupDefaultPrinter: LabelPrinting.Model.LabelTemplateDefaultStorageGroupPrinter) {
    if (storageGroupDefaultPrinter.entityAspect.entityState.isAdded()) {
      storageGroupDefaultPrinter.entityAspect.setDetached();
    }
    else {
      await this.labelTemplateDefaultSGPrinterService.deleteEntities([storageGroupDefaultPrinter], true);
    }

    if (this.excludedStorageGroupIds.includes(storageGroupDefaultPrinter.storageGroupId)) {
      this.excludedStorageGroupIds.splice(this.excludedStorageGroupIds.indexOf(storageGroupDefaultPrinter.storageGroupId), 1);
    }
  }
  //#endregion

  //#region label variables
  private async fetchLabelVariables() {
    this.labelVariables.splice(0);
    this.labelVariables.push(... await this.labelVariableService.getEntities(new Predicate("labelTypeId", FilterQueryOp.Equals, this.entity.labelTypeId)));
  }

  private async copyVariableName(labelVariable: LabelPrinting.Model.LabelVariable) {
    await navigator.clipboard.writeText(labelVariable.variableName);
    this.log(this.i18n.tr("labelvariable.copied"), null, true);
  }
  //#endregion
}
