.work-order-line-cancelled {
  text-decoration: line-through;
}
.work-order-line-partially-cancelled,
.work-order-line-cancelled {
  color: $danger;
}

.work-order-details-table {
  .location-identifier {
    min-width: 290px;
    padding: 0 !important;

    @media (min-width: 1000px) {
      min-width: 330px;
    }

    > div > div {
      max-height: 150px;
      overflow: auto;
    }
  }
}
