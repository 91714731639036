import { FieldType, UserListBase } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';

@autoinject
export class UserList extends UserListBase<Zeus.Web.Model.ZeusUser> {
  constructor() {
    super("multiple", Constants.EntityTypeNames.ZeusUser);
    this.service.gridDataSource.queryParameters = { filterDisabled: false };
    this.gridOptions.getRowClass = this.rowClass;

  }

  private rowClass = (params) => {
    if (params.data == null) {
      return null;
    }

    let user: Zeus.Web.Model.ZeusUser = params.data;
    return user.disabled ? "ag-grid-line-disabled" : "";
  }

  public initializeGridOptions(fixedFilters?: boolean, hasHyperlinkColumn?: boolean): void {
    super.initializeGridOptions(fixedFilters, hasHyperlinkColumn);

    let initialFnUpdate = this.gridOptions.onModelUpdated;
    this.gridOptions.onModelUpdated = (event: any) => {
      initialFnUpdate(event);
      this.gridOptions.api?.redrawRows()
    }
  }

  public getDataGridColumns() {
    let baseDefs: ColDef[] = super.getDataGridColumns();
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("user.hasBadge"),
        field: "hasBadge",
        type: FieldType.Boolean
      }
    ];
    baseDefs.push(...defs);
    return baseDefs;
  }
}
