export class Application {
  public static AddUserBadgeLink = "/api/BadgeLogin/Utils/AddUserBadgeLink";
  public static RemoveUserBadgeLink = "/api/BadgeLogin/Utils/RemoveUserBadgeLink";
}

export enum EnumerationTypes {
}

export class EntityTypeNames {

  public static ResourceNames = {
  };
}
