.ui-table-pagination {
  &__ui-table {
    display: block;
  }

  &__footer {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    align-items: center;
  }
}
