import { Predicate, FilterQueryOp } from 'breeze-client';
import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class ActionHistoryList extends ListViewModelBase<Zeus.Web.Model.ActionHistory> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.ActionHistory>(Constants.EntityTypeNames.ActionHistory));
    this.service.gridDataSource.expands = ['actionType', 'orderSubtype', 'workOrder.inventoryTarget', 'article', 'location', 'workstationUser'];
    this.getPredicatesFunctionInitialState = this.service.gridDataSource.getPredicatesFunction;
  }

  private getPredicatesFunctionInitialState;
  private isFromOtherPage: boolean = false;
  public ressourceName: string = Constants.EntityTypeNames.ActionHistory;

  public async activate(params) {
    if (params?.filter != null) {
      this.isFromOtherPage = true;
      let encodedFilter: string = params.filter;

      const filter = ListViewModelActionHistoryFilter.decode(encodedFilter);
      if (filter.isValidInstance()) {
        this.service.gridDataSource.getPredicatesFunction = (filterText) => {
          let predicate = this.getPredicatesFunctionInitialState(filterText);

          let type: string;
          switch (filter.type) {
            case "storage": {
              type = "storageId"
              break;
            }
            case "processMonitoring": {
              type = "batchProcessId"
              break;
            }
            case "article": {
              type = "articleId"
              break;
            }
            case "workOrder": {
              type = "workOrderId"
              break;
            }
            case "location": {
              type = "location.id"
              break;
            }
          }

          let idPredicates = filter.values.reduce((predicate: Predicate, value): Predicate => {
            let filterPredicate = new Predicate(type, FilterQueryOp.Equals, value);
            return predicate ? predicate.or(filterPredicate) : filterPredicate;
          }, null);

          return predicate ? predicate.and(idPredicates) : idPredicates;
        };
      }
    }
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.ActionHistory) {
    if (entity && entity.id) {
      let url = '/action-history/' + entity.id;
      return url;
    }
    return null;
  }

  public async resetFilter() {
    this.router.navigate('action-history/all', { replace: true, trigger: false });
    this.isFromOtherPage = false;
    this.service.gridDataSource.getPredicatesFunction = this.getPredicatesFunctionInitialState;
    this.gridOptions.api.setDatasource(this.service.gridDataSource);
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("workorder.id"),
        field: "id",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("actionhistory.actionDateTime"),
        field: "actionDateTime",
        type: FieldType.DateTime,
        floatingFilterComponentParams: {
          dateFormat: 'DD/MM/YYYY, HH:mm:ss'
        },
        sort: 'desc'
      },
      {
        headerName: this.i18n.tr("actionhistory.actionType"),
        field: "actionType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.WorkOrderType
        }
      },
      {
        headerName: this.i18n.tr("workorder.orderSubtypeId"),
        field: "orderSubtype.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.OrderSubtype
        },
      },
      {
        headerName: this.i18n.tr("workorder.inventoryTargetId"),
        field: "workOrder.inventoryTarget.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.InventoryTarget
        },
      },
      {
        headerName: this.i18n.tr("actionhistory.requestedQuantity"),
        field: "requestedQuantity",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("actionhistory.confirmedQuantity"),
        field: "confirmedQuantity",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("actionhistory.countedQuantity"),
        field: "countedQuantity",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("actionhistory.articleLocationStockBefore"),
        field: "articleLocationStockBefore",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("actionhistory.articleLocationStockAfter"),
        field: "articleLocationStockAfter",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("articletoinput.lotNumber"),
        field: "articleLotNumber",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("articletoinput.expirationDate"),
        field: "articleExpirationDate",
        type: FieldType.DateTime,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          dateFormat: Various.DateFormat
        },
      },
      {
        headerName: this.i18n.tr("actionhistory.commentOnAction"),
        field: "commentOnAction",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("message.articleReference"),
        field: "article.reference",
        type: FieldType.String
      },

      {
        headerName: this.i18n.tr("message.articleDescription") + ' (FR)',
        field: "article.description.lang1",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("message.articleDescription") + ' (NL)',
        field: "article.description.lang2",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("message.articleDescription") + ' (EN)',
        field: "article.description.lang3",
        type: FieldType.String,
        hide: true
      },
      // For when we have a fallback language (in the future)
      /*{
        headerName: this.i18n.tr("message.articleDescription"),
        field: "article.description._auto",
        type: FieldType.String
      },*/
      {
        headerName: this.i18n.tr("location.locationIdentifier"),
        field: "location.locationIdentifier",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("location.locationIdentifierAlias"),
        field: "location.locationIdentifierAlias",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("actionhistory.workOrderId"),
        field: "workOrderId",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("workorder.workOrderName"),
        field: "workOrder.workOrderName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("batchprocess.batchprocess"),
        field: "batchProcessId",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("workorder.requestedBy"),
        field: "workOrder.requestedBy",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("storage.storageId"),
        field: "storageId",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("batchprocess.createdByFullName"),
        field: "workstationUser.fullName",
        type: FieldType.User,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          userServiceName: Constants.EntityTypeNames.ZeusUser,
        }
      },
      GridHelper.getCreatedTimeColDef(),
      GridHelper.getUpdatedTimeColDef(),
      GridHelper.getUpdatedFullNameColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
}

export class ListViewModelActionHistoryFilter {
  // Entity type
  type: string | 'article' | 'location' | 'storage' | 'workOrder' | 'processMonitoring';
  // List of entities ids
  values: number[];

  constructor(type: string, values: number[]) {
    this.type = type;
    this.values = values;
  }

  public static isInstanceOf(value: any): boolean {
    let filter: ListViewModelActionHistoryFilter = value;
    return filter.type != null && filter.values != null && Array.isArray(filter.values);
  }

  public isValidInstance(): boolean {
    return this.type != null && this.values != null;
  }

  public static decode(value: any): ListViewModelActionHistoryFilter {
    let decoded = JSON.parse(atob(value));
    return new ListViewModelActionHistoryFilter(decoded.type, decoded.values);
  }

  public encode(): string {
    return btoa(JSON.stringify(this));
  }
}
