import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomLogger, ViewModelBase } from 'digiwall-lib';
import { AppModuleProvider } from './app-module-provider';
import { AppModuleService } from './app-module-service';
import { AppModuleEnum } from './constants';
import * as Constants from '../constants';
import { Endpoints } from 'endpoints';

@autoinject
export class ChangeActivations extends ViewModelBase {

  ribbonHeaderText = 'appmodules.appModules';
  ressourceName = 'appModules';

  private appModules: AppModule[];

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient,
    private appModuleService: AppModuleService, private appModuleProvider: AppModuleProvider) {
    super(router, logger);
    this.appModules = [];
    this.appModuleProvider.getRootMap().forEach((root, appModule) =>
      this.appModules.push({
        appModule,
        name: root.name,
        isActive: false
      })
    );
    this.loadIsActiveStates();
  }

  private loadIsActiveStates() {
    this.appModules.forEach(x => x.isActive = this.appModuleService.isActive(x.appModule));
  }

  private get canSave() {
    return this.appModules.some(x => x.isActive != this.appModuleService.isActive(x.appModule));
  }

  cancel() {
    this.loadIsActiveStates();
  }

  async save() {
    let response = await this.httpClient.post(
      Endpoints.AppModule.AppModulesSetDevelopmentActiveModules,
      JSON.stringify(this.appModules.filter(x => x.isActive).map(x => x.appModule))
    );
    if (response.ok) {
      this.reloadPage();
    }
  }

  async clear() {
    if ((await this.httpClient.post(Endpoints.AppModule.AppModulesClearDevelopmentActiveModules)).ok) {
      this.reloadPage();
    }
  }

  reloadPage() {
    sessionStorage.removeItem(Constants.SessionStorage.WoCreateShortcuts);
    location.reload();
  }
}

interface AppModule {
  appModule: AppModuleEnum;
  name: string;
  isActive: boolean;
}
