import { customElement } from "aurelia-templating";
import { ServiceBase } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../../../constants';
import { InventoryBaseActions } from './inventory-base-actions';
import { InventoryBaseFloatingBox } from 'work-orders/floating-box/inventory/inventory-base-floating-box';
import { InventoryTrayContainerFloatingBox } from "work-orders/floating-box/inventory/inventory-trayContainer-floating-box";

@customElement('inventory-tray-actions')
export class InventoryTrayActions extends InventoryBaseActions<Zeus.Web.Model.InventoryWorkOrderTrayContainer> {

  constructor() {
    super(new ServiceBase<Zeus.Web.Model.InventoryWorkOrderTrayContainer>(Constants.EntityTypeNames.InventoryWorkOrderTrayContainer));
  }

  protected getExpands(withLocations: boolean = false): string[] {
    return withLocations ? ['trayContainer.storage', 'trayContainer.locations.articleLocations'] : ['trayContainer.storage'];
  }

  protected setNumberOfLocations(entity: Zeus.Web.Model.InventoryWorkOrderTrayContainer): Zeus.Web.Model.InventoryWorkOrderTrayContainer {
    (entity as any).nbrLocations = entity
      .trayContainer.locations?.reduce((sum: number, location: Zeus.Web.Model.Location) => sum + location.articleLocations?.length, 0);
    return entity;
  }

  protected getFloatingBoxInstance(): InventoryBaseFloatingBox<Zeus.Web.Model.InventoryWorkOrderTrayContainer> {
    return new InventoryTrayContainerFloatingBox(this.workOrder);
  }

}
