import { Zeus } from "../generated";
import * as Constants from '../constants';
import { autoinject, useView } from 'aurelia-framework';
import { AlgorithmDetail } from "./algorithm-detail";

@autoinject
@useView('./algorithm-detail.html')
export class AlgorithmDetailInput extends AlgorithmDetail<Zeus.Web.Model.InputAlgorithm, Zeus.Web.Model.InputAlgorithmPart> {

  constructor() {
    super(Constants.EntityTypeNames.InputAlgorithm, Constants.EntityTypeNames.InputAlgorithmPart,
      Constants.WorkOrderType.Input, Constants.EnumerationTypes.SortingLocationType);
  }
}
