import { AppModuleService } from 'app-modules/app-module-service';
import { ZeusHubClient } from '../external-src/zeus-hub-client';
import { UnloadHelper } from './../utils/unload-helper';
import { FrameworkConfiguration } from 'aurelia-framework';
import { ZeusAppModuleService } from 'app-modules/app-module-service-zeus';
import { AppModuleProvider } from 'app-modules/app-module-provider';
import { ZeusAppModuleProvider } from 'app-modules/app-module-provider-zeus';
import { CustomPropertiesForm } from 'extension-hooks/custom-properties/custom-properties-form/custom-properties-form';
import { CustomPropertiesPanel } from 'extension-hooks/custom-properties/custom-properties-panel/custom-properties-panel';

export function configure(config: FrameworkConfiguration) {
  config.singleton(UnloadHelper);
  config.singleton(ZeusHubClient);

  config.singleton(AppModuleService, ZeusAppModuleService);
  config.singleton(AppModuleProvider, ZeusAppModuleProvider);

  config.globalResources([CustomPropertiesForm, CustomPropertiesPanel]);
}
