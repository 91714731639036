import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class ArticleFamilyList extends ListViewModelBase<Zeus.Web.Model.ArticleFamily> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.ArticleFamily>(Constants.EntityTypeNames.ArticleFamily));
  }

  public ressourceName: string = Constants.EntityTypeNames.ArticleFamily;

  public getDetailsUrl(self, entity: Zeus.Web.Model.ArticleFamily) {
    if (entity && entity.id) {
      let url = '/article-families/' + entity.id;
      return url;
    }
    return null;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("articlefamily.name"),
        field: "name._translation",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("articlefamily.parent"),
        field: "articleFamilyParent.name._translation",
        type: FieldType.String
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
}
