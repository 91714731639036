import { Predicate } from 'breeze-client';
import { Zeus } from 'generated';
import { bindable, customElement } from "aurelia-templating";
import { ServiceBase } from 'digiwall-lib';
import * as Constants from "../../constants";
import { bindingMode, computedFrom } from 'aurelia-binding';

@customElement('storage-group-advanced-select')
export class StorageGroupAdvancedSelect {

  @bindable
  private entity: { storageGroupId: number, storageGroup: Zeus.Web.Model.StorageGroup }
  @bindable
  private isDisabled: boolean | (() => boolean) = false;
  @bindable
  private allowClear: boolean | (() => boolean) = false;
  @bindable
  private onChange: () => void = () => {};
  @bindable({ defaultBindingMode: bindingMode.fromView })
  private uiSelect : any;
  @bindable
  private queryParameters: any | (() => any);
  @bindable
  private predicates: Predicate | (() => Predicate);

  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;

  constructor() {
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.storageGroupService.gridDataSource.expands = ['parentStorageGroup.parentStorageGroup.parentStorageGroup'];
    this.storageGroupService.gridDataSource.customSelect2Predicates =
      () => this.predicates ? (typeof this.predicates == 'function' ? this.predicates() : this.predicates) : null;
  }

  private queryParametersChanged(newValue, oldValue) {
    this.storageGroupService.gridDataSource.queryParameters = 
      typeof this.queryParameters == 'function' ? this.queryParameters() : this.queryParameters;
  }

  private get disabled() : boolean {
    if(typeof this.isDisabled == 'function') {
      return this.isDisabled();
    } else {
      return this.isDisabled;
    }
  }

  private get clearable() : boolean {
    if(typeof this.allowClear == 'function') {
      return this.allowClear();
    } else {
      return this.allowClear;
    }
  }
}
