import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { RemoteFileStorage, UIInternal } from 'digiwall-lib';

@autoinject
export class BorrowedArticles {
  private previewUrl: string;

  constructor(private http: HttpClient, private fileStorageService: RemoteFileStorage.Service) {

  }

  private async attached() {
    let response = await this.http.get('/api/Borrowing/Report/BorrowedArticles')
    if (response.ok) {
      this.previewUrl = await response.text();
    } else {
      alert(response.statusText)
    }
  }
}
