import { autoinject, computedFrom } from 'aurelia-framework';
import { ServiceBase } from 'digiwall-lib';
import { ApiService } from 'external-src/api.service';
import { Zeus } from "../generated";
import * as Constants from "../constants";
import * as toastr from 'toastr';


@autoinject
export class ParcLocationsGenerator {

  public model: Zeus.Web.Model.SiteStructureGeneratorModel;
  public storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  // private storageVolumes: Zeus.Web.Model.StorageVolume[]; // Toujours utile ?
  public isCreatingLocations = false;

  constructor(private apiService: ApiService) {
    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    // this.storageVolumeService.gridDataSource.expands = ['volumeType'];
  }

  @computedFrom(...["model.sitesCount", "model.warehousesCount", "model.zoneGroupsCount", "model.zonesCount", "model.storagesCount"])
  get locationsCount() {
    if (this.model == null) return 0;
    return this.model.locationsCountByTray *
      this.model.traysCount *
      this.model.storagesCount *
      this.model.zonesCount *
      this.model.zoneGroupsCount *
      this.model.warehousesCount *
      this.model.sitesCount
  }

  async attached() {
    // this.storageVolumes = await this.storageVolumeService.getEntities();
    this.newModel();
  }

  public async generateLocations(): Promise<boolean> {
    if (this.isCreatingLocations) return Promise.resolve(false);
    this.isCreatingLocations = true;

    let val = false;
    try {
      val = await this.apiService.createParcOfLocations(this.model);
      toastr.success("Parc created with success");
    } catch (error) {
      toastr.error(error);
    }

    this.isCreatingLocations = false;
    return Promise.resolve(val);
  }

  private async newModel(): Promise<void> {
    this.model = {
      sitesCount: 1,
      sitesNamePrefix: "Site Auto",
      warehousesCount: 1,
      warehousesNamePrefix: "Warehouse Auto",
      zoneGroupsCount: 1,
      zoneGroupsNamePrefix: "Zone Group Auto",
      zonesCount: 1,
      zonesNamePrefix: "Zone Auto",
      storagesCount: 4,
      storagesNamePrefix: "Storage Auto",
      storageStartDynamicStorageId: 850,
      // traysCount: this.storageVolumes.length,
      traysCount: await this.storageVolumeService.getCount(),
      locationsCountByTray: 50
    };
  }

}
