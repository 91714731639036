import { HttpClient } from 'aurelia-fetch-client';
import { Container, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { Box, FloatingBoxViewModel, ServiceBase } from "digiwall-lib";
import { UIInput } from "digiwall-lib/dist/typings/ui/forms/ui-input";
import { Zeus } from "generated";
import * as ConvConstants from "../constants";
import * as Constants from "../../../constants";

export class LaunchInBinFloatingBox extends FloatingBoxViewModel {

  private binNumber: string;
  private scanBinInput: UIInput;

  private maxBarcodeLength: number;

  private box: Box;
  private i18n: I18N;
  private http: HttpClient;

  constructor(private workOrder: Zeus.Web.Model.WorkOrder) {
    super('./launch-in-bin-floating-box.html', 'sm');

    this.box = Container.instance.get(Box);
    this.i18n = Container.instance.get(I18N);
    this.http = Container.instance.get(HttpClient);
  }

  private async attached() {
    this.scanBinInput.element.addEventListener('enterpressed', () => this.binNumberScanned());

    let hardwareParams = await new ServiceBase<Zeus.Web.Model.HardwareParameters>(Constants.EntityTypeNames.HardwareParameters).firstEntity();
    this.maxBarcodeLength = hardwareParams.binNumberMaxBarcodeLength ?? Number.MAX_SAFE_INTEGER;
  }

  @computedFrom('workOrder.workOrderName', 'workOrder.id')
  private get workOrderName(): string {
    return this.workOrder.workOrderName ?? "" + this.workOrder.id
  }

  private focusInput(submit: boolean) {
    this.scanBinInput?.focus();
    if (submit) {
      this.binNumberScanned();
    }
  }

  private async binNumberScanned() {
    if (this.binNumber == null || this.binNumber.length == 0) {
      return;
    }

    if (this.binNumber?.length > this.maxBarcodeLength) {
      await this.box.showError(
        this.i18n.tr('launchinbin.barcodeLengthError', { max: this.maxBarcodeLength }),
        this.i18n.tr('general.error'), null, false);
      this.binNumber = null;
      return;
    }

    let response = await this.http.post(ConvConstants.Application.LinkBinToPickingWorkOrder + "?workOrderId=" + this.workOrder.id, JSON.stringify(this.binNumber));
    if (response.ok) {
      this.dialogController.ok(this.binNumber);
    } else {
      await this.box.showError(await response.text(), this.i18n.tr('general.error'));
      this.binNumber = null;
    }
  }
}
