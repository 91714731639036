import { autoinject } from 'aurelia-framework';
import { AuthService } from 'digiwall-lib';

@autoinject
export class Login {
  constructor(private auth: AuthService) {
    
  }
  activate(params) {
    let fragment = decodeURIComponent(params.fragment);
    this.auth.login(fragment);
  }
}
