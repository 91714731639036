export enum EnumerationTypes {
  LabelPrinter = 22,
  LabelType = 23,
  LabelBarcodeLocation = 25,
  LabelBarcodeArticle = 26,
  LabelBarcodeOrder = 38,
  LabelBarcodePick = 41,
  PickLabelConcept = 42
}

export class EntityTypeNames {
  public static LabelTemplate = "LabelTemplate";
  public static LabelTemplateFile = "LabelTemplateFile";
  public static LabelTemplateDefaultStorageGroupPrinter = "LabelTemplateDefaultStorageGroupPrinter";
  public static LabelVariable = "LabelVariable";
  public static LabelPrintConfiguration = "LabelPrintConfiguration";
  public static SentMessage = "SentMessage";

  public static ResourceNames = {
    LabelTemplate: "LabelTemplates",
    LabelTemplateFile: "LabelTemplateFiles",
    LabelVariable: "LabelVariables",
    LabelPrintConfiguration: "LabelPrintConfigurations",
    SentMessage: "SentMessages",
    LabelTemplateDefaultStorageGroupPrinter: "LabelTemplateDefaultStorageGroupPrinters"
  };
}

export class Application {
  public static PrintLabelsFromDTO = "/api/LabelPrinting/PrintLabel/PrintLabelsFromDTO";
  public static GetLocationsToPrint = "/api/LabelPrinting/PrintLabel/GetLocationsToPrint";
  public static FilterLocationByUserSG = "/api/LabelPrinting/PrintLabel/FilterLocationByUserSG";
}

export enum LabelTypeId {
  Article = 230,
  Location,
  Order,
  Pick
}
