import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Various } from 'digiwall-lib';
import { Zeus } from "../generated";
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { ValidationRules } from 'aurelia-validation';

@autoinject
export class ProjectDetail extends EntityDetailViewModelBase<Zeus.Web.Model.Project> {
  public ressourceName: string = Constants.EntityTypeNames.Project;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.Project>(Constants.EntityTypeNames.Project));
  }

  public async activate(params) {
    let id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity()
      this.entity.active = true;
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.controller.addObject(this.entity);
    }
    ValidationRules
      .ensure('projectName').required()
      .on(this.entity);
  }

  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("project.addProject");
    } else {
      if (this.entity) {
        return this.entity.number + " - " + (this.entity.projectName ?? '');
      }
    }
  }
}
