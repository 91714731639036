import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class StorageVolumeList extends ListViewModelBase<Zeus.Web.Model.StorageVolume> {
  constructor(
    router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume));
    this.service.gridDataSource.expands = ['volumeType']
  }

  public ressourceName: string = Constants.EntityTypeNames.StorageVolume;

  public getDetailsUrl(self, entity: Zeus.Web.Model.StorageVolume) {
    if (entity && entity.id) {
      let url = '/storage-volumes/' + entity.id;
      return url;
    }
    return null;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("storagevolume.volumeType"),
        field: "volumeType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.StorageVolumeType
        },
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("enumerationtype.uniqueCode"),
        field: "volumeType.uniqueCode",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storagevolume.width"),
        field: "width",
        type: FieldType.Number,
        cellRendererParams: { minimumFractionDigits: 0, maximumFractionDigits: 3 },
        cellStyle: { "text-align": "left" }
      },
      {
        headerName: this.i18n.tr("storagevolume.depth"),
        field: "depth",
        type: FieldType.Number,
        cellRendererParams: { minimumFractionDigits: 0, maximumFractionDigits: 3 },
        cellStyle: { "text-align": "left" }
      },
      {
        headerName: this.i18n.tr("storagevolume.height"),
        field: "height",
        type: FieldType.Number,
        cellRendererParams: { minimumFractionDigits: 0, maximumFractionDigits: 3 },
        cellStyle: { "text-align": "left" }
      },
      {
        headerName: this.i18n.tr("enumerationtype.active"),
        field: "volumeType.active",
        type: FieldType.Boolean
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
} 
