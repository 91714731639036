export class Permissions {
  public static Reception = 'reception';
  public static ReceptionParameters = 'reception-parameters';
}

export class RoleNames {
  public static Reception = "role.reception"
}

export class EntityTypeNames {
  public static ReceptionParameters = "ReceptionParameters";

  public static ResourceNames = {
    ReceptionParameters: "ReceptionParameters"
  };
}
