
import { Actions, PermissionsConfig, RouteSettings } from "digiwall-lib";
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import receptionTranslationsJson from './locales/index';
import dtlTranslationJson from '../app-modules/drop-to-light/locales/index';
import * as Constants from '../constants';
import { AppModuleRoot, AppModuleRouteConfig } from 'app-modules/app-module-root';
import { EntityTypeNames, Permissions } from './constants';

export class ReceptionActivator extends AppModuleRoot {

  private _hasBeenActivated = new Map<string, boolean>();

  private avoidDoubleActivation(key: string) {
    if (!this._hasBeenActivated.get(key)) {
      this._hasBeenActivated.set(key, true);
      return false;
    }
    return true;
  }

  public get name() {
    return "ReceptionActivator";
  }

  public get translations(): { [key: string]: object; } {
    // Merge with DTL translations
    return this.mergeTranslations([dtlTranslationJson, receptionTranslationsJson]);
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    if (this.avoidDoubleActivation("enumerationTypeList")) {
      return {};
    }
    return EntityTypeNames.ResourceNames;
  }

  protected get pathName(): string {
    return 'reception';
  }

  public getRoutes(): AppModuleRouteConfig[] {
    if (this.avoidDoubleActivation("getRoutes")) {
      return [];
    }

    return [
      {
        after: Constants.RouteName.AppParam,
        route: "receptionParameters/all",
        name: 'reception-parameters/reception-parameters',
        moduleId: "../../reception/reception-parameters/reception-parameters",
        title: 'applicationparameters.receptionParameters',
        nav: true,
        settings: {
          group: Constants.MenuType.ApplicationParameters,
          resource: Constants.EntityTypeNames.ReceptionParameters,
          action: Actions.Read
        }
      }
    ];
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [];
  }

  protected get resourceNames(): {} {
    return {};
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {};
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
