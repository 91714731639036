import { ApiService } from 'external-src/api.service';
import { Router } from 'aurelia-router';
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, ServiceBase, } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom, TaskQueue } from 'aurelia-framework';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import moment = require('moment');
import { DialogController } from 'aurelia-dialog';


@autoinject
export class WorkOrderLinesToProcess extends EntityDetailViewModelBase<Zeus.Web.Model.ArticleToAction> {
  public ressourceName: string = Constants.EntityTypeNames.ArticleToAction;


  @computedFrom('entity.actionWorkOrder.inputTypeId')
  private get isOfTypeInput(): boolean {
    return this.entity.actionWorkOrder.orderSubtypeId != null;
  }

  public articleVolumeConfigs: Array<Zeus.Web.Model.ArticleVolumeConfig>;
  public isLocationsExpanded: boolean = false;
  public isStorageGroupParametersExpanded: boolean = false;

  private storageGroupParametersExpanded() {
    this.isLocationsExpanded = false;
    return Promise.resolve();
  }
  private locationsExpanded() {
    this.isStorageGroupParametersExpanded = false;
    return Promise.resolve();
  }

  get documentTitle(): any {
    if (this.entity) return this.i18n.tr('workorderlinetoprocess.articleTo' + (this.isOfTypeInput ? 'Input' : 'Pick')) + " : " + (this.entity.article.description._translation ?? '');
  }

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private apiService: ApiService, private taskQueue: TaskQueue, private dialogController: DialogController) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.ArticleToAction>(Constants.EntityTypeNames.ArticleToAction));

    this.articleVolumeConfigs = [];
  }

  public async activate(params: { articleToActionId: number }) {
    await super.activate(params);
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(params.articleToActionId,
      'limitedToStorageGroup', 'actionWorkOrder.workOrderPriority', 'article.articleLocations',
      'article.unitOfMeasures', 'article.articleVolumeConfigs', 'unitOfMeasure'
    );

    if (this.entity.locationForInputPickings.length > 0) {
      if (this.isOfTypeInput) {
        await this.apiService.computeUsedAndFreeSpaceForInput(this.entity as Zeus.Web.Model.ArticleToInput);
      } else {
        await this.apiService.computePickableQuantity(this.entity as Zeus.Web.Model.ArticleToPick);
      }
    }

    this.controller.addObject(this.entity);
  }

  public async validate() {
    await this.dialogController.ok();
  }

  public async close() {
    await this.dialogController.cancel();
  }

}
