.link-badge {
  .icon-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }

  .input-container {
    opacity: 0;

    input {
      height: 0;
    }
  }
}
