import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, Actions, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { DialogService } from 'aurelia-dialog';
import { ImportXLS, ImportXlsParams, subscribeImportRefresh } from '../import-xls/import-xls';
import { Endpoints } from 'endpoints';

@autoinject
export class ProjectList extends ListViewModelBase<Zeus.Web.Model.Project> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private dialogService: DialogService) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.Project>(Constants.EntityTypeNames.Project));
  }

  public ressourceName: string = Constants.EntityTypeNames.Project;

  async attached() {
    await super.attached();
    this.disposables.push(subscribeImportRefresh(this.gridOptions, Constants.DataFileImportType.Project));
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.Project) {
    if (entity && entity.id) {
      let url = '/projects/' + entity.id;
      return url;
    }
    return null;
  }

  public importProject() {
    let params: ImportXlsParams = {
      type: Constants.EntityTypeNames.Project,
      title: 'project.importFile',
      patternUrl: Endpoints.ImportXlsx.GetProjectXLSPattern,
      importUrl: Endpoints.ImportXlsx.ImportProjectXLS,
      folderName: 'project_imports',
    };
    this.dialogService.open({ viewModel: ImportXLS, model: params }).whenClosed((result) => {
      if (!result.wasCancelled && result.output as boolean) {
        this.gridOptions.api.refreshInfiniteCache();
      }
    });
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("project.number"),
        field: "number",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("project.projectName"),
        field: "projectName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("project.active"),
        field: "active",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("project.customerNumber"),
        field: "customerNumber",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("project.customerName"),
        field: "customerName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("project.projectCreationDate"),
        field: "projectCreationDate",
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormat); },
        type: FieldType.DateTime
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    if (this.authService.checkAccess(Constants.EntityTypeNames.Project, Actions.Delete)) {
      defs.splice(0, 0, GridHelper.getSelectedColDef(this));
    }
    return defs;
  }
}
