import { autoinject, bindable, containerless, customElement, bindingMode, computedFrom } from "aurelia-framework";
import { Zeus } from '../../../generated'
import * as GLBConstants from '../constants';
import { EnumerationTypeService } from "digiwall-lib";

@autoinject
@containerless
@customElement('bay-device-ledbar')
export class BayDeviceLedbar {
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  private entity: Zeus.Web.Model.BayDevice;

  private ledBarSizeService: EnumerationTypeService;

  constructor() {
    this.ledBarSizeService = new EnumerationTypeService(GLBConstants.EnumerationTypes.LedBarSizes);
  }

  @computedFrom("entity.deviceTypeId")
  get isLedBar() {
    return this.entity.deviceTypeId == GLBConstants.DeviceType.GraphicalLedBar;
  }

  private async attached() {
    if (this.entity.ledBarSizeId == null) {
      this.entity.ledBarSize = await this.ledBarSizeService.getDefault();
    }
  }

}
