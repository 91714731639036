import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, CamelCase } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../constants';
import { Reception } from '../generated';
import { BindingEngine, computedFrom } from 'aurelia-binding';

@autoinject
export class ReceptionParameters extends EntityDetailViewModelBase<Reception.Model.ReceptionParameters> {
  public ressourceName: string = Constants.EntityTypeNames.ReceptionParameters;

  private pictureRecentField: Reception.Model.ReceptionParametersField;
  private nbrLocationsBelowMaxField: Reception.Model.ReceptionParametersField;
  private maxBinWeightField: Reception.Model.ReceptionParametersField;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Reception.Model.ReceptionParameters>(Constants.EntityTypeNames.ReceptionParameters));
  }

  @computedFrom("documentTitle")
  public get ribbonHeaderText(): string {
    return this.documentTitle;
  }

  @computedFrom("editingMode")
  public get documentTitle() {
    return this.i18n.tr("receptionparameters.receptionparameters");
  }

  public async activate() {
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.firstEntity(null, ['fields']);
    this.pictureRecentField = this.entity.fields.find(x => x.fieldName == "PictureRecent");
    this.nbrLocationsBelowMaxField = this.entity.fields.find(x => x.fieldName == "NbrLocationsBelowMax");
    this.maxBinWeightField = this.entity.fields.find(x => x.fieldName == "MaxBinWeight");
    this.controller.addObject(this.entity);
  }

  public attached() {
    this.disposables.push(...[
      this.bindingEngine.propertyObserver(this.pictureRecentField, "check").subscribe((newVal, oldVal) => {
        this.entity.maxAgeOfPictureInDays = null;
      }),
      this.bindingEngine.propertyObserver(this.nbrLocationsBelowMaxField, "check").subscribe((newVal, oldVal) => {
        this.entity.maxNbLocationsPerInputOrderLine = null;
      }),
      this.bindingEngine.propertyObserver(this.maxBinWeightField, "check").subscribe((newVal, oldVal) => {
        this.entity.maxWeightPerBin = null;
      }),
    ]);
  }

  private getFieldKey(fieldValueName: string) {
    return 'receptionparameters.' + CamelCase.toCamelCase(fieldValueName);
  }

  private getTitle(fieldValueName: string) {
    const trKey = this.getFieldKey(fieldValueName);
    const trKeyTitle = trKey + "Title";
    const tr = this.i18n.tr(trKeyTitle);
    if (tr == trKeyTitle) {
      return this.i18n.tr(trKey);
    }
    return tr;
  }

  protected async beforeSave(): Promise<boolean | void> {
    let isValid: boolean = true;
    if (this.pictureRecentField.check && (this.entity.maxAgeOfPictureInDays == null || this.entity.maxAgeOfPictureInDays <= 0)) {
      this.logError(this.i18n.tr("receptionparameters.maxAgeOfPictureInDaysValidation"), null, true);
      isValid = false;
    }
    if (this.nbrLocationsBelowMaxField.check && (this.entity.maxNbLocationsPerInputOrderLine == null || this.entity.maxNbLocationsPerInputOrderLine <= 0)) {
      this.logError(this.i18n.tr("receptionparameters.maxNbLocationsPerInputOrderLineValidation"), null, true);
      isValid = false;
    }
    if (this.maxBinWeightField.check && (this.entity.maxWeightPerBin == null || this.entity.maxWeightPerBin <= 0)) {
      this.logError(this.i18n.tr("receptionparameters.maxWeightPerBinValidation"), null, true);
      isValid = false;
    }
    return isValid;
  }
}
