import { Zeus } from 'generated';
import { bindable, customElement } from "aurelia-templating";
import { ServiceBase } from 'digiwall-lib';
import * as Constants from "../../constants";
import { bindingMode } from 'aurelia-binding';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { IGetRowsParams } from 'ag-grid-community';
import { Container, autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { TaskQueue } from 'aurelia-framework';
import { Endpoints } from 'endpoints';
import * as BConstants from "../../app-modules/borrowing/constants";
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
@customElement('article-advanced-select')
export class ArticleAdvancedSelect {
  @bindable
  private entity: { articleId: number, article: Zeus.Web.Model.Article }
  @bindable
  private isDisabled: boolean | (() => boolean) = false;
  @bindable
  private onChange: () => void = () => { };
  @bindable({ defaultBindingMode: bindingMode.fromView })
  private uiSelect: any;
  @bindable
  private fetchAvailableStock: boolean = false;
  @bindable
  private limitedToId: number;
  @bindable
  private predicates: null | (() => Predicate);

  private articleService: ServiceBase<Zeus.Web.Model.Article>;

  constructor(private appModuleService: AppModuleService) {
    this.articleService = new ServiceBase<Zeus.Web.Model.Article>(Constants.EntityTypeNames.Article);
    this.articleService.gridDataSource.expands = ['unitOfMeasures.unitOfMeasure', 'articleVolumeConfigs'];
    this.articleService.gridDataSource.customSelect2Predicates = () => {
      let predicate = new Predicate('active', FilterQueryOp.Equals, true);
      if (this.onlyBorrowing) {
        predicate = predicate.and(new Predicate('canBeBorrowed', FilterQueryOp.Equals, true));
      }
      let externalPredicate = this.predicates ? this.predicates() : null;
      if (externalPredicate != null) {
        predicate = predicate.and(externalPredicate);
      }
      return predicate;
    };
    this.articleService.gridDataSource.customSelect2QueryParameters = () => {
      let obj = {};
      if (this.fetchBorrowingStock) {
        obj["onlyCurrentUserBorrwing"] = true;
      }
      return obj;
    }
    let taskQueue = Container.instance.get(TaskQueue);
    taskQueue.queueTask(() => {
      this.setAvailableStock();
      this.setBorrowingStock();
    });
  }

  private setAvailableStock() {
    if (this.fetchAvailableStock) {
      this.setStock("pickableQuantity", Endpoints.WorkOrder.GetPickableStockForArticles + "?" + (this.limitedToId != null ? "limitedToSG=" + this.limitedToId + "&" : ""));
    }
  }

  private setStock(propName: string, endpointAddProp: string, propNameNumber: string = null) {
    const getRowsForSelect2 = this.articleService.gridDataSource.getRowsForSelect2;
    const httpClient = Container.instance.get(HttpClient);
    this.articleService.gridDataSource.getRowsForSelect2 = (params: IGetRowsParams, filterText: string, filterProperties: string[]) => {
      const successCallback = params.successCallback;
      params.successCallback = (rowsThisBlock: Zeus.Web.Model.Article[], lastRow?: number) => {
        let articleIds = rowsThisBlock.filter(x => x[propName] == null);
        if (articleIds.length >= 0) {
          let url = endpointAddProp + articleIds.map(x => "articleIds=" + x.id).join("&");
          httpClient.fetch(url).then(async response => {
            if (response.ok) {
              let json = await response.json();
              delete json.$id;
              delete json.$type;
              let articlesStock: { [key: string]: number } = json;

              Object.keys(articlesStock).forEach((key: string) => {
                const stock = articlesStock[key];
                let article = rowsThisBlock.find(a => a.id.toString() == key);
                if (article != null) {
                  article[propName] = stock + " " + article.unitOfMeasures.find(uom => uom.usedForStockCount)?.unitOfMeasure.denomination._translation;
                  if (propNameNumber != null) {
                    article[propNameNumber] = stock;
                  }
                }
              });
            }
          })
        }
        return successCallback(rowsThisBlock, lastRow);
      };
      return getRowsForSelect2.call(this.articleService.gridDataSource, params, filterText, filterProperties);
    }
  }

  private get disabled(): boolean {
    if (typeof this.isDisabled == 'function') {
      return this.isDisabled();
    } else {
      return this.isDisabled;
    }
  }

  //#region Borrowing
  @bindable private onlyBorrowing: boolean = false;
  @bindable private fetchBorrowingStock: boolean = false;

  private setBorrowingStock() {
    if (this.appModuleService.isActive(AppModuleEnum.Borrowing) && this.fetchBorrowingStock) {
      this.setStock("borrowingQuantityStr", BConstants.Application.GetBorrowingStockForArticles + "?", "borrowingQuantity");
    }
  }
  //#endregion
}
