import { DialogService } from 'aurelia-dialog';
import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, IMenuItems, EnumerationTypeService, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class AlgorithmList extends ListViewModelBase<Zeus.Web.Model.InputPickingAlgorithm> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private dialogService: DialogService) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.InputPickingAlgorithm>(Constants.EntityTypeNames.InputPickingAlgorithm));
    this.service.gridDataSource.expands = ['type'];
  }

  public ressourceName: string = Constants.EntityTypeNames.InputPickingAlgorithm;
  private addSubMenu: IMenuItems[] = [
    {
      label: this.i18n.tr('workorder.picking'),
      icon: 'ze-picking',
      href: '/algorithms/picking/' + Various.NewId,
    },
    {
      label: this.i18n.tr('workorder.input'),
      icon: 'ze-input',
      href: '/algorithms/input/' + Various.NewId,
    },
  ];

  public getDetailsUrl(self, entity: Zeus.Web.Model.InputPickingAlgorithm) {
    if (entity && entity.id) {
      return `/algorithms/${entity.type.uniqueCode.toLowerCase()}/${entity.id}`;
    }
    return null;
  }

  public getDataGridColumns() {
    let typeService = new EnumerationTypeService(Constants.EnumerationTypes.WorkOrderType);
    typeService.gridDataSource.predicates = Predicate.or(
      new Predicate('id', FilterQueryOp.Equals, Constants.WorkOrderType.Input),
      new Predicate('id', FilterQueryOp.Equals, Constants.WorkOrderType.Picking)
    );

    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("algorithm.name"),
        field: "name._translation",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("algorithm.isDefault"),
        field: "isDefault",
        type: FieldType.Boolean,
      },
      {
        headerName: this.i18n.tr("algorithm.type"),
        field: "type.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: typeService,
        }
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
}
