@import "../../../css/main.scss";

.borrowed-articles-report {
  height: calc(100% - #{$base-spacing});

  embed {
    height: calc(100% - #{$base-spacing} * .66);
    width: 100%;
  }
}
