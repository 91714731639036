import { computedFrom } from "aurelia-binding";
import { autoinject } from "aurelia-framework";
import { bindable, customElement, viewResources } from "aurelia-templating";
import { Zeus } from "generated";
import * as Constants from '../../../constants';
import { InventoryCycleCount } from "./inventory-cycle-count";
import { InventoryArticleActions } from "./inventory-article-actions";
import { InventoryStorageActions } from "./inventory-storage-actions";
import { InventoryTrayActions } from "./inventory-tray-actions";

@autoinject
@customElement('inventory-actions')
@viewResources(InventoryCycleCount, InventoryArticleActions, InventoryStorageActions, InventoryTrayActions)
export class InventoryActions {
  @bindable
  private isModifiable: boolean;
  @bindable
  private workOrder: Zeus.Web.Model.WorkOrder;
  @bindable
  private saveWorkOrder: () => Promise<boolean>;
  @bindable
  private onLoaded: () => void;

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetArticle(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.Article;
  }
  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetArticleLocations(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.ArticleLocations;
  }

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetStorage(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.Storage;
  }

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetTrayContainer(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.TrayContainer;
  }

  @computedFrom('workOrder.inventoryTargetId')
  private get isTargetCycleCount(): boolean {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.CycleCount;
  }
}
