import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { DialogService } from 'aurelia-dialog';
import { ImportXLS, ImportXlsParams, subscribeImportRefresh } from '../import-xls/import-xls';
import { CellRenderer } from 'utils/cell-renderer';
import { Endpoints } from 'endpoints';

@autoinject
export class SupplierList extends ListViewModelBase<Zeus.Web.Model.Supplier> {
  constructor(
    router: Router, logger: CustomLogger, private dialogService: DialogService, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.Supplier>(Constants.EntityTypeNames.Supplier));
    this.service.gridDataSource.expands = ['country'];
  }

  public ressourceName: string = Constants.EntityTypeNames.Supplier;

  async attached() {
    await super.attached();
    this.disposables.push(subscribeImportRefresh(this.gridOptions, Constants.DataFileImportType.Supplier));
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.Supplier) {
    if (entity && entity.id) {
      let url = '/suppliers/' + entity.id;
      return url;
    }
    return null;
  }

  public importSuppliers() {
    let params: ImportXlsParams = {
      type: Constants.EntityTypeNames.Supplier,
      title: 'supplier.importFile',
      patternUrl: Endpoints.ImportXlsx.GetSupplierXLSPattern,
      importUrl: Endpoints.ImportXlsx.ImportSupplierXLS,
      folderName: 'supplier_imports',
    };
    this.dialogService.open({ viewModel: ImportXLS, model: params }).whenClosed((result) => {
      if (!result.wasCancelled && result.output as boolean) {
        this.gridOptions.api.refreshInfiniteCache();
      }
    });
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("supplier.supplierRef"),
        field: "supplierRef",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("supplier.supplierName"),
        field: "supplierName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("supplier.phone"),
        field: "phone",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("supplier.email"),
        field: "email",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("supplier.completeAddress"),
        field: "completeAddress",
        valueFormatter: (data) => { return this.cellRenderer.supplierAddressRenderer(data.data); },
      },
      {
        headerName: this.i18n.tr("supplier.active"),
        field: "active",
        type: FieldType.Boolean
      }
    ];
    return defs;
  }
} 
