import { ServiceBase, UITreeNode } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../../generated";
import * as Constants from '../../constants';
import { DialogController } from 'aurelia-dialog';
import { Utils } from 'utils/utils';

@autoinject
export class ArticleFamilyTreeView {
  private entity: Zeus.Web.Model.Article

  private articleFamilies: Array<Zeus.Web.Model.ArticleFamily>;
  private articleFamiliesTree: UITreeNode[] = [];
  private selectedTreeValues: string[] = [];

  private articleFamilyService: ServiceBase<Zeus.Web.Model.ArticleFamily>;
  private articleArticleFamilyService: ServiceBase<Zeus.Web.Model.ArticleArticleFamily>;

  constructor(private dialogController: DialogController) {
    this.articleFamilyService = new ServiceBase<Zeus.Web.Model.ArticleFamily>(Constants.EntityTypeNames.ArticleFamily);
    this.articleArticleFamilyService = new ServiceBase<Zeus.Web.Model.ArticleArticleFamily>(Constants.EntityTypeNames.ArticleArticleFamily);
  }

  public async activate(entity: Zeus.Web.Model.Article) {
    this.entity = entity;
    await this.refreshTreeValues();
    this.refreshSelectedTreeValues();
  }

  public async refreshTreeValues() {
    this.articleFamilies = null;
    this.articleFamilies = await this.articleFamilyService.getEntities(null, null, null);
    this.articleFamiliesTree = [];
    this.articleFamilies.filter(x => x.articleFamilyParentId == null).forEach(articleFamily => {
      let children: Array<UITreeNode> = null;
      if (articleFamily.articleFamilyChildren != null && articleFamily.articleFamilyChildren.length > 0) {
        children = this.getChildren(articleFamily);
      }
      let articleFamilyTreeItem: UITreeNode = new UITreeNode({
        id: articleFamily.id.toString(),
        label: articleFamily.name._translation,
        expanded: true,
        leaf: (children == null),
        children: children
      });
      this.articleFamiliesTree.push(articleFamilyTreeItem);
    });
  }

  public refreshSelectedTreeValues(): void {
    if (this.entity.articleFamilies != null) {
      this.entity.articleFamilies.forEach(articleFamily => {
        this.selectedTreeValues.push(articleFamily.articleFamilyId.toString());
      });
    }
  }

  public getChildren(parentArticleFamily: Zeus.Web.Model.ArticleFamily): Array<UITreeNode> {
    let articleFamilies: Array<UITreeNode> = new Array<UITreeNode>();
    parentArticleFamily.articleFamilyChildren.forEach(articleFamily => {
      let children: Array<UITreeNode> = null;
      if (articleFamily.articleFamilyChildren != null && articleFamily.articleFamilyChildren.length > 0) {
        children = this.getChildren(articleFamily)
      }
      let articleFamilyTreeItem: UITreeNode = new UITreeNode({
        id: articleFamily.id.toString(),
        label: articleFamily.name._translation,
        expanded: true,
        leaf: (children == null),
        children: children
      });
      articleFamilies.push(articleFamilyTreeItem);
    });
    return articleFamilies;
  }

  public async ok() {
    if (this.selectedTreeValues != null) {
      let parsedSelectedTreeValues: Array<number> = this.selectedTreeValues.map(x => parseInt(x));
      for (let index = 0; index < parsedSelectedTreeValues.length; index++) {
        let articleFamilyId: number = parsedSelectedTreeValues[index];
        if (!this.entity.articleFamilies.some(x => x.articleFamilyId == articleFamilyId)) {
          let articleArticleFamily = await this.articleArticleFamilyService.createEntity();
          articleArticleFamily.articleId = this.entity.id;
          articleArticleFamily.articleFamilyId = articleFamilyId;
        }
      }
      let articleArticleFamiliesToDelete = this.entity.articleFamilies.filter(x => !parsedSelectedTreeValues.some(y => y == x.articleFamilyId));
      let toDeleteLength: number = articleArticleFamiliesToDelete.length;
      while (toDeleteLength > 0) {
        let articleArticleFamilyToDelete = articleArticleFamiliesToDelete[toDeleteLength - 1];
        Utils.detachOrDeleteEntity(articleArticleFamilyToDelete);
        toDeleteLength--;
      }
    }
    this.dialogController.ok();
  }
} 
