import { ZeusHubClient } from '../external-src/zeus-hub-client';
import { Router } from 'aurelia-router';
import { CamelCase, CustomLogger, ServiceBase, ViewModelBase } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { MoveTrayToBay } from 'commands/move-tray-to-bay/move-tray-to-bay';
import * as Constants from '../constants';
import * as ALConstants from '../app-modules/auto-launch/constants';
import { Zeus } from 'generated';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { Chart } from 'chart.js';
import { HttpClient } from 'aurelia-fetch-client';
import { Subscription } from 'aurelia-event-aggregator';
import { AppModuleService } from 'app-modules/app-module-service';
import { SiteManagementSelectionHelper } from 'external-src/site-management/site-management-select-view';
import { Endpoints } from 'endpoints';
import { Permissions as AutoLaunchPermisions } from 'app-modules/auto-launch/constants';
import { AppModuleEnum } from 'app-modules/constants';
import environment from 'environment';
import * as ReceptionConstants from "../reception/constants";

@autoinject
export class HomePage extends ViewModelBase {
  public ribbonHeaderText: string = this.i18n.tr("menu.homePage");
  public ressourceName: string = "";

  private subscription: Subscription;

  private workOrderService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  private dynamicStorageSentMessageService: ServiceBase<Zeus.Web.Model.DynamicStorageSentMessage>;

  private chartPanel: HTMLElement;
  private chartCanvas: HTMLCanvasElement;
  private chart: Chart;

  private sentMessages: Array<Zeus.Web.Model.DynamicStorageSentMessage>;

  private outstandingOrders: Array<{ priority: string, priorityNumber: number, output: number, input: number, pickingReturn: number, inventory: number }> = new Array();
  private activeLines: Array<{ site: string, output: number, input: number, pickingReturn: number, inventory: number, consult: number }> = new Array();
  private modulasBars: { [key: string]: number };

  private loadingOutstandingOrders: boolean = true;
  private nbCallsToBuildOutstandingOrders: number = 0;
  private loadingActiveLines: boolean = true;
  private nbCallsToBuildActiveLines: number = 0;
  private nbCallsToSetChart: number = 0;

  private userSite: string;
  private hasAutoLaunch: boolean = false;

  constructor(router: Router, logger: CustomLogger, private dialogService: DialogService, private httpClient: HttpClient, private bindingEngine: BindingEngine, private zeusHub: ZeusHubClient, private appModuleService: AppModuleService) {
    super(router, logger);
    this.workOrderService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.workOrderService.gridDataSource.expands = ['workOrderPriority'];
    this.dynamicStorageSentMessageService = new ServiceBase<Zeus.Web.Model.DynamicStorageSentMessage>(Constants.EntityTypeNames.DynamicStorageSentMessage);
    this.dynamicStorageSentMessageService.gridDataSource.expands = ['storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup', 'originalWorkOrder'];
  }

  public async activate(params) {
    await super.activate(params);
    this.initAutoLaunchPart();
  }

  public async attached() {
    this.chartPanel = document.getElementById('chart-panel');
    this.chartCanvas = document.getElementById('activeLinesChart') as HTMLCanvasElement;
    if (this.chartPanel && this.chartCanvas) {
      await this.buildData(); // Build tables & chart
      this.disposables.push(
        this.bindingEngine.propertyObserver(this.authService.currentUser.userData, "defaultSessionSiteManagementId").subscribe(async (newValue, oldValue) => {
          if (newValue != null && newValue != oldValue) {
            this.userSite = null;
            await this.setChart();
          }
        }),
        this.bindingEngine.propertyObserver(this.chartPanel, "offsetHeight").subscribe(this.chartPanelSizeChange.bind(this)),
        this.bindingEngine.propertyObserver(this.chartPanel, "offsetWidth").subscribe(this.chartPanelSizeChange.bind(this)),
        this.zeusHub.onRefreshHomePage(() => this.buildData())
      );
    }

    this.attachedAutoLaunch();
  }


  public chartPanelSizeChange(newValue: number, oldValue: number) {
    if (newValue != null && newValue != oldValue && Math.abs(newValue - oldValue) > 10) {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
      this.buildChart();
    }
  }

  public detached(): void {
    super.detached();
    this.subscription?.dispose();
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  private navigateToOrdersToProcess(woTypeId: number, subTypeId?: number) {
    this.router.navigate(`/work-orders-to-process/all?workOrderTypeId=${woTypeId}` + (subTypeId != null ? `&subTypeId=${subTypeId}` : ""));
  }

  private goToActionHistory() {
    this.router.navigate('/action-history/all');
  }

  private async consultTray() {
    await this.dialogService.open({ viewModel: MoveTrayToBay, lock: false });
  }

  @computedFrom('authService.currentUser.id')
  private get canCallTray(): boolean {
    return this.authService.checkAccess("tray", Constants.Permissions.Consult);
  }

  //#region tables & chart
  private async buildData() {
    // Build Outstanding orders (for each priority)

    await this.buildOutstandingOrders();

    // Build actives lines (for each site)

    await this.buildActiveLines();

    // Build actives lines chart (for each modula of the user site)

    await this.setChart();
  }

  private async buildOutstandingOrders() {
    let savedNbCalls = ++this.nbCallsToBuildOutstandingOrders;

    let workOrders = await this.workOrderService.getEntities(Predicate.or([
      Constants.WorkOrderStatus.ReadyToBeProcessed,
      Constants.WorkOrderStatus.PartiallyProcessed
    ].map(x => new Predicate("workOrderStatusId", FilterQueryOp.Equals, x))), this.workOrderService.gridDataSource.expands);

    if (this.nbCallsToBuildOutstandingOrders != savedNbCalls) {
      return;
    }

    this.outstandingOrders.splice(0);
    workOrders?.forEach(wo => {
      let foundOrder = this.outstandingOrders.find(ord => ord.priority == wo.workOrderPriority.name._translation);
      if (!foundOrder) {
        foundOrder = { priority: wo.workOrderPriority.name._translation, priorityNumber: wo.workOrderPriority.priority, output: 0, input: 0, pickingReturn: 0, inventory: 0 };
        this.outstandingOrders.push(foundOrder);
      }

      switch (wo.workOrderTypeId) {
        case Constants.WorkOrderType.Picking: foundOrder.output++;
          break;

        case Constants.WorkOrderType.Input: wo.orderSubtypeId == Constants.OrderSubtype.PickingReturn ? foundOrder.pickingReturn++ : foundOrder.input++;
          break;

        case Constants.WorkOrderType.Inventory: foundOrder.inventory++;
          break;
      }
    });
    this.outstandingOrders.sort((a, b) => b.priorityNumber - a.priorityNumber);
    this.loadingOutstandingOrders = false;
  }

  private async buildActiveLines() {
    let savedNbCalls = ++this.nbCallsToBuildActiveLines;

    this.sentMessages = await this.dynamicStorageSentMessageService.getEntities(
      Predicate.and([
        new Predicate("responseReceived", FilterQueryOp.Equals, false),
        new Predicate("batchProcess.executionStatusId", FilterQueryOp.Equals, 171),
        new Predicate("storageId", FilterQueryOp.NotEquals, null)
      ]),
      this.dynamicStorageSentMessageService.gridDataSource.expands);

    if (this.nbCallsToBuildActiveLines != savedNbCalls) {
      return;
    }

    let activeLinesMap = this.sentMessages?.reduce((activeLinesMap: any, sentMsg) => {
      this.addActiveLine(activeLinesMap, sentMsg.storage.storageGroup, sentMsg.messageTypeId, sentMsg.originalWorkOrder?.orderSubtypeId);
      return activeLinesMap;
    }, {});

    this.activeLines.splice(0);
    this.activeLines.push(...Object.values<{ site: string, output: number, input: number, pickingReturn: number, inventory: number, consult: number }>(activeLinesMap));
    this.activeLines.sort((a, b) => a.site.localeCompare(b.site));
    this.loadingActiveLines = false;
  }

  private async setChart() {
    let savedNbCalls = ++this.nbCallsToSetChart;

    let response: Response = await this.httpClient.fetch(Endpoints.StorageGroup.GetStorageGroupSite + '?storageGroupId=' + this.authService.currentUser.userData.defaultSessionSiteManagementId);
    if (response.ok && this.nbCallsToSetChart == savedNbCalls) {
      let result = await response.json();
      let userSiteId = parseInt(result[0]);
      this.userSite = result[1];

      let userSiteSentMessages = this.sentMessages?.filter(sentMsg => sentMsg.storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroupId == userSiteId);

      if (userSiteSentMessages != null) {
        if (this.chart) {
          this.chart.destroy();
          this.chart = null;
        }
        this.modulasBars = {};
        if (userSiteSentMessages.length > 0) {
          userSiteSentMessages.sort((a, b) => this.getModulaName(a).localeCompare(this.getModulaName(b)));
          userSiteSentMessages.forEach(sentMsg => {
            this.setModulaBar(sentMsg.storage.name);
          });

          this.buildChart();
        }
      }
    }
  }

  private buildChart() {
    if (this.modulasBars) {
      const data = {
        labels: Object.keys(this.modulasBars),
        datasets: [{
          label: this.userSite,
          backgroundColor: 'rgb(54, 180, 229)',
          borderColor: 'rgb(9, 135, 184)',
          borderWidth: 1,
          data: Object.values(this.modulasBars),
        }]
      };

      const config = {
        type: 'bar',
        data: data,
        options: {
          layout: {
            padding: {
              top: 20
            }
          },
          legend: {
            display: false
          },
          tooltips: {
            enabled: true
          },
          hover: {
            animationDuration: 1
          },
          animation: {
            duration: 800,
            easing: 'linear',
            onComplete: function () {
              let chartInstance = this.chart,
                ctx = chartInstance.ctx;
              ctx.textAlign = 'center';
              ctx.fillStyle = "rgba(0, 0, 0, 1)";
              ctx.textBaseline = 'bottom';

              // Loop through each data in the datasets

              this.data.datasets.forEach(function (dataset, i) {
                let meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                  let data = dataset.data[index];
                  ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
              });
            }
          },
          scales: {
            yAxes: [{
              display: false,
              gridLines: {
                display: false
              },
              ticks: {
                display: false,
                beginAtZero: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          }
        }
      };

      if (this.chart == null) {
        this.chart = new Chart(
          this.chartCanvas,
          config
        );
      }
      else {
        this.chart.config.data = data;
        this.chart.update();
      }
    }
  }

  private getModulaName(entity: Zeus.Web.Model.DynamicStorageSentMessage): string {
    return entity.storage.name;
  }

  private addActiveLine(activeLinesMap: any, sg: Zeus.Web.Model.StorageGroup, msgTypeId: number, orderSubtypeId?: number) {
    if (sg) {
      while (sg.storageGroupTypeId != Constants.StorageGroupTypes.Site) {
        sg = sg.parentStorageGroup;
      }

      let foundLine = activeLinesMap[sg.id] = activeLinesMap[sg.id] ?? { site: sg.name, output: 0, input: 0, pickingReturn: 0, inventory: 0, consult: 0 };

      switch (msgTypeId) {
        case Constants.MessageType.MoveTrayForPicking: foundLine.output++;
          break;

        case Constants.MessageType.MoveTrayForInput: orderSubtypeId == Constants.OrderSubtype.PickingReturn ? foundLine.pickingReturn++ : foundLine.input++;
          break;

        case Constants.MessageType.MoveTrayForInventory: foundLine.inventory++;
          break;

        case Constants.MessageType.MoveTrayToBay: foundLine.consult++;
          break;
      }
    }
  }

  private setModulaBar(modula: string) {
    if (modula) {
      this.modulasBars[modula] = (this.modulasBars[modula] ?? 0) + 1;
    }
  }
  //#endregion

  //#region appModules - Reception
  @computedFrom('authService.currentUser.id')
  private get isReceptionActive() {
    // TODO input cart permission
    return this.authService.checkAccess(ReceptionConstants.Permissions.Reception, Constants.Permissions.Access);
  }

  private goToReceptionMobileScreen() {
    window.open(environment.remoteApiUrl + "/mobile/reception/filter?fromWeb=true", "_blank");
  }
  //#endregion

  //#region appModules - Auto launch
  private canUpdateAutoLaunch: boolean = false;
  private storageGroups: Array<Zeus.Web.Model.StorageGroup> = [];
  private async initAutoLaunchPart() {
    this.hasAutoLaunch = this.appModuleService.isActive(AppModuleEnum.AutoLaunch);
    if (this.hasAutoLaunch) {
      this.canUpdateAutoLaunch = this.authService.checkAccess('autolaunch', AutoLaunchPermisions.ActivateAutoLaunch);
      let response = await this.httpClient.fetch(Endpoints.StorageGroup.GetWarehousesFromSG + "?storageGroupId=" + SiteManagementSelectionHelper.selectedSite.id);
      if (response.ok) {
        this.storageGroups.push(...JSON.parse(await response.text(), (key: string, value: any) => { return CamelCase.convertKeysToCamelCase(key, value) }));
      }
    }
  }

  private attachedAutoLaunch() {
    if (this.canUpdateAutoLaunch) {
      this.storageGroups.forEach(x => {
        this.disposables.push(
          this.bindingEngine.propertyObserver(x, "activateAutoLaunch").subscribe(async () => {
            await this.setActivateAutoLaunchToSG(x);
          }),
          this.bindingEngine.propertyObserver(x, "includePartiallyProcessedOrders").subscribe(async () => {
            await this.setIncludePartiallyProcessedOrdersToSG(x);
          })
        );
      });
    }
  }

  private isBeeingSetActivateAutoLaunchToSG: boolean = false;
  private async setActivateAutoLaunchToSG(storageGroup: Zeus.Web.Model.StorageGroup) {
    if (!(await this.sentMessageToUpdateSG(ALConstants.Application.SetActivateAutoLaunchToSG, { storageGroupId: storageGroup.id, activateAutoLaunch: storageGroup.activateAutoLaunch }))) {
      storageGroup.activateAutoLaunch = !storageGroup.activateAutoLaunch;
    }
  }
  private async setIncludePartiallyProcessedOrdersToSG(storageGroup: Zeus.Web.Model.StorageGroup) {
    if (!(await this.sentMessageToUpdateSG(ALConstants.Application.SetIncludePartiallyProcessedOrdersToSG, { storageGroupId: storageGroup.id, includePartiallyProcessedOrders: storageGroup.includePartiallyProcessedOrders }))) {
      storageGroup.includePartiallyProcessedOrders = !storageGroup.includePartiallyProcessedOrders;
    }
  }

  private async sentMessageToUpdateSG(url: string, body: any) {
    if (!this.isBeeingSetActivateAutoLaunchToSG) {
      this.isBeeingSetActivateAutoLaunchToSG = true;
      let response = await this.httpClient.post(
        url,
        JSON.stringify(body)
      );
      if (response.ok) {
        this.log("general.successfullySaved", null, true, { ns: "common" });
      } else {
        this.log(await response.text(), null, true);

      }
      this.isBeeingSetActivateAutoLaunchToSG = false;
      return response.ok;
    }
    return true;
  }
  //#endregion
}
