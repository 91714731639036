import { DialogController, DialogService } from 'aurelia-dialog';
import { autoinject, computedFrom, Container } from "aurelia-framework";
import { FilterQueryOp, Predicate } from 'breeze-client';
import { AuthService, ServiceBase, UIInternal, UITreePanelConfig } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../../constants';
import { StorageUtils } from '../utils/utils-storages';
import { HttpClient } from 'aurelia-fetch-client';

@autoinject
export class SiteManagementSelectView {

  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  private treeConfig: UITreePanelConfig<Zeus.Web.Model.StorageGroup>;
  private selectedStorageGroups: number[] = [];
  private storageGroupListForTree: Zeus.Web.Model.StorageGroup[] = [];
  private siteManagementList: number[] = [];

  private user: Zeus.Web.Model.ZeusUser;

  constructor(private controller: DialogController, private authService: AuthService) {
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);

    this.treeConfig = new UITreePanelConfig<Zeus.Web.Model.StorageGroup>({
      parentId: 'parentStorageGroupId',
      label: 'name',
      withLines: true,
      autoExpand: true,
      isNodeSelectable: storageGroup => (<any>storageGroup)._selectable
    });
  }

  @computedFrom('storageGroupListForTree.length', 'selectedStorageGroups.length')
  get okDisabled(): boolean {
    return this.storageGroupListForTree.length > 0 && this.selectedStorageGroups.length != 1;
  }

  isStorageGroupInManagement(storageGroup: Zeus.Web.Model.StorageGroup): boolean {
    return (this.hasFreeSiteSelection && this.siteManagementList.length == 0)
      || this.siteManagementList.find(id => id == storageGroup.id) != null;
  }

  @computedFrom('user.roles.length')
  get hasFreeSiteSelection(): boolean {
    return this.user.roles?.some(userRole => userRole.role?.permissions?.some(rolePermission => rolePermission.permissionId == 'free-site-selection:storage-group'));
  }

  async activate() {
    let vm = this;
    this.siteManagementList.splice(0);
    this.storageGroupListForTree.splice(0);
    let user = this.user = await SiteManagementSelectionHelper.getUser();
    this.siteManagementList.push(...user.userSiteManagements.map(usm => usm.storageGroupId));
    this.storageGroupListForTree.push(... (await this.storageGroupService.getEntities(null, null, { forCurrentSiteSelection: true })).map(sg => {
      // Set selectable prop
      Object.defineProperty(sg, '_selectable', {
        configurable: true,
        get() {
          return vm.isStorageGroupInManagement(this) || (this.parentStorageGroup != null && this.parentStorageGroup._selectable);
        }
      });
      return sg;
    }));

    if (user.defaultSessionSiteManagementId && this.storageGroupListForTree.find(sg => sg.id == user.defaultSessionSiteManagementId)) {
      this.selectedStorageGroups.push(user.defaultSessionSiteManagementId);
    }
  }

  async ok() {
    if (this.okDisabled) {
      return;
    }
    this.controller.ok(this.selectedStorageGroups.length > 0 ? this.selectedStorageGroups[0] : null);
  }
}

export class SiteManagementSelectionHelper {

  private static _instance: SiteManagementSelectionHelper;
  private static get instance(): SiteManagementSelectionHelper {
    if (SiteManagementSelectionHelper._instance == null) {
      SiteManagementSelectionHelper._instance = new SiteManagementSelectionHelper();
    }
    return SiteManagementSelectionHelper._instance;
  }

  public static get selectedSite(): Zeus.Web.Model.StorageGroup {
    return SiteManagementSelectionHelper.instance.selectedSite;
  }
  public static get isSingleZone(): boolean {
    return SiteManagementSelectionHelper.instance.isSingleZone;
  }
  public static get isMultiZone(): boolean {
    return false == SiteManagementSelectionHelper.isSingleZone;
  }
  public static async openSelectSite(forceOpenSelect = false, fromHub = false): Promise<void> {
    return SiteManagementSelectionHelper.instance.openSelectSite(forceOpenSelect, fromHub);
  }
  public static async getUser() {
    return SiteManagementSelectionHelper.instance.getUser();
  }
  public static onStorageGroupsChanged(storageGroup: Zeus.Web.Model.StorageGroup) {
    SiteManagementSelectionHelper.instance.onStorageGroupChanged(storageGroup);
  }

  private authService: AuthService;
  private dialogService: DialogService;
  private httpClient: HttpClient;
  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  private userService: ServiceBase<Zeus.Web.Model.ZeusUser>;

  private user: Zeus.Web.Model.ZeusUser;

  private accessibleZonesCount: number;
  private singleZonePredicate: Predicate;
  private singleZoneParams: any = { forCurrentSiteSelection: true };

  @computedFrom('accessibleZonesCount')
  public get isSingleZone(): boolean {
    return this.accessibleZonesCount === 1;
  }

  private constructor() {
    this.authService = Container.instance.get(AuthService);
    this.dialogService = Container.instance.get(DialogService);
    this.httpClient = Container.instance.get(HttpClient);
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.userService = new ServiceBase<Zeus.Web.Model.ZeusUser>(Constants.EntityTypeNames.ZeusUser);

    this.singleZonePredicate = new Predicate('storageGroupTypeId', FilterQueryOp.Equals, Constants.StorageGroupTypes.Zone);
    this.init();
  }

  private async init() {
    this.accessibleZonesCount = await this.storageGroupService.getCount(this.singleZonePredicate, this.singleZoneParams);
  }

  public async onStorageGroupChanged(storageGroup: Zeus.Web.Model.StorageGroup) {
    if (storageGroup.storageGroupTypeId == Constants.StorageGroupTypes.Zone) {
      this.accessibleZonesCount = null
      await this.init();
    }
    if (storageGroup.id == this.selectedSite?.id) {
      await this.openSelectSite();
    }
  }

  private get selectedSite(): Zeus.Web.Model.StorageGroup {
    if (this.authService?.currentUser != null) {
      return (this.authService?.currentUser.userData as Zeus.Web.Model.ZeusUser).defaultSessionSiteManagement;
    }
    return null;
  }

  private async openSelectSite(forceOpenSelect = false, fromHub = false): Promise<void> {
    await this.init();
    if (this.accessibleZonesCount <= 1) {
      let storageGroupId = (await this.storageGroupService.firstEntity(this.singleZonePredicate, null, this.singleZoneParams))?.id;
      await this.selectSite(storageGroupId, false);
      return;
    }
    if (!forceOpenSelect) {
      let user = await this.getUser();
      if (user.defaultSessionSiteManagementId != null && await StorageUtils.isStorageGroupAllowed(user.id, user.defaultSessionSiteManagementId)) {
        let storageGroupId = user.defaultSessionSiteManagementId;
        await this.selectSite(storageGroupId, fromHub);
        return;
      }
    }
    return this.dialogService.open({ viewModel: SiteManagementSelectView, lock: true })
      .whenClosed(async (result) => {
        if (false == result.wasCancelled) {
          await this.selectSite(result.output, false);
        }
        return Promise.resolve();
      });
  }

  private async selectSite(storageGroupId: number | null, fromHub: boolean) {
    let user = await this.getUser();

    // Ensure storage group entity is loaded in cache
    let selected = await this.storageGroupService.getEntityByIdFromLocalCache(storageGroupId) || await this.storageGroupService.getEntityById(storageGroupId);

    // Do nothing when not changing
    if (user.defaultSessionSiteManagementId !== storageGroupId) {
      if (storageGroupId != null) {
        user.defaultSessionSiteManagementId = selected.id;
      } else {
        user.defaultSessionSiteManagementId = null;
      }
      if (false == fromHub) {
        await this.httpClient.post('/api/zeusUser/setSelectedSite', storageGroupId);
        user.entityAspect.setUnchanged();
        await this.authService?.getProfile();
      }
    }
    // Always broadcast: if fetched user due to call to selectSite(), the user default site will never be different, but UI must change
    UIInternal.broadcast(Constants.Various.SessionSelectedSiteManagementEvent);
  }

  private async getUser() {
    this.user = await this.userService.getEntityById(this.authService.currentUser.id, 'userSiteManagements', 'roles.role.permissions');
    return this.user;
  }
}
