import { Zeus } from 'generated';
import { bindable, bindingMode } from "aurelia-framework";

export class CountingStrategyInput {
  @bindable({ defaultBindingMode: bindingMode.twoWay })
  private value: Zeus.Web.Model.CountingStrategy;
  @bindable({ defaultBindingMode: bindingMode.toView })
  private parent: Zeus.Web.Model.StorageGroup;
  @bindable private disabled: boolean = false;

  private properties = [
    'needCountingWhenStockBelowZero',
    'needCountingWhenStockBelowMinimum',
    'needCountingWhenPickedQuantityIsDifferentThanRequested',
    'showExpectedQuantity'
  ];

  private getParentValue(property: string, parent: Zeus.Web.Model.StorageGroup) {
    if (parent == null) {
      return false;
    }
    let parentCountingStrategy = parent.countingStrategy;
    if (parentCountingStrategy != null) {
      let parentValue: boolean | null = parentCountingStrategy[property];
      if (parentValue != null) {
        return parentValue;
      }
    }
    return this.getParentValue(property, parent.parentStorageGroup);
  }
}
