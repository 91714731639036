import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Various, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as Constants from '../../../constants';
import { CellRenderer } from '../../../utils/cell-renderer';
import * as LPConstants from '../constants';
import { LabelPrinting } from '../generated';

@autoinject
export class LabelTemplateList extends ListViewModelBase<LabelPrinting.Model.LabelTemplate> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<LabelPrinting.Model.LabelTemplate>(LPConstants.EntityTypeNames.LabelTemplate));
    this.service.gridDataSource.expands = ['labelType', 'labelBarcode', 'templateFile', 'labelPrinter'];
  }

  public ressourceName: string = LPConstants.EntityTypeNames.LabelTemplate;

  @computedFrom('canDelete', 'selectedEntities.length')
  public get showDelete(): boolean {
    return this.canDelete && this.selectedEntities.length > 0;
  }

  public getDetailsUrl(self, entity: LabelPrinting.Model.LabelTemplate) {
    if (entity && entity.id) {
      let url = '/label-templates/' + entity.id;
      return url;
    }
    return null;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("labeltemplate.name"),
        field: "name._translation",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("labeltemplate.languageCode"),
        field: "languageCode",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("labeltemplate.labelTypeId"),
        field: "labelType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: LPConstants.EnumerationTypes.LabelType
        }
      },
      {
        headerName: this.i18n.tr("labeltemplate.labelBarcodeId"),
        field: "labelBarcode.denomination._translation",
        type: FieldType.Enumeration,
        filter: null,
        sortable: false
        // --- Regarder pour envoyer deux catégories au composant DWF pour qu'il puisse afficher les deux listes de valeurs EnumType ---
        // floatingFilterComponentParams: {
        //   suppressFilterButton: true,
        //   category: Constants.EnumerationTypes.LabelBarcodeArticle || Constants.EnumerationTypes.LabelBarcodeLocation
        // }
      },
      {
        headerName: this.i18n.tr("labeltemplate.labelPrinterId"),
        field: "labelPrinter.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: LPConstants.EnumerationTypes.LabelPrinter
        }
      },
      {
        headerName: this.i18n.tr("labeltemplate.activeTemplate"),
        field: "activeTemplate",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("labeltemplate.defaultTemplate"),
        field: "defaultTemplate",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("labeltemplate.zeusTemplate"),
        field: "zeusTemplate",
        type: FieldType.Boolean
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
}
