import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import * as Constants from '../../../constants';
import * as DTLConstants from '../../drop-to-light/constants';
import * as LPConstants from '../constants';
import { LabelPrinting } from '../generated';
import { DropToLight } from '../../drop-to-light/generated';
import { Zeus } from '../../../generated';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
export class LabelPrintConfigurationDetail extends EntityDetailViewModelBase<LabelPrinting.Model.LabelPrintConfiguration> {
  public documentTitle: string = "labelprintconfiguration.labelprintconfigurations";
  public ressourceName: string = LPConstants.EntityTypeNames.LabelTemplate;
  public LPConstants = LPConstants;

  private labelPrintConfigurationService: ServiceBase<LabelPrinting.Model.LabelPrintConfiguration>;
  private labelTemplateOrderService: ServiceBase<LabelPrinting.Model.LabelTemplate>;
  private labelTemplatePickService: ServiceBase<LabelPrinting.Model.LabelTemplate>;
  private workstationService: ServiceBase<DropToLight.Model.Workstation>;
  private bayService: ServiceBase<Zeus.Web.Model.Bay>;
  private applicationParameterService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;
  private applicationParameter: Zeus.Web.Model.ApplicationParameters;

  private hasDTLModule: boolean = false;
  private labelPrintConfigOrders: Array<LabelPrinting.Model.LabelPrintConfiguration> = [];
  private labelPrintConfigPicks: Array<LabelPrinting.Model.LabelPrintConfiguration> = [];

  private collapsedOrders: boolean = false;
  private collapsedPicks: boolean = false;

  constructor(router: Router, logger: CustomLogger, private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<LabelPrinting.Model.LabelPrintConfiguration>(LPConstants.EntityTypeNames.LabelPrintConfiguration));
    this.labelPrintConfigurationService = new ServiceBase<LabelPrinting.Model.LabelPrintConfiguration>(LPConstants.EntityTypeNames.LabelPrintConfiguration);
    this.labelTemplateOrderService = new ServiceBase<LabelPrinting.Model.LabelTemplate>(LPConstants.EntityTypeNames.LabelTemplate);
    this.labelTemplatePickService = new ServiceBase<LabelPrinting.Model.LabelTemplate>(LPConstants.EntityTypeNames.LabelTemplate);
    this.workstationService = new ServiceBase<DropToLight.Model.Workstation>(DTLConstants.EntityTypeNames.Workstation);
    this.bayService = new ServiceBase<Zeus.Web.Model.Bay>(Constants.EntityTypeNames.Bay);
    this.bayService.gridDataSource.expands = ['storage'];
    this.applicationParameterService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);
    this.hasDTLModule = this.appModuleService.isActive(AppModuleEnum.DropToLight)
  }

  public async activate(params) {
    this.applicationParameter = await this.applicationParameterService.firstEntity(null, ["pickLabelConcept"]);
    if (this.hasDTLModule) {
      this.labelPrintConfigOrders.push(...await this.labelPrintConfigurationService.getEntities(new Predicate("workstationId", FilterQueryOp.NotEquals, null), ['workstation', 'labelTemplate', 'printer']))
    }
    this.labelPrintConfigPicks.push(...await this.labelPrintConfigurationService.getEntities(new Predicate("bayId", FilterQueryOp.NotEquals, null), ['bay.storage', 'labelTemplate', 'printer']))

    this.workstationService.gridDataSource.customSelect2Predicates = () => {
      return Predicate.and(this.labelPrintConfigOrders.filter(x => x.workstationId != null).map(x => new Predicate("id", FilterQueryOp.NotEquals, x.workstationId)));
    }
    this.bayService.gridDataSource.customSelect2Predicates = () => {
      return Predicate.and(this.labelPrintConfigPicks.filter(x => x.bayId != null).map(x => new Predicate("id", FilterQueryOp.NotEquals, x.bayId)));
    }
    let activeTemplate: Predicate = new Predicate("activeTemplate", FilterQueryOp.Equals, true);
    this.labelTemplateOrderService.gridDataSource.customSelect2Predicates = () => {
      return new Predicate("labelTypeId", FilterQueryOp.Equals, LPConstants.LabelTypeId.Order).and(activeTemplate);
    }
    this.labelTemplatePickService.gridDataSource.customSelect2Predicates = () => {
      return new Predicate("labelTypeId", FilterQueryOp.Equals, LPConstants.LabelTypeId.Pick).and(activeTemplate);
    }
  }

  public async addConfiguration(configType: number) {
    let newConfig = await this.labelPrintConfigurationService.createEntity({ active: true });
    if (configType == LPConstants.LabelTypeId.Order) {
      this.labelPrintConfigOrders.push(newConfig);
    } else if (configType == LPConstants.LabelTypeId.Pick) {
      this.labelPrintConfigPicks.push(newConfig);
    }
  }

  public async removeConfiguration(configuration: LabelPrinting.Model.LabelPrintConfiguration) {
    if (await this.labelPrintConfigurationService.deleteEntities([configuration], true)) {
      //clear all validations for the delete configuration
      //first part of filter is for all binding from html
      //second part of filter is for all binding from an element in html (ui-enumeration-select)
      Array.from(((this.controller as any).bindings as Map<any, any>).keys())
        .filter(b => b.source.overrideContext.bindingContext.id == configuration.id || b.source.overrideContext.bindingContext.entity?.id == configuration.id)
        .forEach(x => {
          this.controller.unregisterBinding(x);
        });
      this.labelPrintConfigPicks.remove(configuration);
      this.labelPrintConfigOrders.remove(configuration);
    }
  }

  public async innerCancel(allowNavigateBack?: boolean, silentMode?: boolean, onlyCurrentEntity?: boolean) {
    let result = await super.innerCancel(allowNavigateBack, silentMode, onlyCurrentEntity);
    if (result) {
      this.labelPrintConfigOrders.filter(x => x.id < 0).forEach(x => this.labelPrintConfigOrders.remove(x));
      this.labelPrintConfigPicks.filter(x => x.id < 0).forEach(x => this.labelPrintConfigPicks.remove(x));
      ((this.controller as any).bindings as Map<any, any>).clear(); //clear all validations on detached entities
    }
  }
}
