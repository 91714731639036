import { I18N } from 'aurelia-i18n';
import { DialogController } from 'aurelia-dialog';
import { autoinject, computedFrom } from "aurelia-framework";
import * as toastr from 'toastr';

@autoinject
export class PasswordResetModal {

  public password: string;
  public passwordConfirm: string;

  constructor(private dialogController: DialogController, private i18n: I18N) {
  }

  @computedFrom('password', 'passwordConfirm')
  private get canReset(): boolean {
    return this.password?.trim().length > 0 && this.passwordConfirm?.trim().length > 0;
  }

  private async doReset() {
    if (false == this.canReset) {
      return;
    }
    if (this.password.trim() != this.passwordConfirm.trim()) {
      toastr.error(this.i18n.tr('user.confirmPasswordError'));
      return;
    }
    this.dialogController.close(true, this.password);
  }

  private confirmAttached(element: HTMLElement) {
    element.addEventListener('paste', e => e.preventDefault());
  }
}
