import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, EnumerationTypeService, changeRoutesNav, UIInternal, DialogBoxViewModel } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { Zeus } from 'generated';
import { UserUtils } from '../utils/utils-users';

@autoinject
export class ApplicationParameterDetail extends EntityDetailViewModelBase<Zeus.Web.Model.ApplicationParameters> {
  public ressourceName: string = Constants.EntityTypeNames.ApplicationParameters;
  public headerText: string = this.i18n.tr("applicationparameters.applicationparameters");
  public isHelpDesk: boolean = false;

  public unitOfMeasureService: EnumerationTypeService;
  public storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  public languagePriorityService: ServiceBase<Zeus.Web.Model.LanguagePriority>;
  private ERPReturnCommunicationTypeService: EnumerationTypeService;
  public languageService: EnumerationTypeService;
  public storageStrategyService: EnumerationTypeService;

  private languagePriorities: Array<Zeus.Web.Model.LanguagePriority> = [];

  private EnumerationTypes = Constants.EnumerationTypes;

  private uiPanelLangPrio: HTMLElement;
  private uiPanelLocation: HTMLElement;
  private uiPanelPushAPi: any;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters));
    this.unitOfMeasureService = new EnumerationTypeService(Constants.EnumerationTypes.UnitOfMeasure);
    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ["volumeType"];
    this.languagePriorityService = new ServiceBase<Zeus.Web.Model.LanguagePriority>(Constants.EntityTypeNames.LanguagePriority);
    this.ERPReturnCommunicationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ERPReturnCommunicationType);

    this.languageService = new EnumerationTypeService(Constants.EnumerationTypes.Language);

    this.storageStrategyService = new EnumerationTypeService(Constants.EnumerationTypes.StorageStrategy);
    this.isHelpDesk = UserUtils.isUserHelpDesk(this.authService.currentUser.userData);
  }

  public get documentTitle() {
    return this.i18n.tr("applicationparameters.applicationparameters");
  }

  public async activate() {
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.firstEntity(null, [
      'defaultArticleUOM', 'defaultArticleStorageVolume.volumeType', 'defaultStorageVolume.volumeType',
      'defaultArticleHandling', 'inputERPReturnCommunicationType', 'pickingERPReturnCommunicationType',
      'alternateLocationOnShortPick']
    );
    this.controller.addObject(this.entity);

    this.languagePriorities = await this.languagePriorityService.getAllEntities('language');
  }

  public async attached() {
    this.observableWorkOrder(this.entity);
  }

  private observableWorkOrder(applicationParameters: Zeus.Web.Model.ApplicationParameters) {
    this.disposables.push(
      this.bindingEngine.propertyObserver(applicationParameters, "useProject").subscribe((newValue, oldValue) => {
        if (!newValue) {
          this.entity.isProjectMandatory = false;
        }
      }),
      this.bindingEngine.propertyObserver(applicationParameters, "useCostCenter").subscribe((newValue, oldValue) => {
        if (!newValue) {
          this.entity.isCostCenterMandatory = false;
        }
      })
    );
  }

  private movePart(index: number, delta: number) {
    let newIndex = Math.max(0, Math.min(this.languagePriorities.length - 1, index + delta));
    if (newIndex == index) {
      return;
    }
    let part = this.languagePriorities.splice(index, 1);
    this.languagePriorities.splice(newIndex < index ? newIndex : newIndex, 0, ...part);
    this.resetPartsOrder();
  }

  private resetPartsOrder() {
    this.languagePriorities.forEach((part, index) => part.priority = index + 1);
  }

  private orderChanged(record: Zeus.Web.Model.LanguagePriority, oldIndex: number, newIndex: number) {
    // for (let iLangPrio = oldIndex; iLangPrio < newIndex; iLangPrio++) {
    //   this.languagePriorities[iLangPrio].priority = iLangPrio + (oldIndex < newIndex ? -1 : 1);
    // }
    // record.priority = newIndex + (oldIndex < newIndex ? -1 : 1);

    this.movePart(record.priority - 1, newIndex - oldIndex);
  }

  public override async beforeSave() {
    let originalLeadingZeros = (this.entity?.entityAspect?.originalValues as Zeus.Web.Model.ApplicationParameters)?.leadingZeros;
    if (originalLeadingZeros != null) {
      return await this.box.showQuestion(this.i18n.tr('applicationparameters.leadingZerosChanged.message'),
        this.i18n.tr('applicationparameters.leadingZerosChanged.title'),
        [
          { label: this.i18n.tr('general.no'), theme: 'primary', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
          { label: this.i18n.tr('general.yes'), theme: 'warning', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() }
        ]
      ).whenClosed(result => !result.wasCancelled);
    }

    // When all the checks have been done
    // We must do this here instead of afterSave because originalValues are already modified in afterSave
    // Otherwise we could save originalValues here and use them in afterSave
    let oldDelayBeforeLogout = (this.entity?.entityAspect?.originalValues as Zeus.Web.Model.ApplicationParameters)?.delayBeforeLogout;
    if (this.entity.delayBeforeLogout != oldDelayBeforeLogout) {
      UIInternal.broadcast(Constants.Various.DelayBeforeLogoutChangedEvent, { oldValue: oldDelayBeforeLogout, newValue: this.entity.delayBeforeLogout });
    }

    return true
  }

  public async afterSave() {
    changeRoutesNav(this.router, [
      { name: 'projects', nav: this.entity.useProject },
      { name: 'cost-centers', nav: this.entity.useCostCenter }
    ]);
  }
}
