.borderless {
  border: none;
}
.padding-0 {
  padding: 0 !important;
}

div.picker button.btn-flat {
  padding: 0;
}

.input-control input:disabled,
.input-control textarea:disabled,
.input-control select:disabled {
  background-color: white;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -ms-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

  .input-control input:disabled:focus,
  .input-control textarea:focus:disabled,
  .input-control select:focus:disabled,
  .input-control input:hover:disabled,
  .input-control textarea:hover:disabled,
  .input-control select:hover:disabled {
      background-color: #dcdada;
      -webkit-transition: background-color 0.2s;
      -moz-transition: background-color 0.2s;
      -ms-transition: background-color 0.2s;
      -o-transition: background-color 0.2s;
      transition: background-color 0.2s;
  }

md-input.uppercase input {
  text-transform: uppercase;
}
md-input.lowercase input {
  text-transform: lowercase;
}
md-input textarea.materialize-textarea {
  overflow-y: auto;
}

md-input.borderless textarea,
md-input.borderless textarea:focus {
  border: none;
  border-bottom: none !important;
  box-shadow:none !important;
}

section {
  background-color: white;  
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
  border: none;
  transition: all 0.4s ease-in-out 0s;  
  position: relative;
  padding: 20px;
  margin-top: 20px;

}
section:last-child {
  margin-bottom: 20px;
}


froala-editor.borderless .fr-toolbar {
  border-top: none;
}
.btn-floating.white > .material-icons {
  color: black !important;
}

.btn-floating.transparent:hover,
.btn-floating.white:hover {
box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),
0 3px 1px -2px rgba(0,0,0,0.12),
0 1px 5px 0 rgba(0,0,0,0.2) !important;
}
.btn-floating i {
color: inherit !important;
}

.card-image {
  >div.ui-flex {
    position: absolute;
    bottom: -20px;
    right: 0;
    left: 0;
  }
}
