.traypicks-to-process-list {
  ui-toolbar {
    background: none !important;
    display: flex;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 18px;
  }
}
.wo-traypick-error {
  background-color: lightcoral;
}
