export enum EnumerationTypes {
}

export class EntityTypeNames {
  public static AutoLaunchParameter = "AutoLaunchParameter";
  public static AutoLaunch = "AutoLaunch";

  public static ResourceNames = {
    AutoLaunchParameter: "AutoLaunchParameters"
  };
}

export class Permissions {
  public static ActivateAutoLaunch = 'activate';
}

export class Application {
  public static SetActivateAutoLaunchToSG = "/api/AutoLaunch/Utils/SetActivateAutoLaunchToSG";
  public static SetIncludePartiallyProcessedOrdersToSG = "/api/AutoLaunch/Utils/SetIncludePartiallyProcessedOrdersToSG";
}
