import * as Constants from './../../../constants';
import * as CSConstants from '../constants';
import * as DTLConstants from "../../drop-to-light/constants";
import { Zeus } from 'generated';
import { CheckScanning } from '../generated';
import { BindingEngine, Container, Disposable, bindable, computedFrom } from "aurelia-framework";
import { EnumerationTypeService, ServiceBase } from 'digiwall-lib';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { DropToLight } from 'app-modules/drop-to-light/generated';
import * as toastr from 'toastr';
import { I18N } from 'aurelia-i18n';

const woTypeList = [Constants.WorkOrderType.Input, Constants.WorkOrderType.Picking, Constants.WorkOrderType.Inventory];

export class CheckScanningConfiguration {
  @bindable
  private storageGroup: Zeus.Web.Model.StorageGroup;
  @bindable
  private isValid: boolean;

  private configurations: CheckScanning.Model.CheckScanningConfiguration[];
  private methodService: EnumerationTypeService;

  private workstationService: ServiceBase<DropToLight.Model.Workstation>;
  private i18n: I18N;
  private bindingEngine: BindingEngine;
  private disposables: Array<Disposable> = new Array();

  @computedFrom('storageGroup.storageGroupTypeId')
  private get isZone(): boolean {
    return this.storageGroup?.storageGroupTypeId == Constants.StorageGroupTypes.Zone;
  }

  constructor() {
    this.methodService = new EnumerationTypeService(CSConstants.EnumerationTypes.CheckScanningMethod);
  }

  private async attached() {
    if (false == this.isZone) {
      return;
    }

    // Get the existing configuration
    let service = new ServiceBase<CheckScanning.Model.CheckScanningConfiguration>(CSConstants.EntityTypeNames.CheckScanningConfiguration);
    this.configurations = await service.getEntities(new Predicate('storageGroupId', FilterQueryOp.Equals, this.storageGroup.id), ['workOrderType', 'method']);

    if (this.configurations.length == 0) {
      // No configuration exists, make one per type
      let workOrderTypeService = new EnumerationTypeService(Constants.EnumerationTypes.WorkOrderType);
      let woTypes = await workOrderTypeService.getEntities(
        Predicate.or(...woTypeList.map(x =>
          new Predicate('id', FilterQueryOp.Equals, x)
        ))
      );
      let noScan = await (this.methodService.getDefault())
      this.configurations.push(...woTypes.map(woType =>
        service.createEntity({
          storageGroup: this.storageGroup,
          workOrderType: woType,
          method: noScan
        }, true)
      ));
      // Save the default configurations
      await service.saveEntities(this.configurations, true);
    }
    this.configurations.sort((a, b) => woTypeList.indexOf(a.workOrderTypeId) - woTypeList.indexOf(b.workOrderTypeId));

    this.bindingEngine ??= Container.instance.get(BindingEngine);
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.configurations.find(c => c.workOrderTypeId == Constants.WorkOrderType.Picking), "methodId").subscribe(async (newVal, oldVal) => {
        if (newVal != null && newVal != oldVal) {
          await this.canUseCheckScanForPickingMethod(newVal);
        }
      })
    );
  }

  private async detached() {
    this.disposables.forEach(d => d.dispose());
  }

  private async canUseCheckScanForPickingMethod(pickingMethodId: number) {
    this.isValid = true;
    if (pickingMethodId != CSConstants.CheckScanningMethod.NoScan) {
      this.workstationService ??= new ServiceBase<DropToLight.Model.Workstation>(DTLConstants.EntityTypeNames.Workstation);
      // When the workstation is configured under a zone for which the "check scanning" configuration has any “Picking” value other than "No Scan" ("Pick" checkscan <> "No Scan")
      const predicate: Predicate = Predicate.and(
        new Predicate("zoneId", FilterQueryOp.Equals, this.storageGroup.id),
        new Predicate("withDTL", FilterQueryOp.Equals, true),
        new Predicate("isMobileWorkstation", FilterQueryOp.Equals, false)
      );
      if ((await this.workstationService.getCount(predicate.and(new Predicate("releasePickingOnDTL", FilterQueryOp.Equals, true)))) > 0) {
        this.i18n ??= Container.instance.get(I18N);
        toastr.error(this.i18n.tr("checkscanningconfiguration.checkScanWithUseDTLImpossible"));
        this.isValid = false;
      }
      if ((await this.workstationService.getCount(predicate.and(new Predicate("lightUpOnCopilotConfirm", FilterQueryOp.Equals, false)))) > 0) {
        this.i18n ??= Container.instance.get(I18N);
        toastr.error(this.i18n.tr("checkscanningconfiguration.checkScanWithLightUpMandatory"));
        this.isValid = false;
      }
    }
  }
}
