import { DialogService } from 'aurelia-dialog';
import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, Actions, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { ImportXLS, ImportXlsParams, subscribeImportRefresh } from '../import-xls/import-xls';
import { Endpoints } from 'endpoints';

@autoinject
export class CostCenterList extends ListViewModelBase<Zeus.Web.Model.CostCenter> {
  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private dialogService: DialogService) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.CostCenter>(Constants.EntityTypeNames.CostCenter));
  }

  public ressourceName: string = Constants.EntityTypeNames.CostCenter;

  async attached() {
    await super.attached();
    this.disposables.push(subscribeImportRefresh(this.gridOptions, Constants.DataFileImportType.CostCenter));
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.CostCenter) {
    if (entity && entity.id) {
      let url = '/cost-centers/' + entity.id;
      return url;
    }
    return null;
  }

  public importCostCenter() {
    let params: ImportXlsParams = {
      type: Constants.EntityTypeNames.CostCenter,
      title: 'costcenter.importFile',
      patternUrl: Endpoints.ImportXlsx.GetCostCenterXLSPattern,
      importUrl: Endpoints.ImportXlsx.ImportCostCenterXLS,
      folderName: 'cost_center_imports',
    };
    this.dialogService.open({ viewModel: ImportXLS, model: params }).whenClosed((result) => {
      if (!result.wasCancelled && result.output as boolean) {
        this.gridOptions.api.refreshInfiniteCache();
      }
    });
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("costcenter.costCenterNum"),
        field: "costCenterNum",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("costcenter.description"),
        field: "description",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("costcenter.active"),
        field: "active",
        type: FieldType.Boolean
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    if (this.authService.checkAccess(Constants.EntityTypeNames.CostCenter, Actions.Delete)) {
      defs.splice(0, 0, GridHelper.getSelectedColDef(this));
    }
    return defs;
  }
}
