#locations-tableview {
  height: 75vh;
  display: flex; 
  flex-direction: column;

  .nav {
    padding-bottom: 1em;

    button {
      margin-right: 1em;
  
      i {
        font-size: 12px;
        margin-left: 1em;
      }
    }
  } 

  > div.table-container {
    overflow: auto;
  }

  .tray-container {
    display: flex;
    flex-direction: row;
    margin: 2em 0;

    .tray-label-container {
      width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      .tray-label {
        transform: rotate(-90deg); 
        color: #ccc;
        text-align: center; 
        font-weight: bold; 
        font-size: 16px; 
        position: -webkit-sticky; 
        position: sticky; 
        top: 2em; 
        bottom: 2em;
      }
    }

    .tray-locations-container {
      width: calc(100% - 80px);
      max-height: 60vh;
      overflow: scroll;
      display: block;
      position: relative;

      .stripes-overlay {
        background-image: linear-gradient(45deg, #ffffff 25%, #ebebeb 25%, #ccc 50%, #ffffff 50%, #ffffff 75%, #ccc 75%, #ccc 100%);
        background-size: 60px 60px;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 10;
        position: absolute;
      }

      .range {

        .location {
          vertical-align: top; 
          border: 2px solid #ccc;
          background: #F1F1F1;
          padding: 0;
          margin: 0;

          .location-details {
            display: flex;

            .identifier, .location-volume {
              flex: 1; 
              padding: 0.5em; 
              display: inline-block;
              width: 100%;
            }

            .location-volume {
              font-weight: bold;
              text-align: center;
              font-size: 0.9em;
              color: #888;
              padding-top: 0;
            }

            .article-locations {
              background: rgba(0,0,255,0.1);
              border: 1px solid rgba(0,0,255,0.2); 
            }

            .location-for-inputs {
              background: rgba(0,255,0,0.1);
              border: 1px solid rgba(3, 100, 3, 0.5); 

              .manually-blocked {
                color: orange;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }

  .simple-view {
    .location-details > div {
      display: inline-flex;
      
    }

    .article-locations,
    .location-for-inputs { 
      padding: 0.25em; 
      margin: 0.25em;
      width: 20px;
      height: 20px;
      display: inline-block;
      align-self: center;

      .manually-blocked {
        border-radius: 20px;
        width:10px;
        height:10px;
        background: orange;
        margin:auto; 
        align-self: center;
      }
    }
  }

  .detailed-view {
    .location-details {
      flex-direction: column;
    }

    .article-locations,
    .location-for-inputs {
      margin: 0.25em;
      padding: 0.25em;
      font-size: 0.8em;
      min-width: 15em;
      
      > div {
        border-top: 1px solid #ccc;
      }
    }

    .article-reference {
      display: flex;
      justify-content: space-between;
    }
  }
}
