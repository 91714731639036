import { InputPickReserveLocations } from './../input-pick-reserve-locations/input-pick-reserve-locations';
import { Predicate, FilterQueryOp, EntityState } from 'breeze-client';
import { HttpClient } from 'aurelia-fetch-client';
import { Container, TaskQueue, BindingEngine } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { computedFrom } from "aurelia-binding";
import { Actions, AuthService, Box, CamelCase, DialogBoxViewModel, EnumerationTypeService, FloatingBoxViewModel, ServiceBase, UIInternal } from "digiwall-lib";
import { Zeus } from 'generated';
import { ArticleToInputStorageGroups } from "work-orders/article-to-input-storage-groups/article-to-input-storage-groups";
import * as Constants from "../../constants";
import { CreateLotNumberService } from './lot-number-helper';
import { Endpoints } from 'endpoints';
import * as BConstants from "../../app-modules/borrowing/constants";
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import { DataFormat } from 'select2';
import { Utils } from 'utils/utils';

export type InputPickFloatingBoxViewMode = 'update' | 'read' | 'read-quantity';

export class InputPickFloatingBox extends FloatingBoxViewModel {
  private i18n: I18N;
  private httpClient: HttpClient;
  private taskQueue: TaskQueue;
  private bindingEngine: BindingEngine;
  private box: Box;
  private error: string;

  private articleService: ServiceBase<Zeus.Web.Model.Article>;
  private articleUnitOfMeasureService: EnumerationTypeService;
  private articleToInputService: ServiceBase<Zeus.Web.Model.ArticleToInput>;
  private articleToPickService: ServiceBase<Zeus.Web.Model.ArticleToPick>;
  private articleStorageGroupService: ServiceBase<Zeus.Web.Model.ArticleStorageGroup>;
  private articleLocationService: ServiceBase<Zeus.Web.Model.ArticleLocation>;

  private minDate: Date;
  private isStorageGroupParametersExpanded: boolean = false;
  private isLocationsExpanded: boolean = false;
  private isEditMode: boolean = false;
  private originalValues: any;
  private selectedLotNumber: DataFormat;

  private articleVolumeConfigs: Zeus.Web.Model.ArticleVolumeConfig[] = [];
  private inputPickReserveLocations: InputPickReserveLocations;

  private canUpdateStorageGroupParameter: boolean = Container.instance.get(AuthService).checkAccess(Constants.Permissions.StorageGroupParam, Actions.Read);

  constructor(private workOrder: Zeus.Web.Model.WorkOrder, private onSaveAndNext: (ata: Zeus.Web.Model.ArticleToAction) => void,
    private articleToAction: Zeus.Web.Model.ArticleToAction = null, private viewMode: InputPickFloatingBoxViewMode = 'update') {

    super('./input-pick-floating-box.html', 'lg');

    this.i18n = Container.instance.get(I18N);
    this.httpClient = Container.instance.get(HttpClient);
    this.taskQueue = Container.instance.get(TaskQueue);
    this.bindingEngine = Container.instance.get(BindingEngine);
    this.box = Container.instance.get(Box);

    this.articleService = new ServiceBase<Zeus.Web.Model.Article>(Constants.EntityTypeNames.Article);
    this.articleService.gridDataSource.expands = ['unitOfMeasures.unitOfMeasure', 'articleVolumeConfigs'];
    this.articleToInputService = new ServiceBase<Zeus.Web.Model.ArticleToInput>(Constants.EntityTypeNames.ArticleToInput);
    this.articleToPickService = new ServiceBase<Zeus.Web.Model.ArticleToPick>(Constants.EntityTypeNames.ArticleToPick);
    this.articleStorageGroupService = new ServiceBase<Zeus.Web.Model.ArticleStorageGroup>(Constants.EntityTypeNames.ArticleStorageGroup, null, 'ArticleStorageGroupsByStorageGroups');
    this.articleUnitOfMeasureService = new EnumerationTypeService(Constants.EnumerationTypes.UnitOfMeasure);

    if (articleToAction == null) {
      this.articleToAction = this.service.createEntity({
        actionWorkOrderId: this.workOrder.id,
        limitedToStorageGroupId: this.workOrder.limitedToStorageGroupId,
        qtyNotInlocation: 0,
      }, true);
      this.resetArticleToAction();
    } else {
      this.isEditMode = true;
      this.originalValues = {};
    }
  }

  public async activate(params) {
    this.articleLocationService = CreateLotNumberService(this.articleToAction);
    if (this.articleToAction.lotNumber != null) {
      const articleLocs = (await this.articleLocationService.getEntities(new Predicate("lotNumber", FilterQueryOp.Equals, this.articleToAction.lotNumber)))
      this.selectedLotNumber = articleLocs.length > 0 ? { id: articleLocs[0].id, text: articleLocs[0].lotNumber } : null;
    }
    this.minDate = new Date();
    this.articleUnitOfMeasureService.gridDataSource.customSelect2Predicates = () => {
      if (this.articleToAction?.article?.unitOfMeasures?.length > 0) {
        return Predicate.or(this.articleToAction.article.unitOfMeasures.map(
          articleUom => new Predicate('id', FilterQueryOp.Equals, articleUom.unitOfMeasureId
          )));
      } else {
        // In case of invalid data
        return new Predicate('id', FilterQueryOp.Equals, 0);
      }
    }
    if (false == this.isOfTypeInput) {
      this.setupAvailableStockObservers();
      if (this.isEditMode) {
        this.fetchAvailableStock();
      }
    }
    await this.fetchBorrowingLotNumbers();
    await super.activate(params);
  }

  //#region getter
  @computedFrom('workOrder.workOrderTypeId')
  private get isOfTypeInput(): boolean {
    return this.workOrder.workOrderTypeId == Constants.WorkOrderType.Input;
  }

  @computedFrom('articleToAction.id')
  private get articleToInput(): Zeus.Web.Model.ArticleToInput {
    return this.articleToAction as Zeus.Web.Model.ArticleToInput;
  }
  @computedFrom('articleToAction.id')
  private get articleToPick(): Zeus.Web.Model.ArticleToPick {
    return this.articleToAction as Zeus.Web.Model.ArticleToPick;
  }

  @computedFrom('isOfTypeInput')
  private get service(): ServiceBase<Zeus.Web.Model.ArticleToAction> {
    return this.isOfTypeInput ? this.articleToInputService : this.articleToPickService;
  }

  @computedFrom('workOrder.isFromErp', 'viewMode')
  private get disableMainFields() {
    return this.workOrder.isFromERP || this.viewMode != 'update';
  }

  @computedFrom('viewMode')
  private get isReadOnlyMode() {
    return this.viewMode == 'read';
  }

  @computedFrom('viewMode')
  private get isUpdateMode() {
    return this.viewMode == 'update';
  }

  @computedFrom('articleToAction.article', 'articleToAction.requestedActionQuantity', 'articleToAction.article.hasLotNumber',
    'articleToAction.articleStorageGroupForParameterId', 'articleToAction.lotNumber', 'articleToAction.action.hasExpirationDate',
    'articleToAction.expirationDate', 'articleToAction.unitOfMeasure')
  private get canSaveArticleToAction(): boolean {
    return this.articleToAction.article != null && this.articleToAction.requestedActionQuantity > 0
      && this.articleToAction.unitOfMeasure != null && this.articleToAction.articleStorageGroupForParameterId != null
      // Lot number is only required for input (picking can select empty lotnumber)
      && (false == this.isOfTypeInput || false == this.articleToAction.article.hasLotNumber || this.articleToAction.lotNumber?.length > 0)
      // Expiration date is only required for input (picking does not use expiration date)
      && (false == this.isOfTypeInput || false == this.articleToAction.article.hasExpirationDate || this.articleToInput.expirationDate != null);
  }
  //#endregion

  private isCancelling = false;
  public async cancel() {
    if (this.isHandlingFormUpdate) {
      this.taskQueue.queueTask(() => this.cancel());
      return;
    }
    this.isCancelling = true;
    if (this.articleToAction != null) {
      if (this.articleToAction.id > 0 || this.articleToAction.articleId != null) {
        let canContinue = await this.canContinueWithStorageGroupForParameter();
        if (!canContinue) {
          this.isCancelling = false;
          return;
        }

        this.articleToAction.requestedActionQuantity ??= 0;
        // Adding -> detach entity
        if (this.articleToAction.entityAspect.entityState == EntityState.Added) {
          this.articleToAction.entityAspect.setDetached();
        }
        // Adding, entity saved, but not confirmed -> delete
        else if (false == this.isEditMode) {
          this.articleToAction.entityAspect.setDeleted();
          // Backend delete fails if the UOM is null, so we set it to 0 
          if (this.articleToAction.id > 0) {
            this.articleToAction.unitOfMeasureId = this.articleToAction.unitOfMeasureId || 0;
          }
          await this.service.saveEntity(this.articleToAction, true);
        }
        // Editing, ignore changes (if any) and close
        else {
          try {
            this.articleToAction.entityAspect.rejectChanges();
            // Reset original values
            Object.keys(this.originalValues).forEach(key =>
              this.articleToAction[key] = this.originalValues[key]
            );
            await this.service.saveEntity(this.articleToAction);
          } catch {
            // Sometimes the entity is detached when it should not. TODO later
          }
        }
      }
      // id is neither positive (saved entity) nor has an articleId.
      // Detach entity (if serves as a security but should always be true)
      else if (this.articleToAction.entityAspect.entityState == EntityState.Added) {
        this.articleToAction.entityAspect.setDetached();
      }
    }
    if (await this.verifyAssignedLocations()) {
      this.articleSearchSelect?.close();
      super.cancel();
    }
    this.isCancelling = false;
  }

  private logError(message: string) {
    this.error = message;
  }
  private dismissError() {
    this.error = null;
  }

  private onFormAttached(element: HTMLElement) {
    element.getElementsByTagName('input')[0].focus();
  }

  private onKeyPressed(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.formUpdated();
    }
    return true;
  }

  private articleSearchSelect;
  private searchArticleReady() {
    setTimeout(() => {
      this.articleSearchSelect?.open();
    }, 350);
    // 300ms is the floating box animation time.
    // Adding 50ms avoids the dropdown being shown slightly early and being unaligned with the input
  }

  private async onArticleSelected() {
    if (this.articleToAction.article == null) {
      return;
    }
    this.dismissError();
    if (this.articleToAction.article.unitOfMeasures?.length > 0) {
      // If added OR modified and UOM no longer valid, update unit of measure
      if (this.articleToAction.entityAspect.entityState == EntityState.Added || (
        this.articleToAction.entityAspect.entityState == EntityState.Modified
        && null == this.articleToAction.article.unitOfMeasures.find(x => x.unitOfMeasureId == this.articleToAction.unitOfMeasureId)
      )) {
        this.articleToAction.unitOfMeasureId = this.getDefaultUom().unitOfMeasureId;
      }
    }
    // Remove lot number and expiration date
    this.articleToAction.lotNumber = null;
    this.articleToInput.expirationDate = null;

    if (this.articleToAction.entityAspect.entityState == EntityState.Modified) {
      // Article was modified while articleToAction already saved. Handle this as form update
      this.formUpdated();
    }

    await this.getArticleToActionStorageGroupForParameter();

    this.fetchAvailableStock();
    await this.fetchBorrowingLotNumbers();
  }

  private getStockCountUom() {
    return this.articleToAction.article.unitOfMeasures.find(x => x.usedForStockCount);
  }
  private getDefaultUom() {
    if (this.isOfTypeInput && false == this.isOfTypeBorrowingReturn) {
      return this.articleToAction.article.unitOfMeasures.find(x => x.defaultInputUOM) || this.getStockCountUom();
    } else {
      return this.getStockCountUom();
    }
  }

  private locationsExpanded() {
    this.isStorageGroupParametersExpanded = false;
    return Promise.resolve();
  }

  private isHandlingFormUpdate: boolean = false;
  private async formUpdated() {
    if (this.isHandlingFormUpdate || this.isCancelling) {
      return;
    }
    this.isHandlingFormUpdate = true;
    await this.saveArticleToAction();
    this.isHandlingFormUpdate = false;
  }

  private requestedActionQuantityInput;
  private resetArticleToAction() {
    this.articleToAction.locatedAll = null;
    this.articleToAction.articleId = null;
    this.articleToAction.unitOfMeasureId = null;
    this.articleToAction.requestedActionQuantity = null;
    this.articleToAction.lotNumber = null;
    if (this.isOfTypeInput) {
      this.articleToInput.expirationDate = null;
    }
    if (this.requestedActionQuantityInput) {
      this.requestedActionQuantityInput.number = null;
    }
  }

  //#region Save
  private async saveAndNext() {
    if (this.isHandlingFormUpdate) {
      this.taskQueue.queueTask(() => this.saveAndNext());
      return;
    }
    let canContinue = await this.canContinueWithStorageGroupForParameter();
    if (!canContinue) {
      this.isCancelling = false;
      return;
    }
    await this.saveArticleToAction();

    this.onSaveAndNext(this.articleToAction);
    this.articleToAction = null;
    this.selectedLotNumber = null;

    this.articleToAction = this.service.createEntity({
      actionWorkOrderId: this.workOrder.id,
      limitedToStorageGroupId: this.workOrder.limitedToStorageGroupId,
      qtyNotInlocation: 0,
    }, true);

    this.resetArticleToAction();
    this.articleLocationService = CreateLotNumberService(this.articleToAction);
    this.availableStockInStockUOM = null;
    this.isEditMode = false;
    this.searchArticleReady();
  }

  private async saveAndClose() {
    if (this.isHandlingFormUpdate) {
      this.taskQueue.queueTask(() => this.saveAndClose());
      return;
    }
    let canContinue = await this.canContinueWithStorageGroupForParameter();
    if (!canContinue) {
      this.isCancelling = false;
      return;
    }
    if (false == await this.saveArticleToAction()) {
      return;
    }
    if (await this.verifyAssignedLocations()) {
      this.dialogController.ok(this.articleToAction);
    }
  }

  private async verifyAssignedLocations(): Promise<boolean> {
    if (this.inputPickReserveLocations == null) {
      // Not attached
      return true;
    }
    return this.inputPickReserveLocations.verifyAssignedLocations();
  }

  public async saveArticleToAction(): Promise<boolean> {
    if (false == this.canSaveArticleToAction) {
      return false;
    }
    if (this.canSaveArticleToActionForBorrowing() == false) {
      return false;
    }

    if (this.articleToAction.articleId != this.articleToAction.articleStorageGroupForParameter?.articleId) {
      // Get storage parameters if articleId is modified
      await this.getArticleToActionStorageGroupForParameter();
    }

    if (this.isEditMode) {
      // Add new original values, but don't replace existing (if we change quantity twice, we want to keep the original)
      this.originalValues = Object.assign({}, this.articleToAction.entityAspect.originalValues, this.originalValues);
    }

    if (this.articleToAction.lineNumber == null) {
      let maxLineNum = Math.max(...this.workOrder.articleToActions.map(x => x.lineNumber))
      this.articleToAction.lineNumber = maxLineNum + 1;
    }

    if (!this.isOfTypeInput && (this.selectedLotNumber?.id ?? this.selectedLotNumber) != null) {
      let articleToAction = (await this.articleLocationService.getEntityById(Utils.extractIdFromDataFormat(this.selectedLotNumber?.id ?? this.selectedLotNumber)));
      let lotNumber = this.selectedLotNumber?.text ?? articleToAction.lotNumber;
      if (this.isOfTypeBorrowingReturn) {
        lotNumber ??= this.borrowingReturnLotNumbers.find(x => x.id == this.selectedLotNumber)?.text;
      }
      this.articleToAction.lotNumber = lotNumber;
    }
    await this.service.saveEntity(this.articleToAction);
  }
  //#endregion

  //#region StorageGroupForParameter
  private isGettingStorageGroupForParameter = false;
  public async getArticleToActionStorageGroupForParameter() {
    if (this.isGettingStorageGroupForParameter) {
      this.taskQueue.queueTask(() => this.waitForGetStorageGroupForParameterToBeDone());
      return;
    }
    this.isGettingStorageGroupForParameter = true;
    // Get storage parameters if new entity OR if articleId is modified
    let result = await this.getArticleStorageGroupForParameter(this.articleToAction);
    UIInternal.nextTick(() => {
      if (result === false) {
        this.resetArticleToAction();
      } else if (result?.id) {
        this.articleToAction.articleStorageGroupForParameterId = result.id;
        this.articleToAction.articleStorageGroupForParameter = result;
      }
      this.isGettingStorageGroupForParameter = false;
    });
  }

  private waitForGetStorageGroupForParameterToBeDone() {
    if (this.isGettingStorageGroupForParameter) {
      this.taskQueue.queueTask(() => this.waitForGetStorageGroupForParameterToBeDone());
    }
  }

  public async getArticleStorageGroupForParameter(entity: { articleId: number, limitedToStorageGroupId: number }): Promise<any> {
    let response = await this.getArticleStorageGroupsWithLimit(entity.articleId);
    if (response.ok) {
      let storageGroups: Array<Zeus.Web.Model.StorageGroup> = JSON.parse(await response.text(), (key: string, value: any) => { return CamelCase.convertKeysToCamelCase(key, value) });
      if (storageGroups.length <= 1) {
        let articleStorageGroups = await this.articleStorageGroupService.getEntities(null, null, { articleId: entity.articleId, storageGroupsId: storageGroups.map(x => x.id) })
        if (articleStorageGroups.length > 0) {
          if (storageGroups.length > 0) {
            // Set to matched storage group
            entity.limitedToStorageGroupId = storageGroups[0].id;
            // If limit on workorder, and this limit is lower in the hierarchy, use wo-limit instead
            if (this.workOrder.limitedToStorageGroup != null && this.workOrder.limitedToStorageGroup.storageGroupTypeId > storageGroups[0].storageGroupTypeId) {
              entity.limitedToStorageGroupId = this.workOrder.limitedToStorageGroupId
            }
          }
          return articleStorageGroups[0];
        } else {
          this.logError(this.i18n.tr('articletoinput.errorFindingStorageGroup'));
          return false;
        }
      } else {
        return await this.dialogService.open({ viewModel: ArticleToInputStorageGroups, model: { storageGroups: storageGroups, articleId: entity.articleId }, lock: true, keyboard: true }).whenClosed(
          async (result) => {
            if (result.wasCancelled) {
              return false;
            } else {
              entity.limitedToStorageGroupId = result.output.id;
              return result.output.articleStorageGroup;
            }
          }
        );
      }
    } else {
      if (response.status == 400) {
        this.logError(await response.text());
      } else {
        this.logError(this.i18n.tr('articletoinput.errorFindingStorageGroup'));
      }
      return false;
    }
  }

  public async getArticleStorageGroupsWithLimit(articleId: number): Promise<Response> {
    return await this.httpClient.fetch(Endpoints.Article.GetArticleStorageGroups
      + "?articleId=" + articleId + (this.workOrder.limitedToStorageGroupId ? "&storageGroupId=" + this.workOrder.limitedToStorageGroupId : ''));
  }

  private async canContinueWithStorageGroupForParameter() {
    if (this.articleToAction.articleStorageGroupForParameter.entityAspect.entityState.isAddedModifiedOrDeleted() || this.articleVolumeConfigs.some(x => x.entityAspect.entityState.isAddedModifiedOrDeleted())) {
      let cancelBtn = { label: this.i18n.tr("menu.cancel"), title: this.i18n.tr("menu.cancel"), theme: 'dark', type: 'ghost', disabled: false, fn: (dialogBox: DialogBoxViewModel) => { dialogBox.controller.ok(false) } };
      let stayBtn = { label: this.i18n.tr("menu.stay"), title: this.i18n.tr("menu.stay"), theme: 'primary', type: 'solid', disabled: false, fn: (dialogBox: DialogBoxViewModel) => { dialogBox.controller.ok(true) } };
      let wantToStay = await this.box.showQuestion(this.i18n.tr("articletoinput.cancelWithoutSave"), this.i18n.tr("menu.del-filter-set-title"), [cancelBtn, stayBtn]).whenClosed(result => {
        return result.output;
      });
      return !wantToStay;
    }
    return true;
  }

  private storageGroupParametersExpanded() {
    this.isLocationsExpanded = false;
    return Promise.resolve();
  }
  //#endregion

  //#region available stock
  private availableStockInStockUOM: { [key: string]: number } = null;
  private async fetchAvailableStock() {
    if (this.isOfTypeInput) {
      return;
    }

    this.availableStockInStockUOM = null;

    let url = Endpoints.WorkOrder.GetPickableStockForArticle + "?articleId=" + this.articleToAction.articleId;
    let limitedToSg = this.articleToAction.limitedToStorageGroupId || this.workOrder.limitedToStorageGroupId;
    if (limitedToSg != null) {
      url += `&limitedToSG=${limitedToSg}`;
    }
    let response = await this.httpClient.fetch(url);
    if (response.ok) {
      let json = await response.json();
      delete json.$id;
      delete json.$type;
      this.availableStockInStockUOM = json;
    }
    this.availableStockRefreshed++;
  }

  private availableStockRefreshed = 0;
  @computedFrom('availableStockInStockUOM', 'articleToAction.unitOfMeasureId', 'articleToAction.lotNumber', 'availableStockRefreshed')
  private get availableStock(): string {
    if (this.availableStockInStockUOM == null) {
      return null;
    }
    let availableStock: number;
    if (this.articleToAction.article.hasLotNumber && this.articleToAction.lotNumber) {
      // Limit to selected lot number
      availableStock = this.availableStockInStockUOM[this.articleToAction.lotNumber] ?? 0;
    } else {
      // If has lot number but none given: pick from any. Available stock is sum
      // If article uses no lot numbers but there are still articleLocations with a number we also need to do the sum
      availableStock = Object.values(this.availableStockInStockUOM).reduce((total, stock) => total + stock, 0);
    }
    let articleUnitOfMeasure = this.articleToAction.article.unitOfMeasures.find(x => x.unitOfMeasureId == this.articleToAction.unitOfMeasureId);
    if (articleUnitOfMeasure.usedForStockCount) {
      return `${availableStock} ${articleUnitOfMeasure.unitOfMeasure.denomination._translation}`;
    } else {
      let stockUom = this.getStockCountUom();
      return `${availableStock / articleUnitOfMeasure.quantityInStockCountUOM} ${articleUnitOfMeasure.unitOfMeasure.denomination._translation} (${availableStock} ${stockUom.unitOfMeasure.denomination._translation})`;
    }
  }

  private setupAvailableStockObservers() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.articleToAction, "limitedToStorageGroupId").subscribe(() => this.fetchAvailableStock()),
    );
  }
  //#endregion

  //#region Borrowing
  private borrowingReturnLotNumbers: Array<DataFormat> = [];
  private borrowingQuantityLotNumbers: Map<string, number> = new Map();
  @computedFrom('workOrder.orderSubtypeId')
  private get isOfTypeBorrowing(): boolean {
    return this.workOrder.orderSubtypeId == BConstants.OrderSubtype.Borrowing;
  }

  @computedFrom('workOrder.orderSubtypeId')
  private get isOfTypeBorrowingReturn(): boolean {
    return this.workOrder.orderSubtypeId == BConstants.OrderSubtype.BorrowingReturn;
  }

  private borrowingActive = Container.instance.get(AppModuleService).isActive(AppModuleEnum.Borrowing);
  private async fetchBorrowingLotNumbers() {
    this.borrowingReturnLotNumbers.splice(0);
    this.borrowingQuantityLotNumbers.clear();
    if (this.borrowingActive && this.isOfTypeBorrowingReturn && this.articleToAction?.article?.hasLotNumber) {
      let result = await this.httpClient.get(BConstants.Application.GetBorrowingLotNumberForArticle + "?articleId=" + this.articleToAction.articleId);
      if (result.ok) {
        let response = await result.json();
        delete response.$id;
        delete response.$type;
        let id = 1;
        for (let [key, value] of Object.entries<number>(response)) {
          this.borrowingReturnLotNumbers.push({ id: id, text: key });
          this.borrowingQuantityLotNumbers.set(key, value);
          id++;
        }
        this.selectedLotNumber = this.borrowingReturnLotNumbers.find(x => x.text == this.articleToAction.lotNumber);
      }
    }
  }

  private canSaveArticleToActionForBorrowing(): boolean {
    if (this.borrowingActive && this.isOfTypeBorrowingReturn) {
      let maxQty = 0;
      let msgKey = null;
      if (this.articleToAction?.article?.hasLotNumber) {
        maxQty = this.borrowingQuantityLotNumbers.get(this.articleToAction.lotNumber);
        msgKey = "articleToAction.higherBorrowingQtyWithLot";
      } else {
        maxQty = this.articleToAction.article.borrowingQuantity;
        msgKey = "articleToAction.higherBorrowingQty";
      }
      const articleUOM = this.articleToAction.article.unitOfMeasures.find(x => x.unitOfMeasureId == this.articleToAction.unitOfMeasureId);
      let qty = articleUOM.quantityInStockCountUOM * this.articleToAction.requestedActionQuantity;
      if (maxQty != null && maxQty < qty) {
        this.logError(this.i18n.tr(msgKey));
        return false;
      }
    }
    return true;
  }
  //#endregion
}
