import { BindingEngine, Container, computedFrom } from 'aurelia-framework';
import { CamelCase, CustomLogger, ServiceBase } from "digiwall-lib";
import { Zeus } from 'generated';
import * as Constants from "../../../constants";
import { I18N } from 'aurelia-i18n';
import { Utils } from 'utils/utils';
import { InventoryArticleBaseFloatingBox } from './inventory-article-base-floating-box';
import { Endpoints } from 'endpoints';

export class InventoryArticleLocationsFloatingBox extends InventoryArticleBaseFloatingBox {

  private articleLocationForInventoryDTOs: Array<ArticleLocationForInventoryDTO> = [];
  private articleLocationForInventoryDTOsSelected: Array<ArticleLocationForInventoryDTO> = [];
  private verifySelection: boolean = false;

  private bindingEngine: BindingEngine;
  private logger: CustomLogger;
  private i18n: I18N;

  constructor(workOrder: Zeus.Web.Model.WorkOrder, private inv: Zeus.Web.Model.InventoryWorkOrderArticle = null) {
    super(workOrder, 'article-locations');
    if (this.isEditionMode) {
      this.link.entityAspect.setDetached();
      this.link = inv;
      this.canSave = true;
    }

    this.bindingEngine = Container.instance.get(BindingEngine);
    this.logger = Container.instance.get(CustomLogger);
    this.i18n = Container.instance.get(I18N);
  }

  @computedFrom("inv")
  private get isEditionMode(): boolean {
    return this.inv != null;
  }

  @computedFrom("isEditionMode")
  private get labelTitle(): string {
    return this.isEditionMode ? 'workorder.editArticleLocations' : 'workorder.addSelected.articleLocations';
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.link, "articleId").subscribe(async (newVal, oldVal) => {
        await this.getLocationForInventory();
      }),
      this.bindingEngine.propertyObserver(this.link, "lotNumber").subscribe(async (newVal, oldVal) => {
        await this.getLocationForInventory();
      }),
    );
    if (this.isEditionMode) {
      await this.getLocationForInventory();
    }
  }

  private async getLocationForInventory() {
    if (false == await this.save(true)) {
      return;
    }

    let response = await this.httpClient.fetch(Endpoints.WorkOrder.GetArticleLocationsForInventory + "?articleId=" + this.link.articleId + "&inventoryId=" + this.link.id);
    if (response.ok) {
      this.articleLocationForInventoryDTOs.splice(0);
      this.articleLocationForInventoryDTOsSelected.splice(0);
      this.articleLocationForInventoryDTOs.push(...JSON.parse(await response.text(), (key: string, value: any) => { return CamelCase.convertKeysToCamelCase(key, value) }));
      this.articleLocationForInventoryDTOsSelected.push(...this.articleLocationForInventoryDTOs.filter(alfi => this.link.locationForInventories.some(lfi => lfi.locationId == alfi.locationId)));
    }
  }

  protected async beforeSave(): Promise<boolean> {
    if (this.verifySelection && this.articleLocationForInventoryDTOsSelected.length == 0) {
      this.logger.LogError(this.i18n.tr("workorder.errorInventoryLocationSelected"), null, null, true);
      return false;
    }

    // Check article compatibility
    let response = await this.httpClient.fetch(Endpoints.Article.GetArticleStorageGroups
      + "?articleId=" + this.link.articleId + (this.workOrder.limitedToStorageGroupId ? "&storageGroupId=" + this.workOrder.limitedToStorageGroupId : ''));
    if (!response.ok) {
      if (response.status == 400) {
        this.logger.LogError(await response.text(), null, null, true);
      } else {
        this.logger.LogError(this.i18n.tr('articletoinput.errorFindingStorageGroup'), null, null, true);
      }
      return false;
    }
    return true;
  }

  protected async saveAndClose() {
    this.verifySelection = true;
    super.saveAndClose();
  }

  protected async save(silentSave: boolean = false): Promise<boolean> {
    let result = await super.save(silentSave);
    if (result && this.articleLocationForInventoryDTOsSelected.length > 0) {
      result = (
        await this.httpClient.post(Endpoints.WorkOrder.UpdateLocationForInventory + "?inventoryId=" + this.link.id, JSON.stringify(this.articleLocationForInventoryDTOsSelected))
      ).ok;
      if (result) {
        this.logger.Log(this.i18n.tr("general.successfullySaved", { ns: "common" }), this.link, null, true);
      }
    }
    this.verifySelection = false;
    return result;
  }

  public async cancel() {
    if (!this.isEditionMode && this.link != null && this.link.entityAspect.entityState.isUnchangedOrModified()) {
      await this.service.deleteEntities([this.link], true, null, true);
    }
    await super.cancel();
  }
}

export interface ArticleLocationForInventoryDTO {
  articleLocationId: number;
  locationId: number;
  articleLotNumber: string;
  locationIdentifier: string;
}
