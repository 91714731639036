import { Router } from 'aurelia-router';
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, ServiceBase, TranslatedPropertyRules, Various } from 'digiwall-lib';
import { BindingEngine, autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { ZeusUtilsMlFieldHelper } from 'utils/utils-ml-field-helper';
import * as toastr from 'toastr';

@autoinject
export class WorkOrderPriorityDetail extends EntityDetailViewModelBase<Zeus.Web.Model.WorkOrderPriority> {
  public ressourceName: string = Constants.EntityTypeNames.WorkOrderPriority;
  public Constants: any = Constants;
  public selectedLanguageCode = 'en';
  private canBeUrgent: boolean = true;

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.WorkOrderPriority>(Constants.EntityTypeNames.WorkOrderPriority));
  }

  public async activate(params) {
    const id = params.param1;
    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
    }
    this.selectedLanguageCode = this.i18n.getLocale();
    this.activateValidation();
  }

  private activateValidation() {
    TranslatedPropertyRules.anyRequired('name')
      .ensure('priority').required()
      .then().ensure('priority').satisfies(async priority => {
        let predicate = new Predicate('priority', FilterQueryOp.Equals, priority);
        if (false == this.isCreationMode) {
          predicate = predicate.and('id', FilterQueryOp.NotEquals, this.entity.id);
        }
        return 0 == await this.service.getCount(predicate);
      }).withMessage('workorderpriority.errorPriorityAlreadyExist')
      .on(this.entity);
  }

  get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("workorderpriority.priority");
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return this.entity?.name?._translation;
    }
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "priority").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          await this.checkIfCanBeUrgent();
        }
      })
    );
  }

  private async checkIfCanBeUrgent() {
    if (this.entity.urgent) {
      this.canBeUrgent = (await this.service.getCount(
        new Predicate("id", FilterQueryOp.NotEquals, this.entity.id)
          .and(new Predicate("priority", FilterQueryOp.GreaterThan, this.entity.priority))
      ) == 0);
      if (!this.canBeUrgent) {
        this.logError(this.i18n.tr("workorderpriority.cantBeUrgent"), null);
        this.entity.urgent = false;
      }
    }
  }

  public async beforeSave() {
    let filledTr = await ZeusUtilsMlFieldHelper.BeforeSave(this.entity, [
      { prop: this.entity.name, label: "workorderpriority.name", isRequired: true }
    ]);

    if (await this.service.getCount(
      new Predicate("id", FilterQueryOp.NotEquals, this.entity.id)
        .and(new Predicate("urgent", FilterQueryOp.Equals, true))
    ) > 0) {
      toastr.info(this.i18n.tr("workorderpriority.newHighestPriority"));
    }

    return filledTr;
  }
}
