import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { Actions, CamelCase, PermissionsConfig } from "digiwall-lib";
import * as DTLConstants from "./constants";
import * as Constants from "../../constants";
import translationJson from './locales/index';
import { EnumerationTypeCategory } from "digiwall-lib";
import { DropToLight } from "./generated";
import * as breeze from 'breeze-client';
import { I18N } from "aurelia-i18n";
import { Container } from "aurelia-framework";
import { AppModule } from "app-modules/constants";

export class DropToLightRoot extends AppModuleRoot {
  public get name() {
    return AppModule.DropToLight;
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [
      { category: DTLConstants.EnumerationTypes.DTLColor, isClosed: true, name: "enumerationtype.dTLColor" },
      { category: DTLConstants.EnumerationTypes.WorkstationPositionColor, isClosed: false, name: "enumerationtype.workstationPositionColor" },
    ];
  }

  public get enumerationTypeList(): object {
    return DTLConstants.EnumerationTypes;
  }

  protected get pathName(): string {
    return 'drop-to-light';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return []
  }

  protected get resourceNames(): {} {
    return DTLConstants.EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        { context: DTLConstants.EntityTypeNames.Workstation, qualifiedName: [Constants.MenuType.Storages, 'workstation.workstations'] }
      ]
    }
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
    const i18n = Container.instance.get(I18N);
    metadataStore.registerEntityTypeCtor(DTLConstants.EntityTypeNames.DTLController, null, async (entity: DropToLight.Model.DTLController) => {
      Object.defineProperty(entity, 'displayName', {
        configurable: true,
        get: () => {
          let label = i18n.tr('dtlcontroller.dtlcontroller');
          if (entity.tcpAddress?.trim().length > 0) {
            label += ' - TCP ' + entity.tcpAddress;
          }
          if (entity.portNumber ?? 0 > 0) {
            label += ':' + entity.portNumber;
          }
          return label;
        }
      });
    });
    metadataStore.registerEntityTypeCtor(DTLConstants.EntityTypeNames.WorkstationPosition, function () {
      this.lastWorkstationPositionHistoryEvent = null;
    }, (entity: DropToLight.Model.WorkstationPosition) => {
      const lastObject = entity.lastWorkstationPositionHistoryEvent;
      entity.lastWorkstationPositionHistoryEvent = JSON.parse(JSON.stringify(lastObject), (key: string, value: any) => { return CamelCase.convertKeysToCamelCase(key, value) });
    });
  }
}
