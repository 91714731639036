import { Entity } from 'breeze-client';
import { ServiceBase, UtilsMlFieldHelper } from 'digiwall-lib';
import { IncompleteMlField } from 'digiwall-lib/dist/typings/utils/incomplete-multilingual-field/incomplete-multilingual-field';
import { Zeus } from "../generated";
import * as Constants from '../constants';

export class ZeusUtilsMlFieldHelper {
  public static async BeforeSave(entity: Entity, mlProps: Array<IncompleteMlField>) {
    let languagePriorityService = new ServiceBase<Zeus.Web.Model.LanguagePriority>(Constants.EntityTypeNames.LanguagePriority);
    let languagePriorities: Array<Zeus.Web.Model.LanguagePriority> = await languagePriorityService.getAllEntities('language');
    return UtilsMlFieldHelper.BeforeSave(entity, mlProps, languagePriorities.sortBy("priority").map(x => x.language))
  }
}
