import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';

@autoinject
export class WorkOrderPriorityList extends ListViewModelBase<Zeus.Web.Model.WorkOrderPriority> {
  public ressourceName: string = Constants.EntityTypeNames.WorkOrderPriority;

  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.WorkOrderPriority>(Constants.EntityTypeNames.WorkOrderPriority));
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.WorkOrderPriority) {
    if (entity && entity.id) {
      let url = '/work-order-priorities/' + entity.id;
      return url;
    }
    return null;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("workorderpriority.name"),
        field: "name._translation",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workorderpriority.priority"),
        field: "priority",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("workorderpriority.default"),
        field: "default",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("workorderpriority.active"),
        field: "active",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("workorderpriority.urgent"),
        field: "urgent",
        type: FieldType.Boolean
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
} 
