import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';

@autoinject
export class MessageSentDetail extends EntityDetailViewModelBase<Zeus.Web.Model.DynamicStorageSentMessage> {
  public ressourceName: string = Constants.EntityTypeNames.DynamicStorageSentMessage;
  public childsStorageGroup: Zeus.Web.Model.DynamicStorageSentMessage[];

  constructor(
    router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.DynamicStorageSentMessage>(Constants.EntityTypeNames.DynamicStorageSentMessage));
  }

  public get documentTitle() {
    return "TEST"
  }
  public async activate(params) {
    const id = params.param1;
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id);
    this.controller.addObject(this.entity);
  }

  public get ribbonHeaderText() {
    return this.i18n.tr("message.sentMessage");
  }

  public stringifyObj(obj) {
    if (typeof obj == 'string') {
      return obj;
    }
    let json = JSON.parse(obj);
    return JSON.stringify(json, null, 4);
  }

} 
