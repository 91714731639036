import { autoinject, bindable, customElement } from "aurelia-framework";
import { CustomPropertiesExtensionService } from "../custom-properties-extension-service";
import { DetailViewSlot, EntityType, CustomPropDetail, PropertyType } from "../custom-properties-model";
import { Zeus } from 'generated';
import { ServiceBase, UIInternal } from "digiwall-lib";
import * as Constants from '../../../constants';

@autoinject
@customElement('custom-property-form')
export class CustomPropertiesForm {
  @bindable private entityType: EntityType;
  @bindable private entity: Zeus.Web.Model.IWithCustomProperties;
  @bindable private viewSlot: DetailViewSlot = DetailViewSlot.AfterForm;

  private props: CustomPropDetail[] = [];
  private PropertyType = PropertyType;
  private isLoading = true;

  constructor(private customPropService: CustomPropertiesExtensionService) {
    UIInternal.subscribe(UIInternal.EVT_CHANGE_LANGUAGE, () => {
      this.setProps();
    })
  }

  public async bind() {
    this.isLoading = true;
    await this.getCustomPropForEntity();
    this.setProps();
    this.isLoading = false;
  }

  private async getCustomPropForEntity() {
    if (this.entity.customProperties == null) {
      const service = new ServiceBase<Zeus.Web.Model.CustomProperties>(Constants.EntityTypeNames.CustomProperties);
      if (this.entity.customPropertiesId == null) {
        this.entity.customProperties = await service.createEntity();
      } else {
        this.entity.customProperties = await service.getEntityById(this.entity.customPropertiesId);
      }
    }
  }

  private setProps() {
    this.props.splice(0);
    this.props.push(...this.customPropService.getCustomProp(this.entityType, this.viewSlot));
  }

  private entityChanged() {
    this.bind();
  }
}
