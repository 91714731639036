import { Aurelia, Container } from 'aurelia-framework';
import environment from './environment';
import 'breeze-client/breeze.modelLibrary.backingStore';
import 'breeze-client/breeze.dataService.webApi';
import 'breeze-client/breeze.uriBuilder.json';
import { UIFrameworkConfig } from 'digiwall-lib';
import { ZeusConfig } from './zeus-config';
import ZeusBreezeModel from 'model';
import moment = require('moment');
import 'moment/locale/fr';
import 'moment/locale/nl';
import * as Constants from './constants';
import { ApiService } from 'external-src/api.service';

export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .feature('resources')
    .plugin('aurelia-dialog', config => {
      config.useDefaults();
      config.settings.lock = true;
      config.settings.centerHorizontalOnly = true;
      config.settings.startingZIndex = 1000;
      config.settings.keyboard = true;
      config.useCSS('_ux-dialog-override');
    })
    .plugin('digiwall-lib', (config: UIFrameworkConfig) => {
      config.setDefaultLang('en');
      config.setApplicationConfigImplementation(ZeusConfig);
      config.autoDetectAuthServiceImplementation(environment.auth);
      config.setBreezeModelImplementation(ZeusBreezeModel);
      config.setFileStorageServiceImplementation();
      config.setHttpClientConfiguration(config => config.withBaseUrl(Constants.Application.RemoteServiceName));
    })
    .plugin('aurelia-application-insights', config => {
      config.init('4167b083-df0f-4532-8104-2693d7e7f18b');
      config.attach({
        logging: {
          enabled: true // Set to `true` to have some log messages appear in the browser console.
        },
        logForwarding: {
          enabled: true // Set to `true` to have logged messages and exceptions sent to app inights.
        },
        pageTracking: {
          enabled: true // Set to `false` to disable in non-production environments.
        },
        clickTracking: {
          enabled: false // Set to `false` to disable in non-production environments.
        }
      });
    })
    .globalResources(
      [
        'resources/value-converters/index',
        'resources/elements/button-detail.html',
        'resources/elements/counting-strategy-input',
        'resources/attributes/integer-input',
        'resources/elements/ui-table-pagination/ui-table-pagination'
      ]);


  if (environment.debug) {
    aurelia.use.developmentLogging();
  }

  if (environment.testing === true) {
    aurelia.use.plugin('aurelia-testing');
  }
  aurelia.start().then(() => {
    let loadingShadow = document.getElementById("loader-loading-shadow");
    if (loadingShadow) loadingShadow.className = "loader-loading-shadow loader-loading-shadow-animate";
    setTimeout(() => {
      aurelia.setRoot('app');
    }, 700);
  });

  window.addEventListener("error", async function (e: ErrorEvent) { await handleError(e); });
  window.addEventListener("unhandledrejection", async function (e: PromiseRejectionEvent) { await handleError(e); });
  window.addEventListener("rejectionhandled", async function (e: PromiseRejectionEvent) { await handleError(e); });

  async function handleError(e: PromiseRejectionEvent | ErrorEvent) {
    let stack = e instanceof ErrorEvent ? e.error?.stack : e.reason?.stack;
    stack ??= "Error stack is null";
    console.warn(`Type : ${e.type} \n${stack}`);
    await Container.instance.get(ApiService).LogFrontendError(stack);
    return true;
  }
}
