import { CustomLogger, EnumerationTypeService, EnumerationType, ViewModelBase, ServiceBase } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, computedFrom, Disposable, observable } from 'aurelia-framework';
import * as Constants from '../constants';
import { DataFormat } from 'select2';
import { Zeus } from 'generated';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { Endpoints } from 'endpoints';
import { Utils } from 'utils/utils';

@autoinject
export class SendMessage extends ViewModelBase {
  public ressourceName: string = Constants.EntityTypeNames.Message;
  private modulaMessageTypeService: EnumerationTypeService;
  private batchProcessService: ServiceBase<Zeus.Web.Model.BatchProcess>;
  private storageService: ServiceBase<Zeus.Web.Model.Storage>;
  private httpResult: any;
  private typeOfMessageToGenerate: 1 | 2 | 3 = 1;

  @observable
  private messageType: DataFormat;

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private bindingEngine: BindingEngine) {
    super(router, logger);

    this.modulaMessageTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ModulaMessageType);
    this.batchProcessService = new ServiceBase<Zeus.Web.Model.BatchProcess>(Constants.EntityTypeNames.BatchProcess);
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
  }

  @computedFrom('messageType')
  private get isMoveTrayToBay(): boolean {
    return this.messageType.id == Constants.MessageType.MoveTrayToBay;
  }
  @computedFrom('messageType')
  private get isMoveTrayForInput(): boolean {
    return this.messageType.id == Constants.MessageType.MoveTrayForInput;
  }
  @computedFrom('messageType')
  private get isMoveTrayForPicking(): boolean {
    return this.messageType.id == Constants.MessageType.MoveTrayForPicking;
  }
  @computedFrom('messageType')
  private get isMoveTrayForInventory(): boolean {
    return this.messageType.id == Constants.MessageType.MoveTrayForInventory;
  }
  @computedFrom(...['isMoveTrayToBay', 'isMoveTrayForInput', 'isMoveTrayForInventory'])
  private get isMoveTray(): boolean {
    return this.isMoveTrayToBay || this.isMoveTrayForInput || this.isMoveTrayForPicking || this.isMoveTrayForInventory;
  }

  @computedFrom('typeOfMessageToGenerate')
  private get isSendRequest(): boolean {
    return this.typeOfMessageToGenerate < 3;
  }
  @computedFrom('typeOfMessageToGenerate')
  private get isSendResponse(): boolean {
    return this.typeOfMessageToGenerate > 1;
  }

  myMessage: Message;
  myResponse: ResponseDto;

  public async activate() {
    let defaultMsgType = await this.modulaMessageTypeService.getDefault();
    this.messageType = { id: defaultMsgType.id, text: defaultMsgType.denomination._translation };

    this.myMessage = new Message();
    this.myMessage.messagePriority = 0

    this.myResponse = new ResponseDto();
    this.myResponse.returnStatus = "Done";
    this.myResponse.bins = new Array();
  }

  get ribbonHeaderText(): string {
    return this.i18n.tr('menu.sendMessage');
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.myMessage, "isUrgent").subscribe((newValue, oldValue) => {
        newValue ? this.myMessage.messagePriority = 10 : this.myMessage.messagePriority = 0;
      }),
    );
  }

  private messageTypeChanged(newValue: DataFormat, oldValue: DataFormat) {
    if (newValue != null && newValue.id != null) {
      if (oldValue != null && newValue.id != oldValue.id) {
        this.myMessage.quantityToInput = null;
        this.myMessage.quantityToPick = null;
        this.myMessage.unitOfMeasure = null;

        this.myResponse.realInputQuantity = null;
        this.myResponse.realPickedQuantity = null;
        this.myResponse.countedQuantity = null;
      }
    }
  }

  //To desactivate the warning when leaving the page and we leave it.
  public async canDeactivate() {
    return true;
  }

  private dateFilters = [
    { id: 1, name: this.i18n.tr("message.actionSendMessage") },
    { id: 2, name: this.i18n.tr("message.actionSendMessageAndAnswer") },
    { id: 3, name: this.i18n.tr("message.actionSendAnswer") },
  ];
  private selectedDateFilterId: number = 1;

  public async postToApi() {
    if (this.myMessage.messagePriority < 0 || this.myMessage.messagePriority > 10) {
      this.logError(this.i18n.tr('message.errorPriority'), null);
      return;
    }

    if (this.isSendResponse && this.isMoveTrayForPicking && this.myResponse.bins.length > 0) {
      if (this.myResponse.bins.some(b => b.binNumber == null || b.quantity == null)) {
        this.logError(this.i18n.tr('message.existingBinsMustBeFilled'), null);
        return;
      }

      if (this.myResponse.bins.some(b => b.quantity < 0 || (false == b.binNumber?.length > 0))) {
        this.logError(this.i18n.tr('message.binDataMustBePositive'), null);
        return;
      }

      if (Utils.arrayHasDuplicates(this.myResponse.bins.map(b => b.binNumber))) {
        this.logError(this.i18n.tr('message.binNumberMustBeUnique'), null);
        return;
      }
    }

    if (this.myMessage.bayNumber == null || this.myMessage.bayNumber < 1) {
      this.myMessage.bayNumber = 1;
    }

    this.myMessage.articleExpirationDate = new Date(this.myMessage.articleExpirationDate);

    //message type is mandatory
    if (this.messageType.id === null || (parseInt(this.messageType.id.toString())) <= 0) {
      this.logError(this.i18n.tr('message.errorSelectMessageType'), null);
    } else {

      let requestOk = true;
      if (this.isSendRequest) {
        this.myMessage.messageTypeId = <number>this.messageType.id;
        this.myMessage.storageId = (await this.storageService.firstEntity(new Predicate('dynamicStorageId', FilterQueryOp.Equals, this.myMessage.machineId))).id;
        let postResult = await this.httpClient.post(Endpoints.Message.MessageSendEndPoint, JSON.stringify(this.myMessage));

        if (postResult.ok) {
          this.httpResult = await postResult.json();
          if (this.isSendResponse) {
            this.myResponse.messageId = this.httpResult.Guid;
          }
        } else {
          requestOk = false;
          this.logError(await postResult.text(), null, true);
        }
      }

      if (this.isSendResponse && requestOk) {
        switch (parseInt(this.messageType.id.toString())) {
          case Constants.MessageType.MoveTrayForInventory:
            this.myResponse.messageType = ModulaMessageResponseType[ModulaMessageResponseType.MoveTrayForInventory];
            break;
          case Constants.MessageType.MoveTrayForInput:
            this.myResponse.messageType = ModulaMessageResponseType[ModulaMessageResponseType.MoveTrayForInput];
            break;
          case Constants.MessageType.MoveTrayForPicking:
            this.myResponse.messageType = ModulaMessageResponseType[ModulaMessageResponseType.MoveTrayForPicking];
            break;
          default:
            this.myResponse.messageType = ModulaMessageResponseType[ModulaMessageResponseType.MoveTrayToBay];
            break;
        }

        let postResult = await this.httpClient.post(Endpoints.Message.MessageSimulateReceiveEndPoint, JSON.stringify(this.myResponse));
        if (false == postResult.ok) {
          if (postResult.status == 400) {
            var postResultError = await postResult.json();
            this.logError(postResultError.errors.messageId.join(', '), null, true);
          } else {
            this.logError(await postResult.text(), null, true);
          }
        }
      }

    }
  }

  private addBin() {
    let newBin: BinDto = { binNumber: (this.myResponse.bins.length + 1).toString(), quantity: 1 };
    newBin.observer = this.bindingEngine.propertyObserver(newBin, "quantity").subscribe((newValue, oldValue) => {
      if (newValue != null && newValue != oldValue) {
        this.recalculateRealPickedQty();
      }
    });
    this.myResponse.bins.push(newBin);
    this.recalculateRealPickedQty();
  }

  private removeBin(bin: BinDto) {
    bin.observer.dispose();
    this.myResponse.bins.remove(bin);
    this.recalculateRealPickedQty();
  }

  private recalculateRealPickedQty() {
    this.myResponse.realPickedQuantity = this.myResponse.bins.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.quantity
    }, 0);
  }
}

class Message {
  id: number;
  queueIdentifier: string;
  messageTypeId: number;
  messageType: EnumerationType;
  storageId: number;
  machineId: number;
  batchNumber: number;
  workOrderId: number;
  bayNumber: number;
  trayId: number;
  isUrgent: boolean;
  sendDate: Date | string;
  guidReceptionDate: Date | string | null;
  erpOrderLineComment: string;

  erpWorkOrderNum: string;
  articleReference: string;
  articleDescription: string;
  barcodeToScanForChecking: string;
  quantityToInput: number;
  locationWidth: number;
  locationDepth: number;
  quantityToPick: number;

  messagePriority: number;
  businessProcessingComment: string;

  unitOfMeasure: string;

  typeOfMessageToGenerate: number;
  articleLotNumber: string;
  articleExpirationDate: Date | null;
}

class ResponseDto {
  messageId: string;
  returnStatus: string;
  messageType: string;
  articleLocationZeusId: number;
  realInputQuantity: number;
  realPickedQuantity: number;
  countedQuantity: number;
  bins: BinDto[];
}

export interface BinDto {
  binNumber: string;
  quantity: number;
  observer?: Disposable;
}

enum ModulaMessageResponseType {
  MoveTrayToBay,
  MoveTrayForInput,
  MoveTrayForPicking,
  MoveTrayForInventory
}
