import * as Constants from './../constants';
import { Actions, AuthService, ServiceBase, UITreeNode, UITreePanelConfig, UITreePanelConfigAction } from 'digiwall-lib';
import { autoinject, bindable, bindingMode, computedFrom, customElement } from "aurelia-framework";
import { Zeus } from 'generated';
import { I18N } from 'aurelia-i18n';
import { SiteManagementSelectionHelper } from 'external-src/site-management/site-management-select-view';
import * as DTLConstants from '../app-modules/drop-to-light/constants';
import * as ConvConstants from '../app-modules/conveyor/constants';
import { DropToLight } from "../app-modules/drop-to-light/generated";
import { Conveyor } from "../app-modules/conveyor/generated";
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
@customElement('storage-tree-panel')
export class StorageTreePanel {
  @bindable
  private isNodeSelectable: (args: { node: UnifiedStorageEntity }) => boolean;
  @bindable
  private showCollapseAll: boolean = true;
  @bindable
  public showNodeExpand: boolean = true;
  @bindable
  private treeConfigActions: UITreePanelConfigAction<UnifiedStorageEntity>[];

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public storageId: number;
  @bindable
  private onStorageSelected: (args: { storage: Zeus.Web.Model.Storage }) => Promise<boolean>;
  @bindable
  private storageExpands: string[] = [];

  @bindable({ defaultBindingMode: bindingMode.twoWay })
  public storageGroupId: number;
  @bindable
  private onStorageGroupSelected: (args: { storageGroup: Zeus.Web.Model.StorageGroup }) => Promise<boolean>;

  @bindable
  private onCreateSite: () => Promise<void>;
  @bindable
  private exportToCsv: () => void;

  private treeList: UnifiedStorageEntity[] = [];
  private treeConfig: UITreePanelConfig<UnifiedStorageEntity>;
  private treeSelection = [];
  private loadingTree: boolean = true;

  private storages: Array<Zeus.Web.Model.Storage>;
  private storageService: ServiceBase<Zeus.Web.Model.Storage>;
  private storageGroups: Array<Zeus.Web.Model.StorageGroup>;
  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;

  private canAddStorageGroup: boolean = false;
  private canReadStorage: boolean = false;
  private canReadWorkstation: boolean = false;

  constructor(private i18n: I18N, private appModuleService: AppModuleService, private authService: AuthService) {
    let _this = this;
    this.treeConfig = new UITreePanelConfig<UnifiedStorageEntity>({
      parentId: 'parentId',
      label: 'name',
      icon: 'icon',
      withLines: true,
      autoExpand: false,
      isNodeSelectable: node => _this.isNodeSelectable == null || _this.isNodeSelectable({ node })
    });
    this.canAddStorageGroup = this.authService.checkAccess(Constants.EntityTypeNames.StorageGroup, Actions.Create);
    this.canReadStorage = this.authService.checkAccess(Constants.EntityTypeNames.Storage, Actions.Read);
    this.canReadWorkstation = this.authService.checkAccess(DTLConstants.EntityTypeNames.Workstation, Actions.Read);
  }

  public get nodeList() {
    return this.treeList;
  }

  public async attached() {
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.workstationService = new ServiceBase<DropToLight.Model.Workstation>(DTLConstants.EntityTypeNames.Workstation);
    this.workstationBayGroupService = new ServiceBase<DropToLight.Model.WorkstationBayGroup>(DTLConstants.EntityTypeNames.WorkstationBayGroup);
    this.conveyorService = new ServiceBase<Conveyor.Model.ConveyorConfiguration>(ConvConstants.EntityTypeNames.ConveyorConfiguration);

    this.treeConfig.autoExpand = SiteManagementSelectionHelper.isSingleZone;

    if (this.treeConfigActions) {
      this.treeConfig.actions.push(...this.treeConfigActions);
    }

    this.treeList.splice(0, this.treeList.length, ... await this.buildUnifiedTree());

    this.treeSelection.splice(0, this.treeSelection.length);
    if (this.storageId) {
      this.selectStorage(this.storageId);
    } else if (this.storageGroupId) {
      this.selectStorageGroup(this.storageGroupId);
    } else if (this.workstationId) {
      this.selectWorkstation(this.workstationId);
    } else if (this.workstationBayGroupId) {
      this.selectWorkstationBayGroup(this.workstationBayGroupId);
    } else if (this.conveyorId) {
      this.selectConveyor(this.conveyorId);
    }
    this.loadingTree = false;
  }

  private async buildUnifiedTree(): Promise<UnifiedStorageEntity[]> {
    return new Promise(async (resolve) => {
      this.storageGroups = await this.storageGroupService.getEntities();
      this.storages = this.canReadStorage ? await this.storageService.getEntities(null, this.storageExpands.concat('trayContainers')) : [];
      if (this.appModuleService.isActive(AppModuleEnum.DropToLight) && this.canReadWorkstation) {
        this.workstations = await this.workstationService.getEntities();
        this.workstationBayGroups = await this.workstationBayGroupService.getEntities();
      } else {
        this.workstations = [];
        this.workstationBayGroups = [];
      }
      this.conveyors = this.appModuleService.isActive(AppModuleEnum.Conveyor) && this.canReadStorage ? await this.conveyorService.getEntities() : [];
      resolve([...this.storages, ...this.storageGroups, ...this.workstations, ...this.workstationBayGroups, ...this.conveyors].map(x => new UnifiedStorageEntity(x)));
    });
  }

  private async treeSelected(id: string): Promise<boolean> {
    let entry: UnifiedStorageEntity = this.treeList.find(node => node.id == id);
    if (entry == null) {
      return false;
    }

    let mayContinue = true;
    if (entry.storage && this.onStorageSelected) {
      mayContinue = await this.onStorageSelected({ storage: entry.storage });
    } else if (entry.storageGroup && this.onStorageGroupSelected) {
      mayContinue = await this.onStorageGroupSelected({ storageGroup: entry.storageGroup });
    } else if (entry.workstation && this.onWorkstationSelected) {
      mayContinue = await this.onWorkstationSelected({ workstation: entry.workstation });
    } else if (entry.workstationBayGroup && this.onWorkstationBayGroupSelected) {
      mayContinue = await this.onWorkstationBayGroupSelected({ workstationBayGroup: entry.workstationBayGroup });
    } else if (entry.conveyor && this.onConveyorSelected) {
      mayContinue = await this.onConveyorSelected({ conveyor: entry.conveyor });
    }
    if (false == mayContinue) {
      // Reset selection of tree panel
      this.treeSelection.splice(0);
      if (this.storageId) {
        this.treeSelection.push(StorageTreePanelTypeId.Storage + '-' + this.storageId);
      } else if (this.storageGroupId) {
        this.treeSelection.push(StorageTreePanelTypeId.StorageGroup + '-' + this.storageGroupId);
      } else if (this.workstationId) {
        this.treeSelection.push(StorageTreePanelTypeId.Workstation + '-' + this.workstationId);
      } else if (this.workstationBayGroupId) {
        this.treeSelection.push(StorageTreePanelTypeId.WorkstationBayGroup + '-' + this.workstationBayGroupId);
      } else if (this.conveyorId) {
        this.treeSelection.push(StorageTreePanelTypeId.Conveyor + '-' + this.conveyorId);
      }
      return Promise.resolve(false);
    }

    this.storageId = entry.storage?.id;
    this.storageGroupId = entry.storageGroup?.id;
    this.workstationId = entry.workstation?.id;
    this.workstationBayGroupId = entry.workstationBayGroup?.id;
    this.conveyorId = entry.conveyor?.id;
    return Promise.resolve(true);
  }

  public clearSelection() {
    this.storageId = null;
    this.storageGroupId = null;
    this.workstationId = null;
    this.workstationBayGroupId = null;
    this.conveyorId = null;
    this.treeSelection.splice(0);
  }

  public async selectStorage(id: number) {
    this.select(StorageTreePanelTypeId.Storage + '-' + id);
  }

  public async selectStorageGroup(id: number) {
    this.select(StorageTreePanelTypeId.StorageGroup + '-' + id);
  }

  private async select(id: string) {
    if (await this.treeSelected(id)) {
      this.treeSelection.push(id);
    }
  }

  public async onNodeSaved(entity: any) {
    let entry = new UnifiedStorageEntity(entity);
    let index = this.treeList.findIndex(node => node.id == entry.id);
    this.treeList.splice(index, index > -1 ? 1 : 0, entry);
    // If new storage created
    if (index < 0) {
      if (entry.storage) {
        this.storages.push(entity);
      } else if (entry.storageGroup) {
        this.storageGroups.push(entity);
      } else if (entry.workstation) {
        this.workstations.push(entity);
      } else if (entry.workstationBayGroup) {
        this.workstationBayGroups.push(entity);
      } else if (entry.conveyor) {
        this.conveyors.push(entity);
      }
      await this.select(entry.id);
    }
  }

  private customNodesSortFunction(nodeA: UITreeNode, nodeB: UITreeNode) {
    //if one node is from module, so it is at the end
    if (!nodeA.model.isFromModule() && !nodeB.model.isFromModule()) {
      return nodeA.label.toLowerCase() > nodeB.label.toLowerCase() ? 1 : -1;
    }
    if (nodeA.model.isFromModule() && !nodeB.model.isFromModule()) {
      return 1;
    }
    if (!nodeA.model.isFromModule() && nodeB.model.isFromModule()) {
      return -1;
    }

    //nodeA and nodeB are from a module
    if (nodeA.model.isFromModuleConveyor() && nodeB.model.isFromModuleConveyor()) {
      return nodeA.label.toLowerCase() > nodeB.label.toLowerCase() ? 1 : -1;
    }
    if (nodeA.model.isFromModuleConveyor() && !nodeB.model.isFromModuleConveyor()) {
      return -1;
    }
    if (!nodeA.model.isFromModuleConveyor() && nodeB.model.isFromModuleConveyor()) {
      return 1;
    }

    //nodeA and nodeB are from DTL module only
    if (nodeA.model.workstation && nodeB.model.workstation) {
      return nodeA.label.toLowerCase() > nodeB.label.toLowerCase() ? 1 : -1;
    }
    if (nodeA.model.workstationBayGroup && nodeB.model.workstationBayGroup) {
      return nodeA.label.toLowerCase() > nodeB.label.toLowerCase() ? 1 : -1;
    }
    if (nodeA.model.workstationBayGroup) {
      return -1;
    }
    return 1;
  }

  public removeNode(node: UnifiedStorageEntity) {
    this.treeList.splice(this.treeList.findIndex(n => n.id == node.id), 1);
    let selectionIndex = this.treeSelection.findIndex(id => id == node.id);
    if (selectionIndex > -1) {
      this.clearSelection();
    }
  }

  @computedFrom('exportToCsv')
  private get showExportCsv(): boolean {
    return this.exportToCsv != null;
  }

  //#region DTL
  @bindable({ defaultBindingMode: bindingMode.twoWay }) public workstationId: number;
  @bindable private onWorkstationSelected: (args: { workstation: DropToLight.Model.Workstation }) => Promise<boolean>;
  private workstations: Array<DropToLight.Model.Workstation>;
  private workstationService: ServiceBase<DropToLight.Model.Workstation>;

  public async selectWorkstation(id: number) {
    this.select(StorageTreePanelTypeId.Workstation + '-' + id);
  }

  @bindable({ defaultBindingMode: bindingMode.twoWay }) public workstationBayGroupId: number;
  @bindable private onWorkstationBayGroupSelected: (args: { workstationBayGroup: DropToLight.Model.WorkstationBayGroup }) => Promise<boolean>;
  private workstationBayGroups: Array<DropToLight.Model.WorkstationBayGroup>;
  private workstationBayGroupService: ServiceBase<DropToLight.Model.WorkstationBayGroup>;

  public async selectWorkstationBayGroup(id: number) {
    this.select(StorageTreePanelTypeId.WorkstationBayGroup + '-' + id);
  }
  //#endregion

  //#region Conveyor
  @bindable({ defaultBindingMode: bindingMode.twoWay }) public conveyorId: number;
  @bindable private onConveyorSelected: (args: { conveyor: Conveyor.Model.ConveyorConfiguration }) => Promise<boolean>;
  private conveyors: Array<Conveyor.Model.ConveyorConfiguration>;
  private conveyorService: ServiceBase<Conveyor.Model.ConveyorConfiguration>;

  public async selectConveyor(id: number) {
    this.select(StorageTreePanelTypeId.Conveyor + '-' + id);
  }
  //#endregion
}

export class UnifiedStorageEntity {
  public id: string;
  public parentId: string;
  public name: string;
  public icon: string;
  public storageGroup: Zeus.Web.Model.StorageGroup | null;
  public storage: Zeus.Web.Model.Storage | null;
  public workstation: DropToLight.Model.Workstation | null;
  public workstationBayGroup: DropToLight.Model.WorkstationBayGroup | null;
  public conveyor: Conveyor.Model.ConveyorConfiguration | null;

  constructor(entity: Zeus.Web.Model.StorageGroup | Zeus.Web.Model.Storage | DropToLight.Model.Workstation | DropToLight.Model.WorkstationBayGroup | Conveyor.Model.ConveyorConfiguration) {
    this.name = entity.name;
    switch (entity.entityType.shortName) {
      case Constants.EntityTypeNames.Storage:
        this.setStorageParameter(entity as Zeus.Web.Model.Storage);
        break;
      case Constants.EntityTypeNames.StorageGroup:
        this.setStorageGroupParameter(entity as Zeus.Web.Model.StorageGroup);
        break;
      case DTLConstants.EntityTypeNames.Workstation:
        this.setWorkstationParameter(entity as DropToLight.Model.Workstation);
        break;
      case DTLConstants.EntityTypeNames.WorkstationBayGroup:
        this.setWorkstationBayGroupParameter(entity as DropToLight.Model.WorkstationBayGroup);
        break;
      case ConvConstants.EntityTypeNames.ConveyorConfiguration:
        this.setConveyorParameter(entity as Conveyor.Model.ConveyorConfiguration);
        break;
    }
  }

  isFromModuleDTL() {
    return this.workstation != null || this.workstationBayGroup != null;
  }

  isFromModuleConveyor() {
    return this.conveyor != null;
  }

  isFromModule() {
    return this.workstation != null || this.workstationBayGroup != null || this.conveyor != null;
  }

  private setStorageParameter(entity: Zeus.Web.Model.Storage) {
    this.id = StorageTreePanelTypeId.Storage + '-' + entity.id;
    this.parentId = StorageTreePanelTypeId.StorageGroup + '-' + entity.storageGroupId;
    this.icon = entity.storageTypeId == Constants.StorageTypes.Dynamic ? 'ze-bay' : 'ze-shelf';
    this.storage = entity;
  }
  private setStorageGroupParameter(entity: Zeus.Web.Model.StorageGroup) {
    this.id = StorageTreePanelTypeId.StorageGroup + '-' + entity.id;
    this.parentId = entity.parentStorageGroupId ? StorageTreePanelTypeId.StorageGroup + '-' + entity.parentStorageGroupId : null;
    this.storageGroup = entity;
    switch (entity.storageGroupTypeId) {
      case Constants.StorageGroupTypes.Site:
        this.icon = 'digi-departement';
        break;
      case Constants.StorageGroupTypes.Warehouse:
        this.icon = 'digi-stock';
        break;
      case Constants.StorageGroupTypes.ZoneGroup:
        this.icon = 'ze-zone-group';
        break;
      default:
        this.icon = 'ze-zone';
        break;
    }
  }
  private setWorkstationParameter(entity: DropToLight.Model.Workstation) {
    this.id = StorageTreePanelTypeId.Workstation + '-' + entity.id;
    this.parentId = StorageTreePanelTypeId.StorageGroup + '-' + entity.zoneId;
    this.icon = 'ze-workstation';
    this.workstation = entity;
  }
  private setWorkstationBayGroupParameter(entity: DropToLight.Model.WorkstationBayGroup) {
    this.id = StorageTreePanelTypeId.WorkstationBayGroup + '-' + entity.id;
    this.parentId = StorageTreePanelTypeId.StorageGroup + '-' + entity.zoneId;
    this.icon = 'ze-workstation-storage-group';
    this.workstationBayGroup = entity;
  }
  private setConveyorParameter(entity: Conveyor.Model.ConveyorConfiguration) {
    this.id = StorageTreePanelTypeId.Conveyor + '-' + entity.id;
    this.parentId = StorageTreePanelTypeId.StorageGroup + '-' + entity.warehouseId;
    this.icon = 'ze-conveyor-belt';
    this.conveyor = entity;
  }
}

export enum StorageTreePanelTypeId {
  Storage = "Storage",
  StorageGroup = "StorageGroup",
  Workstation = "Workstation",
  WorkstationBayGroup = "WorkstationBayGroup",
  Conveyor = "Conveyor"
}
