import { KebabCase } from "digiwall-lib";

export class Permissions {
  public static WorkOrderBorrowing_AsResource = "WorkOrderTypeBorrowing";
  public static WorkOrderBorrowing = KebabCase.fromCamelCase(Permissions.WorkOrderBorrowing_AsResource);
}

export class Application {
  public static UpdateBorrowingNumber = '/api/Borrowing/Utils/UpdateBorrowingNumber';
  public static UpdateBorrowingReturnNumber = '/api/Borrowing/Utils/UpdateBorrowingReturnNumber';
  public static GetBorrowingStockForArticles = "/api/Borrowing/Utils/GetBorrowingStockForArticles";
  public static GetBorrowingLotNumberForArticle = "/api/Borrowing/Utils/GetBorrowingLotNumberForArticle";
}

export class EntityTypeNames {
  public static ResourceNames = {};
}

export enum OrderSubtype {
  BorrowingReturn = 121,
  Borrowing = 123,
}

export class SequenceNumberKey {
  public static BorrowingReturnNumber = "BorrowingReturnNumber";
  public static BorrowingNumber = "BorrowingNumber";
}
