import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, Various } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';


@autoinject
export class LocationFormatDetail extends EntityDetailViewModelBase<Zeus.Web.Model.LocationFormat> {
  public fromDialogBox: boolean = false;

  public ressourceName: string = Constants.EntityTypeNames.LocationFormat;
  public locationLevelsService: ServiceBase<Zeus.Web.Model.LocationLevel>;

  public fetchedLocationLevels: Zeus.Web.Model.LocationLevel[];

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.LocationFormat>(Constants.EntityTypeNames.LocationFormat));
    this.locationLevelsService = new ServiceBase<Zeus.Web.Model.LocationLevel>(Constants.EntityTypeNames.LocationLevel);
  }

  @computedFrom('entity', 'editingMode', 'entity.isDynamicStorageFormat')
  public get isDynamicLocationFormat(): boolean {
    return this.entity?.isDynamicStorageFormat;
  }

  public async activate(params) {
    await super.activate(params);
    const id = params.param1;

    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      if (params.callback) {
        params.callback(this.entity);
        this.fromDialogBox = true;
      }
    } else {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(id);
      this.controller.addObject(this.entity);
    }

    await this.fetchLocationLevels();
  }

  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("locationformat.addLocationFormat");
    } else {
      return this.entity.name;
    }
  }

  // Parsing all locationLevel and take the max
  public get getMaxLevel() {
    return Math.max.apply(Math, this.fetchedLocationLevels.map(function (o) { return o.level; }));
  }

  public minIsNotNumber(min: any) {
    return isNaN(min?.toString());
  }

  // Display the trash icon
  public displayTrash(level: number, levelId: number) {
    return false == this.isDynamicLocationFormat && (level == this.getMaxLevel || levelId < 0);
  }

  // Display the accordeon if location format saved
  get isSaved() {
    return this.entity.id > 0;
  }

  public async saveCurrentEntity(silentSave?: boolean) {
    if (this.fromDialogBox) {
      await super.saveCurrentEntity();
      this.entity.locationLevels.forEach(location => {
        this.locationLevelsService.saveEntity(location);
      });
    } else {
      await this.save();
    }
    return this.entity;
  }

  public async save() {
    this.entity.locationLevels.forEach((x, i) => x.level = i);
    await super.save();
    this.entity = await this.service.getEntityById(this.entity.id);

    this.loadingLocationLevels = true;
    await this.fetchLocationLevels();
  }

  private loadingLocationLevels: boolean = true;
  public async fetchLocationLevels() {
    if (this.loadingLocationLevels) {
      this.fetchedLocationLevels = await this.locationLevelsService.getEntities(null, null, { locationFormatId: this.entity.id });
      this.parseLevelRanges();

      this.loadingLocationLevels = false;
    }
  }

  public async addLocationLevel() {
    if (this.isDynamicLocationFormat) {
      return;
    }
    let newLocationLevel = await this.locationLevelsService.createEntity();
    newLocationLevel.locationFormatId = this.entity.id;
  }

  public async removeLocationLevel(locationLevel: Zeus.Web.Model.LocationLevel) {
    if (this.isDynamicLocationFormat) {
      return;
    }
    await this.locationLevelsService.deleteEntities([locationLevel], true);
    this.loadingLocationLevels = true;
    await this.fetchLocationLevels();
  }

  public get levelsTitle(): string {
    let format = this.entity?.locationLevels?.map(level => ((level as any).max > 0 ? level.valueRange : '0-0') + (level.separator || '')).join('');
    return this.i18n.tr('locationlevel.locationlevels') + (format ? ': ' + format : '');
  }

  private parseLevelRanges() {
    this.entity.locationLevels?.forEach(level => {
      let matches = /^([^-]+)(-([^-]+))?$/.exec(level.valueRange);
      if (matches) {
        (level as any).min = matches[1];
        if (matches[3]) {
          // Range
          (level as any).max = matches[3];
        } else {
          // Single value
          (level as any).max = (level as any).min;
        }
      }
    });
  }

  public calculateRange(locationLevel: Zeus.Web.Model.LocationLevel | any, minMax: 'min' | 'max') {
    if (minMax == 'min') {
      // Range start changed
      if (this.minIsNotNumber(locationLevel.min)) {
        // Non numeric min
        locationLevel.max = locationLevel.min;
      } else {
        locationLevel.max = Math.max(locationLevel.min, locationLevel.max || 0);
      }
    } else {
      // Range end changed
      if (locationLevel.max) {
        locationLevel.min = Math.min(locationLevel.max, locationLevel.min || 0);
      }
    }
    if (locationLevel.min != null && locationLevel.max != null) {
      (locationLevel as Zeus.Web.Model.LocationLevel).valueRange = locationLevel.min + (locationLevel.min == locationLevel.max ? '' : '-' + locationLevel.max);
    } else {
      (locationLevel as Zeus.Web.Model.LocationLevel).valueRange = null;
    }
  }
} 
