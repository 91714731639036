import moment = require('moment');
import { noView } from 'aurelia-framework';
import { Zeus } from 'generated';
import { Compare } from 'utils/compare';

export class FormatHourValueConverter {
  toView(value: Date) {
    if (value) {
      return moment(value).format('HH:mm');
    }
  }
}

export class SuffixValueConverter {
  toView(value, suffix) {
    if (value) {
      return value + suffix;
    }
    else {
      return '';
    }
  }
}

export class DisplayDecimalValueConverter {
  toView(value: number) {
    if (value != null) {
      return value.toLocaleString('fr-BE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
    else {
      return '';
    }
  }
}

export class SortArrayDescValueConverter {
  toView(array: any, propertyName: string) {
    if (array != null && Array.isArray(array) && array.length > 0) {
      if (array[0][propertyName] instanceof Date) {
        return array.slice(0).sort((a, b) => {
          return Compare.compareDate(b[propertyName], a[propertyName]) ? -1 : 1;
        });
      }
      return array.slice(0).sort((a, b) => {
        return a[propertyName]?.toString().localeCompare(b[propertyName]?.toString(), undefined, { numeric: true, sensitivity: 'base' })
      });
    }
  }
}

