import { Zeus } from 'generated';
import { Permissions } from '../constants';

export class UserUtils {
  private static getPermissionsFromUser(user: Zeus.Web.Model.ZeusUser): string[] {
    let permissions: string[] = user?.roles?.flatMap(x => x.role ? x.role.permissions?.map(x => x.permissionId) : []);
    // Fetched user from currentUser -> check if permissions property exists
    if (permissions?.length == 0 && user.permissions != null) {
      permissions = user.permissions.split(';');
    }
    return permissions;
  }

  public static isUserHelpDesk(user: Zeus.Web.Model.ZeusUser): boolean {
    return this.getPermissionsFromUser(user).some(p => p == Permissions.Helpdesk);
  }
}
