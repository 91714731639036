import { ListViewModelActionHistoryFilter } from './../action-histories/action-history-list';
import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper, EnumerationTypeService } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { ImportXLS, ImportXlsParams, subscribeImportRefresh } from '../import-xls/import-xls';
import { PrintLabel } from 'app-modules/label-printing/print-label/print-label';
import { AppModuleService } from 'app-modules/app-module-service';
import * as AMConstants from 'app-modules/constants';
import * as LPConstants from 'app-modules/label-printing/constants';
import { Endpoints } from 'endpoints';

@autoinject
export class ArticleList extends ListViewModelBase<Zeus.Web.Model.Article> {
  public ressourceName: string = Constants.EntityTypeNames.Article;
  private labelPrintingActive: boolean = false;
  private borrowingActive: boolean = false;

  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private dialogService: DialogService, private appModuleService: AppModuleService) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.Article>(Constants.EntityTypeNames.Article));
    this.service.gridDataSource.expands = ['articleLocations', 'unitOfMeasures', 'articleVolumeConfigs.storageVolume.volumeType', 'articleBlock', 'articleStorageGroups.storageStrategy',
      'articleStorageGroups.storageLocationType1', 'articleStorageGroups.storageLocationType2', 'articleStorageGroups.storageLocationType3', 'articleStorageGroups.articleRotationLevel',
      'articleStorageGroups.inputAlgorithm'];

    this.labelPrintingActive = this.appModuleService.isActive(AMConstants.AppModuleEnum.LabelPrinting);
    this.borrowingActive = this.appModuleService.isActive(AMConstants.AppModuleEnum.Borrowing);
  }

  public async activate(params) {
    this.initializeGridOptions();
  }

  async attached() {
    await super.attached();
    this.disposables.push(subscribeImportRefresh(this.gridOptions, Constants.DataFileImportType.Article));
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.Article) {
    if (entity && entity.id) {
      return '/articles/' + entity.id;
    }
    return null;
  }

  public importArticles(isPatch: boolean) {
    let params: ImportXlsParams = {
      type: Constants.EntityTypeNames.Article,
      title: isPatch ? 'article.dataEnrichmentArticle' : 'article.importFile',
      patternUrl: Endpoints.ImportXlsx.GetArticleXLSPattern,
      importUrl: isPatch ? Endpoints.ImportXlsx.UpdateArticleXLS : Endpoints.ImportXlsx.ImportArticleXLS,
      folderName: 'article_imports',
      isForPatch: isPatch
    };
    this.dialogService.open({ viewModel: ImportXLS, model: params }).whenClosed((result) => {
      if (!result.wasCancelled && result.output as boolean) {
        this.gridOptions.api.refreshInfiniteCache();
      }
    });
  }

  public openActionHistory(): void {
    if (this.selectedEntities.length > 0) {
      let ids = this.selectedEntities.map(e => e.id);
      let filter = new ListViewModelActionHistoryFilter('article', ids);
      this.navigateTo('action-history/all/' + filter.encode());
    }
  }

  public async printArticleLabels() {
    await this.dialogService.open({
      viewModel: PrintLabel, model: { labelTypeId: LPConstants.LabelTypeId.Article, articles: await this.getSelectedEntitiesFromCache() }
    });
  }

  public getDataGridColumns() {
    const storageLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageLocationType);
    const articleRotationLevelService = new EnumerationTypeService(Constants.EnumerationTypes.ArticleRotationLevel);
    const inputAlgorithmService = new ServiceBase<Zeus.Web.Model.InputAlgorithm>(Constants.EntityTypeNames.InputAlgorithm);
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("article.reference"),
        field: "reference",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("article.quantity"),
        field: "stock",
        type: FieldType.String,
        filter: null,
        sortable: false
      },
      {
        headerName: this.i18n.tr("article.volume"),
        field: "volume",
        type: FieldType.String,
        filter: null,
        sortable: false
      },
      {
        headerName: this.i18n.tr("article.barcodeEan"),
        field: "barcodeEan",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("article.barcodeEans"),
        field: "barcodeEans",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.referenceAliasTags"),
        field: "referenceAliasTags",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.description") + '(FR)',
        field: "description.lang1",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.description") + '(NL)',
        field: "description.lang2",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.description") + '(EN)',
        field: "description.lang3",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.info") + '(FR)',
        field: "info.lang1",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.info") + '(NL)',
        field: "info.lang2",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.info") + '(EN)',
        field: "info.lang3",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.defaultQuantityWhenOrdering"),
        field: "defaultQuantityWhenOrdering",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.hasLotNumber"),
        field: "hasLotNumber",
        type: FieldType.Boolean,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.hasExpirationDate"),
        field: "hasExpirationDate",
        type: FieldType.Boolean,
        hide: true
      },
    ];

    if (this.borrowingActive) {
      defs.push({
        headerName: this.i18n.tr("article.canBeBorrowed"),
        field: "canBeBorrowed",
        type: FieldType.Boolean,
        hide: true
      });
    }

    defs.push(
      {
        headerName: this.i18n.tr("article.articleBlockId"),
        field: "articleBlock.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.InventoryBlock
        }
      },
      {
        headerName: this.i18n.tr("article.active"),
        field: "active",
        type: FieldType.Boolean,
        hide: true
      },
      {
        headerName: this.i18n.tr("articlestoragegroup.storageStrategyId"),
        field: "articleStorageGroups.storageStrategy.denomination._translation",
        cellRenderer: 'manyToManyTagRenderer',
        type: FieldType.ManyToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          serviceName: Constants.EnumerationTypes.StorageStrategy
        },
        hide: true
      },
      {
        headerName: this.i18n.tr("article.storageLocationType1Id"),
        field: "articleStorageGroups.storageLocationType1.denomination._translation",
        cellRenderer: "manyToManyTagRenderer",
        type: FieldType.ManyToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: storageLocationTypeService
        },
        hide: true
      },
      {
        headerName: this.i18n.tr("article.storageLocationType2Id"),
        field: "articleStorageGroups.storageLocationType2.denomination._translation",
        type: FieldType.ManyToMany,
        cellRenderer: "manyToManyTagRenderer",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: storageLocationTypeService
        },
        hide: true
      },
      {
        headerName: this.i18n.tr("article.storageLocationType3Id"),
        field: "articleStorageGroups.storageLocationType3.denomination._translation",
        type: FieldType.ManyToMany,
        cellRenderer: "manyToManyTagRenderer",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: storageLocationTypeService
        },
        hide: true
      },
      {
        headerName: this.i18n.tr("articlestoragegroup.articleRotationLevelId"),
        field: "articleStorageGroups.articleRotationLevel.denomination._translation",
        type: FieldType.ManyToMany,
        cellRenderer: "manyToManyTagRenderer",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: articleRotationLevelService
        },
        hide: true
      },
      {
        headerName: this.i18n.tr("article.maxQuantityDefaultVolume"),
        field: "maxQuantityDefaultVolume",
        type: FieldType.Number,
        filter: false,
        sortable: false,
        hide: true
      },
      {
        headerName: this.i18n.tr("articlestoragegroup.inputAlgorithm"),
        field: "articleStorageGroups.inputAlgorithm.name._translation",
        type: FieldType.ManyToMany,
        cellRenderer: "manyToManyTagRenderer",
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: inputAlgorithmService
        },
        hide: true
      },
      {
        headerName: this.i18n.tr("articleunitofmeasuresize.baseUOM"),
        field: "baseUOM",
        hide: true
      },
      {
        headerName: this.i18n.tr("article.inputUOM"),
        field: "inputUOM",
        hide: true
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    );
    return defs;
  }
} 
