import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Conveyor } from "../generated";
import * as ConvConstants from "../constants";
import * as Constants from "../../../constants";
import { ZeusHubClient } from 'external-src/zeus-hub-client';

@autoinject
export class BinOverview extends ListViewModelBase<Conveyor.Model.ConveyorBin> {
  public ressourceName: string = ConvConstants.EntityTypeNames.ConveyorBin;

  constructor(router: Router, logger: CustomLogger, private zeusHub: ZeusHubClient) {
    super(router, logger, new ServiceBase<Conveyor.Model.ConveyorBin>(ConvConstants.EntityTypeNames.ConveyorBin));
    this.service.gridDataSource.expands = ["conveyor", "workOrder.workOrderPriority", "workOrder.workOrderType", "workOrder.orderSubtype", "workstation", "workstationPosition"];
  }

  public async attached() {
    this.disposables.push(
      this.zeusHub.onRefreshBinOverview(() => this.gridOptions?.api?.onFilterChanged())
    );
  }

  public getDataGridColumns() {
    return [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("conveyorbin.binNumber"),
        field: "binNumber",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("conveyorbin.lastUpdateDate"),
        field: "lastUpdateDate",
        type: FieldType.DateTime,
        floatingFilterComponentParams: {
          dateFormat: 'DD/MM/YYYY, HH:mm:ss'
        },
      },
      {
        headerName: this.i18n.tr("conveyorbin.exitStation"),
        field: "exitStation",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("conveyorbin.archived"),
        field: "archived",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("conveyorconfiguration.conveyorconfiguration"),
        field: "conveyor.name",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("workorder.workOrderName"),
        field: "workOrder.workOrderName",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("workorder.workOrderPriorityId"),
        field: "workOrder.workOrderPriority.name._translation",
        cellRenderer: 'enumerationRenderer',
        type: FieldType.OneToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          serviceName: Constants.EntityTypeNames.WorkOrderPriority,
        }
      },
      {
        headerName: this.i18n.tr("workorder.workOrderTypeId"),
        field: "workOrder.workOrderType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.WorkOrderType,
        },
      },
      {
        headerName: this.i18n.tr("workorder.orderSubtypeId"),
        field: "workOrder.orderSubtype.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.OrderSubtype,
        },
      },
      {
        headerName: this.i18n.tr("conveyorbin.workstationId"),
        field: "workstation.name",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("conveyorbin.workstationPositionId"),
        field: "workstationPosition.positionID",
        type: FieldType.Number,
      },
    ];
  }
} 
