import { HttpClient } from 'aurelia-fetch-client';
import { Container } from 'aurelia-framework';
import { Endpoints } from 'endpoints';

export class StorageUtils {
  public static async isStorageGroupAllowed(userId: number, storageGroupId: number) {
    let httpClient = Container.instance.get(HttpClient);
    let response: Response = await httpClient.fetch(Endpoints.StorageGroup.IsStorageGroupAllowed + '?userId=' + userId + '&storageGroupId=' + storageGroupId);
    if (response.ok) {
      let result = await response.json();
      return result;
    }
    return false;
  }
}
