import { FilterQueryOp, Predicate } from 'breeze-client';
import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum, Various, ActionDialogBoxInputParameters } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { DataFormat } from 'select2';
import * as toastr from 'toastr';
import { Utils } from 'utils/utils';
import { DialogCloseResult } from 'aurelia-dialog';
import { LocationUtils } from '../utils/utils-locations';

@autoinject
export class PositionDetail extends EntityDetailViewModelBase<Zeus.Web.Model.TrayTemplatePosition> {

  private locationVolumeService: ServiceBase<Zeus.Web.Model.LocationVolume>;
  private storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  private applicationParameterService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;

  public ressourceName: string = Constants.EntityTypeNames.Location;

  private trayLocations: Array<Zeus.Web.Model.TrayTemplatePosition> = new Array<Zeus.Web.Model.TrayTemplatePosition>();

  private applicationParameter: Zeus.Web.Model.ApplicationParameters;

  private storageVolume: DataFormat;
  private positionX: number;
  private positionXAlias: number;
  private positionY: number;
  private positionYAlias: number;
  public fromDialogBox: boolean = false;
  private originalValues: {
    locationIdentifier: string;
    storageUnitWidth: number | null;
    storageUnitLength: number | null;
    storageUnitHeight: number | null;
  };

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.TrayTemplatePosition>(Constants.EntityTypeNames.TrayTemplatePosition));

    this.locationVolumeService = new ServiceBase<Zeus.Web.Model.LocationVolume>(Constants.EntityTypeNames.LocationVolume);

    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];

    this.applicationParameterService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);
  }

  public async activate(params) {
    await super.activate(params);
    const id = params.param1;

    this.applicationParameter = await this.applicationParameterService.firstEntity();

    if (id == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      if (params.callback) {
        params.callback(this);
      }
    } else {
      this.editingMode = EditingModeEnum.Update;

      if (params.callback) {
        params.callback(this);
      }
      if (this.entity.id > 0) {
        this.entity = await this.service.getEntityById(id, ...['defaultStorageVolume', this.fromDialogBox ? null : 'trayTemplate.storage'].filter(x => x));
      }

      this.storageVolume = {
        id: this.entity.defaultStorageVolumeId,
        text: this.entity.defaultStorageVolume.volumeType.denomination._translation
      } as DataFormat;

      [this.positionX, this.positionY] = LocationUtils.getIntCoordFromIdentifier(this.entity.locationIdentifier);
      [this.positionXAlias, this.positionYAlias] = LocationUtils.getIntCoordFromIdentifier(this.entity.locationIdentifierAlias);

      this.controller.addObject(this.entity);

      this.originalValues = {
        locationIdentifier: this.entity.locationIdentifier,
        storageUnitWidth: this.entity.storageUnitWidth,
        storageUnitLength: this.entity.storageUnitLength,
        storageUnitHeight: this.entity.storageUnitHeight,
      };
    }

    if (this.isDialogView) {
      this.dialogRightActions.push(
        { label: "menu.save", icon: "digi-save", closeDialog: true, fn: "beforeSaveModal", theme: "success", type: 'solid' } as ActionDialogBoxInputParameters
      );
    }
  }

  @computedFrom('editingMode', 'entity.locationIdentifier')
  public get documentTitle() {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr("location.location");
    } else if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.locationIdentifier;
    }
  }

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.entity, "defaultStorageVolumeId").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.storageUnitWidth = this.entity.defaultStorageVolume.width;
          this.entity.storageUnitLength = this.entity.defaultStorageVolume.depth;
        }
      }),
      this.bindingEngine.propertyObserver(this, "positionX").subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.locationIdentifier = this.getLocationIdentifier(this.positionX, this.positionY);
        }
      }),
      this.bindingEngine.propertyObserver(this, "positionY").subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.locationIdentifier = this.getLocationIdentifier(this.positionX, this.positionY);
        }
      }),
      this.bindingEngine.propertyObserver(this, "positionXAlias").subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.locationIdentifierAlias = this.getLocationIdentifier(this.positionXAlias, this.positionYAlias);
        }
      }),
      this.bindingEngine.propertyObserver(this, "positionYAlias").subscribe((newValue, oldValue) => {
        if (newValue != oldValue && newValue != null) {
          this.entity.locationIdentifierAlias = this.getLocationIdentifier(this.positionXAlias, this.positionYAlias);
        }
      })
    );
  }

  canDeactivate(): Promise<boolean> {
    // If we are here, we are in a cancel
    if (this.editingMode == EditingModeEnum.Update) {
      Object.assign(this.entity, this.originalValues);
    }
    return new Promise(resolve => resolve(true));
  }

  private getLocationIdentifier(posX, posY): string {
    if (posX!= null && posY != null && !Utils.hasDecimals(posX) && !Utils.hasDecimals(posY)) {
      if (this.applicationParameter.leadingZeros != null) {
        return this.padStartElem(posX) + "." + this.padStartElem(posY);
      }
      else {
        return posX + "." + posY;
      }
    }
    else {
      return null;
    }
  }

  private padStartElem(str): string {
    if (!isNaN(str)) {
      str = str.toString();
    }
    return str.padStart(this.applicationParameter.leadingZeros, "0");
  }

  public async beforeSaveModal() {
    let rslt: DialogCloseResult = { wasCancelled: true };
    let canSave = await this.beforeSave();
    if (typeof (canSave) != "boolean") {
      canSave = false;
    }
    rslt.wasCancelled = !canSave;
    return rslt;
  }

  protected async beforeSave(): Promise<boolean | void> {
    if (isNaN(this.positionX) || Utils.hasDecimals(this.positionX)) {
      toastr.error(this.i18n.tr("location.invalidPositionX"));
      return false;
    }

    if (isNaN(this.positionY) || Utils.hasDecimals(this.positionY)) {
      toastr.error(this.i18n.tr("location.invalidPositionY"));
      return false;
    }

    if (this.positionXAlias && (isNaN(this.positionXAlias) || Utils.hasDecimals(this.positionXAlias))) {
      toastr.error(this.i18n.tr("location.invalidPositionXAlias"));
      return false;
    }

    if (this.positionYAlias && (isNaN(this.positionYAlias) || Utils.hasDecimals(this.positionYAlias))) {
      toastr.error(this.i18n.tr("location.invalidPositionYAlias"));
      return false;
    }

    if (this.positionX < 1 || this.positionX > this.entity.trayTemplate.trayWidthCm) {
      toastr.error(this.i18n.tr("location.positionXOutOfTray"));
      return false;
    }

    if (this.positionY < 1 || this.positionY > this.entity.trayTemplate.trayDepthCm) {
      toastr.error(this.i18n.tr("location.positionYOutOfTray"));
      return false;
    }

    if (isNaN(this.entity.storageUnitWidth) || this.entity.storageUnitWidth < 1
      || this.positionX - (this.entity.storageUnitWidth / 2) < 0 || this.positionX + (this.entity.storageUnitWidth / 2) > this.entity.trayTemplate.trayWidthCm) {
      toastr.error(this.i18n.tr("location.widthOutOfTray"));
      return false;
    }

    if (isNaN(this.entity.storageUnitLength) || this.entity.storageUnitLength < 1
      || this.positionY - (this.entity.storageUnitLength / 2) < 0 || this.positionY + (this.entity.storageUnitLength / 2) > this.entity.trayTemplate.trayDepthCm) {
      toastr.error(this.i18n.tr("location.lengthOutOfTray"));
      return false;
    }

    if (this.entity.defaultStorageVolumeId == null) {
      toastr.error(this.i18n.tr("location.volumeMandatory"));
      return false;
    }

    if (this.entity.storageUnitWidth == null) {
      toastr.error(this.i18n.tr("location.widthMandatory"));
      return false;
    }

    if (this.entity.storageUnitLength == null) {
      toastr.error(this.i18n.tr("location.lengthMandatory"));
      return false;
    }

    if (await this.service.getCount(new Predicate("locationIdentifier", FilterQueryOp.Equals, this.entity.locationIdentifier)
      .and(new Predicate("trayTemplateId", FilterQueryOp.Equals, this.entity.trayTemplateId))
      .and(new Predicate("id", FilterQueryOp.NotEquals, this.entity.id))) > 0) {
      toastr.error(this.i18n.tr("location.locationAlreadyExists"));
      return false;
    }

    return true;
  }
}
