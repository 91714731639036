import { bindable, customElement } from "aurelia-templating";
import { Box, ServiceBase } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../../../constants';
import * as FPConstants from '../constants';
import { InventoryTrayContainerFloatingBox } from "work-orders/floating-box/inventory/inventory-trayContainer-floating-box";
import { I18N } from "aurelia-i18n";
import { Container } from "aurelia-framework";
import { BindingEngine, computedFrom, Disposable } from "aurelia-binding";
import { EntityState, EntityStateSymbol, FilterQueryOp, Predicate } from "breeze-client";
import { FreePicking } from "../generated";

@customElement('tray-pick-work-order')
export class TrayPickWorkOrder {
  @bindable
  private isModifiable: boolean;
  @bindable
  protected workOrder: Zeus.Web.Model.WorkOrder;
  @bindable
  protected saveWorkOrder: () => Promise<boolean>;
  @bindable
  private onLoaded: () => void;

  protected box: Box;
  protected i18n: I18N;
  protected bindingEngine: BindingEngine;
  protected disposables: Disposable[] = [];
  protected service: ServiceBase<FreePicking.Model.TrayPickWorkOrder>;
  protected locationsService: ServiceBase<Zeus.Web.Model.Location>;

  protected trayPicks: FreePicking.Model.TrayPickWorkOrder[] = [];

  constructor() {
    this.box = Container.instance.get(Box);
    this.i18n = Container.instance.get(I18N);
    this.bindingEngine = Container.instance.get(BindingEngine);
    this.service = new ServiceBase<FreePicking.Model.TrayPickWorkOrder>(FPConstants.EntityTypeNames.TrayPickWorkOrder);
    this.locationsService = new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location);
    this.disposables.push(
      this.bindingEngine.expressionObserver(this, 'workOrder.entityAspect.entityState').subscribe((state: EntityStateSymbol) => {
        console.debug("InventoryBaseActions - state ", state);
        if (state == EntityState.Unchanged) {
          this.reload();
        } else {
          console.warn("InventoryBaseActions onreload not triggered");
        }
      }),
    );
  }

  private async reload() {
    let links = await this.service.getEntities(new Predicate("workOrderId", FilterQueryOp.Equals, this.workOrder.id), ['trayContainer.storage']);

    for (let link of links) {
      this.setIsCancellable(link);
      await this.setNumberOfLocations(link);
    }

    this.trayPicks.splice(0, this.trayPicks.length, ...links);
    this.workOrder.trayPicks.splice(0, this.workOrder.trayPicks.length, ...links);
    if (this.onLoaded) {
      this.onLoaded();
    }
  }

  private markNbrLocationsLoading(link: any) {
    link.nbrLocations = this.i18n.tr('common:select2.loadingMore');
  }

  private detached() {
    this.disposables.forEach(x => x.dispose());
  }

  protected async setNumberOfLocations(entity: FreePicking.Model.TrayPickWorkOrder) {
    (entity as any).nbrLocations = await this.locationsService.getCount(new Predicate('trayContainerId', FilterQueryOp.Equals, entity.trayContainerId));
  }

  protected getRowClass(inv: FreePicking.Model.TrayPickWorkOrder) {
    return inv.hasBeenCancelled ? 'work-order-line-cancelled' : '';
  }

  private async openAdd() {
    if (this.workOrder.id <= 0) {
      let saveOk = await this.saveWorkOrder();
      if (saveOk == false) {
        return;
      }
    }
    this.box.showFloatingBox(new InventoryTrayContainerFloatingBox(this.workOrder, this.workOrder.trayPicks.map(x => x.trayContainerId), true)).whenClosed(async response => {
      if (false == response.wasCancelled && response.output != null) {
        let newLink: Zeus.Web.Model.InventoryWorkOrderTrayContainer = response.output;
        let newTrayPick = await this.service.createEntity({
          trayContainerId: newLink.trayContainerId,
          trayContainer: newLink.trayContainer,
          workOrderId: newLink.workOrderId ?? this.workOrder.id
        });
        newLink.entityAspect.setDetached();
        await this.service.saveEntity(newTrayPick, true);

        (newTrayPick as any).nbrLocations = this.i18n.tr('common:select2.loadingMore');

        // Fetch nbr of locations
        await this.service.getEntityById(newTrayPick.id, 'trayContainer.storage');
        this.setIsCancellable(newTrayPick);
        this.trayPicks.push(newTrayPick);
        this.workOrder.trayPicks.push(newTrayPick);
        this.setNumberOfLocations(newTrayPick);
      }
    });
  }

  private async removeLink(entity: FreePicking.Model.TrayPickWorkOrder) {
    let index = this.trayPicks.findIndex(x => x == entity);
    await this.service.deleteEntities([entity], true);
    this.trayPicks.splice(index, 1);
    this.workOrder.trayPicks.splice(index, 1);
  }

  @computedFrom('workOrder.workOrderStatusId')
  private get cancellable(): boolean {
    return this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.ReadyToBeProcessed ||
      this.workOrder.workOrderStatusId == Constants.WorkOrderStatus.PartiallyProcessed;
  }

  private async cancelLink(entity: FreePicking.Model.TrayPickWorkOrder) {
    entity.hasBeenCancelled = true;
    await this.service.saveEntity(entity);
    this.setIsCancellable(entity);
  }

  private setIsCancellable(entity: FreePicking.Model.TrayPickWorkOrder): FreePicking.Model.TrayPickWorkOrder {
    (entity as any).isCancellable = false == entity.hasBeenCancelled
    return entity;
  }
}
