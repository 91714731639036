import { WorkOrderUtils } from 'utils/work-order-utils';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { CustomLogger, ServiceBase, ViewModelBase, UIInternal, DialogBoxViewModel, ActionDialogBoxInputParameters, GlobalLoaderService } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, bindable, BindingEngine, computedFrom } from 'aurelia-framework';
import { Zeus } from "../../generated";
import * as Constants from '../../constants';
import { ApiService } from 'external-src/api.service';

@autoinject
export class InventoryToProcessList extends ViewModelBase {
  private Constants = Constants;
  @bindable
  public workOrderSelected: Array<Zeus.Web.Model.WorkOrder>;

  public inventoryElements: Zeus.Web.Model.InventoryWorkOrderManyToMany[] = [];
  public cycleCountElements: Zeus.Web.Model.WorkOrder[] = [];
  private elements: Array<(Zeus.Web.Model.InventoryWorkOrderManyToMany | Zeus.Web.Model.WorkOrder) & { isSelected?: boolean }> = [];

  public paginationChange: boolean = false;

  public workOrderService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  public isLaunchingMessage = false;

  private inventoryService: ServiceBase<Zeus.Web.Model.InventoryWorkOrderManyToMany>;
  private inventoryArticleService: ServiceBase<Zeus.Web.Model.InventoryWorkOrderArticle>;
  private inventoryStorageService: ServiceBase<Zeus.Web.Model.InventoryWorkOrderStorage>;
  private inventoryTrayContainerService: ServiceBase<Zeus.Web.Model.InventoryWorkOrderTrayContainer>;

  get ribbonHeaderText(): string {
    return "Inventory";
  }
  get ressourceName(): string {
    return null;
  }

  get disableLaunchMessage(): boolean {
    return this.elements.find((x: any) => x.isSelected) == null;
  }

  @computedFrom(...['isLaunchingMessage'])
  public get isBusy() {
    return this.isLaunchingMessage;
  }

  constructor(router: Router, logger: CustomLogger, private apiService: ApiService, private bindingEngine: BindingEngine, private globalLoaderService: GlobalLoaderService) {
    super(router, logger);
    this.workOrderService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.inventoryService = new ServiceBase<Zeus.Web.Model.InventoryWorkOrderManyToMany>(Constants.EntityTypeNames.InventoryWorkOrderManyToMany);

    this.inventoryArticleService = new ServiceBase<Zeus.Web.Model.InventoryWorkOrderArticle>(Constants.EntityTypeNames.InventoryWorkOrderArticle);
    this.inventoryStorageService = new ServiceBase<Zeus.Web.Model.InventoryWorkOrderStorage>(Constants.EntityTypeNames.InventoryWorkOrderStorage);
    this.inventoryTrayContainerService = new ServiceBase<Zeus.Web.Model.InventoryWorkOrderTrayContainer>(Constants.EntityTypeNames.InventoryWorkOrderTrayContainer);
  }

  private updateLock = false;
  public attached() {
    this.disposables.push(
      this.bindingEngine.collectionObserver(this.workOrderSelected).subscribe(() => this.updateList())
    );
    this.updateList();
  }

  private async updateList() {
    if (this.updateLock) {
      setTimeout(() => this.updateList(), 100);
      return;
    }
    this.updateLock = true;
    this.globalLoaderService.show();
    let workOrdersById = Object.assign({}, ...this.workOrderSelected.map(x => ({ [x.id]: { shouldFetch: true, workOrder: x } })));

    // Reverse loop for removing entries no longer in the selected workOrders and tagging those we still have
    for (let i = this.inventoryElements.length - 1; i >= 0; --i) {
      if (null == workOrdersById[this.inventoryElements[i].workOrderId]) {
        this.inventoryElements.splice(i, 1);
      } else {
        workOrdersById[this.inventoryElements[i].workOrderId].shouldFetch = false;
      }
    }

    let newElements = [];

    let predicate = this.loadNewManyToMany(workOrdersById, Constants.InventoryTarget.Article);
    if (predicate !== null) {
      this.pushNewElements(newElements, (await this.inventoryArticleService.getEntities(predicate, ['article.articleLocations.storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup', 'article.articleLocations.location.trayContainer', 'locationForInventories'])).map(inv => {
        let filter: (al: Zeus.Web.Model.ArticleLocation) => boolean;
        if (inv.lotNumber?.length > 0) {
          filter = al => al.lotNumber === inv.lotNumber;
        } else {
          filter = al => true;
        }

        let locations = inv.article.articleLocations.filter(filter).filter(al =>
          !inv.workOrder.limitedToStorageGroupId || al.storage.storageGroupId == inv.workOrder.limitedToStorageGroupId || al.storage.storageGroup.parentStorageGroupId == inv.workOrder.limitedToStorageGroupId
          || al.storage.storageGroup.parentStorageGroup.parentStorageGroupId == inv.workOrder.limitedToStorageGroupId || al.storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroupId == inv.workOrder.limitedToStorageGroupId
        );
        this.setNbrLocationsFromArticleLocationList(inv, locations);
        return inv;
      }));
    }
    predicate = this.loadNewManyToMany(workOrdersById, Constants.InventoryTarget.ArticleLocations);
    if (predicate !== null) {
      this.pushNewElements(newElements, (await this.inventoryArticleService.getEntities(predicate, [
        'article.articleLocations.storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup',
        'locationForInventories.location.trayContainer', 'locationForInventories.location.storage'])).map(inv => {
          this.setNbrLocationsFromArticleLocationList(inv, inv.locationForInventories);
          return inv;
        }));
      let result = await WorkOrderUtils.verifyLocationsForInventory(newElements);
      result?.forEach(x => {
        newElements.find(e => e.id == x.Id).hasError = x.HasError;
      });
    }
    predicate = this.loadNewManyToMany(workOrdersById, Constants.InventoryTarget.Storage);
    if (predicate !== null) {
      this.pushNewElements(newElements, (await this.inventoryStorageService.getEntities(predicate, ['storage.articleLocations.location.trayContainer', 'locationForInventories'])).map(inv => {
        this.setNbrLocationsFromArticleLocationList(inv, inv.storage.articleLocations);
        return inv;
      }));
    }
    predicate = this.loadNewManyToMany(workOrdersById, Constants.InventoryTarget.TrayContainer);
    if (predicate !== null) {
      this.pushNewElements(newElements, (await this.inventoryTrayContainerService.getEntities(predicate, ['trayContainer.storage', 'trayContainer.locations.articleLocations', 'locationForInventories'])).map(inv => {
        let locations = inv.trayContainer.locations?.reduce((articleLocations: Zeus.Web.Model.ArticleLocation[], location: Zeus.Web.Model.Location) => {
          if (location.articleLocations?.length > 0) {
            articleLocations.push(...location.articleLocations);
          }
          return articleLocations;
        }, []);
        this.setNbrLocationsFromArticleLocationList(inv, locations);
        return inv;
      }));
    }
    this.inventoryElements.push(...newElements);
    this.elements.splice(0);
    this.cycleCountElements.splice(0);

    let cycleCountWos = this.workOrderSelected.filter(x => x.inventoryTargetId == Constants.InventoryTarget.CycleCount);
    if (cycleCountWos.length > 0) {
      this.cycleCountElements.push(... await this.workOrderService.getEntities(
        Predicate.or(...cycleCountWos.map(x => new Predicate("id", FilterQueryOp.Equals, x.id))),
        ['cycleCountParameter']
      ));
    }
    this.elements.push(...this.inventoryElements, ...this.cycleCountElements);
    this.elements.forEach(entry => entry.isSelected = true);
    this.globalLoaderService.hide();
    this.updateLock = false;
  }
  private getRowClass(record) {
    return record?.hasError ? 'wo-inventory-error' : '';
  }

  private loadNewManyToMany(workOrdersById: any, targetId: number): Predicate {
    let byWorkOrder = Object.keys(workOrdersById)
      .filter((woId: string) => workOrdersById[woId].shouldFetch && workOrdersById[woId].workOrder.inventoryTargetId == targetId)
      .map((woId: string) => new Predicate('workOrderId', FilterQueryOp.Equals, parseInt(woId)));

    return byWorkOrder.length > 0 ? new Predicate('hasBeenCancelled', FilterQueryOp.Equals, false).and(Predicate.or(byWorkOrder)) : null;
  }

  private pushNewElements(newElements: Zeus.Web.Model.InventoryWorkOrderManyToMany[], toPush: Zeus.Web.Model.InventoryWorkOrderManyToMany[]) {
    newElements.push(...toPush.map(inv => {
      if (inv.locationForInventories?.length > 0) {
        // Sent
        (<any>inv).nbrLocations = this.getNbElementsByDistinct(inv.locationForInventories);
        let toSend = inv.locationForInventories.filter(lfi => lfi.msgHasBeenSent === false || (lfi.isResponseReceived === false && lfi.countedQuantity == null));
        (<any>inv).nbrLocationsToSend = this.getNbElementsByDistinct(toSend);
        let unavailable = toSend.filter(lfi => lfi.location.storage.isUnavailable || lfi.location.trayContainer.isUnavailable);
        (<any>inv).nbrUnavailableLocations = this.getNbElementsByDistinct(unavailable);
      }
      return inv;
    })
      // Remove those that have been sent but 0 remain to send (hence the triple ==)      
      .filter((inv: any) => inv.nbrLocationsToSend !== 0));
  }

  private getNbElementsByDistinct(locations: Zeus.Web.Model.LocationForInventory[]): number {
    return locations.map(item => item.locationId).filter((value, index, self) => self.indexOf(value) === index).length;
  }

  private setNbrLocationsFromArticleLocationList(inv: any, locations: { location: Zeus.Web.Model.Location }[]) {
    inv.nbrUnavailableLocations = locations.filter(x => x.location.storage.isUnavailable || x.location.trayContainer.isUnavailable).length;
    inv.nbrLocations = locations.length - inv.nbrUnavailableLocations;
  }

  private toggleSelection(entry: any) {
    entry.isSelected = !entry.isSelected;
  }

  public async launchMessage() {
    if (this.isLaunchingMessage || this.disableLaunchMessage) return;
    let invSelected = this.inventoryElements.filter((inv: any) => inv.isSelected);
    let result = Constants.ActionInvWOError.launch;
    if (invSelected.some((inv: any) => inv.hasError)) {
      result = await this.continueLaunchWithError(invSelected);
    }
    if (result == Constants.ActionInvWOError.launch) {
      await this.sendMessage(invSelected);
    } else if (result == Constants.ActionInvWOError.cancel) {
      const workOrdersToCancelId: Array<number> = invSelected
        .filter((x: any) => x.hasError)
        .map(x => x.workOrderId)
        .filter((value, index, array) => array.indexOf(value) === index);
      const woLinesToCancel = invSelected.filter(x => workOrdersToCancelId.includes(x.workOrderId));
      await this.cancel(woLinesToCancel);
      const woLinesToSend = invSelected.filter(x => false == workOrdersToCancelId.includes(x.workOrderId));
      if (woLinesToSend.length > 0) {
        await this.sendMessage(woLinesToSend);
      }
    } else if (result == Constants.ActionInvWOError.edit) {
      this.router.navigate("/work-orders/" + this.inventoryElements[0].workOrderId);
    }
  }

  private async continueLaunchWithError(invSelected: Zeus.Web.Model.InventoryWorkOrderManyToMany[]): Promise<Constants.ActionInvWOError> {
    const hasManyWO = invSelected.groupBy("workOrderId").size > 1;
    let buttons: ActionDialogBoxInputParameters[] = [
      {
        label: "workordertoprocess.errorInvArticleLocLine." + (hasManyWO ? "cancelAll" : "cancel"),
        theme: "danger",
        type: "tool",
        fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(Constants.ActionInvWOError.cancel),
      },
      {
        label: "workordertoprocess.errorInvArticleLocLine.launch",
        theme: "primary",
        type: "solid",
        fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(Constants.ActionInvWOError.launch),
      },
    ];
    if (!hasManyWO) {
      buttons.splice(1, 0,
        {
          label: "workordertoprocess.errorInvArticleLocLine.edit",
          theme: "primary",
          type: "tool",
          fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(Constants.ActionInvWOError.edit),
        },
      );
    }
    let result = await (await this.box.showQuestion(
      this.i18n.tr("workordertoprocess.errorInvArticleLocLine.questionToAsk"),
      this.i18n.tr("workorder.inventory"),
      buttons
    )).closeResult;
    if (!result.wasCancelled) {
      return result.output;
    }
    return null;
  }

  private async sendMessage(invSelected: Zeus.Web.Model.InventoryWorkOrderManyToMany[]) {
    this.isLaunchingMessage = true;
    await WorkOrderUtils.launchBatchProcessFromDTO(invSelected, this.cycleCountElements.filter((x: any) => x.isSelected),
      async () => {
        this.workOrderSelected?.forEach(wo => (<any>wo).isSelected = false);
        this.workOrderSelected.splice(0);
        this.inventoryElements.splice(0);
        this.cycleCountElements.splice(0);
        this.elements.splice(0);
        UIInternal.broadcast(Constants.WorkOrderProcessEvents.ReloadWorkOrder);
      });
    this.isLaunchingMessage = false;
  }

  public async cancelInventory() {
    if (!(await this.cancelInventoryConfirmed())) {
      return;
    } await this.cancel(this.inventoryElements)
  }

  public async cancel(invSelected: Zeus.Web.Model.InventoryWorkOrderManyToMany[]) {
    invSelected.forEach(inv => inv.hasBeenCancelled = true);

    await this.inventoryService.saveEntities(invSelected);
    UIInternal.broadcast(Constants.WorkOrderProcessEvents.BatchProcessSent, { workOrderIdsToKeep: [] });
  }

  public async cancelInventoryConfirmed(): Promise<boolean> {
    return await this.box.showQuestion(
      this.i18n.tr("articletoinput.confirmCancellationAtas"), this.i18n.tr("menu.del-filter-set-title"),
      [
        { label: this.i18n.tr('general.no'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
        { label: this.i18n.tr('general.yes'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() }
      ]
    ).whenClosed(result => !result.wasCancelled);
  }
}

