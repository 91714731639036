.remaining-items-badge {
  color: lightsalmon;
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  .badge {
    background: lightsalmon;
    color: white;
    padding: 0.5em;
    border-radius: 5px;
    margin-right: 1em;
    text-align: center;
  }

  .badge-qty {
    font-weight: bold;
    font-size: 1.2em;
  }

  .badge-label {
    font-size: 0.8em;
  }

  .status {
    font-size: 1.3em;
    font-weight: bold;
  }
}
