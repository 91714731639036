import { customElement } from "aurelia-templating";
import { ServiceBase } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../../../constants';
import { InventoryBaseActions } from './inventory-base-actions';
import { InventoryBaseFloatingBox } from 'work-orders/floating-box/inventory/inventory-base-floating-box';
import { InventoryStorageFloatingBox } from 'work-orders/floating-box/inventory/inventory-storage-floating-box';

@customElement('inventory-storage-actions')
export class InventoryStorageActions extends InventoryBaseActions<Zeus.Web.Model.InventoryWorkOrderStorage> {

  constructor() {
    super(new ServiceBase<Zeus.Web.Model.InventoryWorkOrderStorage>(Constants.EntityTypeNames.InventoryWorkOrderStorage));
  }

  protected getExpands(withLocations: boolean = false): string[] {
    return withLocations ? ['storage.articleLocations'] : ['storage'];
  }

  protected setNumberOfLocations(entity: Zeus.Web.Model.InventoryWorkOrderStorage): Zeus.Web.Model.InventoryWorkOrderStorage {
    (entity as any).nbrLocations = entity.storage.articleLocations.length;
    return entity;
  }
  protected getFloatingBoxInstance(): InventoryBaseFloatingBox<Zeus.Web.Model.InventoryWorkOrderStorage> {
    return new InventoryStorageFloatingBox(this.workOrder);
  }

}
