.locations-to-update {
  height: 400px;
  overflow-y: auto;

  th {
    cursor: default;
  }

  .ui-col {
    text-align: center;
  }

  .change-content {
    grid-template-columns: 45% 10% 45%;
    display: grid;
    overflow-x: clip;

    > ui-icon {
      width: 100%;
      text-align: center;
    }

    :first-child {
      text-align: right;
    }

    :last-child {
      text-align: left;
    }
  }
}
