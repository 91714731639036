import { Router } from 'aurelia-router';
import { autoinject, bindable, BindingEngine, computedFrom, customElement } from "aurelia-framework";
import { CustomLogger, EditingModeEnum, EntityDetailViewModelBase, ServiceBase, Various } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { SiteManagementSelectionHelper } from 'external-src/site-management/site-management-select-view';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';

@autoinject
@customElement('storage-group-detail-view')
export class StorageGroupDetailView extends EntityDetailViewModelBase<Zeus.Web.Model.StorageGroup> {

  public ressourceName: string = Constants.EntityTypeNames.StorageGroup;

  @bindable
  private storageGroupId: number;
  @bindable
  private onCancel = () => { };
  @bindable
  private onSaved: (args: { storageGroup: Zeus.Web.Model.StorageGroup }) => Promise<void>;

  private checkScanningConfigurationValid: boolean = true;

  @computedFrom('editingMode', 'entity.name', 'entity.storageGroupType.denomination._translation')
  get documentTitle(): string {
    if (this.editingMode === EditingModeEnum.Create) {
      return this.i18n.tr('menu.addnew') + ' ' + (this.entity?.storageGroupType?.denomination._translation || this.i18n.tr("storagegroup.storagegroup"));
    }
    if (this.editingMode === EditingModeEnum.Update) {
      return this.entity.name;
    }
  }

  @computedFrom('entity.storageGroupTypeId')
  get isParentStorageGroupRequired(): boolean {
    return this.entity?.storageGroupTypeId != Constants.StorageGroupTypes.Site
  }

  @computedFrom('entity.id')
  private get isCheckScanningActive() {
    return this.appModuleService.isActive(AppModuleEnum.CheckScanning);
  }

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup));
    // Parent cannot be self && cannot be a zone group
    this.service.gridDataSource.customSelect2Predicates = () => {
      let predicateResult = new Predicate('storageGroupTypeId', FilterQueryOp.NotEquals, Constants.StorageGroupTypes.Zone);
      let uniquePredicate = this.entity ? new Predicate('id', FilterQueryOp.NotEquals, this.entity.id) : null;
      return uniquePredicate ? predicateResult.and(uniquePredicate) : predicateResult;
    }
  }

  public async attached() {
    if (this.storageGroupId == Various.NewId) {
      this.editingMode = EditingModeEnum.Create;
      this.entity = await this.service.createEntity();
      await this.selectStorageGroupType();
    } else if (this.storageGroupId > 0) {
      this.editingMode = EditingModeEnum.Update;
      this.entity = await this.service.getEntityById(this.storageGroupId, 'parentStorageGroup', 'storageGroupType');
    } else {
      throw `Incorrect id ${this.storageGroupId}`;
    }

    this.controller.addObject(this.entity);

    this.disposables.push(
      this.bindingEngine.expressionObserver(this, "entity.parentStorageGroupId")
        .subscribe(async () => await this.selectStorageGroupType())
    );
  }

  //#region Deactivation

  public detached(): void {
    this.controller.removeObject(this.entity);
    this.entity = null;
    this.disposables.forEach(d => d.dispose());
  }

  protected doNavigateBack(): void {
    // Unselect tree
    this.onCancel();
  }

  //#endregion Deactivation

  //#region Saving

  public async save(silentSave?: boolean, byPassLocking?: boolean, navigateBack?: boolean): Promise<any> {
    await super.save(silentSave, byPassLocking, navigateBack);
    if (this.onSaved) {
      await this.onSaved({ storageGroup: this.entity });
    }
    SiteManagementSelectionHelper.onStorageGroupsChanged(this.entity);
  }

  protected changeRouteAfterCreation(): void {
    // Do nothing
  }

  //#endregion Saving

  protected async selectStorageGroupType() {
    if (this.entity == null) {
      return;
    }
    if (this.entity.parentStorageGroupId == null) {
      this.entity.storageGroupTypeId = Constants.StorageGroupTypes.Site;
    } else {
      let parentStorageGroup = await this.service.getEntityByIdFromLocalCache(this.entity.parentStorageGroupId)
        || await this.service.getEntityById(this.entity.parentStorageGroupId);
      switch (parentStorageGroup.storageGroupTypeId) {
        case Constants.StorageGroupTypes.Site:
          this.entity.storageGroupTypeId = Constants.StorageGroupTypes.Warehouse;
          break;
        case Constants.StorageGroupTypes.Warehouse:
          this.entity.storageGroupTypeId = Constants.StorageGroupTypes.ZoneGroup;
          break;
        case Constants.StorageGroupTypes.ZoneGroup:
          this.entity.storageGroupTypeId = Constants.StorageGroupTypes.Zone;
          break;
      }
    }
  }
}
