import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { Actions, PermissionsConfig } from "digiwall-lib";
import * as GLBConstants from "./constants";
import translationJson from './locales/index';
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";
import * as Constants from "../../constants";

export class GraphicalLedBarRoot extends AppModuleRoot {
  public get name() {
    return AppModule.GraphicalLedBar;
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [
      { category: GLBConstants.EnumerationTypes.LedBarSizes, isClosed: true, name: "enumerationtype.ledBarSizes" },
    ];
  }

  public get enumerationTypeList(): object {
    return GLBConstants.EnumerationTypes;
  }

  protected get pathName(): string {
    return 'graphical-led-bar';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      {
        after: Constants.RouteName.ImportLog,
        route: 'glb-configuration/all',
        moduleId: 'glb-configurations/glb-configuration-list',
        title: 'glbconfiguration.glbconfigurations',
        nav: true,
        settings: { group: Constants.MenuType.ApplicationParameters, resource: GLBConstants.EntityTypeNames.GLBConfiguration, action: Actions.Read }
      },
      {
        route: 'glb-configuration/:param1',
        moduleId: 'glb-configurations/glb-configuration-detail',
        title: 'glbconfiguration.glbconfiguration',
        nav: false,
        settings: { group: Constants.MenuType.ApplicationParameters, resource: GLBConstants.EntityTypeNames.GLBConfiguration, action: [Actions.Create, Actions.Update] }
      },
    ];
  }

  protected get resourceNames(): {} {
    return GLBConstants.EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        { context: GLBConstants.Permissions.GLBConfiguration, qualifiedName: [Constants.MenuType.ApplicationParameters, 'glbconfiguration.glbconfiguration'] }
      ]
    };
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
