import * as Constants from '../constants';
import moment = require('moment');

export class WeekFormat {
  public static getMondayOfWeekFromDate(date: Date): Date {
    let monday: Date = moment(date).weekday(0).toDate();
    monday.setHours(0);
    return monday;
  }
  public static getSundayOfWeekFromMonday(monday: Date): Date {
    return new Date(monday.getTime() + (Constants.TimeInMilliseconds.WeekInMilliseconds - 1));
  }

  public static getBeginOfDay(date: Date): Date {
    date.setHours(0);
    return date;
  }
  public static getBeginOfNextDay(date: Date): Date {
    return new Date(date.getTime() + (Constants.TimeInMilliseconds.DayInMilliseconds - 1));
  }
}
