import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, bindable, bindingMode, containerless, customElement } from 'aurelia-framework';
import { ColDef } from "ag-grid-community";
import * as Constants from '../../../../constants';
import { Zeus } from '../../../../generated';
import { FilterQueryOp, Predicate } from 'breeze-client';

@customElement('article-selected-list')
@autoinject
@containerless
export class ArticleSelectedList extends ListViewModelBase<Zeus.Web.Model.Article> {
  @bindable({ defaultBindingMode: bindingMode.toView })
  private articleSelected: Array<Zeus.Web.Model.Article>;

  public ressourceName: string = Constants.EntityTypeNames.Location;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.Article>(Constants.EntityTypeNames.Article));
    this.service.gridDataSource.expands = ['articleLocations', 'unitOfMeasures', 'articleVolumeConfigs.storageVolume.volumeType', 'articleBlock'];
  }

  public bind() {
    let predicate = Predicate.or(this.articleSelected.map(x => new Predicate('id', FilterQueryOp.Equals, x.id)));
    this.service.gridDataSource.getPredicatesFunction = () => predicate;
  }

  public async attached() {
    await super.attached();
    this.routeName = "print-location-list";
    this.selectAll = true;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("article.reference"),
        field: "reference",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("article.quantity"),
        field: "stock",
        type: FieldType.String,
        filter: null,
        sortable: false
      },
      {
        headerName: this.i18n.tr("article.volume"),
        field: "volume",
        type: FieldType.String,
        filter: null,
        sortable: false
      },
      {
        headerName: this.i18n.tr("article.barcodeEan"),
        field: "barcodeEan",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("article.barcodeEans"),
        field: "barcodeEans",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.referenceAliasTags"),
        field: "referenceAliasTags",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.description") + '(FR)',
        field: "description.lang1",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.description") + '(NL)',
        field: "description.lang2",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.description") + '(EN)',
        field: "description.lang3",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.info") + '(FR)',
        field: "info.lang1",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.info") + '(NL)',
        field: "info.lang2",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.info") + '(EN)',
        field: "info.lang3",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.defaultQuantityWhenOrdering"),
        field: "defaultQuantityWhenOrdering",
        type: FieldType.String,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.hasLotNumber"),
        field: "hasLotNumber",
        type: FieldType.Boolean,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.hasExpirationDate"),
        field: "hasExpirationDate",
        type: FieldType.Boolean,
        hide: true
      },
      {
        headerName: this.i18n.tr("article.articleBlockId"),
        field: "articleBlock.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.InventoryBlock
        }
      },
      {
        headerName: this.i18n.tr("article.active"),
        field: "active",
        type: FieldType.Boolean,
        hide: true
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
}
