import { ZeusHubClient } from '../external-src/zeus-hub-client';
import { HttpClient } from 'aurelia-fetch-client';
import { FileFlow, ViewModelBase, UIInternal } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { CustomLogger } from 'digiwall-lib';
import { autoinject, computedFrom, Container } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import * as Constants from '../constants';
import { GridOptions } from 'ag-grid-community';

export interface ImportXLSResult {
  HeaderError: string;
  IsFileValid: boolean;
  NbRequest: number;
  NbOk: number;
  NbTotalError: number;
  NbOtherError: number;
  NbMissingValue: number;
  NbBadFormat: number;
  ResultFileURL: string;
}

export interface ImportXlsParams {
  type: string,
  title: string,
  patternUrl: string,
  isForPatch?: boolean,
  importUrl: string,
  folderName: string,
  dialogType?: any,
}

export function subscribeImportRefresh(gridOptions: GridOptions<any>, ...datatypes: Constants.DataFileImportType[]) {
  return Container.instance.get(ZeusHubClient).onImportFinished(dataType => {
    if (datatypes.some(t => t == dataType)) {
      gridOptions.api.refreshInfiniteCache();
    }
  })
};

@autoinject
export class ImportXLS extends ViewModelBase {
  public ressourceName: string;
  protected params: ImportXlsParams;

  @computedFrom('params.title')
  get ribbonHeaderText(): string {
    return this.params.title;
  }

  @computedFrom('params.isForPatch')
  get buttonTr(): string {
    return this.params.isForPatch ? 'menu.update' : 'general.import';
  }

  protected url: string;
  protected fileName: string;
  protected response: ImportXLSResult;
  protected viewResponse: boolean = false;
  protected isLoading: boolean = false;
  protected isFileUploaded: boolean = false;
  protected fileFormat: string;
  protected waitForResult: boolean;

  constructor(router: Router, logger: CustomLogger, protected dialogController: DialogController, protected httpClient: HttpClient) {
    super(router, logger);
  }

  public async activate(params: ImportXlsParams) {
    await super.activate(params);
    this.ressourceName = params.type;
    this.params = params;
    this.viewResponse = false;

  }

  public async uploadedXLS(fileUploaded: FileFlow) {
    this.url = fileUploaded.url;
    this.fileName = fileUploaded.displayName;
    this.response = null;
    this.viewResponse = false;
    this.fileFormat = this.fileName.split(".")[1]
    this.isFileUploaded = true;
  }

  public async downloadResultXLS() {
    const link = document.createElement("a");
    link.href = this.response.ResultFileURL;
    link.setAttribute('download', '');
    link.click();
  }

  public async getPattern() {
    await this.httpClient.get(this.params.patternUrl).then(async result => {
      if (result.ok) {
        this.router.navigate(await result.text(), { replace: false });
      }
    });
  }

  public async import() {
    this.isLoading = true;
    this.isFileUploaded = false;
    this.fileName = null;
    await this.httpClient.get(this.params.importUrl + '?url=' + this.url + "&format=" + this.fileFormat + "&isAsync=" + !this.waitForResult).then(async result => {
      if (result.ok) {
        if (this.waitForResult) {
          this.response = (await result.json()) as ImportXLSResult;
          this.viewResponse = true;
        } else {
          this.dialogController.ok();
        }
      } else {
        await this.box.showError(
          this.i18n.tr("general.importError", { error: (await result.json()).Message }), this.i18n.tr("general.error"),
          null, false
        ).whenClosed(() => false);
      }
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }

  @computedFrom('response.NbOk')
  protected get isImportedWithSuccess(): boolean {
    return this.response?.NbOk > 0;
  }
}
