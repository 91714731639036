import { CustomLogger, UIInternal, ViewModelBase } from 'digiwall-lib';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { DialogController } from 'aurelia-dialog';
import { HttpClient } from 'aurelia-fetch-client';
import * as Constants from '../../../constants';
import * as BLConstants from '../constants';
import * as toastr from 'toastr';
import { UIInput } from 'digiwall-lib/dist/typings/ui/forms/ui-input';

@autoinject()
export class LinkBadge extends ViewModelBase {
  ribbonHeaderText: string = this.i18n.tr("badgelogin.linkBadge");
  ressourceName: string = Constants.EntityTypeNames.ZeusUser;

  private uiInputBadge: UIInput;
  private isSaving = false;

  private userId: number;
  private badge: string;

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private controller: DialogController) {
    super(router, logger);
  }

  public async activate(params: number) {
    if (params) {
      this.userId = params;
    } else {
      throw "UserId not provided";
    }
  }

  private refocus() {
    this.uiInputBadge.focus();
  }

  private preventEvent() {
    return false;
  }

  public bind() {
    this.uiInputBadge.element.addEventListener("enterpressed", (value) => {
      if (this.isBadgeValid) {
        this.validate();
      }
    });
  }

  public async cancel() {
    await this.controller.cancel();
  }

  @computedFrom('badge')
  private get isBadgeValid() {
    return this.badge?.length > 0;
  }

  public async validate() {
    if (this.isSaving) {
      return;
    }
    try {
      this.isSaving = true;
      let response: Response = await this.httpClient.post(BLConstants.Application.AddUserBadgeLink + "?userId=" + this.userId + "&badge=" + this.badge);
      if (response.ok) {
        toastr.success(this.i18n.tr("badgelogin.badgeLinkSuccess"));
        this.controller.ok(true);
      } else {
        try {
          let error: { CODE: string, DATA: any } = await response.json();
          toastr.error(this.i18n.tr('badgelogin.errors.' + error.CODE, { data: error.DATA }));
        } catch {
          toastr.error(await response.text());
        }
        this.badge = null;
      }
    } finally {
      this.isSaving = false;
    }
  }
}
