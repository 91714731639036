import { StorageDetailView } from './storage-detail-view';
import { CustomLogger, UITreePanelConfigAction, ViewModelBase, ServiceBase, Various, EnumerationTypeService, CsvExporter, FieldType, CsvExporterOptions, Actions, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, TaskQueue, viewResources } from 'aurelia-framework';
import { Zeus } from "../generated";
import { DropToLight } from "../app-modules/drop-to-light/generated";
import * as Constants from '../constants';
import * as DTLConstants from '../app-modules/drop-to-light/constants';
import * as ConvConstants from '../app-modules/conveyor/constants';
import { UnifiedStorageEntity, StorageTreePanel } from './storage-tree-panel';
import { StorageGroupDetailView } from './storage-group-detail-view';
import { ColDef } from 'ag-grid-community';
import { SiteManagementSelectionHelper } from 'external-src/site-management/site-management-select-view';
import { WorkstationDetailView } from 'app-modules/drop-to-light/workstations/workstation-detail-view';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import { WorkstationBayGroupDetailView } from 'app-modules/drop-to-light/workstation-bay-group/workstation-bay-group-detail-view';
import { ConveyorDetailView } from 'app-modules/conveyor/conveyor/conveyor-detail-view';
import { Conveyor } from 'app-modules/conveyor/generated';

@autoinject
@viewResources(StorageTreePanel, StorageDetailView, StorageGroupDetailView, WorkstationDetailView, WorkstationBayGroupDetailView, ConveyorDetailView)
export class StorageList extends ViewModelBase {

  get ribbonHeaderText(): string {
    return 'ribbonHeaderText';
  }
  get ressourceName(): string {
    return 'ressourceName';
  }

  private storageTree: StorageTreePanel;

  private storageDetailView: StorageDetailView;
  private selectedStorageId: number;
  private storageService: ServiceBase<Zeus.Web.Model.Storage>;

  private storageGroupDetailView: StorageGroupDetailView;
  private selectedStorageGroupId: number;
  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;

  private newStorageStorageGroupId: number;

  private treeConfigActions: UITreePanelConfigAction<UnifiedStorageEntity>[];

  private canAddStorageGroup: boolean = false;
  private canRemoveStorageGroup: boolean = false;
  private canAddStorage: boolean = false;
  private canRemoveStorage: boolean = false;

  constructor(router: Router, logger: CustomLogger, private taskQueue: TaskQueue, private appModuleService: AppModuleService) {
    super(router, logger);
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);
    this.workstationService = new ServiceBase<DropToLight.Model.Workstation>(DTLConstants.EntityTypeNames.Workstation);
    this.workstationBayGroupService = new ServiceBase<DropToLight.Model.WorkstationBayGroup>(DTLConstants.EntityTypeNames.WorkstationBayGroup);
    this.conveyorService = new ServiceBase<Conveyor.Model.ConveyorConfiguration>(ConvConstants.EntityTypeNames.ConveyorConfiguration);

    this.canAddStorageGroup = this.authService.checkAccess(Constants.EntityTypeNames.StorageGroup, Actions.Create);
    this.canRemoveStorageGroup = this.authService.checkAccess(Constants.EntityTypeNames.StorageGroup, Actions.Delete);
    this.canAddStorage = this.authService.checkAccess(Constants.EntityTypeNames.Storage, Actions.Create);
    this.canRemoveStorage = this.authService.checkAccess(Constants.EntityTypeNames.Storage, Actions.Delete);
    this.canAddWorkstation = this.authService.checkAccess(DTLConstants.EntityTypeNames.Workstation, Actions.Create);
    this.canRemoveWorkstation = this.authService.checkAccess(DTLConstants.EntityTypeNames.Workstation, Actions.Delete);

    // Load all storage group types so these are linked automatically in the entities
    new EnumerationTypeService(Constants.EnumerationTypes.StorageGroupType).getAll();

    this.treeConfigActions = [
      // STORAGE ADD
      new UITreePanelConfigAction<UnifiedStorageEntity>({
        label: 'storage.addStorage',
        icon: 'digi-plus',
        color: 'primary',
        asDropdown: true,
        fn: async entry => {
          this.storageTree.clearSelection();
          this.selectedStorageGroupId = null;
          if (this.selectedStorageId == Various.NewId) {
            // Creation mode already in progress. Update storage group
            this.storageDetailView.entity.storageGroupId = entry.storageGroup.id;
          } else {
            // Open creation mode
            this.newStorageStorageGroupId = entry.storageGroup.id;
            await this.onStorageSelected(Various.NewId);
          }
        },
        // Can only add storages on Storage Groups of type Zone
        display: entry => this.canAddStorage && entry.storageGroup && entry.storageGroup.storageGroupTypeId == Constants.StorageGroupTypes.Zone
      }),
      // ADD CONVEYOR
      new UITreePanelConfigAction<UnifiedStorageEntity>({
        label: 'storage.addConveyor',
        icon: 'digi-plus',
        color: 'primary',
        asDropdown: true,
        fn: async entry => {
          this.storageTree.clearSelection();
          this.selectedStorageGroupId = null;
          if (this.selectedConveyorId == Various.NewId) {
            // Creation mode already in progress. Update storage group
            this.conveyorDetailView.entity.warehouseId = entry.storageGroup.id;
          } else {
            // Open creation mode
            this.newStorageStorageGroupId = entry.storageGroup.id;
            await this.onConveyorSelected(Various.NewId);
          }
        },
        // Can only add DTL_StorageGroups on Storage Groups of type Zone
        display: entry => appModuleService.isActive(AppModuleEnum.Conveyor) && this.canAddStorage && entry.storageGroup && entry.storageGroup.storageGroupTypeId == Constants.StorageGroupTypes.Warehouse
      }),
      // ADD GROUP OF BAYS
      new UITreePanelConfigAction<UnifiedStorageEntity>({
        label: 'workstationbaygroup.addWorkstationBayGroup',
        icon: 'digi-plus',
        color: 'primary',
        asDropdown: true,
        fn: async entry => {
          this.storageTree.clearSelection();
          this.selectedStorageGroupId = null;
          if (this.selectedWorkstationBayGroupId == Various.NewId) {
            // Creation mode already in progress. Update storage group
            this.workstationBayGroupDetailView.entity.zoneId = entry.storageGroup.id;
          } else {
            // Open creation mode
            this.newStorageStorageGroupId = entry.storageGroup.id;
            await this.onWorkstationBayGroupSelected(Various.NewId);
          }
        },
        // Can only add DTL_StorageGroups on Storage Groups of type Zone
        display: entry => appModuleService.isActive(AppModuleEnum.DropToLight) && this.canAddWorkstation && entry.storageGroup && entry.storageGroup.storageGroupTypeId == Constants.StorageGroupTypes.Zone
      }),
      // WORKSTATION ADD
      new UITreePanelConfigAction<UnifiedStorageEntity>({
        label: 'workstation.addworkstation',
        icon: 'digi-plus',
        color: 'primary',
        asDropdown: true,
        fn: async entry => {
          this.storageTree.clearSelection();
          this.selectedStorageGroupId = null;
          if (this.selectedWorkstationId == Various.NewId) {
            // Creation mode already in progress. Update storage group
            this.workstationDetailView.entity.zoneId = entry.storageGroup.id;
          } else {
            // Open creation mode
            this.newStorageStorageGroupId = entry.storageGroup.id;
            await this.onWorkstationSelected(Various.NewId);
          }
        },
        // Can only add workstation on Storage Groups of type Zone
        display: entry => appModuleService.isActive(AppModuleEnum.DropToLight) && this.canAddWorkstation && entry.storageGroup && entry.storageGroup.storageGroupTypeId == Constants.StorageGroupTypes.Zone
      }),
      // STORAGE GROUP ADD
      new UITreePanelConfigAction<UnifiedStorageEntity>({
        label: (entry) => {
          let label = 'storagegroup.addnew.';
          switch (entry.storageGroup?.storageGroupTypeId) {
            case Constants.StorageGroupTypes.Site: return label + 'warehouse';
            case Constants.StorageGroupTypes.Warehouse: return label + 'zonegroup';
            case Constants.StorageGroupTypes.ZoneGroup: return label + 'zone';
          }
          return null;
        },
        icon: 'digi-plus',
        color: 'primary',
        asDropdown: true,
        fn: async entry => {
          this.storageTree.clearSelection();
          this.selectedStorageId = null;
          if (this.selectedStorageGroupId == Various.NewId) {
            // Creation mode already in progress. Update storage group
            this.storageGroupDetailView.entity.parentStorageGroupId = entry.storageGroup.id;
          } else {
            // Open creation mode
            this.newStorageStorageGroupId = entry.storageGroup.id;
            await this.onStorageGroupSelected(Various.NewId);
          }
        },
        // Cannot add below a zone
        display: entry => this.canAddStorageGroup && entry.storageGroup && entry.storageGroup.storageGroupTypeId != Constants.StorageGroupTypes.Zone
      }),

      // GENERAL DELETE
      new UITreePanelConfigAction<UnifiedStorageEntity>({
        label: 'common:general.remove',
        icon: 'digi-trash',
        color: 'danger',
        asDropdown: true,
        fn: async entry => {
          const deleteHelper = deleteHelperFn(entry);

          if (await deleteHelper.serviceDelete()) {
            if (deleteHelper.isSelected()) {
              // Selected element is removed
              this.deselectAll();
            }
            // Remove from tree
            this.storageTree.removeNode(entry);
            if (entry.storageGroup != null) {
              SiteManagementSelectionHelper.onStorageGroupsChanged(entry.storageGroup);
            }
          }
        },
        // Can only delete storages or storage groups without children
        display: (entry, node) => node.leaf && deleteHelperFn(entry).canDisplay()
      }),
    ];

    const deleteHelperFn = (entry: UnifiedStorageEntity) => {
      if (entry.storage != null) {
        return {
          serviceDelete: async () => await this.storageService.deleteEntities([entry.storage], true),
          isSelected: () => this.selectedStorageId == entry.storage.id,
          canDisplay: () => !entry.storage || this.canRemoveStorage
        };
      } else if (entry.storageGroup != null) {
        return {
          serviceDelete: async () => await this.storageGroupService.deleteEntities([entry.storageGroup], true),
          isSelected: () => this.selectedStorageGroupId == entry.storageGroup.id,
          canDisplay: () => !entry.storageGroup || this.canRemoveStorageGroup
        };
      } else if (entry.workstation != null) {
        return {
          serviceDelete: async () => await this.workstationService.deleteEntities([entry.workstation], true),
          isSelected: () => this.selectedWorkstationId == entry.workstation.id,
          canDisplay: () => !entry.workstation || this.canRemoveWorkstation
        };
      } else if (entry.workstationBayGroup != null) {
        return {
          serviceDelete: async () => await this.workstationBayGroupService.deleteEntities([entry.workstationBayGroup], true),
          isSelected: () => this.selectedWorkstationBayGroupId == entry.workstationBayGroup.id,
          canDisplay: () => !entry.workstationBayGroup || this.canRemoveWorkstation
        };
      } else if (entry.conveyor != null) {
        return {
          serviceDelete: async () => await this.conveyorService.deleteEntities([entry.conveyor], true),
          isSelected: () => this.selectedConveyorId == entry.conveyor.id,
          canDisplay: () => !entry.conveyor || this.canRemoveStorage
        };
      } else {
        return {
          serviceDelete: () => Promise.resolve(true),
          isSelected: () => false,
          canDisplay: () => true
        };
      }
    }
  }

  private async onCreateSite() {
    this.storageTree.clearSelection();
    this.selectedStorageId = null;
    if (this.selectedStorageGroupId == Various.NewId) {
      // Creation mode already in progress. Update storage group
      this.storageGroupDetailView.entity.parentStorageGroupId = null;
    } else {
      // Open creation mode
      this.newStorageStorageGroupId = null;
      await this.onStorageGroupSelected(Various.NewId);
    }
  }

  public async activate(params) {
    await super.activate(params);

    let id: string = params.param1;

    if (id == 'all') {
      // Do nothing, show tree only
    } else if (id.endsWith('-' + Various.NewId)) {
      // Refresh on creation screen, redirect to all view
      this.navigateToAll();
    } else if (id.startsWith(StorageTreePanelType.StorageGroup + "-")) {
      // Preselect storage group
      this.selectedStorageGroupId = parseInt(id.slice((StorageTreePanelType.StorageGroup + "-").length, id.length));
    } else if (id.startsWith(StorageTreePanelType.Storage + "-")) {
      // Preselect storage
      this.selectedStorageId = parseInt(id.slice((StorageTreePanelType.Storage + "-").length, id.length));
    } else if (id.startsWith(StorageTreePanelType.WorkstationBayGroup + "-")) {
      // Preselect workstation
      this.selectedWorkstationBayGroupId = parseInt(id.slice((StorageTreePanelType.WorkstationBayGroup + "-").length, id.length));
    } else if (id.startsWith(StorageTreePanelType.Workstation + "-")) {
      // Preselect workstation
      this.selectedWorkstationId = parseInt(id.slice((StorageTreePanelType.Workstation + "-").length, id.length));
    } else if (id.startsWith(StorageTreePanelType.Conveyor + "-")) {
      // Preselect workstation
      this.selectedConveyorId = parseInt(id.slice((StorageTreePanelType.Conveyor + "-").length, id.length));
    }
  }

  public async canDeactivate() {
    if (this.selectedWorkstationId != null) {
      return await this.workstationDetailView.canDeactivate();
    } else if (this.selectedStorageId != null) {
      return await this.storageDetailView.canDeactivate();
    } else if (this.selectedStorageGroupId != null) {
      return await this.storageGroupDetailView.canDeactivate();
    } else if (this.selectedWorkstationBayGroupId != null) {
      return await this.workstationBayGroupDetailView.canDeactivate();
    } else if (this.selectedConveyorId != null) {
      return await this.conveyorDetailView.canDeactivate();
    }
    return true;
  }

  private onStorageDetailAttached() {
    // Set storage group on storage creation
    if (this.storageDetailView.entity?.id < 0) {
      this.taskQueue.queueTask(() => this.storageDetailView.entity.storageGroupId = this.newStorageStorageGroupId);
    }
  }

  private onStorageGroupDetailAttached() {
    // Set storage group on storage group creation
    if (this.storageGroupDetailView.entity?.id < 0) {
      this.taskQueue.queueTask(() => this.storageGroupDetailView.entity.parentStorageGroupId = this.newStorageStorageGroupId);
    }
  }

  private deselectAll() {
    this.selectedStorageId = null;
    this.selectedStorageGroupId = null;
    this.selectedWorkstationId = null;
    this.selectedWorkstationBayGroupId = null;
    this.selectedConveyorId = null;
    this.navigateToAll();
    this.storageTree.clearSelection();
  }

  private navigateToAll() {
    this.router.navigate('/storages/all', { replace: true, trigger: false });
  }

  private async onStorageSelected(id: number): Promise<boolean> {
    return await this.onTreeSelected(id, StorageTreePanelType.Storage);
  }

  private async onStorageGroupSelected(id: number): Promise<boolean> {
    return await this.onTreeSelected(id, StorageTreePanelType.StorageGroup);
  }

  private async onTreeSelected(id: number, type: StorageTreePanelType): Promise<boolean> {
    let selectedIdProp: string;
    switch (type) {
      case StorageTreePanelType.Storage:
        selectedIdProp = 'selectedStorageId';
        break;
      case StorageTreePanelType.StorageGroup:
        selectedIdProp = 'selectedStorageGroupId';
        break;
      case StorageTreePanelType.Workstation:
        selectedIdProp = 'selectedWorkstationId';
        break;
      case StorageTreePanelType.WorkstationBayGroup:
        selectedIdProp = 'selectedWorkstationBayGroupId';
        break;
      case StorageTreePanelType.Conveyor:
        selectedIdProp = 'selectedConveyorId';
        break;
    }

    if (id == this[selectedIdProp]) {
      // Do nothing on duplicate select (e.g. when loading the page with an Id)
      return true;
    }

    // Check if allowed to unload component => call cancel btn
    let canDeactivate: boolean = true;
    if (this.selectedStorageId != null) {
      canDeactivate = await this.storageDetailView.canDeactivate();
    } else if (this.selectedStorageGroupId != null) {
      canDeactivate = await this.storageGroupDetailView.canDeactivate();
    } else if (this.selectedWorkstationId != null) {
      canDeactivate = await this.workstationDetailView.canDeactivate();
    } else if (this.selectedWorkstationBayGroupId != null) {
      canDeactivate = await this.workstationBayGroupDetailView.canDeactivate();
    } else if (this.selectedConveyorId != null) {
      canDeactivate = await this.conveyorDetailView.canDeactivate();
    }
    if (false == canDeactivate) {
      return false;
    }

    // Unload component
    this.selectedWorkstationId = null;
    this.selectedStorageId = null;
    this.selectedStorageGroupId = null;
    this.selectedWorkstationBayGroupId = null;
    this.selectedConveyorId = null;
    // Reload component
    this.taskQueue.queueTask(() => {
      this[selectedIdProp] = id;
      this.router.navigate(`/storages/${type}-${id}`, { replace: true, trigger: false });
    });

    return true;
  }

  private exportToCsv() {
    new CsvExporter<Zeus.Web.Model.Storage>(<any[]>this.csvExportStorageColumns, new CsvExporterOptions({ filename: this.i18n.tr('menu.storagePlaces') }))
      .export(this.storageTree.nodeList.map(unified => unified.storage).filter(s => !!s));
    new CsvExporter<Zeus.Web.Model.StorageGroup>(<any[]>this.csvExportStorageGroupColumns, new CsvExporterOptions({ filename: this.i18n.tr('menu.storagegroups') }))
      .export(this.storageTree.nodeList.map(unified => unified.storageGroup).filter(s => !!s));
  }

  protected get csvExportStorageColumns() {
    var defs: ColDef[] = [
      {
        headerName: this.i18n.tr("storage.name"),
        field: "name",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.isUnavailable"),
        field: "isUnavailable",
        type: FieldType.Boolean,
      },
      {
        headerName: this.i18n.tr("storage.formattedLocationFormat"),
        field: "formattedLocationFormat",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("storage.dynamicStorageId"),
        field: "dynamicStorageId",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("storage.storageLocationTypeId"),
        field: "storageLocationType.denomination._translation",
        type: FieldType.Enumeration,
      },
      {
        headerName: this.i18n.tr("storage.storageType"),
        field: "dynamicStorageType.denomination._translation",
        type: FieldType.Enumeration,
      },
      {
        headerName: this.i18n.tr("storage.zoneName"),
        field: "storageGroup.name",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.zoneGroupName"),
        field: "storageGroup.parentStorageGroup.name",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.warehouseName"),
        field: "storageGroup.parentStorageGroup.parentStorageGroup.name",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.siteName"),
        field: "storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup.name",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.storageLocationTypeId"),
        field: "storageLocationType.denomination._translation",
        type: FieldType.Enumeration,
      },
      {
        headerName: this.i18n.tr("storage.heightTray"),
        field: "heightTray",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("storage.nbTrays"),
        field: "nbTrays",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("storage.nbBays"),
        field: "nbBays",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("storage.trayWidthCm"),
        field: "trayWidthCm",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("storage.trayDepthCm"),
        field: "trayDepthCm",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("storage.locationFormatId"),
        field: "locationFormat.name",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("storage.nbLocations"),
        field: "nbLocations",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("storage.manufacturingDate"),
        field: "manufacturingDate",
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("storage.firstUserDate"),
        field: "firstUserDate",
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("storage.nextInspectionDate"),
        field: "nextInspectionDate",
        type: FieldType.Date
      },
      {
        headerName: this.i18n.tr("storage.inspectionPeriodicityInDays"),
        field: "inspectionPeriodicityInDays",
        type: FieldType.Number,
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser, { baseColDef: { hide: false } }),
    ];
    return defs;
  }

  protected get csvExportStorageGroupColumns() {
    let defs: ColDef[] = [
      {
        headerName: this.i18n.tr("storagegroup.name"),
        field: "name",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storagegroup.description"),
        field: "description",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storagegroup.type"),
        field: "storageGroupType.denomination._translation",
        type: FieldType.Enumeration,
      },
      {
        headerName: this.i18n.tr("storagegroup.zonegroup"),
        valueGetter: (params) => {
          if (params?.data?.parentStorageGroup
            && (<Zeus.Web.Model.StorageGroup>params.data.parentStorageGroup).storageGroupTypeId == Constants.StorageGroupTypes.ZoneGroup) {
            return (<Zeus.Web.Model.StorageGroup>params.data.parentStorageGroup).name;
          }
        },
        field: "zoneGroupParentName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storagegroup.warehouse"),
        valueGetter: (params) => {
          if (params?.data?.parentStorageGroupId != null) {
            let psg: Zeus.Web.Model.StorageGroup = params.data.parentStorageGroup;
            while (psg != null && psg.storageGroupTypeId != Constants.StorageGroupTypes.Warehouse) {
              psg = psg.parentStorageGroup;
            }
            if (psg != null) {
              return psg.name;
            }
          }
        },
        field: "warehouseParentName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storagegroup.site"),
        valueGetter: (params) => {
          if (params?.data?.parentStorageGroupId != null) {
            let psg: Zeus.Web.Model.StorageGroup = params.data.parentStorageGroup;
            while (psg.parentStorageGroupId != null) {
              psg = psg.parentStorageGroup;
            }
            if (psg != null) {
              return psg.name;
            }
          }
        },
        field: "siteName",
        type: FieldType.String
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser, { baseColDef: { hide: false } }),
    ];
    return defs;
  }

  //#region DTL
  private workstationDetailView: WorkstationDetailView;
  private selectedWorkstationId: number;
  private workstationService: ServiceBase<DropToLight.Model.Workstation>;
  private canAddWorkstation: boolean = false;
  private canRemoveWorkstation: boolean = false;

  private async onWorkstationSelected(id: number): Promise<boolean> {
    return await this.onTreeSelected(id, StorageTreePanelType.Workstation);
  }
  private onWorkstationDetailAttached() {
    // Set storage group on storage group creation
    if (this.workstationDetailView.entity?.id < 0) {
      this.taskQueue.queueTask(() => this.workstationDetailView.entity.zoneId = this.newStorageStorageGroupId);
    }
  }

  private workstationBayGroupDetailView: WorkstationBayGroupDetailView;
  private selectedWorkstationBayGroupId: number;
  private workstationBayGroupService: ServiceBase<DropToLight.Model.WorkstationBayGroup>;

  private async onWorkstationBayGroupSelected(id: number): Promise<boolean> {
    return await this.onTreeSelected(id, StorageTreePanelType.WorkstationBayGroup);
  }
  private onWorkstationBayGroupDetailAttached() {
    // Set bay group on storage group creation
    if (this.workstationBayGroupDetailView.entity?.id < 0) {
      this.taskQueue.queueTask(() => this.workstationBayGroupDetailView.entity.zoneId = this.newStorageStorageGroupId);
    }
  }
  //#endregion

  //#region Conveyor
  private conveyorDetailView: ConveyorDetailView;
  private selectedConveyorId: number;
  private conveyorService: ServiceBase<Conveyor.Model.ConveyorConfiguration>;
  private async onConveyorSelected(id: number): Promise<boolean> {
    return await this.onTreeSelected(id, StorageTreePanelType.Conveyor);
  }
  private onConveyorDetailAttached() {
    // Set storage group on storage group creation
    if (this.conveyorDetailView.entity?.id < 0) {
      this.taskQueue.queueTask(() => this.conveyorDetailView.entity.warehouseId = this.newStorageStorageGroupId);
    }
  }
  //#endregion
}

enum StorageTreePanelType {
  Storage = "storage",
  StorageGroup = "storage-group",
  Workstation = "workstation",
  WorkstationBayGroup = "workstation-bay-group",
  Conveyor = "conveyor",
}
