//#region required ?
div.row {
  margin-bottom: 5px !important;
}

div[slot="main-content"] .collapsible-body {
  padding: 14px;
}

ui-checkbox {
  > label {
    cursor: pointer;
    font-size: inherit;
  }
}

.no-focus {
  outline: none;
}

.dropdown-content {
  z-index: 999 !important;
  &.select-dropdown {
    z-index: 1003 !important;
  }
}

strong {
  font-weight: bolder !important;
}

li.disabled {
  background-color: #eee;
  cursor: not-allowed !important;
}

td > i.material-icons {
  vertical-align: middle;
}

aside img {
  margin-left: calc(#{$left-part-width/2} - #{$brand-logo-width/2}) !important;
  left: 0;
  top: 10px;
  width: $brand-logo-width;
  position: absolute;

  .condensed & {
    margin-left: calc(#{$left-part-condensed-width/2} - #{$brand-logo-width-condensed/2}) !important;
    width: $brand-logo-width-condensed;
  }
}

.ag-row .ag-cell .ag-cell-wrapper {
  display: block;
  overflow: hidden;
}

body {
  margin: 0 !important;
}
//#endregion

.app-bar {
  &-profile {
    font-size: 16px;
    ui-button {
      height: inherit;
    }
    img {
      height: 40px;
      border-radius: 50%;
    }
    .username-container {
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin-left: 10px;

      > span {
        line-height: 1.5rem;
      }
    }
  }
  &-button {
    top: -3px;

    > div {
      box-shadow: none !important;
    }
  }
}

.info-message-container {
  height: 100%;
  > div {
    height: 100%;
    display: block;
  }
}

.ag-grid-line {
  &-disabled,
  &-disabled.ag-row-hover:not(.ag-full-width-row)::before {
    background-color: $black-4 !important;
  }
}

.message-layout {
  > div {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    & > div {
      width: 33%;
      ul:first-child {
        padding-left: 0;
      }
      ul:not(:first-child) {
        padding-left: revert;
      }
    }
  }
}

@media only screen and (min-width: 993px) {
  #toast-container {
    &.toast-bottom-right {
      right: initial;
      left: 50%;
      transform: translate(-50%);
    }

    > .toast {
      flex-direction: row-reverse;
      align-items: center;
      > .toast-close-button {
        top: 0;
        right: 0;

        &:focus,
        &:hover {
          opacity: 1;
          color: white;
        }
      }
    }
  }
}

.halfway-fab {
  &.left {
    left: 20px;
    right: none;
  }

  &.right {
    right: 20px;
    left: none;
  }
}

.card, .card-image, .card-image>img {
  width: inherit;
}

.ui-display-table {
  display: table;

  &-row {
    display: table-row;
  }

  &-cell {
    display: table-cell;
  }
}
