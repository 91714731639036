@font-face {
  font-family: 'Zeus-Icons';
  src:  url('fonts/Zeus-Icons.eot?c0cgpb');
  src:  url('fonts/Zeus-Icons.eot?c0cgpb#iefix') format('embedded-opentype'),
    url('fonts/Zeus-Icons.woff2?c0cgpb') format('woff2'),
    url('fonts/Zeus-Icons.ttf?c0cgpb') format('truetype'),
    url('fonts/Zeus-Icons.woff?c0cgpb') format('woff'),
    url('fonts/Zeus-Icons.svg?c0cgpb#Zeus-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ze-"], [class*=" ze-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Zeus-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ze-delivery-box:before {
  content: "\e926";
}
.ze-scanner:before {
  content: "\e925";
}
.ze-Tray-pick:before {
  content: "\e924";
}
.ze-scan-send:before {
  content: "\e923";
}
.ze-borrowing-return:before {
  content: "\e921";
}
.ze-borrowing:before {
  content: "\e922";
}
.ze-conveyor-belt:before {
  content: "\e920";
}
.ze-cart-reception:before {
  content: "\e91f";
}
.ze-scan:before {
  content: "\e91e";
}
.ze-unlink-badge:before {
  content: "\e91d";
}
.ze-badge:before {
  content: "\e91c";
}
.ze-shelf:before {
  content: "\e91b";
}
.ze-workstation:before {
  content: "\e91a";
}
.ze-zone:before {
  content: "\e915";
}
.ze-workstation-storage-group:before {
  content: "\e916";
}
.ze-zone-group:before {
  content: "\e917";
}
.ze-static-storage:before {
  content: "\e918";
}
.ze-workstation-2:before {
  content: "\e919";
}
.ze-file-synchro:before {
  content: "\e911";
}
.ze-box-open-template:before {
  content: "\e912";
}
.ze-box-open-location:before {
  content: "\e913";
}
.ze-box-open-preview:before {
  content: "\e914";
}
.ze-picking-return:before {
  content: "\e902";
}
.ze-inventory:before {
  content: "\e903";
}
.ze-picking:before {
  content: "\e90f";
}
.ze-input:before {
  content: "\e910";
}
.ze-inputed-article:before {
  content: "\e901";
}
.ze-save-close:before {
  content: "\e900";
}
.ze-bay:before {
  content: "\e904";
}
.ze-broadcast-tower:before {
  content: "\e905";
}
.ze-location-coordinates:before {
  content: "\e906";
}
.ze-article-unit-of-measure:before {
  content: "\e907";
}
.ze-volume:before {
  content: "\e908";
}
.ze-article-sorting-type:before {
  content: "\e909";
}
.ze-article-group-storage:before {
  content: "\e90a";
}
.ze-article-location:before {
  content: "\e90b";
}
.ze-unlink-article:before {
  content: "\e90c";
}
.ze-box-open:before {
  content: "\e90d";
}
.ze-print-label:before {
  content: "\e90e";
}
