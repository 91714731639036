import { ApiService } from 'external-src/api.service';
import { CustomLogger, ServiceBase, ViewModelBase } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { Zeus } from 'generated';
import { Router } from 'aurelia-router';
import { DialogController } from 'aurelia-dialog';
import * as Constants from '../../constants';
import * as RTConstants from '../../app-modules/restricted-trays/constants';
import { DataFormat } from 'select2';
import { Utils } from 'utils/utils';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { AppModuleEnum } from 'app-modules/constants';
import { AppModuleService } from 'app-modules/app-module-service';
import { RestrictedTrays } from 'app-modules/restricted-trays/generated';

@autoinject()
export class MoveTrayToBay extends ViewModelBase {
  ribbonHeaderText: string = this.i18n.tr("traycontainer.callTray");
  ressourceName: string = Constants.EntityTypeNames.Bay;

  private storageService: ServiceBase<Zeus.Web.Model.Storage>;

  private params: { storage: Zeus.Web.Model.Storage, trayId: number };
  private selectedStorage: DataFormat;
  private storage: Zeus.Web.Model.Storage;
  private filteredTrayContainers: Zeus.Web.Model.TrayContainer[] = new Array();
  private selectedTrayId: number;
  private selectedBayNumber: number;
  private warnMsg: string;
  private isFetchDone: boolean = false;

  private isRestrictedTraysActive: boolean = false;
  private userRestrictedTrays: RestrictedTrays.Model.TrayRestriction[];

  constructor(router: Router, logger: CustomLogger, private apiService: ApiService, private controller: DialogController, private bindingEngine: BindingEngine, private appModuleService: AppModuleService) {
    super(router, logger);
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.storageService.gridDataSource.expands = ['trayContainers', 'bays'];
    this.storageService.gridDataSource.customSelect2Predicates = () => new Predicate('isUnavailable', FilterQueryOp.Equals, false);
  }

  public async activate(params: { storage: Zeus.Web.Model.Storage, trayId: number }) {
    this.isRestrictedTraysActive = this.appModuleService.isActive(AppModuleEnum.RestrictedTrays);
    if (params) {
      this.params = params;
      if (params.storage) {
        this.storage = params.storage;
        await this.filterTrayContainers();
      }
      if (params.trayId) {
        this.selectedTrayId = params.trayId;
      }
    }
  }

  public attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this, "selectedStorage").subscribe(async (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.storage = null;
          this.selectedTrayId = null;
          this.selectedBayNumber = null;
          await this.filterTrayContainers();
        }
      })
    );
  }

  private async filterTrayContainers() {
    this.filteredTrayContainers.splice(0);
    if (this.storage == null) {
      this.storage = await this.storageService.getEntityByIdFromLocalCache(Utils.extractIdFromDataFormat(this.selectedStorage));
    }
    this.filteredTrayContainers = this.storage.trayContainers.filter(t => false == t.isUnavailable);
    if (this.filteredTrayContainers.length == 0) {
      this.warnMsg = "traycontainer.noTraysAvailable";
    }
    else if (this.isRestrictedTraysActive) {
      if (this.userRestrictedTrays == null) {
        let trayRestrictionService = new ServiceBase<RestrictedTrays.Model.TrayRestriction>(RTConstants.EntityTypeNames.TrayRestriction);
        this.userRestrictedTrays = await trayRestrictionService.getEntities(new Predicate("userId", FilterQueryOp.Equals, this.authService.currentUser.id));
      }
      this.filteredTrayContainers = this.filteredTrayContainers.filter(t => false == t.isRestricted || this.userRestrictedTrays.map(rt => rt.trayId).includes(t.id));
      if (this.filteredTrayContainers.length == 0) {
        this.warnMsg = "traycontainer.noTraysForUser";
      }
    }
    this.filteredTrayContainers.sort((a, b) => a.trayId - b.trayId);
    this.storage.bays.sort((a, b) => a.bayNumber - b.bayNumber);
    // In case of single bay, pre-select bay
    if (this.selectedBayNumber == null && this.storage.bays.length == 1) {
      this.selectedBayNumber = this.storage.bays[0].bayNumber;
    }
    this.isFetchDone = true;
  }

  public async cancel() {
    await this.controller.cancel();
  }

  @computedFrom('storage', 'selectedBayNumber', 'selectedTrayId')
  private get canCallTray(): boolean {
    return this.storage != null && this.selectedBayNumber != null && this.selectedTrayId != null
  }

  public async moveTrayToBay() {
    if (this.canCallTray) {
      try {
        await this.apiService.moveTrayToBay(this.selectedTrayId, this.storage, null, this.selectedBayNumber);
        this.log("traycontainer.calledTray", null, true);
        this.controller.ok();
      } catch (e) {
        if (typeof e == "string") {
          this.logError(this.i18n.tr("general.errorTitle"), e, true);
        }
        else {
          this.logError(this.i18n.tr("general.errorTitle"), e.error, true);
        }
      };
    }
  }
}
