import { I18N } from 'aurelia-i18n';
import { DialogService } from 'aurelia-dialog';
import { BindingEngine, bindingMode, computedFrom, Disposable } from 'aurelia-binding';
import { Zeus } from 'generated';
import { autoinject, bindable, customElement } from "aurelia-framework";
import { EnumerationTypeService, ServiceBase } from 'digiwall-lib';
import * as Constants from '../../constants';
import { Predicate, FilterQueryOp, EntityState } from 'breeze-client';
import { ArticleVolumeConfig } from 'article-volume-config/article-volume-config';
import { Utils } from 'utils/utils';

@autoinject
@customElement('input-storage-parameters')
export class InputStorageParameters {
  @bindable({ defaultBindingMode: bindingMode.twoWay }) private articleToInput: Zeus.Web.Model.ArticleToInput;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) private articleVolumeConfigs: Zeus.Web.Model.ArticleVolumeConfig[] = [];
  @bindable private isLoading: boolean = false; // External storage group params loading
  @bindable private readOnlyMode: boolean = false;

  private isParamsLoading = false;
  private isVolumesLoading = false;

  @computedFrom('isLoading', 'isParamsLoading', 'isVolumesLoading')
  public get showLoader(): boolean {
    return this.isLoading || this.isParamsLoading || this.isVolumesLoading;
  }

  private isSavingDefaultStorageGroup = false;
  private articleStorageGroupForParameter: Zeus.Web.Model.ArticleStorageGroup;

  private articleStorageGroupService: ServiceBase<Zeus.Web.Model.ArticleStorageGroup>;

  private avcService: ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>;
  private asgService: ServiceBase<Zeus.Web.Model.ArticleStorageGroup>;
  private inputAlgorithmService: ServiceBase<Zeus.Web.Model.InputAlgorithm>;

  private storageLocationTypeService: EnumerationTypeService;
  private storageStrategyService: EnumerationTypeService;
  private articleRotationLevelService: EnumerationTypeService;
  private articleVolumeConfigService: ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>;
  private storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  private volumeTypeService: EnumerationTypeService;

  private disposables: Disposable[] = [];
  private volumeConfigDisposables: Disposable[] = [];
  private defaultInputAlgo: Zeus.Web.Model.InputAlgorithm;

  constructor(private bindingEngine: BindingEngine, private dialogService: DialogService, private i18n: I18N) {
    this.articleStorageGroupService = new ServiceBase<Zeus.Web.Model.ArticleStorageGroup>(Constants.EntityTypeNames.ArticleStorageGroup);

    this.avcService = new ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>(Constants.EntityTypeNames.ArticleVolumeConfig);
    this.asgService = new ServiceBase<Zeus.Web.Model.ArticleStorageGroup>(Constants.EntityTypeNames.ArticleStorageGroup);
    this.inputAlgorithmService = new ServiceBase<Zeus.Web.Model.InputAlgorithm>(Constants.EntityTypeNames.InputAlgorithm);

    this.storageLocationTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageLocationType);
    this.storageStrategyService = new EnumerationTypeService(Constants.EnumerationTypes.StorageStrategy);
    this.articleRotationLevelService = new EnumerationTypeService(Constants.EnumerationTypes.ArticleRotationLevel);
    this.articleVolumeConfigService = new ServiceBase<Zeus.Web.Model.ArticleVolumeConfig>(Constants.EntityTypeNames.ArticleVolumeConfig);



    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];
    this.storageVolumeService.gridDataSource.customSelect2Predicates = (filterText: string, filterProperties: string[]) => {
      let avcIdsToExclude = this.articleVolumeConfigs.filter(x => x.storageVolumeId != null).map(x => x.storageVolumeId);
      return Predicate.and(avcIdsToExclude.map(avcId => new Predicate('id', FilterQueryOp.NotEquals, avcId)));
    }

    this.volumeTypeService = new EnumerationTypeService(Constants.EnumerationTypes.StorageVolumeType);
    this.volumeTypeService.gridDataSource.customSelect2Predicates = (filterText: string, filterProperties: string[]) => {
      let avcIdsToExclude = this.articleVolumeConfigs.filter(x => x.storageVolume.volumeTypeId != null).map(x => x.storageVolume.volumeTypeId);
      return Predicate.and(avcIdsToExclude.map(avcId => new Predicate('id', FilterQueryOp.NotEquals, avcId)));
    }

    new ServiceBase<Zeus.Web.Model.InputAlgorithm>(Constants.EntityTypeNames.InputAlgorithm)
      .firstEntity(new Predicate('isDefault', FilterQueryOp.Equals, true))
      .then(defaultAlgo => this.defaultInputAlgo = defaultAlgo);
  }

  private async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.articleToInput, 'articleStorageGroupForParameterId')
        .subscribe(async () => await this.initParameters()),
      this.bindingEngine.propertyObserver(this.articleToInput, 'unitOfMeasureId')
        .subscribe(async () => await this.initVolumeConfigs()),
      this.bindingEngine.propertyObserver(this.articleToInput, 'articleId')
        .subscribe(async () => await this.initVolumeConfigs())
    );

    await this.initParameters();
    await this.initVolumeConfigs();
  }

  @computedFrom('defaultInputAlgo.name._translation')
  private get inputAlgorithmPlaceholder(): string {
    return `${this.i18n.tr('algorithm.usingDefault')}: ${this.defaultInputAlgo?.name._translation}`;
  }

  private async initParameters() {
    this.isParamsLoading = true;
    this.cancelParameters();

    this.articleStorageGroupForParameter = await this.articleStorageGroupService.getEntityById(this.articleToInput.articleStorageGroupForParameterId,
      'inputAlgorithm', 'storageLocationType1', 'storageLocationType2', 'storageLocationType3', 'articleRotationLevel', 'storageStrategy'
    );
    this.isParamsLoading = false;
  }

  private cancelParameters() {
    this.articleStorageGroupForParameter?.entityAspect?.rejectChanges();
  }

  private async initVolumeConfigs() {
    this.isVolumesLoading = true;
    this.cancelVolumeConfigChanges();

    this.articleVolumeConfigs = await this.articleVolumeConfigService.getEntities(
      Predicate.and(
        new Predicate('articleId', FilterQueryOp.Equals, this.articleToInput.articleId),
        new Predicate('unitOfMeasureId', FilterQueryOp.Equals, this.articleToInput.unitOfMeasureId)
      ), ['storageVolume.volumeType']);


    this.articleVolumeConfigObserver();
    this.isVolumesLoading = false;
  }

  private cancelVolumeConfigChanges() {
    this.articleVolumeConfigs?.forEach(a => {
      if (a.isNew) {
        a.entityAspect?.setDetached();
      } else if (a.entityAspect && false == a.entityAspect.entityState.isDetached()) {
        a.entityAspect.rejectChanges();
      }
    });

    this.volumeConfigDisposables.forEach(x => x.dispose());
  }

  private getVolumeRowClass(record: Zeus.Web.Model.ArticleVolumeConfig) {
    return record?.default ? 'ui-bg--primary-tint' : '';
  }

  private detached() {
    this.disposables.forEach(d => d.dispose());

    this.cancelParameters();
    this.cancelVolumeConfigChanges();
  }

  private articleVolumeConfigObserver(avc?: Zeus.Web.Model.ArticleVolumeConfig) {
    ((avc == null) ? this.articleVolumeConfigs : [avc]).forEach(x => {
      this.volumeConfigDisposables.push(
        this.bindingEngine.propertyObserver(x, 'default').subscribe((n, o) => {
          if (n) {
            x.entityAspect.setModified();
            this.articleVolumeConfigs.filter(y => y.id != x.id && false === y.entityAspect.entityState.isDetached()).forEach(y => {
              if (x.id != y.id && y.default) {
                y.default = false;
                x.entityAspect.setModified();
              }
              else {
                y.entityAspect.setUnchanged();
              }
            });
          }
        })
      );
    });
  }

  private async addVolumeConfig() {
    let articleVolumeConfig: Zeus.Web.Model.ArticleVolumeConfig = await this.articleVolumeConfigService.createEntity({
      articleId: this.articleToInput.articleId,
      unitOfMeasureId: this.articleToInput.unitOfMeasureId
    });
    this.articleVolumeConfigs.push(articleVolumeConfig);
  }

  public removeVolumeConfig(volumeConfig: Zeus.Web.Model.ArticleVolumeConfig): void {
    Utils.detachOrDeleteEntity(volumeConfig);
    let index = this.articleVolumeConfigs.findIndex(x => x == volumeConfig);
    if (index >= 0) {
      this.articleVolumeConfigs.splice(index, 1);
    }
  }

  public async saveParamStorageGroup(): Promise<boolean> {
    if (this.isSavingDefaultStorageGroup) return;
    this.isSavingDefaultStorageGroup = true;
    await this.asgService.saveEntity(this.articleStorageGroupForParameter, true);

    await this.storageVolumeService.saveEntities(this.articleVolumeConfigs.filter(x => x.entityAspect.entityState.isAdded()).map(avc => avc.storageVolume));
    await this.articleVolumeConfigService.saveEntities(this.articleVolumeConfigs, true);

    this.isSavingDefaultStorageGroup = false;
    return Promise.resolve(true);
  }
}
