import { Zeus } from 'generated';
import * as Constants from "../../../constants";
import { InventoryArticleBaseFloatingBox } from './inventory-article-base-floating-box';
import { Endpoints } from 'endpoints';

export class InventoryArticleFloatingBox extends InventoryArticleBaseFloatingBox {

  constructor(workOrder: Zeus.Web.Model.WorkOrder) {
    super(workOrder);
  }

  protected async beforeSave(): Promise<boolean> {
    // Catch empty lotnumbers
    if (null == this.workOrder.inventoryLinks.find((inv: Zeus.Web.Model.InventoryWorkOrderArticle) =>
      inv.articleId == this.link.articleId && (inv.lotNumber == this.link.lotNumber || inv.lotNumber == null))
    ) {
      // Check article compatibility
      let response = await this.httpClient.fetch(Endpoints.Article.GetArticleStorageGroups
        + "?articleId=" + this.link.articleId + (this.workOrder.limitedToStorageGroupId ? "&storageGroupId=" + this.workOrder.limitedToStorageGroupId : ''));
      return response.ok;
    }
    return false;
  }
}
