import { Container } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Box } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { Endpoints } from 'endpoints';
import * as toastr from 'toastr';

export class ConveyorUtils {
  public static async canUseBinNumber(barcode: string, displayMode: 'toast' | 'box') {
    var httpClient = Container.instance.get(HttpClient);
    var i18n = Container.instance.get(I18N);
    var box = Container.instance.get(Box);

    let result = await httpClient.get(Endpoints.Reception.IsBinAlreadyInUse + `?binNumber=${barcode}`);
    if (result.ok) {
      return true;
    }
    if (result.status == 409) {
      let response = await result.text();
      let errorMsg = i18n.tr("launchinbin.binInUse") + " " + response;
      if (displayMode == "toast") {
        toastr.error(errorMsg);
      } else {
        box.showError(errorMsg, i18n.tr('general.error'));
      }
    }
    return false;
  }
}