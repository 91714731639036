import { CustomLogger, ServiceBase, EntityDetailViewModelBase, EditingModeEnum } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import moment from 'moment';

@autoinject
export class ActionHistoryDetail extends EntityDetailViewModelBase<Zeus.Web.Model.ActionHistory> {

  public ressourceName: string = Constants.EntityTypeNames.ActionHistory;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.ActionHistory>(Constants.EntityTypeNames.ActionHistory));
  }

  public async activate(params) {
    const id = params.param1;
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.getEntityById(id, 'actionType', 'workOrder.inventoryTarget', 'article', 'location', 'workstationUser');
    this.controller.addObject(this.entity);
  }

  get ribbonHeaderText(): string {
    return this.i18n.tr('actionhistory.actionHistory') + ': ' + this.documentTitle;
  }

  get documentTitle(): string {
    return moment((this.entity as Zeus.Web.Model.ActionHistory).actionDateTime).format('DD/MM/YYYY, HH:mm:ss');
  }

  private get isInput(): boolean {
    return this.entity.actionTypeId == Constants.WorkOrderType.Input;
  }

  private get isInventory(): boolean {
    return this.entity.actionTypeId == Constants.WorkOrderType.Inventory;
  }
}
