@import "../../../css/main.scss";

.ui-content-storage-tree-with-panel {
  overflow-y: hidden;

  .tree-col,
  .panel-col {
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{$ui-navbar-height} - #{$base-spacing} - #{$ui-viewport-footer-height});
  }

  .tree-col {
    > :first-child {
      flex: 0 1 auto;
    }
    > :last-child {
      flex: 1 1 auto;
      width: 100%;

      ui-tree-panel {
        height: 100%;
        display: flex;
        flex-direction: column;

        > :first-child {
          flex: 0 1 auto;
        }
        > :last-child {
          flex: 1 1 auto;
          height: 100%;
        }
      }
    }
  }

  .panel-col {
    > storage-detail-view {
      display: flex;
      flex-direction: column;
      height: calc(100vh - #{$ui-navbar-height} - #{$base-spacing * 1.5} - #{$ui-viewport-footer-height});

      > :first-child {
        flex: 0 1 auto;
      }
      > :last-child {
        flex: 1 1 auto;
      }
    }
  }
}
