import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { Actions, PermissionsConfig } from "digiwall-lib";
import * as ALConstants from "./constants";
import * as Constants from "../../constants";
import translationJson from './locales/index';
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";

export class AutoLaunchRoot extends AppModuleRoot {
  public get name() {
    return AppModule.AutoLaunch;
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return {};
  }

  protected get pathName(): string {
    return 'auto-launch';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [
      {
        after: Constants.RouteName.AppParam,
        route: 'auto-launch-parameter/:param1',
        name: 'auto-launch-parameter/auto-launch-parameter',
        moduleId: 'auto-launch-parameter/auto-launch-parameter',
        title: 'autolaunchparameter.autolaunchparameters',
        href: 'auto-launch-parameter/all',
        nav: true,
        settings: { group: Constants.MenuType.ApplicationParameters, resource: ALConstants.EntityTypeNames.AutoLaunchParameter, action: Actions.Read }
      },
    ];
  }

  protected get resourceNames(): {} {
    return ALConstants.EntityTypeNames.ResourceNames;
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        { context: ALConstants.EntityTypeNames.AutoLaunch, qualifiedName: [Constants.MenuType.HomePage, 'storagegroup.activateAutoLaunch'] }
      ],
      permissionNames: {
        'auto-launch': {
          [ALConstants.Permissions.ActivateAutoLaunch]: 'storagegroup.activateAutoLaunch'
        }
      }
    };
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
