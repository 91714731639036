import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, getButtonsColDef, ButtonsColDefAction, IMenuItems, DialogBoxViewModel, GridHelper, Actions } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import { ColDef } from "ag-grid-community";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { ListViewModelActionHistoryFilter } from 'action-histories/action-history-list';
import { DialogService } from 'aurelia-dialog';
import { PrintLabel } from 'app-modules/label-printing/print-label/print-label';
import { TrayPreviewDialog } from './tray-preview-dialog';
import { LocationDetailFromTemplate } from './location-detail-from-template';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { UpdateLocations } from './update-locations';
import { LocationUtils } from '../utils/utils-locations';
import { SiteManagementSelectionHelper } from 'external-src/site-management/site-management-select-view';
import * as AMConstants from 'app-modules/constants';
import * as LPConstants from 'app-modules/label-printing/constants';
import { AppModuleService } from 'app-modules/app-module-service';

@autoinject
export class LocationList extends ListViewModelBase<Zeus.Web.Model.Location> {
  public ressourceName: string = Constants.EntityTypeNames.Location;
  private storageService: ServiceBase<Zeus.Web.Model.Storage>;
  private storageGroupService: ServiceBase<Zeus.Web.Model.StorageGroup>;
  private articleLocationService: ServiceBase<Zeus.Web.Model.ArticleLocation>
  private storage: Zeus.Web.Model.Storage;

  private displayAllReservedLocation: Boolean = false;
  private addMethods: IMenuItems[];

  private labelPrintingActive: boolean = false;
  private printlabelMenuItems: IMenuItems[];

  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private dialogService: DialogService, private appModuleService: AppModuleService) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location));
    this.service.gridDataSource.expands = [
      'storage', 'dedicatedToRotationLevel', 'storageLocationType', 'defaultStorageVolume.volumeType',
      'articleLocations.article.unitOfMeasures.unitOfMeasure',
      'articleLocations.article.articleVolumeConfigs.storageVolume.volumeType',
      'articleLocations.article.articleLocations'
    ];

    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.articleLocationService = new ServiceBase<Zeus.Web.Model.ArticleLocation>(Constants.EntityTypeNames.ArticleLocation);
    this.storageGroupService = new ServiceBase<Zeus.Web.Model.StorageGroup>(Constants.EntityTypeNames.StorageGroup);

    this.addMethods = [
      {
        label: this.i18n.tr('location.singleLocation'),
        handler: () => this.addLocationModal()
      },
      {
        label: this.i18n.tr('location.locationFromTemplate'),
        handler: async () => await this.addFromTemplate()
      },
      // Hidden for now : Create location from mask
      // {
      //   label: this.i18n.tr('location.locationFromMask'),
      //   // icon: 'digi-plus',
      //   handler: async () => await this.addFromMask()
      // }
    ];

    this.labelPrintingActive = this.appModuleService.isActive(AMConstants.AppModuleEnum.LabelPrinting);
    this.printlabelMenuItems = [
      {
        label: this.i18n.tr('printlabel.selectedLocation'),
        handler: () => this.printSelectedLocation(),
        disabled: () => this.selectedEntitiesCount == 0
      },
      {
        label: this.i18n.tr('printlabel.locationRange'),
        handler: () => this.printLocationRange()
      }
    ];
  }

  @computedFrom('storage.name')
  public get getListTitle() {
    if (this.storage != null) {
      return this.i18n.tr("location.locationsFor") + this.storage.name;
    }
    return this.i18n.tr("location.locations");
  }

  public get ribbonHeaderText() {
    let usedView = this.agGridViewList?.find(x => x.used);
    return this.getListTitle + ((usedView != null && !usedView.isDefaultView) ? ` - ${this.i18n.tr("view.view", { ns: "common" })} : ${usedView.name}` : '');
  }

  @computedFrom('storage.id')
  private get canBack(): boolean {
    return this.storage != null;
  }

  public async activate(params: { param1: number | string }) {
    const id: number = Number(params.param1);
    if (false == isNaN(id)) {
      this.service.gridDataSource.getPredicatesFunction = () => new Predicate('storageId', FilterQueryOp.Equals, id);
      this.storage = await this.storageService.getEntityById(id);
    }
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.Location) {
    if (entity && entity.id) {
      return '/locations-detail/' + entity.id;
    }
    return null;
  }

  public openActionHistory(): void {
    if (this.selectedEntities.length > 0) {
      let ids = this.selectedEntities.map(e => e.id);
      let filter = new ListViewModelActionHistoryFilter('location', ids);
      this.navigateTo('action-history/all/' + filter.encode());
    }
  }

  private async openBulkUpdate() {
    if (SiteManagementSelectionHelper.selectedSite.storageGroupTypeId == Constants.StorageGroupTypes.Site) {
      this.box.showError(this.i18n.tr("location.siteSelected"), this.i18n.tr("general.errorTitle"));
      return;
    }
    await this.dialogService.open({
      viewModel: UpdateLocations, model: {}
    }).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        this.gridOptions.api.refreshInfiniteCache();
      }
    });
  }

  //#region addMethods
  private async addFromTemplate() {
    await this.dialogService.open({ viewModel: LocationDetailFromTemplate })
      .whenClosed(result => {
        if (!result.wasCancelled) {
          this.gridOptions.api.refreshInfiniteCache();
        }
      });
  }

  private async addLocationModal() {
    if (!await LocationUtils.addLocationModal()) {
      this.gridOptions.api.refreshInfiniteCache();
    }
  }
  //#endregion

  //#region ArticleLocation
  // Fake computedFrom in order to not recalculate
  @computedFrom('ressourceName')
  private get canDeleteArticleLocations(): boolean {
    return this.authService.checkAccess(Constants.EntityTypeNames.ArticleLocation, Actions.Delete);
  }

  @computedFrom('canDeleteArticleLocations', 'displayAllReservedLocation', 'selectedEntities.length')
  private get showDeleteArticleLocations(): boolean {
    return (this.displayAllReservedLocation && this.selectedEntities.length > 0)
      || (false == this.displayAllReservedLocation && this.selectedEntities.filter(x => x.articleLocations?.find(artLoc => this.isArticleLocationEmpty(artLoc))).length > 0);
  }

  public async deleteArticleLocation() {
    let locationToDelete = [];

    this.selectedEntities.forEach(loc =>
      locationToDelete.push(...loc.articleLocations?.filter(articleLoc => this.isArticleLocationEmpty(articleLoc)))
    );

    await this.box.showQuestion(this.i18n.tr("location.deleteArticleLocation") + " ?", this.i18n.tr("menu.question", { ns: "common" }),
      [
        { label: this.i18n.tr('menu.close'), theme: 'dark', type: 'ghost', closeDialog: true, fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
        { label: this.i18n.tr('menu.ok'), theme: 'primary', type: 'solid', closeDialog: true, fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() }
      ]).whenClosed(async result => {
        if (!result.wasCancelled) {
          await this.articleLocationService.deleteEntities(locationToDelete, false, null, true);
          this.gridOptions.api.refreshInfiniteCache();
        }
      });
  }

  private isArticleLocationEmpty(articleLocation: Zeus.Web.Model.ArticleLocation): boolean {
    return articleLocation.currentQuantityInLocation == 0 && articleLocation.reservedInputQuantity == 0 && articleLocation.reservedPickingQuantity == 0;
  }

  public async findReservedLocation(getAllLocation: boolean) {
    this.displayAllReservedLocation = getAllLocation;
    this.service.gridDataSource.queryParameters = { getReservedLocation: getAllLocation };
    if (this.gridOptions.api) {
      this.gridOptions.api.refreshInfiniteCache();
    }
  }
  //#endregion

  //#region Printing label
  private async printSelectedLocation() {
    await this.dialogService.open({
      viewModel: PrintLabel, model: { labelTypeId: LPConstants.LabelTypeId.Location, isLocationRangeMode: false, locations: await this.getSelectedEntitiesFromCache() }
    });
  }

  private async printLocationRange() {
    if (SiteManagementSelectionHelper.selectedSite.storageGroupTypeId == Constants.StorageGroupTypes.Site) {
      this.box.showError(this.i18n.tr("location.siteSelected"), this.i18n.tr("general.errorTitle"));
      return;
    }
    await this.dialogService.open({
      viewModel: PrintLabel, model: { labelTypeId: LPConstants.LabelTypeId.Location, isLocationRangeMode: true }
    });
  }
  //#endregion

  //#region AG GRID
  // Reset filters when coming from another storage
  public async onGridReady() {
    await super.onGridReady();
    if (this.storage != null) {
      this.resetFilters();
    }
  }

  public initializeGridOptions(showFixedFilter?: boolean, hasHyperlinkColumn?: boolean | null): void {
    super.initializeGridOptions(showFixedFilter, hasHyperlinkColumn);

    const onModelUpdated = this.gridOptions.onModelUpdated;
    this.gridOptions.onModelUpdated = async event => {
      onModelUpdated(event);

      let storageGroupMap = new Map<number, boolean>();
      event?.api?.forEachNode(rowNode => {
        if (rowNode.data != null) {
          let sgId = (<Zeus.Web.Model.Location>rowNode.data).storage?.storageGroupId;
          if (sgId && false == storageGroupMap.has(sgId)) {
            storageGroupMap.set(sgId, true);;
          }
        }
      });

      let sgIds = Array.from(storageGroupMap.keys())
      for (let i = sgIds.length - 1; i >= 0; --i) {
        let storageGroup = await this.storageGroupService.getEntityByIdFromLocalCache(sgIds[i]);
        if (storageGroup?.parentStorageGroup?.parentStorageGroup?.parentStorageGroup != null) {
          // Aleady loaded
          sgIds.splice(i, 1);
        }
      }

      if (sgIds.length > 0) {
        let idsPredicate = sgIds.map(x => new Predicate('id', FilterQueryOp.Equals, x));
        await this.storageGroupService.getEntities(Predicate.or(idsPredicate), ['parentStorageGroup.parentStorageGroup.parentStorageGroup']);
        this.gridOptions.api.refreshCells();
      }
    };
  }

  public getDataGridColumns() {
    let vm = this;
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      getButtonsColDef<Zeus.Web.Model.Location>([
        new ButtonsColDefAction<Zeus.Web.Model.Location>({
          icon: 'ze-volume',
          title: this.i18n.tr('location.trayPreview'),
          clicked: async location => {
            await vm.dialogService.open({
              viewModel: TrayPreviewDialog,
              model: { trayId: location.trayContainerId, locationId: location.id },
            });
          }
        })
      ]),
      {
        headerName: this.i18n.tr("storage.identifier"),
        field: "locationIdentifier",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("storage.identifierAlias"),
        field: "locationIdentifierAlias",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.width"),
        field: "storageUnitWidth",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("storage.length"),
        field: "storageUnitLength",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("storage.height"),
        field: "storageUnitHeight",
        type: FieldType.Number
      },
      {
        headerName: this.i18n.tr("location.dedicatedToRotationLevelId"),
        field: "dedicatedToRotationLevel.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.ArticleRotationLevel,
        },
      },
      {
        headerName: this.i18n.tr("storage.storageLocationTypeId"),
        field: "storageLocationType.denomination._translation",
        hide: true,
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.StorageLocationType
        }
      },
      {
        headerName: this.i18n.tr("storage.zoneName"),
        field: "storage.storageGroup.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.zoneGroupName"),
        field: "storage.storageGroup.parentStorageGroup.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.warehouseName"),
        field: "storage.storageGroup.parentStorageGroup.parentStorageGroup.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.siteName"),
        field: "storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("storage.isUnavailable"),
        field: "storage.isUnavailable",
        hide: true,
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("location.storageId"),
        field: "storage.name",
        hide: true,
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("location.moreThanOneArticleAllowed"),
        field: "moreThanOneArticleAllowed",
        hide: true,
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("location.notAvailableForPicking"),
        field: "notAvailableForPicking",
        hide: true,
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("location.notAvailableForInput"),
        field: "notAvailableForInput",
        hide: true,
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("location.lastInventoryTime"),
        field: "lastInventoryTime",
        type: FieldType.DateTime,
        floatingFilterComponentParams: {
          dateFormat: 'DD/MM/YYYY, HH:mm:ss'
        },
      },
      {
        headerName: this.i18n.tr("location.articlesLinked"),
        sortable: false,
        filter: false,
        resizable: true,
        // Remove default onCellClickedBehaviour
        onCellClicked: () => { },
        valueGetter: (params) => {
          return params.data?.articleLocations?.map((articleLocation: Zeus.Web.Model.ArticleLocation) => {
            return `${articleLocation.article.reference} (${articleLocation.currentQuantityInLocation || 0})`
          }).join(', ');
        },
        cellRenderer: (params: { data: Zeus.Web.Model.Location }) => {
          return params.data?.articleLocations?.map((articleLocation: Zeus.Web.Model.ArticleLocation) => {
            return `<a href="/articles/${articleLocation.articleId}" title="${articleLocation.article.description._translation}" class="ui-link" style="color: rgba(0, 0, 0, 0.87);">`
              + `${articleLocation.article.reference}</a> (${articleLocation.currentQuantityInLocation || 0})`;
          }).join(', ');
        }
      },
      {
        headerName: this.i18n.tr("location.locationVolume"),
        field: "defaultStorageVolume.volumeType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.StorageVolumeType
        }
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
  //#endregion
} 
