import { AppModuleRoot, AppModuleRouteConfig } from "app-modules/app-module-root";
import { PermissionsConfig } from "digiwall-lib";
import { EnumerationTypeCategory } from "digiwall-lib";
import * as breeze from 'breeze-client';
import { AppModule } from "app-modules/constants";
import translationJson from './locales/index';
import { Permissions, RoleNames } from "../../constants";
import * as ReceptionConstants from "../../reception/constants";

export class MobileScreensRoot extends AppModuleRoot {
  public get name() {
    return AppModule.MobileScreens;
  }

  public get translations(): { [key: string]: object; } {
    return translationJson;
  }

  public get enumerationTypeCategories(): EnumerationTypeCategory[] {
    return [];
  }

  public get enumerationTypeList(): object {
    return {};
  }

  protected get pathName(): string {
    return 'mobile-screens';
  }

  protected get routes(): AppModuleRouteConfig[] {
    return [];
  }

  protected get resourceNames(): {} {
    return {};
  }

  protected get permissionsConfig(): PermissionsConfig {
    return {
      perContext: [
        { context: ReceptionConstants.Permissions.Reception, qualifiedName: [RoleNames.Mobile] }
      ],
      permissionNames: {
        [ReceptionConstants.Permissions.Reception]: {
          [Permissions.Access]: ReceptionConstants.RoleNames.Reception
        }
      }
    };
  }

  public setupModel(metadataStore: breeze.MetadataStore, manager: breeze.EntityManager) {
  }
}
