import { HttpClient } from 'aurelia-fetch-client';
import { Container } from "aurelia-framework";
import { ServiceBase } from "digiwall-lib";
import { Zeus } from 'generated';
import * as Constants from '../../../constants';
import { InventoryBaseFloatingBox } from "./inventory-base-floating-box";
import { CreateLotNumberService } from '../lot-number-helper';
import { FilterQueryOp, Predicate } from 'breeze-client';

export abstract class InventoryArticleBaseFloatingBox extends InventoryBaseFloatingBox<Zeus.Web.Model.InventoryWorkOrderArticle> {

  private articleService: ServiceBase<Zeus.Web.Model.Article>;
  private articleLocationService: ServiceBase<Zeus.Web.Model.ArticleLocation>;
  private articlePredicates: () => Predicate;

  protected httpClient: HttpClient;

  constructor(workOrder: Zeus.Web.Model.WorkOrder, floatingName: string = null) {
    super(workOrder, 'article', new ServiceBase<Zeus.Web.Model.InventoryWorkOrderArticle>(Constants.EntityTypeNames.InventoryWorkOrderArticle), floatingName);

    this.articleService = new ServiceBase<Zeus.Web.Model.Article>(Constants.EntityTypeNames.Article);
    this.articleLocationService = CreateLotNumberService(this.link);

    this.httpClient = Container.instance.get(HttpClient);

    this.articlePredicates = () => {
      let predicates: Predicate[] = this.workOrder.inventoryLinks?.filter(x => (x as Zeus.Web.Model.InventoryWorkOrderArticle).articleId != null)
        .map(x => new Predicate('id', FilterQueryOp.NotEquals, (x as Zeus.Web.Model.InventoryWorkOrderArticle).articleId))
        || [];
      if (predicates.length > 0) {
        return Predicate.and(predicates);
      }
      return null;
    }
  }
}
