export enum EnumerationTypes {
  CheckScanningMethod = 39
}

export class EntityTypeNames {
  public static CheckScanningConfiguration = "CheckScanningConfiguration";

  public static ResourceNames = {
    CheckScanningConfiguration: "CheckScanningConfigurations"
  };
}

export enum CheckScanningMethod {
  NoScan = 390,
  Article,
  Location,
  LotNumber
}
