import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AuthService, Datacontext } from "digiwall-lib";
import environment from "environment";
import { EventAggregator } from "aurelia-event-aggregator";
import { DialogService } from "aurelia-dialog";
import moment = require('moment');
import 'moment/locale/fr';
import 'moment/locale/nl';

@autoinject
export class Layout {

  theme: string = "grey";
  constructor(private i18n: I18N, private eventAggregator: EventAggregator, public authService: AuthService, private dialogService: DialogService, private context: Datacontext) {

  }

  private switchLanguage(language: string) {
    if (this.isValidLanguage(language)) {
      this.i18n.setLocale(language);
      moment.locale(language);
      this.eventAggregator.publish('changeLanguage');
    }
  }

  private isValidLanguage(language: string): boolean {
    return (language == 'fr' || language == 'nl');
  }

  private disconnect() {
    // Deconnexion de l'utilisateur
    this.authService.logout();
  }
}
