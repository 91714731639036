import { HttpClient } from 'aurelia-fetch-client';
import { BindingEngine, autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { AuthService, CustomLogger, DisposableViewModelBase, ServiceBase, UIInternal, } from 'digiwall-lib';
import { Zeus } from 'generated';
import * as Constants from '../../../constants';
import * as FPConstants from '../constants';
import { FilterQueryOp, Predicate } from 'breeze-client';

@autoinject
export class FreePicking extends DisposableViewModelBase {
  private previewUrl: string;
  private isLoading: boolean;

  private workOrderService: ServiceBase<Zeus.Web.Model.WorkOrder>;
  private projectService: ServiceBase<Zeus.Web.Model.Project>;
  private costCenterService: ServiceBase<Zeus.Web.Model.CostCenter>;

  private parameters: FreePickingParameters = new FreePickingParameters();

  constructor(private logger: CustomLogger, private http: HttpClient, public i18n: I18N, public authService: AuthService, private bindingEngine: BindingEngine) {
    super();
    this.workOrderService = new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder);
    this.projectService = new ServiceBase<Zeus.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.costCenterService = new ServiceBase<Zeus.Web.Model.CostCenter>(Constants.EntityTypeNames.CostCenter);
  }

  public async activate(params) {
    this.workOrderService.gridDataSource.customSelect2Predicates = () => { return new Predicate("workOrderTypeId", FilterQueryOp.NotEquals, Constants.WorkOrderType.Input) };
    if (false == this.canFreePick) {
      this.parameters.type = this.canTrayPick ? 1 : 2;
    }
  }

  private isFetching: boolean = false;

  public async attached() {
    this.disposables.push(
      this.bindingEngine.propertyObserver(this.parameters, "workOrderId").subscribe(async (newValue, oldValue) => {
        if (!this.isFetching && newValue != null && newValue != oldValue) {
          this.isFetching = true;
          this.parameters.workOrderId = null;
          this.parameters.workOrder = await this.workOrderService.getEntityById(newValue);
          this.parameters.workOrderId = newValue;
          UIInternal.nextTick(() => { this.isFetching = false });
        }
      }),
      this.bindingEngine.propertyObserver(this.parameters, "projectId").subscribe(async (newValue, oldValue) => {
        if (!this.isFetching && newValue != null && newValue != oldValue) {
          this.isFetching = true;
          this.parameters.projectId = null;
          this.parameters.project = await this.projectService.getEntityById(newValue);
          this.parameters.projectId = newValue;
          UIInternal.nextTick(() => { this.isFetching = false });
        }
      }),
      this.bindingEngine.propertyObserver(this.parameters, "costCenterId").subscribe(async (newValue, oldValue) => {
        if (!this.isFetching && newValue != null && newValue != oldValue) {
          this.isFetching = true;
          this.parameters.costCenterId = null;
          this.parameters.costCenter = await this.costCenterService.getEntityById(newValue);
          this.parameters.costCenterId = newValue;
          UIInternal.nextTick(() => { this.isFetching = false });
        }
      }),
    );
  }

  @computedFrom("authService")
  public get canExtraPick(): boolean {
    return this.authService.checkAccess(FPConstants.Permissions.ExtraPick, FPConstants.Action.Access);
  }

  @computedFrom("authService")
  public get canTrayPick(): boolean {
    return this.authService.checkAccess(FPConstants.Permissions.TrayPick, FPConstants.Action.Access);
  }

  @computedFrom("canExtraPick", "canTrayPick")
  public get canFreePick(): boolean {
    return this.canExtraPick && this.canTrayPick;
  }

  private async generate() {
    if (this.parameters.type == FreePickingType.None) {
      this.logger.LogError(this.i18n.tr('report.noTypeSelected'), this.parameters, null, true);
      return;
    }
    this.isLoading = true;
    try {
      let response = await this.http.post(FPConstants.Application.Report,
        JSON.stringify(this.parameters, (key, val) => {
          if (key == 'workOrder' || key == 'project' || key == 'costCenter') return null;
          return val
        })
      );
      if (response.ok) {
        this.previewUrl = await response.text();
      } else {
        this.logger.LogError(this.i18n.tr('report.generateFailed'), null, null, true);
        console.log(response.statusText);
      }
    } catch (e) { }
    this.isLoading = false;
  }
}

class FreePickingParameters {
  startDate: Date;
  endDate: Date;
  workOrderId: number | null;
  workOrder: Zeus.Web.Model.WorkOrder;
  projectId: number | null;
  project: Zeus.Web.Model.Project;
  costCenterId: number | null;
  costCenter: Zeus.Web.Model.CostCenter;
  type: FreePickingType;

  constructor() {
    this.type = FreePickingType.None;
  }
}

enum FreePickingType {
  Both, TrayPick, ExtraPick, None
}
