@import "../../../css/main.scss";

.reporting-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
  > * {
    width: 100%;
  }

  > .report {
    flex: 1 1 auto;
    embed {
      width: 100%;
      height: 99%;
    }
  }
}
