import { autoinject } from "aurelia-framework";
import { AbstractHubClient } from "digiwall-lib";
import * as Constants from '../constants';

@autoinject
export class ZeusHubClient extends AbstractHubClient {

  protected get endpoint(): string {
    return '/zeusHub';
  }

  public onRefreshHomePage(listener: () => void) {
    return this.subscribe('RefreshHomePage', listener);
  }

  public onRefreshBinOverview(listener: () => void) {
    return this.subscribe('RefreshBinOverview', listener);
  }

  public onSiteSelectionChanged(listener: (storageGroupId: number) => void) {
    return this.subscribe('SiteSelectionChanged', listener);
  }

  public onImportFinished(listener: (dataType: Constants.DataFileImportType) => void) {
    return this.subscribe('ImportFinished', listener);
  }

  public onRefreshWorkstationPosition(listener: () => void) {
    return this.subscribe('RefreshWorkstationPosition', listener);
  }

  public subscribeToTopic(topic: string) {
    this.send('subscribe', topic);
  }

  public unsubscribeToTopic(topic: string) {
    this.send('unsubscribe', topic);
  }
}
