export class Application {
  public static LinkBinToPickingWorkOrder = "/api/Conveyor/Utils/LinkBinToPickingWorkOrder";
}

export class Permissions {
  public static LaunchPickBin = 'launch-pick-bin';
  public static BinOverview = 'binOverview';
}

export class EntityTypeNames {
  public static ConveyorConfiguration = "ConveyorConfiguration";
  public static AutoLaunchStation = "AutoLaunchStation";
  public static ExitStation = "ExitStation";
  public static ConveyorBin = "ConveyorBin";

  public static ResourceNames = {
    ConveyorConfiguration: "ConveyorConfigurations",
    AutoLaunchStation: "AutoLaunchStations",
    ExitStation: "ExitStations",
    ConveyorBin: "ConveyorBins",
  };
}
