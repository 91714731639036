import { CustomLogger, EditingModeEnum, ViewModelBase, EnumerationTypeService, ServiceBase } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import { Zeus } from 'generated';
import * as Constants from '../../constants';
import { Router } from 'aurelia-router';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class ArticleToInputStorageGroups extends ViewModelBase {
  get ribbonHeaderText(): string { return ''; }
  public ressourceName: string;

  public articleStorageGroupService: ServiceBase<Zeus.Web.Model.ArticleStorageGroup>;
  public storageGroups: Array<Zeus.Web.Model.StorageGroup> = [];
  public changeLimitedStorageGroup: boolean = false;

  get documentTitle(): any {
    return this.i18n.tr('articletoinputstoragegroup.errorMsg')
  }

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController) {
    super(router, logger);
    this.articleStorageGroupService = new ServiceBase<Zeus.Web.Model.ArticleStorageGroup>(Constants.EntityTypeNames.ArticleStorageGroup, null, 'ArticleStorageGroupsByStorageGroups');
  }

  public async activate(params: { storageGroups: Zeus.Web.Model.StorageGroup[], articleId: number }) {
    await super.activate(params);
    this.storageGroups = params.storageGroups;
    let articleStorageGroups = await this.articleStorageGroupService.getEntities(null, ['articleRotationLevel', 'storageStrategy', 'storageLocationType1', 'storageLocationType2', 'storageLocationType3'], { articleId: params.articleId, storageGroupsId: this.storageGroups.map(x => x.id) })
    this.storageGroups.forEach(x => {
      let asg = articleStorageGroups.find(y => y.storageGroupId == x.id);
      if (asg == null) {
        asg = articleStorageGroups.find(y => y.storageGroupId == x.parentStorageGroupId);
      }
      (x as any).articleStorageGroup = asg;

      let storageGroupType = "";
      switch (x.storageGroupTypeId) {
        case Constants.StorageGroupTypes.Site:
          storageGroupType = "Site";
          break;
        case Constants.StorageGroupTypes.Warehouse:
          storageGroupType = "Warehouse";
          break;
        case Constants.StorageGroupTypes.ZoneGroup:
          storageGroupType = "Zone group";
          break;
        case Constants.StorageGroupTypes.Zone:
          storageGroupType = "Zone";
          break;
      }
      (x as any).storageGroupType = storageGroupType;
    });

  }

  public async close() {
    await this.dialogController.cancel();
  }

  public chooseStorageGroup(storageGroup: Zeus.Web.Model.StorageGroup) {
    this.dialogController.ok(storageGroup);
  }
}
