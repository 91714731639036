import { CustomLogger, ViewModelBase } from 'digiwall-lib';
import { HttpClient } from 'aurelia-fetch-client';
import { computedFrom } from "aurelia-binding";
import { autoinject, TaskQueue } from 'aurelia-framework';
import { Router } from 'aurelia-router';

type GigasoftMigrationDto = {
  connectionString: string;
  xLocationIsAlias: boolean;
  yLocationIsAlias: boolean;
};

@autoinject
export class GigasoftMigration extends ViewModelBase {

  private configuration: GigasoftMigrationDto = {
    connectionString: "Data Source=LOCALHOST;Initial Catalog=ExtV53;Integrated Security=True;MultipleActiveResultSets=True;TrustServerCertificate=True",
    xLocationIsAlias: false,
    yLocationIsAlias: false
  }

  private pollerId: number;
  private state: { Progress: number, Status: string, JobId: string };
  private logs: string[] = [];

  constructor(router: Router, logger: CustomLogger, private httpClient: HttpClient, private taskQueue: TaskQueue) {
    super(router, logger);
  }

  @computedFrom('logs.length')
  private get logString(): string {
    return this.logs.join("\n");
  }

  // Expected connectionstrings:
  // Data Source={dataSrc};Initial Catalog={dbName};User ID={userName};Password={password};
  // Data Source={dataSrc};Initial Catalog={dbName};Integrated Security=True;
  @computedFrom('configuration.connectionString')
  private get canStart(): boolean {
    return this.configuration.connectionString?.length > 0;
  }

  private async startMigration() {
    if (false == this.canStart) {
      return;
    }

    let url = '/api/migration/start';
    let response = await this.httpClient.post(url, JSON.stringify(this.configuration));
    if (response.ok) {
      // Cancel previous
      this.cancelMigration();

      let json: { JobId: string } = await response.json();
      await this.poll(json.JobId);
      this.pollerId = window.setInterval(async () => await this.poll(json.JobId), 5000);
    }
  }

  private stopPoller() {
    if (this.pollerId) {
      window.clearInterval(this.pollerId);
      this.pollerId = null;
    }
    this.state = null;
    this.logs.splice(0);
  }

  private async cancelMigration() {
    if (this.state?.JobId) {
      let response = await this.httpClient.post('/api/migration/cancel?jobId=' + this.state.JobId);
      if (response.ok) {
        this.stopPoller();
      }
    } else {
      this.stopPoller();
    }
  }

  private async poll(jobId: string) {
    let response = await this.httpClient.get('/api/migration/getProgress?jobId=' + jobId);
    if (response.ok) {
      this.state = await response.json();
      this.logs.splice(0);
      this.logs.push(...JSON.parse(this.state.Status));
      if (this.state.Status == 'DONE') {
        this.stopPoller();
      }
    }
  }

  get ribbonHeaderText(): string {
    return '';
  }
  get ressourceName(): string {
    return '';
  }
}
