.table-wo-line {
  display: block;
  width: auto;
}
.table-wo-line tbody {
  display: table-row-group;
  overflow-y: scroll;
}
.table-wo-line td,
.table-wo-line th {
  padding: 5px;
  text-align: center;
}
.table-wo-line ::-webkit-scrollbar-thumb {
  background: lightgray;
}

.date-filter-radio {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: all !important;
  margin: 10px;
  transform: scale(1.5);
}

.date-filter-label {
  font-size: 16px;
}

.bold-text {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.width-text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
}

.long-ui-select-options .select2-results__options {
  max-height: 50vh !important;
}

.ui-white-space--normal {
  white-space: normal;
  &.ui-table__cell {
    white-space: normal !important;
    > div {
      max-height: 12rem;
    }
  }
}

.inner-html-div-ui-alert {
  > p {
    margin: 0;
  }
}

ui-field > label {
  overflow: hidden;
  text-overflow: ellipsis;
}

.element-blocked {
  color: #8e8e8e !important;
}

.ui-menu__item__link[data-disabled="true"]:hover {
  cursor: not-allowed;
}

ui-menu-item:hover {
  cursor: not-allowed;
}

.theme-light ui-panel.ui-table-panel .ui-panel__body ui-table .ui-table__cell > div,
.theme-light ui-accordeon.ui-accordeon > .ui-accordeon-item > .ui-accordeon-item__body ui-table .ui-table__cell > div {
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.ui-input__error > ul {
  top: 6px;
  left: 30px;
}

.check-box-extra-small-top {
  margin-top: 5px;
}
