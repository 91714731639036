import { InventoryArticleFloatingBox } from './../../floating-box/inventory/inventory-article-floating-box';
import { Container, TaskQueue, computedFrom } from "aurelia-framework";
import { customElement } from "aurelia-templating";
import { ServiceBase } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../../../constants';
import { InventoryBaseActions } from './inventory-base-actions';
import { InventoryBaseFloatingBox } from 'work-orders/floating-box/inventory/inventory-base-floating-box';
import { InventoryArticleLocationsFloatingBox } from 'work-orders/floating-box/inventory/inventory-article-locations-floating-box';

@customElement('inventory-article-actions')
export class InventoryArticleActions extends InventoryBaseActions<Zeus.Web.Model.InventoryWorkOrderArticle> {

  constructor() {
    super(new ServiceBase<Zeus.Web.Model.InventoryWorkOrderArticle>(Constants.EntityTypeNames.InventoryWorkOrderArticle));
    let taskQueue = Container.instance.get(TaskQueue);
    this.disposables.push(
      this.bindingEngine.expressionObserver(this, 'workOrder.limitedToStorageGroupId').subscribe(() => {
        // Refresh calculation of article links location counts
        if (this.workOrder.inventoryLinks?.length > 0) {
          let links = this.workOrder.inventoryLinks.splice(0);
          taskQueue.queueMicroTask(() => this.workOrder.inventoryLinks.push(...links));
        }
      })
    );
  }

  @computedFrom("workOrder.inventoryTargetId")
  private get isFromArticleLocation() {
    return this.workOrder.inventoryTargetId == Constants.InventoryTarget.ArticleLocations;
  }

  protected async editLink(entity: Zeus.Web.Model.InventoryWorkOrderArticle) {
    if (this.workOrder.id <= 0) {
      let saveOk = await this.saveWorkOrder();
      if (saveOk == false) {
        return;
      }
    }
    this.box.showFloatingBox(new InventoryArticleLocationsFloatingBox(this.workOrder, entity)).whenClosed(async response => {
      if (false == response.wasCancelled && response.output != null) {
        await this.service.getEntityById(response.output.id, ...this.getExpands(true));
      }
    });
  }

  protected getExpands(withLocations: boolean = false): string[] {
    let expands = [];
    if (this.isFromArticleLocation) {
      expands = ['locationForInventories'];
    }
    return [...expands,
    ...withLocations
      ? ['article.articleLocations.storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroup']
      : ['article']
    ];
  }

  protected setNumberOfLocations(entity: Zeus.Web.Model.InventoryWorkOrderArticle): Zeus.Web.Model.InventoryWorkOrderArticle {
    if (!this.isFromArticleLocation) {
      (entity as any).nbrLocations = this.getNbArticleLocationsForArticle(entity);
    }
    return entity;
  }

  protected getFloatingBoxInstance(): InventoryBaseFloatingBox<Zeus.Web.Model.InventoryWorkOrderArticle> {
    return this.isFromArticleLocation
      ? new InventoryArticleLocationsFloatingBox(this.workOrder)
      : new InventoryArticleFloatingBox(this.workOrder);
  }

  protected async onAdded(newLink: Zeus.Web.Model.InventoryWorkOrderArticle) {
    // If adding lotnumber null, remove all links with a lotnumber
    if (!this.isFromArticleLocation && newLink.lotNumber == null) {
      let linksToDelete: Zeus.Web.Model.InventoryWorkOrderArticle[] = this.workOrder.inventoryLinks.filter(
        (inv: Zeus.Web.Model.InventoryWorkOrderArticle) => inv.articleId == newLink.articleId && inv.lotNumber != null
      ) as Zeus.Web.Model.InventoryWorkOrderArticle[];
      if (linksToDelete.length > 0) {
        linksToDelete.forEach(l => this.inventoryLinks.splice(this.inventoryLinks.findIndex(x => x.id == l.id), 1));
        await this.service.deleteEntities(linksToDelete, true, null, true);
      }
    }
  }

  private getNbArticleLocationsForArticle(articleLink: Zeus.Web.Model.InventoryWorkOrderArticle): number {
    let filter: (al: Zeus.Web.Model.ArticleLocation) => boolean;
    if (articleLink.lotNumber?.length > 0) {
      filter = al => al.lotNumber === articleLink.lotNumber;
    } else {
      filter = al => true;
    }
    return articleLink.article.articleLocations.filter(filter).filter(al =>
      !this.workOrder.limitedToStorageGroupId || al.storage.storageGroupId == this.workOrder.limitedToStorageGroupId || al.storage.storageGroup.parentStorageGroupId == this.workOrder.limitedToStorageGroupId
      || al.storage.storageGroup.parentStorageGroup.parentStorageGroupId == this.workOrder.limitedToStorageGroupId || al.storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroupId == this.workOrder.limitedToStorageGroupId
    ).length;
  }
}
