import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';
import { GraphicalLedBar } from "../generated";
import { ColDef } from "ag-grid-community";
import * as GLBConstants from '../constants';
import * as Constants from '../../../constants';

@autoinject
export class GLBConfigurationList extends ListViewModelBase<GraphicalLedBar.Model.GLBConfiguration> {
  public ressourceName: string = GLBConstants.EntityTypeNames.GLBConfiguration;

  constructor(router: Router, logger: CustomLogger) {
    super(router, logger, new ServiceBase<GraphicalLedBar.Model.GLBConfiguration>(GLBConstants.EntityTypeNames.GLBConfiguration));
    this.service.gridDataSource.expands = ['wOMessageType.workOrderType', 'wOMessageType.orderSubtype'];
  }

  public getDetailsUrl(self, entity: GraphicalLedBar.Model.GLBConfiguration) {
    if (entity && entity.id) {
      return `/glb-configuration/${entity.id}`;
    }
    return null;
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("glbconfiguration.wOMessageTypeId"),
        field: "wOMessageType.displayName",
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser, { baseColDef: { hide: false } }),
    ];
    return defs;
  }
}
