import { Zeus } from 'generated';
import moment = require('moment');

export class CellRenderer {
  public dateRenderer(data: any, format: string) {
    return(data.value == null) ? "" : moment(data.value).format(format);
  }

  public supplierAddressRenderer(supplier: Zeus.Web.Model.Supplier) {
    if (supplier == null) return "";

    return [supplier.street, supplier.postalCode, supplier.city,supplier.country?.name?._translation].filter(x => x != null).join(" - ");
  }
}
