.work-order-create-shortcuts {
  text-align: center;
  > .col {
    padding: 0.75rem !important;
    > ui-button {
      height: 4.5rem;
      border: 0;
      margin: auto;
      cursor: pointer;
      box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
      font-size: 1.5rem;
      &:hover {
        -moz-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
        -webkit-box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
      }
      .ui-btn {
        grid-template-columns: auto 1fr auto auto;
        text-align: left;
      }
    }
  }
}
