import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper, Actions } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom, Container } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { ListViewModelActionHistoryFilter } from 'action-histories/action-history-list';
import { DialogService } from 'aurelia-dialog';
import { ImportXLS } from './import-xls/import-xls';
import { WorkOrderCreateShortcutsModal } from './create-shortcuts/work-order-create-shortcuts-modal';
import { subscribeImportRefresh } from 'import-xls/import-xls';
import { WorkOrderUtils } from 'utils/work-order-utils';
import { EntityType } from 'extension-hooks/custom-properties/custom-properties-model';
import { CustomPropertiesExtensionService } from 'extension-hooks/custom-properties/custom-properties-extension-service';

@autoinject
export class WorkOrderList extends ListViewModelBase<Zeus.Web.Model.WorkOrder> {
  public ressourceName: string = Constants.EntityTypeNames.WorkOrder;

  private projectService: ServiceBase<Zeus.Web.Model.Project>;
  private costCenterService: ServiceBase<Zeus.Web.Model.CostCenter>;

  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private dialogService: DialogService) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.WorkOrder>(Constants.EntityTypeNames.WorkOrder));
    this.service.gridDataSource.expands = ['limitedToStorageGroup', 'workOrderStatus', 'workOrderType',
      'orderSubtype', 'workOrderPriority', 'inventoryTarget', 'project', 'costCenter', 'customProperties'];
  }

  private openCreateWorkOrder() {
    this.dialogService.open({ viewModel: WorkOrderCreateShortcutsModal });
  }

  @computedFrom('authService.currentUser.id')
  public get canAdd() {
    return WorkOrderUtils.hasActionAccessOnAnyType(this.authService, Actions.Create);
  }

  @computedFrom('authService.currentUser.id')
  public get canUpdate() {
    return WorkOrderUtils.hasActionAccessOnAnyType(this.authService, Actions.Update);
  }

  @computedFrom('authService.currentUser.id')
  public get canDelete() {
    return WorkOrderUtils.hasActionAccessOnAnyType(this.authService, Actions.Delete);
  }

  @computedFrom('canDelete', 'selectedEntities.length')
  public get showDelete(): boolean {
    return this.canDelete
      && this.selectedEntities.length > 0
      && false == this.selectedEntities.some(x => x.workOrderStatusId != Constants.WorkOrderStatus.InConstruction)
      && this.selectedEntities.filter(x => this.authService.checkPermissonAccess({ resource: WorkOrderUtils.getResourceForType(x), action: Actions.Delete })).length == this.selectedEntities.length;
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.WorkOrder) {
    if (entity && entity.id) {
      let url = '/work-orders/' + entity.id;
      return url;
    }
    return null;
  }

  async attached() {
    await super.attached();
    const importTypes = [
      Constants.DataFileImportType.InputWorkOrder,
      Constants.DataFileImportType.PickingWorkOrder,
      Constants.DataFileImportType.InventoryWorkOrder
    ]
    this.disposables.push(subscribeImportRefresh(this.gridOptions, ...importTypes));
  }

  public importWorkOrder() {
    this.dialogService.open({ viewModel: ImportXLS }).whenClosed((result) => {
      if (!result.wasCancelled && result.output as boolean) {
        this.gridOptions.api.refreshInfiniteCache();
      }
    });
  }

  public openActionHistory(): void {
    if (this.selectedEntities.length > 0) {
      let ids = this.selectedEntities.map(e => e.id);
      let filter = new ListViewModelActionHistoryFilter('workOrder', ids);
      this.navigateTo('action-history/all/' + filter.encode());
    }
  }

  public getDataGridColumns() {
    this.projectService = new ServiceBase<Zeus.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.costCenterService = new ServiceBase<Zeus.Web.Model.CostCenter>(Constants.EntityTypeNames.CostCenter);

    return [
      GridHelper.getSelectedColDef(this),
      {
        headerName: this.i18n.tr("workorder.id"),
        field: "id",
        type: FieldType.Number,
        sort: 'desc'
      },
      {
        headerName: this.i18n.tr("workorder.limitedToStorageGroupId"),
        field: "limitedToStorageGroup.name",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workorder.workOrderPriorityId"),
        field: "workOrderPriority.name._translation",
        cellRenderer: 'enumerationRenderer',
        type: FieldType.OneToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          serviceName: Constants.EntityTypeNames.WorkOrderPriority,
        }
      },
      {
        headerName: this.i18n.tr("workorder.workOrderName"),
        field: "workOrderName",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workorder.requestedBy"),
        field: "requestedBy",
        type: FieldType.String
      },
      {
        headerName: this.i18n.tr("workorder.workOrderStatusId"),
        field: "workOrderStatus.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.WorkOrderStatus,
        },
      },
      {
        headerName: this.i18n.tr("workorder.isTemplate"),
        field: "isTemplate",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("workorder.isFromERP"),
        field: "isFromERP",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("workorder.generateDeliveryNoteWhenFinished"),
        field: "generateDeliveryNoteWhenFinished",
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("workorder.workOrderTypeId"),
        field: "workOrderType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.WorkOrderType,
        },
      },
      {
        headerName: this.i18n.tr("workorder.orderSubtypeId"),
        field: "orderSubtype.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.OrderSubtype,
        },
      },
      {
        headerName: this.i18n.tr("workorder.inventoryTargetId"),
        field: "inventoryTarget.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.InventoryTarget,
        },
      },
      {
        headerName: this.i18n.tr("workorder.projectId"),
        field: "project.projectName",
        hide: true,
        type: FieldType.OneToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.projectService
        }
      },
      {
        headerName: this.i18n.tr("workorder.costCenterId"),
        field: "costCenter.description",
        hide: true,
        type: FieldType.OneToMany,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          service: this.costCenterService
        }
      },
      {
        headerName: this.i18n.tr("workorder.includeInAutoLaunch"),
        field: "includeInAutoLaunch",
        hide: true,
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("workorder.pickLabelRequired"),
        field: "pickLabelRequired",
        hide: true,
        type: FieldType.Boolean
      },
      {
        headerName: this.i18n.tr("workorder.pickLabelTemplate"),
        field: "pickLabelTemplate",
        hide: true,
        type: FieldType.String
      },
      ...Container.instance.get(CustomPropertiesExtensionService).getColDefs(this, this.gridOptions, EntityType.WorkOrder),
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
  }
} 
