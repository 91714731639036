import { AppModuleRoot, UnkownAppModuleRoot } from './app-module-root';
import { AppModuleEnum } from './constants';

export abstract class AppModuleProvider {

  private moduleRoots: Map<AppModuleEnum, AppModuleRoot>;

  constructor() {
    this.moduleRoots = new Map<AppModuleEnum, AppModuleRoot>();
  }

  protected register(appModule: AppModuleEnum, appModuleRoot: AppModuleRoot) {
    this.moduleRoots.set(appModule, appModuleRoot);
  }

  public get(module: AppModuleEnum) {
    if (false == this.moduleRoots.has(module)) {
      console.info(`Module ${AppModuleEnum[module]} not registered`);
      return new UnkownAppModuleRoot(module);
    }
    return this.moduleRoots.get(module);
  }

  public all(callback: (x: AppModuleRoot) => void) {
    this.moduleRoots.forEach(callback);
  }

  public getRootMap() {
    return this.moduleRoots;
  }
}
